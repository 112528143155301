import {Col, Row, Table} from "react-bootstrap";
import Box from "../../widget/Box";
import formatDateTime from "../../date/formatDateTime";
import React, {memo} from "react";
import TableData from "../../common/TableData";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles({
    tableContainer: {
        background: "rgb(255,255,255)",
        padding: "20px",
    },
    titleRow: {
        padding: "10px 5px",
    },
    headerText: {
        verticalAlign: "middle",
    },
});

interface Props extends Common {
    notifMessage: string;
    notifEvents: Array<NotifyEventsObject>;
}

interface EventTable extends Common {
    notifEvents: Array<NotifyEventsObject>;
    styles: any;
}

export interface NotifyEventsObject {
    _id: string;
    userIdentifier: string;
    timestamp: string;
    deviceIdentifier: string;
}

interface Common {
    eventsTableColumnNames: Array<string>;
    eventsTableTitle: string;
}

const TitleSection = ({
    sectionName,
    title,
    styles,
}: {
    sectionName: string;
    title: string;
    styles: any;
}): JSX.Element => {
    return (
        <Box
            removeBodyPadding
            removeHeaderBorder
            solid
            styleState="default"
            title={sectionName}
        >
            <Row className={styles.titleRow}>
                <Col md={1}>
                    <strong>TITLE:</strong>
                </Col>
                <Col md={11}>{title}</Col>
            </Row>
        </Box>
    );
};

const EventsTable = ({
    notifEvents,
    eventsTableColumnNames,
    eventsTableTitle,
    styles,
}: EventTable): JSX.Element => {
    return (
        <Box
            removeBodyPadding
            removeHeaderBorder
            solid
            styleState="default"
            title={eventsTableTitle}
        >
            <Table
                bordered
                hover
                responsive
                striped
                className={styles.tableContainer}
            >
                <thead>
                    <tr>
                        {eventsTableColumnNames.map((columnName: string) => (
                            <th key={columnName} className={styles.headerText}>
                                {columnName}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {notifEvents.map((notifEvent: NotifyEventsObject) => {
                        return <TableRow notificationEvent={notifEvent} />;
                    })}
                </tbody>
            </Table>
        </Box>
    );
};

const TableRow = ({
    notificationEvent,
}: {
    notificationEvent: NotifyEventsObject;
}): JSX.Element => {
    const key = notificationEvent._id; // eslint-disable-line no-underscore-dangle

    return (
        <tr key={key}>
            <TableData>
                {notificationEvent.userIdentifier || "Anonymous User"}
            </TableData>
            <TableData>{formatDateTime(notificationEvent.timestamp)}</TableData>
        </tr>
    );
};

export default memo(function EventsListView({
    notifMessage,
    notifEvents,
    eventsTableColumnNames,
    eventsTableTitle,
}: Props): JSX.Element {
    const classes = useStyles();

    return (
        <div>
            <TitleSection
                sectionName="Push Notification"
                title={notifMessage}
                styles={classes}
            />
            <EventsTable
                notifEvents={notifEvents}
                eventsTableColumnNames={eventsTableColumnNames}
                eventsTableTitle={eventsTableTitle}
                styles={classes}
            />
        </div>
    );
});
