import {Col} from "react-bootstrap";
import DetailRow from "../../widget/DetailRow";
import formatDateTime from "../../date/formatDateTime";
import React, {memo} from "react";

interface Props {
    schedule: any;
    createdAt: string;
}

export default memo(function Schedule({
    schedule,
    createdAt,
}: Props): JSX.Element {
    const hasSchedule = Boolean(schedule);
    const dateToSend = hasSchedule
        ? schedule.getIn(["conditions", 0, "value"])
        : null;
    return (
        <Col md={8}>
            <DetailRow label="CREATED AT:">
                {formatDateTime(createdAt)}
            </DetailRow>
            {hasSchedule && (
                <DetailRow label="SEND AT:">
                    {formatDateTime(dateToSend)}
                </DetailRow>
            )}
        </Col>
    );
});
