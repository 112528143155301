import apiClient from "./legacyApiClient";
import {loadUser} from "../main/repository";

export default (state) => {
    const userData = loadUser();
    const credentials = {
        username: userData.emailAddress,
        password: userData.password,
        teamNumber: userData.activeTeam.teamNumber,
    };

    return apiClient({
        ...credentials,
        urlPrefix: "/v1",
    });
};
