/*  */

import {action, observable} from "mobx";
import {observer} from "mobx-react";
import ContainerDimensions from "react-container-dimensions";
import createCSVDataFromMultiLineChart from "../createCSVDataFromMultiLineChart";
import DashboardWidget from "../../widgets/DashboardWidget";
import FilteredChart from "./FilteredChart";
import ExportableChartContainer from "../ExportableChartContainer";
import formatNumber from "../../../common/formatNumber";
import immutable from "immutable";
import MultiLineChart from "../../../widget/chart/MultiLineChart";
import React from "react";
import sumOfMapChartValue from "../sumOfMapChartValue";

const colorScheme = ["3c8dbc"];
const CHART_PADDING = {top: 60, bottom: 100};
const AXIS_TITLES = {
    y: "Order Size (£)",
};

const styles = Object.freeze({
    widgetContainer: {
        display: "block",
        textAlign: "center",
        margin: "0 auto",
    },
    boxStyle: {
        height: "inherit",
    },
});

const ACTIVE_PURCHASERS_AVERAGE_ORDER_SIZE =
    "Illustrates the total order size of active purchasers on a daily basis.";

@observer
export default class ActivePurchasersAverageOrderSize extends FilteredChart {
    @observable
    chartData = immutable.List();

    @action
    onDataDidLoad(chartData) {
        if (sumOfMapChartValue(chartData) === 0) {
            throw new Error("Chart data is empty.");
        }

        this.chartData = immutable.List([
            immutable.Map({
                dataSets: immutable.List(chartData),
                lineChartName: "ActivePurchasersAverageOrderSize",
            }),
        ]);
    }

    render() {
        const {chartData, status} = this;

        return (
            <ContainerDimensions>
                {({width}) => (
                    <ExportableChartContainer
                        csvData={createCSVDataFromMultiLineChart(
                            chartData.toJS(),
                            {
                                xLabel: "Date",
                                yLabel: AXIS_TITLES.y,
                            },
                        )}
                        infoPopOverText={ACTIVE_PURCHASERS_AVERAGE_ORDER_SIZE}
                        style={styles.boxStyle}
                        title="Active Purchasers Average Order Size"
                    >
                        <DashboardWidget
                            status={status}
                            style={styles.widgetContainer}
                        >
                            <MultiLineChart
                                axisTitles={AXIS_TITLES}
                                chartData={chartData}
                                chartPadding={CHART_PADDING}
                                colorScheme={colorScheme}
                                height={400}
                                interpolate
                                showEngagement
                                solidArea
                                width={width}
                                yAxis={{
                                    title: "£",
                                    tickFormat: (tick) =>
                                        `£${formatNumber(tick)}`,
                                }}
                            />
                        </DashboardWidget>
                    </ExportableChartContainer>
                )}
            </ContainerDimensions>
        );
    }
}
