export const ADVERT_TITLES = Object.freeze({
    interstitialAds: "Full Screen Ads",
    interstitial: "Full Screen Ads",
    interstitialAdsEntrance: "Interstitial Entrance",
    banner: "Banner Ads",
    productList: "Product List",
    newProducts: "Home Screen New Products Placement",
    offersForYou: "Home Screen Offers for You Products Placement",
    trendingProducts: "Home Screen Trending Products Placement",
    relatedProducts: "Related Products",
    softDrinksProducts: "Soft Drinks Products",
    confectioneryProducts: "Confectionery Products",
    alcoholProducts: "Alcohol Products",
    tobaccoProducts: "Tobacco Products",
    groceryProducts: "Grocery Products",
    householdProducts: "Household Products",
    homeBanner1: "Home Screen Top Banner Ads",
    homeBanner2: "Home Screen Middle Banner Ads",
    homeBanner3: "Home Screen Bottom Banner Ads",
    "Home Banner 1": "Home Screen Top Banner Ads",
    "Home Banner 2": "Home Screen Middle Banner Ads",
    "Home Banner 3": "Home Screen Bottom Banner Ads",
    categoryBannerConfectionery: "Confectionery Banner Ads",
    categoryBannerEnergyDrinks: "Energy Drinks Banner Ads",
    categoryBannerGrocery: "Grocery Banner Ads",
    categoryBannerCrispsSnacks: "Crisps & Snacks Banner Ads",
    categoryBannerSoftDrinks: "Soft Drinks Banner Ads",
    categoryBannerLicensedTrade: "Licensed Trade Banner Ads",
    categoryBannerSeasonal: "Seasonal Banner Ads",
    categoryBannerNonFood: "Non Food Banner Ads",
    categoryBannerSmokersAccessories: "Smoker's Accessories Banner Ads",
    categoryBannerToiletries: "Toiletries Banner Ads",
    categoryBannerPromotions: "Promotions Banner Ads",
    categoryBannerSchoolApproved: "School Approved Banner Ads",
    categoryBannerTobacco: "Tobacco Banner Ads",
    categoryBannerAlcohol: "Alcohol Banner Ads",
    categoryBannerCosmetics: "Cosmetics Banner Ads",
    categoryBannerPersonalCare: "Personal Care Banner Ads",
    categoryBannerSnacking: "Snacking Banner Ads",
    categoryBannerChilled: "Chilled Banner Ads",
    categoryBannerHousehold: "Household Banner Ads",
    categoryBannerWater: "Water Banner Ads",
    categoryProductsConfectionery: "Confectionery Products Carousel",
    categoryProductsEnergyDrinks: "Energy Drinks Products Carousel",
    categoryProductsGrocery: "Grocery Products Carousel",
    categoryProductsCrispsSnacks: "Crisps & Snacks Products Carousel",
    categoryProductsSoftDrinks: "Soft Drinks Products Carousel",
    categoryProductsLicensedTrade: "Licensed Trade Products Carousel",
    categoryProductsSeasonal: "Seasonal Products Carousel",
    categoryProductsNonFood: "Non Food Products Carousel",
    categoryProductsSmokersAccessories:
        "Smoker's Accessories Products Carousel",
    categoryProductsToiletries: "Toiletries Products Carousel",
    categoryProductsPromotions: "Promotions Products Carousel",
    categoryProductsSchoolApproved: "School Approved Products Carousel",
    categoryProductsTobacco: "Tobacco Products Carousel",
    categoryProductsAlcohol: "Alcohol Products Carousel",
    categoryProductsCosmetics: "Cosmetics Products Carousel",
    categoryProductsPersonalCare: "Personal Care Products Carousel",
    categoryProductsSnacking: "Snacking Products Carousel",
    categoryProductsChilled: "Chilled Products Carousel",
    categoryProductsHousehold: "Household Products Carousel",
    categoryProductsWater: "Water Products Carousel",
});
