export default (
    chartData: Array<any>,
    {xLabel, yLabel}: {xLabel: string; yLabel: string},
): Array<any> =>
    chartData.reduce(
        (
            csvData: Array<any>,
            lineChartData: {dataSets: any; lineChartName: string},
        ) => {
            const dataSets = lineChartData.dataSets;
            const lineChartName = lineChartData.lineChartName;
            const lineChartDataValues = dataSets.reduce(
                (
                    lineChartDataArray: Array<any>,
                    lineChartDataItem: {x: string; y: number},
                ) => {
                    lineChartDataArray.push([
                        lineChartDataItem.x,
                        lineChartDataItem.y,
                    ]);

                    return lineChartDataArray;
                },
                [],
            );

            csvData.push([lineChartName]);
            csvData.push([xLabel, yLabel]);

            return csvData.concat(lineChartDataValues);
        },
        [],
    );
