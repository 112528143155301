/*  */

import {action, observable} from "mobx";
import {inject, observer} from "mobx-react";
import moment from "moment";
import React, {PureComponent} from "react";
import {VictoryScatter} from "victory";

import getDataPointsForScatterGraph from "./getDataPointsForScatterGraph";

class EngagementTooltip extends PureComponent {
    static TOOLTIP_WIDTH = 200;
    static TOOLTIP_NOTIFICATIONS_LIMIT = 5;
    static TOOLTIP_SIZE = 20;
    static TOOLTIP_PADDING = 8;

    static styles = Object.freeze({
        engagementTooltipContainer: {
            borderStyle: "solid",
            backgroundColor: "white",
            borderWidth: 1,
            textAlign: "center",
            padding: EngagementTooltip.TOOLTIP_PADDING,
            marginTop: 15,
            position: "absolute",
            left:
                (EngagementTooltip.TOOLTIP_WIDTH / 2 -
                    EngagementTooltip.TOOLTIP_PADDING) *
                -1,
            width: EngagementTooltip.TOOLTIP_WIDTH,
        },
        tooltipTriangle: {
            position: "absolute",
            height: EngagementTooltip.TOOLTIP_SIZE,
            width: EngagementTooltip.TOOLTIP_SIZE,
            overflow: "visible",
        },
    });

    renderEngagementLine({id, sendTime, type}) {
        const engagementTime = moment(sendTime).format("h:mm A"); // eslint-disable-line moment-utc/no-moment-without-utc

        return (
            <a key={id} href={`#/${type}/${id}`}>
                Push Notification at {engagementTime}
                <br />
            </a>
        );
    }

    render() {
        const {
            datum,
            engagementData,
            x,
            y,
            setIsActiveTooltip,
            currentTooltip,
        } = this.props;
        const engagementDataForTooltip = engagementData[datum.x];

        if (!engagementDataForTooltip) {
            return null;
        }

        const engagementQuantity = engagementDataForTooltip.length;
        const {TOOLTIP_WIDTH} = EngagementTooltip;
        const {TOOLTIP_NOTIFICATIONS_LIMIT} = EngagementTooltip;
        const size = EngagementTooltip.TOOLTIP_SIZE;

        return (
            <a
                key={x}
                cursor="pointer"
                pointerEvents="all"
                className="tooltip-link"
            >
                <foreignObject
                    onMouseOver={() => setIsActiveTooltip(x)}
                    onMouseLeave={() => setIsActiveTooltip(0)}
                    style={EngagementTooltip.styles.tooltipTriangle}
                    height={size}
                    width={size}
                    x={x - size / 2}
                    y={y}
                >
                    <div
                        style={{
                            display: currentTooltip === x ? "block" : "none",
                            width: TOOLTIP_WIDTH,
                        }}
                    >
                        <div
                            className="graph-tooltip"
                            style={
                                EngagementTooltip.styles
                                    .engagementTooltipContainer
                            }
                        >
                            <b>{datum.x}</b>
                            <br />
                            {engagementDataForTooltip
                                .slice(0, TOOLTIP_NOTIFICATIONS_LIMIT)
                                .map(this.renderEngagementLine)}
                            {engagementQuantity >
                            TOOLTIP_NOTIFICATIONS_LIMIT ? (
                                <span>
                                    <br />
                                    and{" "}
                                    {engagementQuantity -
                                        TOOLTIP_NOTIFICATIONS_LIMIT}{" "}
                                    more...
                                </span>
                            ) : null}
                        </div>
                    </div>
                </foreignObject>
            </a>
        );
    }
}

@inject("tooltipRepository")
@observer
export default class EngagementScatterGraph extends React.Component {
    static VERTICAL_OFFSET_PERCENT = 0.15;

    @observable
    currentDisplayedTooltip = 0;

    @action.bound
    setCurrentTooltip = (value) => {
        this.currentDisplayedTooltip = value;
    };

    render() {
        const {
            calculateCoordinateRange,
            dataPoints,
            fillStyle,
            useRecentEngagementData,
            tooltipRepository,
            ...props
        } = this.props;

        const coordinateRange = calculateCoordinateRange(dataPoints);
        const yCoordinate =
            coordinateRange.upper +
            Math.abs(coordinateRange.upper - coordinateRange.lower) *
                EngagementScatterGraph.VERTICAL_OFFSET_PERCENT;

        const engagementData = useRecentEngagementData
            ? tooltipRepository.dataForLastSevenDays
            : tooltipRepository.dataForDateRange;

        const engagementDataPoints = getDataPointsForScatterGraph(
            engagementData,
            yCoordinate,
        );

        if (!engagementDataPoints.length) {
            return null;
        }

        return (
            <VictoryScatter
                {...props}
                data={engagementDataPoints}
                size={6}
                style={{data: fillStyle, labels: fillStyle}}
                symbol="triangleDown"
                labelComponent={
                    <EngagementTooltip
                        currentTooltip={this.currentDisplayedTooltip}
                        setIsActiveTooltip={this.setCurrentTooltip}
                        engagementData={engagementData}
                    />
                }
                labels={() => null}
            />
        );
    }
}
