import {Col, FormControl, FormGroup, HelpBlock, Row} from "react-bootstrap";
import InputRow from "../widget/InputRow";
import React, {memo} from "react";

interface Props {
    label: string;
    value?: string;
    isFirst?: boolean;
    placeholder: string;
    onChangeValue: (value: string) => void;
    isError?: boolean;
    errorMessage?: string;
}

export default memo(function AudienceInputRow({
    value,
    onChangeValue,
    label,
    isFirst,
    placeholder,
    isError,
    errorMessage,
}: Props): JSX.Element {
    const validationState: Object = isError ? {validationState: "error"} : {};

    return (
        <Row>
            {isFirst ? null : <hr />}
            <Col sm={12}>
                <InputRow label={label}>
                    <FormGroup {...validationState}>
                        <FormControl
                            onChange={(event): void =>
                                onChangeValue(
                                    (event.target as HTMLInputElement).value,
                                )
                            }
                            placeholder={placeholder}
                            type="text"
                            value={value}
                        />
                        <HelpBlock>{errorMessage}</HelpBlock>
                    </FormGroup>
                </InputRow>
            </Col>
            {isFirst ? <hr /> : null}
        </Row>
    );
});
