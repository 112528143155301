import {useContext} from "react";
import AlertContext from "./AlertContext";
import * as R from "ramda";

const FIVE_SECONDS_IN_MS: number = 5000;

export default function () {
    const {alerts, setAlerts} = useContext(AlertContext);

    const removeAlert = (id: string) => {
        const alertsAfterRemoval = alerts.filter(
            (item: {id: string}) => item.id !== id,
        );

        setAlerts(alertsAfterRemoval);
    };

    const addTransient = (id: string): void => {
        window.setTimeout(() => removeAlert(id), FIVE_SECONDS_IN_MS);
    };

    const addAlert = (message: string, type: string) => {
        const id = String(Math.random() * 1000000);

        const newAlerts = R.prepend(
            {
                id,
                message,
                type,
            },
            alerts,
        );

        setAlerts(newAlerts);

        addTransient(id);
    };

    const addErrorAlert = (message: string) => {
        addAlert(message, "error");
    };

    const addSuccessAlert = (message: string) => {
        addAlert(message, "success");
    };

    return {
        alerts,
        addErrorAlert,
        addSuccessAlert,
        removeAlert,
    };
}
