import {useCallback} from "react";
import base64 from "base-64";
import useSession from "../main/useSession";
import URI from "urijs";
import * as R from "ramda";

const BASE_URL = "/v1/";
const METHODS_WITH_URL_PARAMS = ["GET", "DELETE"];
const THROW_RESPONSES = [404, 400];
const WITH_BODY_OPTION = ["POST", "PUT", "DELETE"];

enum RequestMethods {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    DELETE = "DELETE",
}

const getBodyOption = (
    method: RequestMethods,
    contentType: string,
    requestParams: any,
) => {
    if (contentType !== "multipart/form-data") {
        return WITH_BODY_OPTION.includes(method)
            ? {body: JSON.stringify(requestParams)}
            : {};
    }

    const formData = new FormData();

    R.mapObjIndexed((item: any, key: string) => {
        formData.append(key, item);
    }, requestParams);

    return {body: formData};
};

export default function useApiClient() {
    const {userData} = useSession();

    const networkRequest = useCallback(
        async ({
            path,
            method,
            params,
            contentType = "application/json",
            deleteRequestBody = {},
        }: {
            path: string;
            method: RequestMethods;
            params?: {[key: string]: any};
            contentType?: string;
            deleteRequestBody?: {[key: string]: any};
        }) => {
            const requestParams = {
                ...params,
                teamNumber: userData.activeTeam?.teamNumber,
            };

            const isUrlWithParams = METHODS_WITH_URL_PARAMS.includes(method);
            const baseURLWithPath = `${BASE_URL}${path}`;

            const url = isUrlWithParams
                ? URI(baseURLWithPath).query(requestParams).toString()
                : `${BASE_URL}${path}`;

            const contentTypeHeader =
                contentType === "multipart/form-data"
                    ? {}
                    : {"Content-Type": contentType};

            const result = await fetch(url, {
                method,
                // @ts-ignore
                headers: {
                    ...contentTypeHeader,
                    Authorization: `Basic ${base64.encode(
                        `${userData.emailAddress}:${userData.password}`,
                    )}`,
                },
                ...getBodyOption(method, contentType, {
                    ...(method === RequestMethods.DELETE
                        ? deleteRequestBody
                        : params),
                    teamNumber: userData.activeTeam?.teamNumber,
                }),
            });
            if (THROW_RESPONSES.includes(result.status)) {
                throw new Error(`Network request error ${result.status}`);
            }

            return result;
        },
        [userData],
    );

    const getRequest = useCallback(
        async (path, params = {}) => {
            const result = await networkRequest({
                path: path,
                method: RequestMethods.GET,
                params,
            });

            return result.json();
        },
        [networkRequest],
    );

    const postRequest = useCallback(
        async (path, params = {}) => {
            const result = await networkRequest({
                path: path,
                method: RequestMethods.POST,
                params,
            });

            return result.json();
        },
        [networkRequest],
    );

    const putRequest = useCallback(
        async (path, params = {}) => {
            const result = await networkRequest({
                path: path,
                method: RequestMethods.PUT,
                params,
            });

            return result.json();
        },
        [networkRequest],
    );

    const postWithFormDataRequest = useCallback(
        async (path, params = {}) => {
            const result = await networkRequest({
                path,
                method: RequestMethods.POST,
                params,
                contentType: "multipart/form-data",
            });

            return result.json();
        },
        [networkRequest],
    );

    const deleteRequest = useCallback(
        async (
            path: string,
            params?: {[key: string]: any},
            requestBody?: {[key: string]: any},
        ) => {
            await networkRequest({
                path,
                method: RequestMethods.DELETE,
                deleteRequestBody: requestBody,
                params,
            });

            return {success: true};
        },
        [],
    );
    return {
        getRequest,
        postRequest,
        postWithFormDataRequest,
        putRequest,
        deleteRequest,
    };
}
