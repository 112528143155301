export default (address: string): Promise<any> =>
    new Promise((resolve, reject) => {
        const googleMap = (<any>window).google.maps;
        const geocoder = new googleMap.Geocoder();

        geocoder.geocode({address}, (result: any, status: any) => {
            if (
                status !== googleMap.GeocoderStatus.OK ||
                !result ||
                result.length === 0
            ) {
                reject(status);

                return;
            }

            resolve(result[0].geometry.location);
        });
    });
