export const postGroupNotification = (httpAgent, payload) =>
    httpAgent.post("/groupedNotifications").send(payload);

export const getGroupedNotifications = (httpAgent, filters) =>
    httpAgent.get("/groupedNotifications").query(filters);

export const deleteGroupNotification = (httpAgent, payload) =>
    httpAgent.delete("/groupedNotifications").query(payload);

export const updateGroupNotifications = (httpAgent, body) =>
    httpAgent
        .put(`/groupedNotifications/${body._id}`) // eslint-disable-line no-underscore-dangle
        .send(body);
