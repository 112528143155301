import Box from "../widget/Box";
import React, {useEffect, useState, useCallback, useMemo} from "react";
import * as R from "ramda";
import {fromJS} from "immutable";
import useApiClient from "../apiClient/useApiClient";
import AdvertTable from "./AdvertTable";
import useFeatureFlag from "../featureFlags/useFeatureFlag";
import {
    BANNER,
    BANNER_PLACEMENT_TYPES,
    INTERSTITIAL,
    INTERSTITIAL_PLACEMENT_TYPES,
} from "./PlacementConstants";
import Pagination from "rc-pagination";
import {makeStyles} from "@material-ui/styles";
import getAdvertTitles from "./getAdvertTitles";
import useSession from "../main/useSession";
import {AdvertContentView} from "./AdvertsContainer";
import useAlert from "../alert/useAlert";

const useStyles = makeStyles({
    paginationButtons: {
        textAlign: "right",
    },
});

const PAGE_LIMIT = 10;

const DEFAULT_PAGE_INFO = Object.freeze({
    hasNext: false,
    currentPage: 1,
    numberOfPages: 1,
});

export default function ArchivedAdvertsLists({
    advertContentView,
}: {
    advertContentView: AdvertContentView;
}) {
    const classes = useStyles();
    const {userData} = useSession();
    const [archivedAdverts, setArchivedAdverts] = useState([]);
    const [pageInfo, setPageInfo] = useState(DEFAULT_PAGE_INFO);
    const {featureFlags} = useFeatureFlag();
    const {
        scheduledAdvert: scheduledAdvertEnabled,
        placements: advertFlags,
        placementLimits,
    } = featureFlags;
    const {getRequest} = useApiClient();
    const {addErrorAlert} = useAlert();

    const placementTypes = useMemo(() => {
        return advertContentView === AdvertContentView.ARCHIVED_FULLSCREEN
            ? INTERSTITIAL_PLACEMENT_TYPES.join(",")
            : BANNER_PLACEMENT_TYPES.join(",");
    }, [advertContentView]);

    const onFetchAdverts = useCallback(
        async (page: number = 1) => {
            try {
                const response = await getRequest("archivedAdverts", {
                    placementTypes,
                    limit: PAGE_LIMIT,
                    page,
                });
                setArchivedAdverts(response?.results);
                setPageInfo(response?.pageInfo);
            } catch {
                addErrorAlert(
                    "Something went wrong file fetching Archived Adverts, Please try again",
                );
            }
        },
        [placementTypes],
    );

    useEffect(() => {
        setArchivedAdverts([]);
        setPageInfo(DEFAULT_PAGE_INFO);
        onFetchAdverts(1);
    }, [advertContentView]);

    const onChangePage = useCallback(
        (page: number) => {
            onFetchAdverts(page);
        },
        [onFetchAdverts],
    );

    const seenCountEnabled = R.path(["trackingAdvertsInfo"])(advertFlags);

    // @ts-ignore
    const advertTitles = getAdvertTitles(userData?.activeTeam?.teamNumber);
    const advertTypeForTable =
        advertContentView === AdvertContentView.ARCHIVED_FULLSCREEN
            ? INTERSTITIAL
            : BANNER;

    // @ts-ignore
    const title = `Archived ${advertTitles[advertTypeForTable]}`;

    return (
        <Box title={title}>
            <AdvertTable
                advertFlags={advertFlags}
                placementName={advertTypeForTable}
                limit={placementLimits[advertTypeForTable]}
                scheduledAdvertEnabled={scheduledAdvertEnabled}
                hideButtons={true}
                hideMultiSelect={true}
                bannerAdvertDeepLinking={false}
                seenCountEnabled={seenCountEnabled}
                items={fromJS(archivedAdverts)}
                onSortEnd={() => null}
                showPlacementTypeField
                title={title}
            />
            <div className={classes.paginationButtons}>
                <Pagination
                    onChange={onChangePage}
                    total={pageInfo.numberOfPages * PAGE_LIMIT}
                    pageSize={PAGE_LIMIT}
                    current={pageInfo.currentPage}
                />
            </div>
        </Box>
    );
}
