interface PlatformMap {
    all: string;
    ios: string;
    android: string;
    [key: string]: string;
}

export const PLATFORM_MAP: PlatformMap = Object.freeze({
    all: "All",
    ios: "iOS",
    android: "Android",
});
