import ButtonWithIcon from "../common/buttons/ButtonWithIcon";
import PageHeader from "./PageHeader";
import React, {memo} from "react";
import classNames from "classnames";
import {makeStyles} from "@material-ui/styles";
import useNavigate from "@beam/use-navigate";

const useStyles = makeStyles(() => ({
    disabledPage: {
        fontSize: 20,
        textAlign: "center",
        paddingTop: "50px",
    },
    upgradeMessage: {
        fontSize: 18,
    },
    logo: {
        display: "block",
        margin: "20px auto",
        fontSize: 120,
    },
    getSupportLink: {
        marginTop: "20px",
    },
}));

const DEFAULT_MESSAGE =
    "Get in touch to talk to us about unlocking this feature";

interface Props {
    upgradeMessage?: string;
    subTitle?: string;
    title: string;
}

export default memo(function noFeatureAccessPage({
    upgradeMessage = DEFAULT_MESSAGE,
    subTitle,
    title,
}: Props): JSX.Element {
    const classes = useStyles();
    const {navigate} = useNavigate();

    return (
        <div>
            <PageHeader subTitle={subTitle} title={title} />
            <section className="content">
                <div className={classes.disabledPage}>
                    <i className={classNames("fa fa-lock", classes.logo)} />
                    <p>This feature is currently locked</p>
                    <div className={classes.upgradeMessage}>
                        {upgradeMessage}
                    </div>
                    <div className={classes.getSupportLink}>
                        <ButtonWithIcon
                            iconName="envelope-o"
                            onClick={() => navigate("/contact-us")}
                            size="lg"
                            title="Get in touch"
                            type="primary"
                        />
                    </div>
                </div>
            </section>
        </div>
    );
});
