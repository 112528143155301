/*  */

import {Col, Row} from "react-bootstrap";
import {} from "immutable";
import ColorScheme from "color-scheme";
import LegendList from "./LegendList";
import React, {PureComponent} from "react";
import StackedBarChart from "./StackedBarChart";

const styles = Object.freeze({label: {marginLeft: -40}});

const scheme = new ColorScheme();
const formatColorScheme = (colorScheme) =>
    colorScheme.map((color) => `#${color}`);

export default class StackedBarChartWithLegend extends PureComponent {
    render() {
        const {
            axisLabels,
            colorScheme,
            datasets,
            legends,
            ...props
        } = this.props;
        const defaultColorScheme = scheme.colors();
        const formattedColorScheme = formatColorScheme(defaultColorScheme);
        const colorScale = colorScheme ? colorScheme : formattedColorScheme;

        return (
            <Row>
                <Col md={11}>
                    <StackedBarChart
                        {...props}
                        axisLabels={axisLabels}
                        barColors={colorScale}
                        datasets={datasets}
                        legends={legends}
                    />
                </Col>
                <Col md={1} style={styles.label}>
                    <LegendList colorScheme={colorScale} legends={legends} />
                </Col>
            </Row>
        );
    }
}
