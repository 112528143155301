import {makeStyles, Tab, Tabs} from "@material-ui/core";
import {Well, FormGroup, FormControl, HelpBlock} from "react-bootstrap";
import InputRow from "../widget/InputRow";
import React, {useMemo} from "react";
import {AdvertFieldValues} from "./AddAdvert";
import MediaPreview from "./MediaPreview";
import {useCallback} from "react";
import {BANNER_PLACEMENT_TYPES} from "./PlacementConstants";
import {isValidUrl} from "../common/isValidUrl";

const useStyles = makeStyles(() => {
    return {
        container: {
            marginBottom: 20,
        },
        guidelines: {
            marginTop: 10,
            fontSize: 12,
        },
        tabsContainer: {
            width: "100%",
            display: "flex",
            justifyContent: "center",
        },
        tabs: {
            fontSize: 12,
        },
    };
});

const FILE_LIMIT = 2;

const ValidatedImagePreview = ({
    image,
    onRemoveImage,
}: {
    image: string | object;
    onRemoveImage: () => void;
}) => {
    if (image) {
        if (typeof image === "object") {
            return (
                <MediaPreview source={image} onRemoveMedia={onRemoveImage} />
            );
        } else if (typeof image === "string") {
            return (
                <MediaPreview
                    source={image}
                    isUrl={true}
                    onRemoveMedia={onRemoveImage}
                />
            );
        }
    }

    return null;
};

export enum MediaType {
    IMAGE_ADVERT = "IMAGE ADVERT",
    VIDEO_ADVERT = "VIDEO ADVERT",
}

interface ImageUploadProps {
    placementName: string;
    imageValidationState: any;
    imageErrorMessage: string;
    onSelectImage: (
        event: React.FormEvent<FormControl>,
        placementName: string,
        isVideoPreviewImage: boolean,
    ) => void;
    featureFlags: any;
    fieldValues: AdvertFieldValues;
    onChangeAdvertFields: (
        value: string | Date | null,
        fieldName: string,
    ) => void;
}
export const ImageUpload = ({
    featureFlags,
    imageValidationState,
    onSelectImage,
    placementName,
    fieldValues,
    imageErrorMessage,
    onChangeAdvertFields,
}: ImageUploadProps) => {
    const classes = useStyles();
    const {image} = fieldValues;
    const ref = React.useRef<HTMLInputElement | null>(null);

    const onRemoveImage = useCallback(() => {
        onChangeAdvertFields("", "image");
        if (ref?.current) {
            ref.current.value = "";
        }
    }, [ref]);

    return (
        <>
            <Well>
                <b>Image Upload Guidelines:</b>
                <ul className={classes.guidelines}>
                    <li>
                        Images should be in PNG, JPG, or animated GIF format.
                    </li>
                    <li>
                        NOTE: You should only use a single animated GIF when
                        displaying more than one Full Screen Ad (Interstitial).
                    </li>
                    <li>{`Images should not exceed the file size of ${FILE_LIMIT}MB.`}</li>
                    {featureFlags.relaxImageUploadRule ? null : (
                        <li>
                            Images should ideally be (and must not exceed)
                            800x1000 pixels for Full Screen Ads (Interstitials),
                            and 460x90 pixels for Banner Ads.
                        </li>
                    )}
                </ul>
            </Well>

            <InputRow label="Image:">
                <FormGroup {...imageValidationState}>
                    <FormControl
                        onChange={(event) =>
                            onSelectImage(event, placementName, false)
                        }
                        type="file"
                        // @ts-ignore
                        inputRef={ref}
                    />
                    <HelpBlock>{imageErrorMessage}</HelpBlock>
                </FormGroup>
            </InputRow>

            <ValidatedImagePreview
                image={image}
                onRemoveImage={onRemoveImage}
            />
        </>
    );
};

const VideoUpload = ({
    imageValidationState,
    onSelectImage,
    placementName,
    fieldValues,
    imageErrorMessage,
    onChangeAdvertFields,
}: ImageUploadProps) => {
    const classes = useStyles();
    const {videoPreviewImageUrl, videoUrl} = fieldValues;
    const ref = React.useRef<HTMLInputElement | null>(null);

    const onRemoveImage = useCallback(() => {
        onChangeAdvertFields("", "videoPreviewImageUrl");
        if (ref?.current) {
            ref.current.value = "";
        }
    }, [ref]);
    const videoUrlErrorMessage = useMemo((): string => {
        if (!isValidUrl(videoUrl)) {
            return "Invalid Video Url";
        }

        return "";
    }, [videoUrl]);
    const videoUrlValidationState: any = isValidUrl(videoUrl)
        ? {}
        : {validationState: "error"};

    const showPreviewImage = videoUrl && isValidUrl(videoUrl);
    return (
        <>
            <Well>
                <b>Video Upload Guidelines:</b>
                <ul className={classes.guidelines}>
                    <li>Video should be in MP4 or WebM video format.</li>
                    <li>
                        Video should be hosted on a publicly accessible server.
                    </li>
                    <li>
                        Preview Image: We suggest uploading a single frame of
                        the video as a preview image. Make sure this image is as
                        small as possible as it needs to load fast. The preview
                        image should be in JPG format.
                    </li>
                </ul>
            </Well>
            <InputRow label="Video:">
                <FormGroup {...videoUrlValidationState}>
                    <FormControl
                        onChange={(e) =>
                            onChangeAdvertFields(
                                (e.target as HTMLInputElement).value,
                                "videoUrl",
                            )
                        }
                        placeholder="Enter the video url"
                        type="text"
                        value={videoUrl}
                    />
                    <HelpBlock>{videoUrlErrorMessage}</HelpBlock>
                </FormGroup>
            </InputRow>
            {showPreviewImage && (
                <MediaPreview
                    source={videoUrl}
                    isUrl={true}
                    onRemoveMedia={() => onChangeAdvertFields("", "videoUrl")}
                    isVideo
                />
            )}

            <InputRow label="Video Preview image:">
                <FormGroup {...imageValidationState}>
                    <FormControl
                        onChange={(event) =>
                            onSelectImage(event, placementName, true)
                        }
                        type="file"
                        // @ts-ignore
                        inputRef={ref}
                    />
                    <HelpBlock>{imageErrorMessage}</HelpBlock>
                </FormGroup>
            </InputRow>

            <ValidatedImagePreview
                image={videoPreviewImageUrl}
                onRemoveImage={onRemoveImage}
            />
        </>
    );
};

export interface Props extends ImageUploadProps {
    onSetIsVideoInterstitials: (value: boolean) => void;
    isVideoInterstitials: boolean;
}

export default function AddAdvertUploadMediaField(props: Props) {
    const classes = useStyles();
    const {featureFlags, isVideoInterstitials, placementName} = props;

    const onChange = useCallback((selectedTab: number) => {
        props.onSetIsVideoInterstitials(Boolean(selectedTab === 1));
    }, []);
    const isBanner = BANNER_PLACEMENT_TYPES.includes(placementName);
    const showTabs = Boolean(!isBanner && featureFlags.videoInterstitials);
    return (
        <div className={classes.container}>
            {showTabs && (
                <Tabs
                    value={isVideoInterstitials ? 1 : 0}
                    onChange={(_, value) => onChange(value)}
                    className={classes.tabsContainer}
                >
                    <Tab
                        label={MediaType.IMAGE_ADVERT}
                        className={classes.tabs}
                    />
                    <Tab
                        label={MediaType.VIDEO_ADVERT}
                        className={classes.tabs}
                    />
                </Tabs>
            )}

            {isVideoInterstitials &&
            !isBanner &&
            featureFlags.videoInterstitials ? (
                <VideoUpload {...props} />
            ) : (
                <ImageUpload {...props} />
            )}
        </div>
    );
}
