import {Table} from "react-bootstrap";
import {makeStyles} from "@material-ui/core";
import React, {memo} from "react";

const useStyles = makeStyles({
    td: {
        verticalAlign: "middle",
    },
});

interface ColumnProps {
    cols: Array<{
        displayTitle: string;
        id: string;
    }>;
}
interface RowProps {
    rows: Array<any>;
}
interface TableWidgetProps extends RowProps, ColumnProps {
    tableProps?: {
        striped: boolean;
        bordered: boolean;
        condensed: boolean;
        hover: boolean;
    };
}

function TableHead({cols}: ColumnProps) {
    const header = cols.map((headerData) => (
        <th key={headerData.id} id={headerData.id}>
            {headerData.displayTitle}
        </th>
    ));

    return (
        <thead>
            <tr key={1}>{header}</tr>
        </thead>
    );
}

function TableBody({rows}: RowProps): JSX.Element {
    const classes = useStyles();
    const tableData = rows.map((rowsData, index) => {
        const mapRows = Object.keys(rowsData).map((property, index) => (
            <td className={classes.td} key={index}>
                {rowsData[property]}
            </td>
        ));

        return <tr key={index}>{mapRows}</tr>;
    });

    return <tbody>{tableData}</tbody>;
}

export default memo(function TableWidget({
    cols,
    rows,
    tableProps,
}: TableWidgetProps): JSX.Element {
    return (
        <Table {...tableProps}>
            <TableHead cols={cols} />
            <TableBody rows={rows} />
        </Table>
    );
});
