/*  */

import {action, observable} from "mobx";
import {Component} from "react";
import {observer} from "mobx-react";
import {STATUSES} from "../../widgets/DashboardWidget";
import getDateFilteredChartRange from "./getDateFilteredChartRange";
import moment from "moment";

const isSameDate = (oldDate, newDate) =>
    moment.utc(oldDate).isSame(newDate, "day");

@observer
export default class FilteredChart extends Component {
    componentWillReceiveProps({
        startDate: nextStartDate,
        endDate: nextEndDate,
        dataLoader,
        childrenTeamFilter: nextChildrenTeamFilter,
    }) {
        const {startDate, endDate, childrenTeamFilter} = this.props;

        if (
            isSameDate(startDate, nextStartDate) &&
            isSameDate(endDate, nextEndDate) &&
            childrenTeamFilter === nextChildrenTeamFilter
        ) {
            return;
        }

        this.loadData(
            dataLoader,
            nextStartDate,
            nextEndDate,
            nextChildrenTeamFilter,
        );
    }

    // eslint-disable-next-line no-unused-vars
    dataLoader(query) {
        return Promise.reject(new Error("You must provide a data loader"));
    }

    @observable
    status = STATUSES.normal;

    @action
    onLoadingDataStarted() {
        this.status = STATUSES.dataLoading;
    }

    @action
    onLoadingDataFailed() {
        this.status = STATUSES.dataUnavailable;
    }

    @action
    onLoadingDataFinished() {
        this.status = STATUSES.normal;
    }

    @action
    async loadData(dataLoader, startDate, endDate, childrenTeamFilter) {
        const dataLoaderToUse = dataLoader ? dataLoader : this.dataLoader;

        this.onLoadingDataStarted();

        try {
            const childrenTeamFilterParams = childrenTeamFilter
                ? {childrenTeamFilter}
                : {};
            const filters = {
                ...getDateFilteredChartRange(startDate, endDate),
                ...childrenTeamFilterParams,
            };
            const data = await dataLoaderToUse(filters);

            this.onDataDidLoad(data);

            this.onLoadingDataFinished();
        } catch (error) {
            this.onLoadingDataFailed();
        }
    }

    // eslint-disable-next-line no-unused-vars
    onDataDidLoad(data) {
        throw new Error("You must implement an onDataDidLoad method!");
    }
}
