/*  */

import {Grid, Col, Row} from "react-bootstrap";
import {observable} from "mobx";
import {observer} from "mobx-react";
import LabelledDatePicker from "../../common/forms/LabelledDatePicker";
import React, {PureComponent} from "react";

const styles = Object.freeze({
    datePickerContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
    },
    verticallyAlignedColumn: {
        display: "inline-block",
        verticalAlign: "middle",
        float: "none",
    },
    startDate: {
        marginRight: 10,
    },
});

@observer
class DashboardDatePicker extends LabelledDatePicker {
    render() {
        return (
            <div>
                {this.renderLabel()}
                {this.renderDatePicker()}
            </div>
        );
    }
}

@observer
export default class DashboardDateRangePicker extends PureComponent {
    componentDidMount() {
        this.currentDate = new Date();
    }

    render() {
        const {
            endDate,
            onStartDateSelected,
            onEndDateSelected,
            startDate,
        } = this.props;

        return (
            <div style={styles.datePickerContainer}>
                <div style={styles.startDate}>
                    <DashboardDatePicker
                        label="Start Date"
                        max={endDate}
                        onChange={onStartDateSelected}
                        value={startDate}
                    />
                </div>
                <div>
                    <DashboardDatePicker
                        label="End Date"
                        max={this.currentDate}
                        min={startDate}
                        onChange={onEndDateSelected}
                        value={endDate}
                    />
                </div>
            </div>
        );
    }
}
