import * as R from "ramda";
import Immutable from "immutable";

const formatNumber = (number: number): number => Number(number.toFixed(2));

const getValue = (valueList: any, legend: any) => {
    const valueObject = valueList.find(
        (valueItem: any) => valueItem.get("label") === legend,
    );

    return valueObject ? formatNumber(valueObject.get("value")) : 0;
};

const getDataSet = (legends: any, apiResponse: any) =>
    apiResponse.map((item: any) =>
        legends.map((legend: any) => getValue(item.get("value"), legend)),
    );

const extractUniqueLegends = (values: any) => {
    const legendList = values
        .map((valuesItem: any) =>
            valuesItem.map((item: any) => item.get("label")),
        )
        .flatten();

    return R.uniq(legendList.toJS());
};

export default (apiResponse: any) => {
    if (apiResponse) {
        const axisLabels = apiResponse.map((item: any) => item.get("label"));
        const values = apiResponse.map((item: any) => item.get("value"));
        const legends = extractUniqueLegends(values);
        const datasets = getDataSet(Immutable.fromJS(legends), apiResponse);

        return Immutable.fromJS({
            axisLabels,
            datasets,
            legends,
        });
    }

    return Immutable.fromJS({
        axisLabels: [],
        datasets: [],
        legends: [],
    });
};
