import {createSelector, createStructuredSelector} from "reselect";
import {LOADING_STATUSES} from "./constants";
import ALL_MENU_ITEMS from "./menuConstants";
import immutable from "immutable";

export const getTeamNumber = (state) =>
    state.main.getIn(["user", "activeTeam", "teamNumber"]);
export const getEmailAddress = (state) =>
    state.main.getIn(["user", "emailAddress"]);
export const getPassword = (state) => state.main.getIn(["user", "password"]);

export const getLoadingStatus = createSelector(
    (state) => state.main.get("loadingStatus"),
    (loadingStatus) => ({
        isDone: loadingStatus === LOADING_STATUSES.DONE,
        isError: loadingStatus === LOADING_STATUSES.ERROR,
    }),
);
