import * as R from "ramda";
import {createAction, handleActions} from "redux-actions";
import immutable from "immutable";

const FIVE_SECONDS_IN_MS = 5000;
const ADD_PERMANENT_ERROR = "beam/notification/ADD_PERMANENT_ERROR";
const ADD_PERMANENT_SUCCESS = "beam/notification/ADD_PERMANENT_SUCCESS";
const REMOVE_BY_ID = "beam/notification/REMOVE_BY_ID";

export const addPermanentError = createAction(ADD_PERMANENT_ERROR);
export const addPermanentSuccess = createAction(ADD_PERMANENT_SUCCESS);

const handleAddPermanent = (type) => (state, {payload}) =>
    state.push(
        immutable.Map({
            id: payload.id,
            message: payload.message,
            type,
        }),
    );

const handleAddError = handleAddPermanent("error");
const handleAddSuccess = handleAddPermanent("success");

export const removeById = createAction(REMOVE_BY_ID);

const handleRemoveById = (state, {payload}) =>
    state.filter((item) => item.get("id") !== payload);

export default handleActions(
    {
        [ADD_PERMANENT_ERROR]: handleAddError,
        [ADD_PERMANENT_SUCCESS]: handleAddSuccess,
        [REMOVE_BY_ID]: handleRemoveById,
    },
    immutable.List(),
);

const addTransient = R.curry((addFunction, message, dispatch) => {
    const id = String(Math.random() * 1000000);

    dispatch(
        addFunction({
            id,
            message,
        }),
    );

    window.setTimeout(() => dispatch(removeById(id)), FIVE_SECONDS_IN_MS);
});

export const addError = addTransient(addPermanentError);
export const addSuccess = addTransient(addPermanentSuccess);
