import * as R from "ramda";
// @ts-ignore
import * as DashboardEventHandlers from "./DashboardEventHandlers";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import React, {PureComponent, memo, useEffect, useState} from "react";
import teamDashboardConfiguration from "./teamDashboardConfiguration";
// @ts-ignore
import teamDashboardSelector from "./teamDashboardSelector";
import useSession from "../main/useSession";

let TeamDashboardElement = () => <div>Loading...</div>;

const HooksContainer = memo(function DashboardContainer(props: any) {
    const [dashboardConfiguration, setDashboardConfiguration] = useState({});
    const {userData} = useSession();
    const teamNumber = R.path(["activeTeam", "teamNumber"], userData);
    const getDashboardInfo = async () => {
        const [
            TeamDashboard,
            returntDashboardConfiguration,
        ] = await Promise.all([
            teamDashboardSelector(teamNumber),
            teamDashboardConfiguration(),
        ]);

        TeamDashboardElement = () => <TeamDashboard />;
        setDashboardConfiguration(returntDashboardConfiguration);
    };

    useEffect(() => {
        getDashboardInfo();
    }, []);

    // @ts-ignore
    return (
        <TeamDashboardElement
            configuration={dashboardConfiguration}
            {...props}
        />
    );
});

class Dashboard extends PureComponent {
    render() {
        return <HooksContainer {...this.props} />;
    }
}

const stateToProps = (state: any) => ({
    widgets: state.dashboard.get("widgets"),
    currentTab: state.dashboard.get("currentTab"),
});

const dispatchToProps = (dispatch: Function): Object => ({
    loadWidgetData: (widgetId: number | string, ...args: any) =>
        dispatch(DashboardEventHandlers.loadWidgetData(widgetId, ...args)),
    onActiveFilterChanged: (chartIdentifier: string, filter: Object) =>
        dispatch(
            DashboardEventHandlers.onActiveFilterChanged(
                chartIdentifier,
                filter,
            ),
        ),
    onMultiFilterChanged: (chartIdentifier: string, filter: Object) =>
        dispatch(
            DashboardEventHandlers.onMultiFilterChanged(
                chartIdentifier,
                filter,
            ),
        ),
    onTabChanged: (categoryName: string) =>
        dispatch(DashboardEventHandlers.onTabChanged(categoryName)),
});

export const DashboardPropTypes = {
    configuration: PropTypes.object.isRequired,
    loadWidgetData: PropTypes.func.isRequired,
    onActiveFilterChanged: PropTypes.func.isRequired,
    onMultiFilterChanged: PropTypes.func.isRequired,
    onTabChanged: PropTypes.func,
    resetAllWidgets: PropTypes.func,
    widgets: PropTypes.object.isRequired,
};

export default connect(stateToProps, dispatchToProps)(Dashboard);
