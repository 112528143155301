/*  */

import {action, observable, runInAction} from "mobx";

import BeamAPIClient from "../../apiClient/BeamAPIClient";

export default class PredictiveAudienceRepository {
    constructor(apiClient) {
        this.apiClient = apiClient;
    }

    @observable
    potentialReach = 0;
    @observable
    totalAudienceSize = 0;
    @observable
    recipients = [];
    @observable
    audienceOverview = {};
    @observable
    userSelector = {};
    @observable
    isLoading = false;
    @observable
    isExporting = false;

    @action.bound
    async fetch(userSelector) {
        this.isLoading = true;
        this.potentialReach = 0;
        this.recipients = [];
        this.totalAudienceSize = 0;
        this.userSelector = userSelector;
        try {
            const {
                potentialReach = 0,
                totalAudienceSize = 0,
                recipients = [],
            } = await this.apiClient.invokePostEndpoint("notification", {
                forAudienceEstimate: true,
                showRecipientList: {
                    limit: 5,
                    generateCSV: false,
                },
                ...userSelector,
            });

            runInAction(() => {
                this.potentialReach = potentialReach;
                this.totalAudienceSize = totalAudienceSize;
                this.recipients = recipients;
                this.isLoading = false;
            });

            return {success: true};
        } catch (exception) {
            this.isLoading = false;

            return {success: false};
        }
    }

    @action
    setAudienceOverView(audienceOverview) {
        this.audienceOverview = audienceOverview;
    }

    @action.bound
    clearAudienceOverView() {
        this.audienceOverview = {};
        this.potentialReach = 0;
        this.totalAudienceSize = 0;
        this.recipients = [];
        this.userSelector = {};
        this.isLoading = false;
        this.isExporting = false;
    }
}
