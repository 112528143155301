import * as R from "ramda";
import {toJS} from "mobx";
// @ts-ignore
import {go} from "./eventHandlers";
import {NavLink} from "react-router-dom";
import BuildInfo from "./BuildInfo";
import Gravatar from "react-gravatar";
import hasSubMenu from "./hasSubMenu";
import React, {memo, useState} from "react";
import ALL_MENU_ITEMS from "./menuConstants";
import useFeatureFlag from "../featureFlags/useFeatureFlag";
import classnames from "classnames";
import {makeStyles} from "@material-ui/styles";
import useNavigate from "@beam/use-navigate";
import versionString from "../../../common/versionString";

interface Props {
    emailAddress: string;
    firstName: string;
    teamName: string;
}

const useStyles = makeStyles({
    collapsed: {
        display: "none",
    },
    expanded: {
        display: "block",
    },
    wrapTex: {
        overflowWrap: "break-word",
        wordWrap: "break-word",
        "white-space": "break-spaces",
    },
    userPanel: {
        display: "flex",
        flexDirection: "row",
        padding: 10,
    },
    avatar: {
        minWidth: 30,
    },
    userDetail: {
        width: 150,
        padding: "0px 0px 0px 15px !important",
        color: "rgb(255, 255, 255)",
        lineHeight: 1,
        left: "0 !important",
        // @ts-ignore
        position: "relative !important",
    },
});

interface TreeMenuProps {
    onMenuClicked: () => void;
    icon: string;
    name: string;
    subMenus: Array<any>;
    isActive: boolean;
}

interface SubMenuProps {
    featureFlags: any;
    onMenuClicked: () => void;
    menu: {
        name: string;
        href: string;
        icon: string;
        visibleForFeatureFlag: string;
    };
    index: number;
}

const SubMenu = ({featureFlags, onMenuClicked, menu, index}: SubMenuProps) => {
    if (
        menu.visibleForFeatureFlag &&
        !featureFlags[menu.visibleForFeatureFlag]
    ) {
        return null;
    }

    return (
        <li key={index}>
            <NavLink
                key={menu.name}
                activeClassName="active"
                onClick={onMenuClicked}
                to={menu.href}
            >
                <i className={`fa fa-${menu.icon}`} />
                {`  ${menu.name}`}
            </NavLink>
        </li>
    );
};

const TreeMenu = ({
    icon,
    name,
    subMenus,
    onMenuClicked,
    isActive,
}: TreeMenuProps) => {
    const treeViewClass = isActive ? "treeview active" : "treeview";
    const {featureFlags} = useFeatureFlag();
    const classes = useStyles();
    const ulClass = classnames(
        "treeview-menu",
        isActive ? classes.expanded : classes.collapsed,
    );

    return (
        <li className={treeViewClass} onClick={onMenuClicked}>
            <a>
                <i className={`fa fa-${icon}`} />
                <span>{name}</span>
                <span className="pull-right-container">
                    <i className="fa fa-angle-right pull-right" />
                </span>
            </a>
            <ul className={ulClass}>
                {subMenus.map((menu, index) => (
                    <SubMenu
                        key={index}
                        featureFlags={featureFlags}
                        onMenuClicked={onMenuClicked}
                        menu={menu}
                        index={index}
                    />
                ))}
            </ul>
        </li>
    );
};

const MenuWithSubMenus = ({
    menu,
    index,
    onMenuClicked,
    featureFlags,
}: SubMenuParams) => {
    return (
        <TreeMenu
            {...menu}
            key={index}
            featureFlags={featureFlags}
            onMenuClicked={(event: Event) =>
                onMenuClicked({
                    name: menu.name,
                    event,
                    parentRoute: menu.parentRoute,
                    featureFlag: menu.featureFlag,
                })
            }
        />
    );
};

const MenuWithoutSubMenus = ({menu, index, onMenuClicked}: SubMenuParams) => {
    return (
        <li key={index} className={menu.className}>
            <NavLink
                activeClassName="active"
                onClick={(event) => onMenuClicked({name: menu.name, event})}
                to={menu.href}
            >
                <i className={`fa fa-${menu.icon}`} />
                <span>{menu.name}</span>
            </NavLink>
        </li>
    );
};

const Menus = ({menu, index, onMenuClicked, featureFlags}: SubMenuParams) => {
    if (
        menu.visibleForFeatureFlag &&
        !featureFlags[menu.visibleForFeatureFlag]
    ) {
        return null;
    }

    return hasSubMenu(menu) ? (
        <MenuWithSubMenus
            menu={menu}
            index={index}
            onMenuClicked={onMenuClicked}
            featureFlags={featureFlags}
        />
    ) : (
        <MenuWithoutSubMenus
            menu={menu}
            index={index}
            onMenuClicked={onMenuClicked}
        />
    );
};

const SideMenuBar = ({
    menus,
    onMenuClicked,
    featureFlags,
}: {
    menus: Array<any>;
    onMenuClicked: (params: MenuClickedParams) => void;
    featureFlags: any;
}) => {
    const immutableMenus = toJS(menus);

    return (
        <ul className="sidebar-menu">
            <li className="header">Menu</li>
            {immutableMenus.map((menu, index) => (
                <Menus
                    key={index}
                    menu={menu}
                    index={index}
                    onMenuClicked={onMenuClicked}
                    featureFlags={featureFlags}
                />
            ))}
        </ul>
    );
};

interface SubMenuParams {
    menu: any;
    index?: number;
    onMenuClicked: (params: MenuClickedParams) => void;
    featureFlags?: any;
}

interface MenuClickedParams {
    name: string;
    event: any;
    parentRoute?: string;
    featureFlag?: string;
}

export default memo(function SideBar({
    emailAddress,
    firstName,
    teamName,
}: Props) {
    const [menus, setMenus] = useState(ALL_MENU_ITEMS);

    const {featureFlags} = useFeatureFlag();
    const {navigate} = useNavigate();
    const classes = useStyles();

    const onMenuClicked = ({
        name,
        event,
        parentRoute,
        featureFlag,
    }: {
        name: string;
        event: any;
        parentRoute?: string;
        featureFlag?: string;
    }) => {
        const filteredMenus = R.map(
            (item: {name: string}) => ({
                ...item,
                isActive: item.name === name,
            }),
            menus,
        );

        setMenus(filteredMenus);

        if (parentRoute && featureFlag && featureFlags[featureFlag]) {
            navigate(parentRoute);
        }

        event.stopPropagation();
    };

    return (
        <aside className="main-sidebar">
            <section className="sidebar">
                <div className={`${classes.userPanel} user-panel`}>
                    <div className="pull-left image">
                        <Gravatar
                            className={`${classes.avatar} user-image`}
                            email={emailAddress}
                            size={90}
                        />
                    </div>
                    <div className={`${classes.userDetail} info`}>
                        <p>{firstName}</p>
                        <div className={classes.wrapTex}>{teamName}</div>
                    </div>
                </div>
                <SideMenuBar
                    menus={menus}
                    onMenuClicked={onMenuClicked}
                    featureFlags={featureFlags}
                />
            </section>
            <div className="bottom-links">
                {!featureFlags.disableHelpSupport && (
                    <ul className="sidebar-menu">
                        <li>
                            <NavLink activeClassName="active" to={"contact-us"}>
                                <i className={`fa fa-question-circle`} />
                                <span>Get Support</span>
                            </NavLink>
                        </li>
                    </ul>
                )}
                <hr />

                <BuildInfo version={versionString} />
            </div>
        </aside>
    );
});
