import * as R from "ramda";
import {createAction, handleActions} from "redux-actions";
import immutable from "immutable";

const initialState = immutable.fromJS({
    placements: {},
});

const ADVERT_LIST = "beam/advert/ADVERT_LIST";
const TOGGLE_PLACEMENT_CONTAINER = "beam/advert/TOGGLE_PLACEMENT_CONTAINER";

export const advertList = createAction(ADVERT_LIST);
export const togglePlacement = createAction(TOGGLE_PLACEMENT_CONTAINER);

const advertListHandler = (state, {payload}) => {
    if (!payload) {
        return state;
    }

    const placementsOpenState = state
        .get("placements")
        .map((value) => value.set("items", immutable.List()));
    const placements = payload.reduce((placementMap, currentPlacement) => {
        const placementName = currentPlacement.placement;
        const existingData = placementMap[currentPlacement.placement];

        if (!placementName) {
            return placementMap;
        }

        if (existingData) {
            return {
                ...placementMap,
                [placementName]: {
                    ...existingData,
                    items: [...existingData.items, currentPlacement],
                },
            };
        }

        return {...placementMap, [placementName]: {items: [currentPlacement]}};
    }, placementsOpenState.toJS());

    return state.set("placements", immutable.fromJS(placements));
};

const handleTogglePlacement = (state, {payload}) =>
    state.updateIn(["placements", payload, "open"], false, R.not);

export default handleActions(
    {
        [ADVERT_LIST]: advertListHandler,
        [TOGGLE_PLACEMENT_CONTAINER]: handleTogglePlacement,
    },
    initialState,
);
