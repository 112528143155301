import immutable from "immutable";

import {
    BANNER,
    FEATURED,
    INTERSTITIAL,
    NEW_PRODUCTS,
    OFFERS_FOR_YOU,
    PRODUCT_LIST,
    TRENDING_PRODUCTS,
} from "../advert/PlacementConstants";
import {FeatureFlags} from "../message/types";
import TEAM_NUMBERS from "../teamNumbers";
import {
    BETA,
    DEVELOPMENT_FEATURES,
    ENTERPRISE,
    ENTRY,
    WHOLESALER_DASHBOARD,
} from "./featureFlagsLevel";

const YACHTBUOY_FEATURES = {
    ...ENTRY,
    messaging: {
        audience: {
            userSegment: true,
        },
        message: true,
        fbNotification: {},
    },
};

const CHEF_CENTRAL_FEATURES = {
    ...ENTRY,
    messaging: {
        audience: {
            sales: true,
            userSegment: true,
            userIdentifier: true,
            userNotificationNotActioned: false,
        },
        message: true,
        fbNotification: {},
    },
};

const SINGHS_FEATURES = {
    ...ENTRY,
    messaging: {
        audience: {
            sales: true,
            userSegment: true,
            userIdentifier: true,
            userNotificationNotActioned: false,
        },
        message: true,
        fbNotification: {},
    },
};

const WHARFDALE_FEATURES = {
    ...ENTRY,
    messaging: {
        audience: {
            sales: true,
            userSegment: true,
            userIdentifier: true,
            userNotificationNotActioned: false,
        },
        message: true,
        fbNotification: {},
    },
};

const SANDERSON_FEATURES = {
    ...ENTRY,
    dashboard: {
        audienceLandscape: {
            pushNotificationEnabled: true,
        },
        ...WHOLESALER_DASHBOARD,
    },
    placementLimits: {
        [FEATURED]: 1,
        [PRODUCT_LIST]: 1,
    },
    placements: {
        interstitialAds: true,
        banner: true,
        featured: true,
        productList: true,
        trackingAdvertsInfo: true,
    },
    messaging: {
        ...ENTERPRISE.messaging,
        audience: {
            userSegment: true,
            userIdentifier: true,
        },
        message: true,
        fbNotification: {
            productUrl: true,
            productDetailsDeeplink: true,
        },
        schedule: false,
    },
    adActions: {
        actionViewWebsite: true,
        actionViewProductDetails: true,
        actionViewProductList: true,
        actionAddProductToBasket: true,
    },
    advertDeepLinking: true,
    disableHelpSupport: true,
    exportCSV: true,
};

const BLAKEMORE_WHOLESALE_FEATURES = {
    ...ENTRY,
    dashboard: {
        audienceLandscape: {
            pushNotificationEnabled: true,
        },
        ...WHOLESALER_DASHBOARD,
    },
    messaging: {
        ...ENTERPRISE.messaging,
        audience: {
            sales: true,
            salesProductSpent: false,
            userIdentifier: true,
            userSegment: false,
            userNotificationNotActioned: false,
            devicePlatform: true,
        },
        schedule: true,
        message: true,
        fbNotification: {
            ...ENTERPRISE.messaging.fbNotification,
            productDeeplinkWithoutLists: true,
        },
    },
    placementLimits: {
        [INTERSTITIAL]: 0,
        [BANNER]: 0,
        [FEATURED]: 1,
    },
    placements: {
        interstitialAds: true,
        banner: true,
        trackingAdvertsInfo: true,
    },
    scheduledAdvert: true,
    exportCSV: true,
    adActions: {
        actionViewWebsite: true,
        actionViewProductDetails: true,
        actionViewProductList: true,
        actionAddProductToBasket: true,
    },
    advertDeepLinking: true,
};

const BXR_FEATURES = {
    ...ENTRY,
    placements: false,
};

const ABRA_FEATURES = {
    ...ENTERPRISE,
    dashboard: {
        audienceLandscape: {
            pushNotificationEnabled: true,
        },
        ...WHOLESALER_DASHBOARD,
    },
    messaging: {
        ...ENTERPRISE.messaging,
        audience: {
            ...ENTERPRISE.messaging.audience,
            salesProductSpent: true,
        },
    },
    businessIntelligenceDashboard: false,

    placements: {
        interstitialAds: true,
        banner: true,
        productList: true,
        offersForYou: true,
    },
};

const BESTWAY_FEATURES = {
    ...ENTERPRISE,
    dashboard: {
        audienceLandscape: {
            pushNotificationEnabled: true,
        },
        ...WHOLESALER_DASHBOARD,
    },
    adFrequencyConfigEnabled: true,
    interstitialAdCTAEnabled: true,
    placements: {
        interstitialAds: true,
        newProducts: true,
        offersForYou: true,
        trendingProducts: true,
        "Home Banner 1": true,
        "Home Banner 2": true,
        "Home Banner 3": true,
        trackingAdvertsInfo: true,
    },
    placementLimits: {
        [INTERSTITIAL]: 0,
        [BANNER]: 0,
        [NEW_PRODUCTS]: 0,
        [OFFERS_FOR_YOU]: 0,
        [TRENDING_PRODUCTS]: 0,
    },
    messaging: {
        ...ENTERPRISE.messaging,
        audience: {
            ...ENTERPRISE.messaging.audience,
            salesProductSpent: true,
        },
        fbNotification: {
            ...ENTERPRISE.messaging.fbNotification,
            productDeeplink: true,
        },
    },
    adActions: {
        actionViewWebsite: true,
        actionViewProductDetails: true,
        actionViewProductList: true,
        actionAddProductToBasket: true,
        actionAddProductToList: true,
    },
    advertDeepLinking: true,
    imageDimensionsLimit: false,
    offersForYouHeader: "Offers for You",
    scheduledAdvert: true,
    allowCSVExport: true,
};

const BESTWAY_FOODSERVICE_FEATURES = {
    ...BESTWAY_FEATURES,

    dashboard: {
        audienceLandscape: {
            pushNotificationEnabled: true,
        },
        ...WHOLESALER_DASHBOARD,
    },
};

const SANDERSON_APP_ENTRY_FEATURES = {
    ...ENTRY,
    dashboard: {
        audienceLandscape: {
            pushNotificationEnabled: true,
        },
        ...WHOLESALER_DASHBOARD,
    },
    placements: {
        interstitialAds: true,
        banner: true,
        trackingAdvertsInfo: true,
    },
    messaging: {
        ...ENTRY.messaging,
        audience: {
            ...ENTRY.messaging.audience,
            userSegment: false,
            devicePlatform: true,
        },
        fbNotification: {
            productUrl: true,
            productDetailsDeeplink: true,
        },
    },
    placementLimits: {
        [INTERSTITIAL]: 3,
        [BANNER]: 3,
    },
    disableHelpSupport: true,
};

const HT_DRINKS_FEATURES = {
    ...ENTRY,
    dashboard: {
        audienceLandscape: {
            pushNotificationEnabled: true,
        },
        ...WHOLESALER_DASHBOARD,
    },
    placements: {
        interstitialAds: true,
        banner: true,
        trackingAdvertsInfo: true,
    },
    messaging: {
        ...ENTRY.messaging,
        audience: {
            ...ENTRY.messaging.audience,
            userSegment: false,
            devicePlatform: true,
        },
        fbNotification: {
            productUrl: true,
            productDetailsDeeplink: true,
        },
        schedule: true,
    },
    placementLimits: {
        [INTERSTITIAL]: 0,
        [BANNER]: 0,
    },
    exportCSV: true,
    disableHelpSupport: true,
};

const PEACH_FEATURES = {
    ...ENTRY,
    placements: false,
    dashboard: {
        audienceLandscape: {
            pushNotificationEnabled: true,
        },
        appReviews: true,
        totalDownloadChart: true,
    },
    messaging: {
        ...ENTRY.messaging,
        fbNotification: {
            productUrl: true,
        },
    },
};

const MUMMY_SOCIAL_FEATURES = {
    ...ENTRY,
    placements: {
        banner: true,
        trackingAdvertsInfo: true,
    },
    messaging: {
        ...ENTRY.messaging,
        audience: {
            ...ENTRY.messaging.audience,
            inactiveUsers: false,
            userSegment: false,
        },
        fbNotification: {
            productUrl: true,
        },
    },
    adActions: {
        actionViewWebsite: true,
    },
    dashboard: {
        audienceLandscape: {
            pushNotificationEnabled: true,
        },
        activePurchasersChart: false,
        appTransactionAndSalesChart: false,
        activePurchasersAverageOrderSizeChart: false,
        fortnightComparisonCharts: false,
        appReviews: false,
        totalDownloadChart: true,
    },
    advertDeepLinking: true,
    placementLimits: {
        [BANNER]: 0,
    },
};

const BLAKEMORE_FEATURES = {
    ...ENTRY,
    dashboard: {
        audienceLandscape: {
            pushNotificationEnabled: true,
        },
        ...WHOLESALER_DASHBOARD,
    },
    messaging: {
        audience: {
            userSegment: false,
        },
    },
    placementLimits: {
        [INTERSTITIAL]: 3,
        [BANNER]: 3,
        [FEATURED]: 1,
    },
    placements: {
        interstitialAds: true,
        banner: true,
        trackingAdvertsInfo: true,
    },
    adActions: {
        actionViewWebsite: true,
        actionViewProductDetails: true,
        actionViewProductList: true,
        actionAddProductToBasket: true,
    },
    advertDeepLinking: true,
};

const BLAKEMORE_TRADE_PARTNERS_FEATURES = {
    ...ENTRY,
    dashboard: {
        audienceLandscape: {
            pushNotificationEnabled: true,
        },
        ...WHOLESALER_DASHBOARD,
    },
    messaging: {
        ...ENTERPRISE.messaging,
        audience: {
            sales: true,
            salesProductSpent: false,
            userIdentifier: true,
            userSegment: false,
            userNotificationNotActioned: false,
            devicePlatform: true,
        },
        schedule: true,
        message: true,
        fbNotification: {
            ...ENTERPRISE.messaging.fbNotification,
            productDeeplinkWithoutLists: true,
        },
    },
    placementLimits: {
        [INTERSTITIAL]: 0,
        [BANNER]: 0,
        [FEATURED]: 1,
    },
    placements: {
        interstitialAds: true,
        banner: true,
        trackingAdvertsInfo: true,
    },
    scheduledAdvert: true,
    exportCSV: true,
    adActions: {
        actionViewWebsite: true,
        actionViewProductDetails: true,
        actionViewProductList: true,
        actionAddProductToBasket: true,
    },
    advertDeepLinking: true,
};

const BRIDGESTONE_FEATURES = {
    ...ENTRY,
    dashboard: {
        audienceLandscape: {
            pushNotificationEnabled: true,
        },
        activePurchasersChart: false,
        appTransactionAndSalesChart: false,
        activePurchasersAverageOrderSizeChart: false,
        fortnightComparisonCharts: false,
        appReviews: true,
    },

    messaging: {
        ...ENTRY.messaging,
        audience: {
            devicePlatform: true,
        },
        fbNotification: {
            productUrl: true,
        },
    },
    placements: {
        banner: true,
        interstitialAds: true,
    },
    scheduledAdvert: true,
    advertDeepLinking: true,
    adActions: {
        actionViewWebsite: true,
    },
};

const CONFEX_FEATURES = {
    ...ENTRY,

    businessIntelligenceDashboard: false,
    dashboard: {
        ...WHOLESALER_DASHBOARD,
        audienceLandscape: {
            pushNotificationEnabled: true,
        },
        appReviews: true,
        totalDownloadChart: true,
    },
    messaging: {
        ...ENTERPRISE.messaging,
        audience: {
            ...ENTRY.messaging.audience,
            userSegment: false,
            devicePlatform: true,
        },
        fbNotification: {
            productUrl: true,
            productDetailsDeeplink: true,
        },
        schedule: false,
    },
    placements: {
        interstitialAds: true,
        banner: true,
        featured: true,
        productList: true,
        trendingProducts: true,
        offersForYou: true,
        trackingAdvertsInfo: true,
    },
    scheduledAdvert: true,
    advertDeepLinking: true,
    adActions: {
        actionViewWebsite: true,
        actionViewProductDetails: true,
    },
    placementLimits: {},
    metaTeam: true,
    advertChildrenTeamFilter: true,
    pushNotificationMemberApp: true,
    audienceBuilderChildrenTeamFilter: true,
};

const BEAM_COMMERCE_BASE_FEATURES = {
    ...ENTERPRISE,

    adFrequencyConfigEnabled: false,
    interstitialAdCTAEnabled: true,
    dashboard: {
        ...ENTERPRISE.dashboard,
        audienceLandscape: {
            ...ENTERPRISE.dashboard.audienceLandscape,
            pushNotificationEnabled: false,
        },
    },
    placements: {
        interstitialAds: true,
        banner: true,
        offersForYou: true,
        basketProductPlacement: true,
        trackingAdvertsInfo: true,
    },
    placementLimits: {
        [INTERSTITIAL]: 0,
        [BANNER]: 0,
        [OFFERS_FOR_YOU]: 0,
    },
    messaging: {
        ...ENTERPRISE.messaging,
        audience: {
            ...ENTERPRISE.messaging.audience,
            salesProductSpent: true,
        },
        fbNotification: {
            ...ENTERPRISE.messaging.fbNotification,
            productDeeplink: true,
        },
    },
    adActions: {
        actionViewWebsite: true,
        actionViewProductDetails: true,
        actionViewProductList: true,
        actionAddProductToBasket: true,
        actionAddProductToList: true,
        allowSKUsWithFullStops: true,
    },
    advertDeepLinking: true,
    imageDimensionsLimit: false,
    scheduledAdvert: true,
    relaxImageUploadRule: true,
    allowCSVExport: true,
    videoInterstitials: true,
};

const FILSHILL_FEATURE_FLAGS = {
    ...SANDERSON_APP_ENTRY_FEATURES,
    placementLimits: {
        [INTERSTITIAL]: 0,
        [BANNER]: 0,
    },
    messaging: {
        ...SANDERSON_APP_ENTRY_FEATURES.messaging,
        schedule: true,
    },
    scheduledAdvert: true,
    adActions: {
        actionViewWebsite: true,
        actionViewProductDetails: true,
        actionViewProductList: true,
        actionAddProductToBasket: true,
    },
    interstitialAdCTAEnabled: true,
    advertDeepLinking: true,
};

const CONFIGURATION = Object.freeze({
    [TEAM_NUMBERS.development]: DEVELOPMENT_FEATURES,
    [TEAM_NUMBERS.rnfdigital]: ENTERPRISE,
    [TEAM_NUMBERS.bestway]: BESTWAY_FEATURES,
    [TEAM_NUMBERS.chefcentral]: CHEF_CENTRAL_FEATURES,
    [TEAM_NUMBERS.wholesaleDemoAll]: BETA,
    [TEAM_NUMBERS.wholesaleDemo]: BETA,
    [TEAM_NUMBERS.singhs]: SINGHS_FEATURES,
    [TEAM_NUMBERS.wharfedale]: WHARFDALE_FEATURES,
    [TEAM_NUMBERS.yachtbuoy]: YACHTBUOY_FEATURES,
    [TEAM_NUMBERS.sanderson]: SANDERSON_FEATURES,
    [TEAM_NUMBERS.bxr]: BXR_FEATURES,
    [TEAM_NUMBERS.abra]: ABRA_FEATURES,
    [TEAM_NUMBERS.bestwayFoodservice]: BESTWAY_FOODSERVICE_FEATURES,
    [TEAM_NUMBERS.bestPets]: BESTWAY_FOODSERVICE_FEATURES,
    [TEAM_NUMBERS.blakemoreWholesale]: BLAKEMORE_WHOLESALE_FEATURES,
    [TEAM_NUMBERS.blakemoreTradePartners]: BLAKEMORE_TRADE_PARTNERS_FEATURES,
    [TEAM_NUMBERS.blakemoreFoodservice]: BLAKEMORE_FEATURES,
    [TEAM_NUMBERS.blakemoreFineFoods]: BLAKEMORE_FEATURES,
    [TEAM_NUMBERS.filshill]: FILSHILL_FEATURE_FLAGS,
    [TEAM_NUMBERS.peach]: PEACH_FEATURES,
    [TEAM_NUMBERS.thames]: SANDERSON_APP_ENTRY_FEATURES,
    [TEAM_NUMBERS.threePears]: SANDERSON_APP_ENTRY_FEATURES,
    [TEAM_NUMBERS.kitwave]: SANDERSON_APP_ENTRY_FEATURES,
    [TEAM_NUMBERS.hbClark]: SANDERSON_APP_ENTRY_FEATURES,
    [TEAM_NUMBERS.mjBaker]: SANDERSON_APP_ENTRY_FEATURES,
    [TEAM_NUMBERS.htDrinks]: HT_DRINKS_FEATURES,
    [TEAM_NUMBERS.mummysocial]: MUMMY_SOCIAL_FEATURES,
    [TEAM_NUMBERS.brigdestonetpc]: BRIDGESTONE_FEATURES,
    [TEAM_NUMBERS.firestonetpc]: BRIDGESTONE_FEATURES,
    [TEAM_NUMBERS.confex]: CONFEX_FEATURES,
    [TEAM_NUMBERS.crowndale]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.giro]: SANDERSON_APP_ENTRY_FEATURES,
    [TEAM_NUMBERS.iainHill]: SANDERSON_APP_ENTRY_FEATURES,
    [TEAM_NUMBERS.eastAndWest]: SANDERSON_APP_ENTRY_FEATURES,
    [TEAM_NUMBERS.beamCommerceDemo]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.chadds]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.rowan]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.woodsFoodservice]: SANDERSON_APP_ENTRY_FEATURES,
    [TEAM_NUMBERS.chappleAndJenkinsSanderson]: SANDERSON_APP_ENTRY_FEATURES,
    [TEAM_NUMBERS.goodwinsDirect]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.chaddsFoodCollection]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.kcFoods]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.barnard]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.paulBentleyWholesale]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.beamCommerceDev]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.staxTradeCentres]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.brewBroker]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.mpos]: SANDERSON_APP_ENTRY_FEATURES,
    [TEAM_NUMBERS.rAndIJones]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.rowanInternationalEu]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore1]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore2]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.stlSolutions]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore4]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore5]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore6]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore7]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore8]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore9]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore10]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore11]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore12]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore13]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore14]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore15]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore16]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.qCatering]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.chappleAndJenkinsB2bStore]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.foodStoreInternational]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.paulBurtonDrinksB2C]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore21]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore22]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore23]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore24]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore25]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore26]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore27]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore28]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore29]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore30]: SANDERSON_APP_ENTRY_FEATURES,
    [TEAM_NUMBERS.b2bStore31]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore32]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore33]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore34]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore35]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore36]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore37]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore38]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore39]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore40]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore41]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore42]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore43]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore44]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore45]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore46]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore47]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore48]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore49]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore50]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore51]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore52]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore53]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore54]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore55]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore56]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore57]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore58]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore59]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore60]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore61]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore62]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore63]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore64]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore65]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore66]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore67]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore68]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore69]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore70]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore71]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore72]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore73]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore74]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore75]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore76]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore77]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore78]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore79]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore80]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore81]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore82]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore83]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore84]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore85]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore86]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore87]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore88]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore89]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore90]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore91]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore92]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore93]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore94]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore95]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.b2bStore96]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.sugro]: BEAM_COMMERCE_BASE_FEATURES,
    [TEAM_NUMBERS.oreillys]: SANDERSON_APP_ENTRY_FEATURES,
});

export default (teamNumber: number): FeatureFlags => {
    const teamFieldConfiguration = CONFIGURATION[teamNumber] || ENTRY;

    return immutable.fromJS(teamFieldConfiguration);
};
