import * as R from "ramda";
import {LOADING_STATUSES} from "../main/constants";
import {setLoadingStatus} from "../main/actionCreators";
import store from "../store";

const apiInvocation = async (method, args) => {
    store.dispatch(setLoadingStatus(LOADING_STATUSES.ONGOING));
    try {
        const result = await method(args);

        store.dispatch(setLoadingStatus(LOADING_STATUSES.DONE));

        return result;
    } catch (e) {
        store.dispatch(setLoadingStatus(LOADING_STATUSES.ERROR));
        throw e;
    }
};

export default R.curry(apiInvocation);
