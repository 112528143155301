import PopOverButton from "./PopOverButton";
import React, {memo, CSSProperties} from "react";
import {ButtonProps} from "react-bootstrap";

interface Props {
    buttonIconStyle?: CSSProperties;
    buttonStyle?: CSSProperties;
    children: JSX.Element;
    placement?: string;
    popOverId?: string;
    popOverTitle?: string | JSX.Element;
    size?: ButtonProps["bsSize"];
    style?: CSSProperties;
}

export default memo(function InfoButton({
    buttonIconStyle,
    buttonStyle,
    children,
    size = "sm",
    placement = "bottom",
    popOverId = "popOver",
    popOverTitle,
    style,
}: Props): JSX.Element {
    return (
        <PopOverButton
            transparent
            buttonIconName="info-circle"
            buttonIconStyle={buttonIconStyle}
            buttonStyle={buttonStyle}
            noBorder
            placement={placement}
            popOverId={popOverId}
            popOverTitle={popOverTitle}
            size={size}
            style={style}
        >
            {children}
        </PopOverButton>
    );
});
