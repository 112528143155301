import {createAction, handleActions} from "redux-actions";
import {STATUSES as WIDGET_STATUSES} from "./widgets/DashboardWidget";
import immutable from "immutable";
import widgetConfiguration from "./widgetConfiguration";

const WIDGET_DATA = "beam/dashboard/WIDGET_DATA";
const WIDGET_STATE_ERROR = "beam/dashboard/WIDGET_STATE_ERROR";
const WIDGET_STATE_LOADING = "beam/dashboard/WIDGET_STATE_LOADING";
const WIDGET_STATE_NORMAL = "beam/dashboard/WIDGET_STATE_NORMAL";
const SET_CHART_FILTER = "beam/dashboard/SET_CHART_FILTER";
const SET_CURRENT_TAB = "beam/dashboard/SET_CURRENT_TAB";
const SET_CHART_MULTI_FILTER = "beam/dashboard/SET_CHART_MULTI_FILTER";

export const widgetData = createAction(WIDGET_DATA);
export const setChartMultiFilter = createAction(SET_CHART_MULTI_FILTER);
export const setCurrentTab = createAction(SET_CURRENT_TAB);
export const widgetStateError = createAction(WIDGET_STATE_ERROR);
export const widgetStateLoading = createAction(WIDGET_STATE_LOADING);
export const widgetStateNormal = createAction(WIDGET_STATE_NORMAL);
export const setChartFilter = createAction(SET_CHART_FILTER);

const widgetDataPath = (widgetId) => ["widgets", widgetId, "widgetData"];
const widgetStatusPath = (widgetId) => ["widgets", widgetId, "widgetStatus"];

const makeWidgetData = (config) =>
    Object.keys(config).reduce(
        (acc, name) => ({
            ...acc,
            [name]: {
                widgetData: config[name].placeholderData,
                widgetStatus: WIDGET_STATUSES.normal,
            },
        }),
        {},
    );

const initialState = immutable.fromJS({
    widgets: makeWidgetData(widgetConfiguration),
    currentTab: "tobacco",
});

const handleWidgetData = (state, {payload}) => {
    const {widgetId, widgetData} = payload;

    return state.setIn(widgetDataPath(widgetId), widgetData);
};

const handleWidgetStateError = (state, {payload}) =>
    state.setIn(widgetStatusPath(payload), WIDGET_STATUSES.dataUnavailable);

const handleWidgetStateLoading = (state, {payload}) => {
    const defaultData = widgetConfiguration[payload]
        ? widgetConfiguration[payload].placeholderData
        : [];

    return state
        .setIn(widgetDataPath(payload), immutable.fromJS(defaultData))
        .setIn(widgetStatusPath(payload), WIDGET_STATUSES.dataLoading);
};

const handleWidgetStateNormal = (state, {payload}) =>
    state.setIn(widgetStatusPath(payload), WIDGET_STATUSES.normal);

const handleSetChartFilterData = (state, {payload}) =>
    state.update(payload.chartIdentifier, immutable.Map(), (chartData) =>
        chartData
            .set("widgetStatus", WIDGET_STATUSES.dataLoading)
            .set("activeFilters", immutable.fromJS({depotId: payload.depotId})),
    );

const handleSetChartMultiFilterData = (state, {payload}) =>
    state.setIn(
        ["widgets", payload.chartIdentifier, "currentFilters"],
        immutable.fromJS(payload.filter),
    );

const handleSetCurrentTab = (state, {payload}) =>
    state.set("currentTab", payload);

export default handleActions(
    {
        [SET_CHART_FILTER]: handleSetChartFilterData,
        [SET_CHART_MULTI_FILTER]: handleSetChartMultiFilterData,
        [WIDGET_DATA]: handleWidgetData,
        [WIDGET_STATE_ERROR]: handleWidgetStateError,
        [WIDGET_STATE_LOADING]: handleWidgetStateLoading,
        [WIDGET_STATE_NORMAL]: handleWidgetStateNormal,
        [SET_CURRENT_TAB]: handleSetCurrentTab,
    },
    initialState,
);
