import {DropdownList} from "react-widgets";
import {Iterable} from "immutable";
import React, {memo} from "react";

interface Props {
    //@ts-ignore
    items: Array<Object> | List | Map;
    onChange: (arg0: string) => void;
    placeholder: string;
    propLabel?: string;
    propValue?: string;
    value: string | number | void;
    disabled?: boolean;
}

const objToArray = (objectToConvert: any): Array<any> =>
    Object.keys(objectToConvert).map((key: any) => objectToConvert[key]);

// @ts-ignore
const formatToNonImmutable = (items: Map): Array<any> => {
    const formattedItems = items.toJS();

    if (formattedItems && !Array.isArray(formattedItems)) {
        return objToArray(formattedItems);
    }
    return formattedItems;
};

export default memo(function DropDown({
    items,
    onChange,
    placeholder,
    propLabel = "label",
    propValue = "value",
    value,
    disabled,
}: Props): JSX.Element {
    return (
        <DropdownList
            data={
                Iterable.isIterable(items) ? formatToNonImmutable(items) : items
            }
            disabled={disabled}
            onChange={({value}) => onChange(value)}
            placeholder={placeholder}
            textField={propLabel}
            value={value}
            valueField={propValue}
        />
    );
});
