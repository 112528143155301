/*       */

import {action, toJS} from "mobx";

export class EntityException extends Error {}

const capitalizeFirstLetter = (string        ) =>
    string.charAt(0).toUpperCase() + string.slice(1);
const validateUpdate = (key        , value      ) => {
    // eslint-disable-next-line no-eq-null, eqeqeq
    if (value == null) {
        throw new EntityException(
            `Error updating Entity. The value for '${key}' is null or undefined. Entity does not support null or undefined values.`,
        );
    }
};

                          
                                                               
                                   
 

export default class Entity                    {
    static fromJSON(dataToUpdate                                )       {
        const self = new this();

        self.update(dataToUpdate);

        return self;
    }

    @action
    update(dataToUpdate                                )       {
        const json =
            dataToUpdate instanceof Entity
                ? dataToUpdate.toJSON()
                : dataToUpdate;

        // eslint-disable-next-line complexity
        Object.entries(json).forEach(([key, value]) => {
            if (!(key in this)) {
                return;
            }

            validateUpdate(key, value);

            // $FlowIgnore
            const Class = this.constructor[capitalizeFirstLetter(key)];

            // $FlowIgnore
            const ArrayItemClass = this.constructor[
                `${capitalizeFirstLetter(key)}Item`
            ];

            if (Class) {
                // $FlowIgnore
                this[key] = Class.fromJSON(value);
            } else if (ArrayItemClass && Array.isArray(value)) {
                // $FlowIgnore
                this[key] = value.map((item) => ArrayItemClass.fromJSON(item));
            } else {
                // $FlowIgnore
                this[key] = value;
            }
        });
    }

    toJSON()                       {
        return toJS(this);
    }
}
