import {Col} from "react-bootstrap";
import DetailRow from "../../widget/DetailRow";
import React, {memo} from "react";

const CAMPAIGN_PATH = "#/campaigns/";

interface Props {
    associatedCampaign: any;
}

export default memo(function AssociatedCampaign({
    associatedCampaign,
}: Props): JSX.Element {
    return (
        <Col md={8}>
            <DetailRow label="CAMPAIGN NAME:">
                <a href={`${CAMPAIGN_PATH}${associatedCampaign}`}>
                    View Campaign
                </a>
            </DetailRow>
        </Col>
    );
});
