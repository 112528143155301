import * as R from "ramda";

const isMultipartFormData = R.compose(R.not, R.isNil, R.path(["_formData"]));

const addFormField = (teamNumber, httpAgent) => {
    httpAgent.field("teamNumber", teamNumber);

    return httpAgent;
};

export default (teamNumber, httpAgent) => {
    if (isMultipartFormData(httpAgent)) {
        return addFormField(teamNumber, httpAgent);
    }

    const methodToCall = {
        DELETE: "query",
        GET: "query",
        POST: "send",
        PUT: "send",
    };

    return httpAgent[methodToCall[httpAgent.method]]({teamNumber});
};
