import {
    BANNER,
    FEATURED,
    INTERSTITIAL,
    INTERSTITIAL_ENTRANCE,
    NEW_PRODUCTS,
    OFFERS_FOR_YOU,
    PRODUCT_LIST,
    RELATED_PRODUCTS,
    TRENDING_PRODUCTS,
} from "../advert/PlacementConstants";

const MESSAGE_AUDIENCE_DEVICE_PLATFORM = {devicePlatform: true};
const MESSAGE_AUDIENCE_SALES = {sales: true};
const MESSAGE_AUDIENCE_USERSEGMENT = {userSegment: true};
const MESSAGE_AUDIENCE_USERIDENTIFIER = {userIdentifier: true};
const MESSAGE_AUDIENCE_NOTIFICATION_ACTIONED = {
    userNotificationNotActioned: false,
};
const MESSAGE_AUDIENCE_INACTIVE_USERS = {inactiveUsers: true};

const MESSAGE_AUDIENCE_WITHOUT_ORDER_IN_DAYS = {userWithoutOrderInDays: true};
const MESSAGE_FBNOTIFICATION_PRODUCT_URL = {productUrl: true};
const MESSAGE_FBNOTIFICATION_PRODUCT_DEEPLINK = {productDeeplink: true};
const MESSAGE_MESSAGE = {message: true};
const MESSAGE_SCHEDULE = {schedule: true};
const MESSAGE_GROUPING = {messageGrouping: true};

const BUSINESS_INTELLIGENCE_DASHBOARD = {businessIntelligenceDashboard: true};
const PUSH_NOTIFICATIONS_ENABLED = {pushNotificationEnabled: true};
const TOTAL_DOWNLOAD_CHARTS = {totalDownloadChart: true};
const MONTHLY_ACTIVE_DEVICES_CHART = {monthlyActiveDevicesChart: true};
const ACTIVE_PURCHASERS_CHART = {activePurchasersChart: true};
const APP_TRANSACTION_AND_SALES_CHART = {appTransactionAndSalesChart: true};
const ACTIVE_PURCHASERS_AVERAGE_ORDER_SIZE_CHART = {
    activePurchasersAverageOrderSizeChart: true,
};
const FORT_NIGHT_COMPARISON_CHARTS = {fortnightComparisonCharts: true};
const DUPLICATE_PUSH_NOTIFICATION = {duplicatePushNotification: true};
const SCHEDULED_ADVERT = {scheduledAdvert: true};
const EXPORT_CSV = {exportCSV: true};
const INTERSTITIAL_ADS = {interstitialAds: true};
const INTERSTITIAL_ADS_ENTRANCE = {interstitialAdsEntrance: true};
const PLACEMENT_BANNER = {banner: true};
const FEATURED_PLACEMENT = {featured: true};
const ACTION_VIEW_WEBSITE = {actionViewWebsite: true};
const ACTION_VIEW_PRODUCT_LIST = {actionViewProductList: true};
const ACTION_VIEW_PRODUCT_DETAILS = {actionViewProductDetails: true};
const ACTION_ADD_PRODUCT_TO_BASKET = {actionAddProductToBasket: true};
const ADVERT_DEEP_LINKING = {advertDeepLinking: true};
const CAMPAIGN_CHART = {campaignCharts: true};
const STATISTICS_DEMO = {statisticsDemo: true};
const APP_REVIEWS = {appReviews: true};

const TRACKING_ADVERTS_INFO = {trackingAdvertsInfo: true};

const ADVERT_CONFIG = {adFrequencyConfigEnabled: true};
const INTERSTITIAL_CTA = {interstitialAdCTAEnabled: true};

export const WHOLESALER_DASHBOARD = {
    ...ACTIVE_PURCHASERS_CHART,
    ...APP_TRANSACTION_AND_SALES_CHART,
    ...ACTIVE_PURCHASERS_AVERAGE_ORDER_SIZE_CHART,
    ...FORT_NIGHT_COMPARISON_CHARTS,
    ...APP_REVIEWS,
    ...TOTAL_DOWNLOAD_CHARTS,
};
const ALL_DASHBOARD = {
    dashboard: {
        audienceLandscape: {
            ...PUSH_NOTIFICATIONS_ENABLED,
        },
        ...WHOLESALER_DASHBOARD,
    },
};

const UNLIMITED_AD_PLACEMENT_LIMIT = {
    placementLimits: {
        [INTERSTITIAL]: 0,
        [INTERSTITIAL_ENTRANCE]: 0,
        [BANNER]: 0,
        [FEATURED]: 0,
        [PRODUCT_LIST]: 0,
        [NEW_PRODUCTS]: 0,
        [OFFERS_FOR_YOU]: 0,
        [TRENDING_PRODUCTS]: 0,
        [RELATED_PRODUCTS]: 0,
    },
};

const ENTRY_AD_PLACEMENT_LIMIT = {
    placementLimits: {
        [INTERSTITIAL]: 1,
        [BANNER]: 1,
        [FEATURED]: 1,
        [PRODUCT_LIST]: 1,
    },
};

export const MESSAGE_FBNOTIFICATION_PRODUCT_DETAILS_DEEPLINK = {
    productDetailsDeeplink: true,
};

export const ALL_MESSAGING = Object.freeze({
    messaging: {
        audience: {
            ...MESSAGE_AUDIENCE_SALES,
            ...MESSAGE_AUDIENCE_USERSEGMENT,
            ...MESSAGE_AUDIENCE_USERIDENTIFIER,
            ...MESSAGE_AUDIENCE_NOTIFICATION_ACTIONED,
            ...MESSAGE_AUDIENCE_DEVICE_PLATFORM,
        },
        ...MESSAGE_MESSAGE,
        fbNotification: {
            ...MESSAGE_FBNOTIFICATION_PRODUCT_URL,
        },
        ...MESSAGE_SCHEDULE,
    },
    messageList: {
        ...MESSAGE_GROUPING,
    },
});

export const ENTRY = Object.freeze({
    messaging: {
        audience: {
            ...MESSAGE_AUDIENCE_USERSEGMENT,
            ...MESSAGE_AUDIENCE_USERIDENTIFIER,
        },
        ...MESSAGE_MESSAGE,
    },
    messageList: {
        ...MESSAGE_GROUPING,
    },
    dashboard: {
        audienceLandscape: {
            ...PUSH_NOTIFICATIONS_ENABLED,
        },
        ...WHOLESALER_DASHBOARD,
    },
    placements: {
        ...INTERSTITIAL_ADS,
    },
    ...ENTRY_AD_PLACEMENT_LIMIT,
    ...MONTHLY_ACTIVE_DEVICES_CHART,
    ...DUPLICATE_PUSH_NOTIFICATION,
});

export const ENTERPRISE = Object.freeze({
    ...ALL_MESSAGING,
    ...ALL_DASHBOARD,

    placements: {
        ...INTERSTITIAL_ADS,
        ...PLACEMENT_BANNER,
        ...FEATURED_PLACEMENT,
    },
    placementLimits: {
        [INTERSTITIAL]: 0,
        [BANNER]: 0,
        [FEATURED]: 0,
    },
    ...MONTHLY_ACTIVE_DEVICES_CHART,
    ...DUPLICATE_PUSH_NOTIFICATION,
    ...EXPORT_CSV,
});

export const PREMIUM = Object.freeze({
    messaging: {
        audience: {
            ...MESSAGE_AUDIENCE_USERSEGMENT,
            ...MESSAGE_AUDIENCE_USERIDENTIFIER,
            ...MESSAGE_AUDIENCE_NOTIFICATION_ACTIONED,
        },
        fbNotification: {},
        ...MESSAGE_MESSAGE,
        ...MESSAGE_SCHEDULE,
    },
    messageList: {
        ...MESSAGE_GROUPING,
    },
    dashboard: {
        audienceLandscape: {
            ...PUSH_NOTIFICATIONS_ENABLED,
        },
        ...WHOLESALER_DASHBOARD,
        totalDownloadChart: true,
    },
    ...MONTHLY_ACTIVE_DEVICES_CHART,
    placements: {
        ...INTERSTITIAL_ADS,
        ...PLACEMENT_BANNER,
        ...FEATURED_PLACEMENT,
    },
});

export const DEVELOPMENT_MESSAGES = Object.freeze({
    messaging: {
        audience: {
            ...MESSAGE_AUDIENCE_SALES,
            ...MESSAGE_AUDIENCE_USERSEGMENT,
            ...MESSAGE_AUDIENCE_USERIDENTIFIER,
            ...MESSAGE_AUDIENCE_NOTIFICATION_ACTIONED,
            ...MESSAGE_AUDIENCE_DEVICE_PLATFORM,
        },
        ...MESSAGE_MESSAGE,
        fbNotification: {},
        ...MESSAGE_SCHEDULE,
    },
    messageList: {
        ...MESSAGE_GROUPING,
    },
});

export const BETA = Object.freeze({
    ...ENTERPRISE,
    ...BUSINESS_INTELLIGENCE_DASHBOARD,
    placements: {
        ...INTERSTITIAL_ADS,
        ...PLACEMENT_BANNER,
        ...INTERSTITIAL_ADS_ENTRANCE,
        ...TRACKING_ADVERTS_INFO,
    },
    ...UNLIMITED_AD_PLACEMENT_LIMIT,
    ...DEVELOPMENT_MESSAGES,
    messaging: {
        ...ENTERPRISE.messaging,
        fbNotification: {
            ...MESSAGE_FBNOTIFICATION_PRODUCT_URL,
            ...MESSAGE_FBNOTIFICATION_PRODUCT_DEEPLINK,
        },
        audience: {
            ...ENTERPRISE.messaging.audience,
            ...MESSAGE_AUDIENCE_WITHOUT_ORDER_IN_DAYS,
            ...MESSAGE_AUDIENCE_DEVICE_PLATFORM,
            ...MESSAGE_AUDIENCE_INACTIVE_USERS,
        },
    },
    adActions: {
        ...ACTION_VIEW_WEBSITE,
        ...ACTION_VIEW_PRODUCT_DETAILS,
        ...ACTION_ADD_PRODUCT_TO_BASKET,
        ...ACTION_VIEW_PRODUCT_LIST,
    },
    ...ADVERT_DEEP_LINKING,
    ...SCHEDULED_ADVERT,
    ...STATISTICS_DEMO,
    ...ADVERT_CONFIG,
    ...INTERSTITIAL_CTA,
});

const DEVELOPMENT_MESSAGING = Object.freeze({
    messaging: {
        audience: {
            ...MESSAGE_AUDIENCE_SALES,
            ...MESSAGE_AUDIENCE_USERSEGMENT,
            ...MESSAGE_AUDIENCE_USERIDENTIFIER,
            ...MESSAGE_AUDIENCE_NOTIFICATION_ACTIONED,
            ...MESSAGE_AUDIENCE_DEVICE_PLATFORM,
        },
        ...MESSAGE_MESSAGE,
        fbNotification: {
            ...MESSAGE_FBNOTIFICATION_PRODUCT_URL,
            ...MESSAGE_FBNOTIFICATION_PRODUCT_DEEPLINK,
        },
        ...MESSAGE_SCHEDULE,
    },
    messageList: {
        ...MESSAGE_GROUPING,
    },
});

export const DEVELOPMENT_FEATURES = {
    ...BETA,
    ...DEVELOPMENT_MESSAGING,
    ...CAMPAIGN_CHART,
};
