import ImmutablePropTypes from "react-immutable-proptypes";
import PropTypes from "prop-types";
import React from "react";
import {Col, Row} from "react-bootstrap";

const styles = Object.freeze({
    legendColumn: {
        listStyle: "none",
        margin: 0,
        padding: 0,
        fontSize: 19,
    },
    listItem: {fontSize: 12},
});

const LegendList = ({colorScheme, legends}) => {
    const legendListItems = legends.map((legend, index) => {
        const squareStyle = {color: `${colorScheme[index]}`, marginRight: -25};

        return (
            <Row key={index} style={styles.listItem}>
                <Col md={1}>
                    <i className="fa fa-square" style={squareStyle} />
                </Col>
                <Col md={1} style={styles.label}>
                    {legend}
                </Col>
            </Row>
        );
    });

    return <ul style={styles.legendColumn}>{legendListItems}</ul>;
};

LegendList.propTypes = {
    colorScheme: PropTypes.arrayOf(PropTypes.string).isRequired,
    legends: ImmutablePropTypes.listOf(PropTypes.string).isRequired,
};

export default LegendList;
