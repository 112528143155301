import PromiseFileReader from "promise-file-reader";
import React, {memo, useEffect, useState} from "react";
import RichNotification from "./RichNotification";

interface Props {
    action1: string;
    action2: boolean | string;
    bodyText: string;
    image: string;
    imageFile: {
        name: string;
    };
}

export default memo(function RichNotificationContainer({
    action1,
    action2,
    bodyText,
    image,
    imageFile,
}: Props): JSX.Element {
    const [imageUri, setImageUri] = useState("");
    const readFile = async () => {
        if (!imageFile) return;

        onImagePreviewReset();
        try {
            const imagePreview = await PromiseFileReader.readAsDataURL(
                imageFile,
            );
            onImagePreviewSet(imagePreview);
        } catch (e) {}
    };

    const onImagePreviewReset = () => {
        setImageUri("");
    };
    const onImagePreviewSet = (imagePreview: string) => {
        setImageUri(imagePreview);
    };
    useEffect(() => {
        readFile();
    }, [imageFile.name]);

    return (
        <RichNotification
            dismissButtonText={action1}
            enableButtonText={action2}
            image={image}
            imageUri={imageUri}
            bodyText={bodyText}
        />
    );
});
