import * as R from "ramda";
import {Button, Col, Row} from "react-bootstrap";
import {
    onMessageGroupItemsUpdate as componentWillReceiveProps,
    onMoveNotification,
    onMoveNotificationButtonClick as onSubmitButtonClicked,
} from "./eventHandlers";
import {connect} from "react-redux";
import {messageListSelector as mapStateToProps} from "./selector";
import createComponent from "@beam/create-component";
import PopOverButton from "../common/buttons/PopOverButton";
import React from "react";
import Select from "../widget/Select";

const styles = {
    createButton: {
        width: 244,
        marginTop: 10,
    },
    input: {
        width: 244,
    },
    container: {
        position: "relative",
    },
};

const MoveToGroupPopOver = createComponent(
    {
        displayName: "MoveToGroupPopOver",
        initialState: {
            selectedGroup: "",
            isSubmitting: false,
            items: [],
        },
        handlers: {
            onSubmitButtonClicked,
            onChangeGroup: ({setState}) => (e) => {
                setState({
                    selectedGroup: e.target.value,
                });
            },
        },
        lifecycle: {
            componentWillReceiveProps,
        },
        pure: true,
    },
    ({
        selectedGroup,
        onChangeGroup,
        items,
        onSubmitButtonClicked,
        notifications,
        style,
        isSubmitting,
    }) => {
        const isNotificationEmpty = R.isEmpty(notifications);

        return (
            <PopOverButton
                buttonIconName="exchange"
                buttonTitle="Move Notification"
                disabled={isNotificationEmpty}
                popOverId="move-group"
                popOverTitle={`Move selected notifications (${R.length(
                    notifications,
                )})`}
                size="sm"
                style={style}
            >
                <Row>
                    <Col md={12}>
                        <Select
                            items={items}
                            onChange={onChangeGroup}
                            placeholder="Select a group"
                            value={selectedGroup}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Button
                            bsStyle="primary"
                            disabled={
                                isSubmitting ||
                                isNotificationEmpty ||
                                R.not(selectedGroup)
                            }
                            onClick={onSubmitButtonClicked}
                            style={styles.createButton}
                        >
                            {isSubmitting ? "moving notifications..." : "Move"}
                        </Button>
                    </Col>
                </Row>
            </PopOverButton>
        );
    },
);

export default connect(mapStateToProps, (dispatch) => ({
    onMoveNotification: (config) => dispatch(onMoveNotification(config)),
}))(MoveToGroupPopOver);
