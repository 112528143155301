import {connect} from "react-redux";
import {createSelector} from "reselect";
// @ts-ignore
import {removeById} from "./notificationDuck";
import Notification from "./Notification";
import React from "react";
import {makeStyles} from "@material-ui/styles";
const Z_INDEX_OVER_NAVBAR = 1100;

const useStyles = makeStyles({
    container: {
        position: "fixed",
        top: 10,
        right: 100,
        zIndex: Z_INDEX_OVER_NAVBAR,
    },
});

interface Props {
    notifications: any;
    removeNotification: (id: string) => void;
}

function NotificationListComponent({
    notifications = [],
    removeNotification,
}: Props) {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            {notifications.map((notification: any, index: number) => (
                <Notification
                    key={index}
                    message={notification.get("message")}
                    onClick={() => removeNotification(notification.get("id"))}
                    type={notification.get("type")}
                />
            ))}
        </div>
    );
}

const dispatchSelector = createSelector(
    (dispatch) => dispatch,
    (dispatch: any) => () => ({
        removeNotification: (id: number) => () => dispatch(removeById(id)),
    }),
);

export default connect(
    (state: any) => ({notifications: state.notification}),
    dispatchSelector,
    // @ts-ignore
)(NotificationListComponent);
