import * as R from "ramda";
import {createSelector, createStructuredSelector} from "reselect";
import {getAppName, isBCPTeam} from "./getNotificationLinkUrl";
// @ts-ignore
import {linkActions, linkActionsMapping} from "./PushNotification";
import {Moment} from "moment";
import getBCPActionAndValue from "../advert/getBCPActionAndValue";

const appName = getAppName();

const viewProductUrlPrefix: string = `${appName}://product/view/`;
const addProductToBasketUrlPrefix: string = `${appName}://product/add/`;
const productListUrlPrefix: string = `${appName}://product/list/`;
const addToProductListUrlPrefix: string = `${appName}://list/add/`;

const urlIncludesPrefix = (url: string, prefix: string): boolean =>
    Boolean(url && url.indexOf(prefix) > -1);

export const isViewProductUrl = (url: string): boolean =>
    urlIncludesPrefix(url, viewProductUrlPrefix);
export const isAddToBasketUrl = (url: string): boolean =>
    urlIncludesPrefix(url, addProductToBasketUrlPrefix);
export const isProductListUrl = (url: string): boolean =>
    urlIncludesPrefix(url, productListUrlPrefix);
export const isAddToProductListUrl = (url: string): boolean =>
    urlIncludesPrefix(url, addToProductListUrlPrefix);

const getFbNotification = R.propOr({}, "fbNotification");
const getGlobalRule = R.propOr({}, "globalRule");

const getMessage = R.prop("message");
const getUrlToOpen = R.prop("urlToOpen");

const getMessageText = createSelector(
    getFbNotification,
    R.path(["body", "content", "text"]),
);
const getImageHeight = createSelector(
    getFbNotification,
    R.path(["hero", "background", "height"]),
);
const getImageWidth = createSelector(
    getFbNotification,
    R.path(["hero", "background", "width"]),
);
const getMessageImageUrl = createSelector(
    getFbNotification,
    R.path(["hero", "background", "url"]),
);
const getMessageButtonText = createSelector(
    getFbNotification,
    R.path(["actions", 1, "content", "text"]),
);
const getMessageActionUrl: any = createSelector(
    getFbNotification,
    R.path(["actions", 1, "url"]),
);
const getWantFbNotification = createSelector(getFbNotification, R.has("body"));
const getDismissButtonText = createSelector(
    getFbNotification,
    R.path(["actions", 0, "content", "text"]),
);
const getDescription = R.prop("description");

export const isDeepLinkingUrl = (actionUrl: string) =>
    isViewProductUrl(actionUrl) ||
    isAddToBasketUrl(actionUrl) ||
    isProductListUrl(actionUrl) ||
    isAddToProductListUrl(actionUrl);

export const getButtonUrl = createSelector(
    getMessageActionUrl,
    (actionUrl: string) => {
        if (isDeepLinkingUrl(actionUrl)) {
            return "";
        }

        return actionUrl;
    },
);

// eslint-disable-next-line complexity
export const getLinkActionFromUrl = (actionUrl: string): string => {
    if (actionUrl) {
        if (isBCPTeam()) {
            const {action} = getBCPActionAndValue(actionUrl);
            //@ts-ignore
            return linkActionsMapping[action] || linkActions.VISIT_WEBSITE;
        }
        if (isViewProductUrl(actionUrl)) {
            return linkActions.VIEW_PRODUCT_DETAILS;
        } else if (isAddToBasketUrl(actionUrl)) {
            return linkActions.ADD_PRODUCT_TO_BASKET;
        } else if (isProductListUrl(actionUrl)) {
            return linkActions.VIEW_PRODUCT_LIST;
        } else if (isAddToProductListUrl(actionUrl)) {
            return linkActions.ADD_PRODUCT_TO_LIST;
        } else if (!actionUrl || !actionUrl.length) {
            return linkActions.NONE;
        }

        return linkActions.NONE;
    } else {
        return linkActions.NONE;
    }
};

const getLinkAction = createSelector(getMessageActionUrl, (actionUrl: string) =>
    getLinkActionFromUrl(actionUrl),
);

export const getProductSkuFromUrl = (actionUrl: string): string => {
    if (actionUrl) {
        if (isBCPTeam()) {
            const {actionValue} = getBCPActionAndValue(actionUrl);
            return actionValue;
        }
        if (isViewProductUrl(actionUrl)) {
            return actionUrl.replace(viewProductUrlPrefix, "");
        } else if (isAddToBasketUrl(actionUrl)) {
            return actionUrl.replace(addProductToBasketUrlPrefix, "");
        } else if (isProductListUrl(actionUrl)) {
            return actionUrl.replace(productListUrlPrefix, "");
        } else if (isAddToProductListUrl(actionUrl)) {
            return actionUrl.replace(addToProductListUrlPrefix, "");
        }
        return "";
    } else {
        return "";
    }
};

const getProductSku = createSelector(getMessageActionUrl, (actionUrl: string) =>
    getProductSkuFromUrl(actionUrl),
);

const getSchedule = createSelector(
    getGlobalRule,
    R.path(["conditions", 0, "value"]),
);

const getActionParams = createSelector(
    getFbNotification,
    R.path(["actions", 1, "actionParams"]),
);

const getActionType = createSelector(
    getFbNotification,
    R.path(["actions", 1, "actionType"]),
);

const emptyObject = {};

interface MessageFormData {
    alert: {
        message: string;
        urlToOpen: string;
    };
    message: {
        actionUrl: string;
        backgroundUrl: string;
        buttonText: string;
        text: string;
        wantFbNotification: boolean;
        dismissButtonText?: string;
        linkAction?: string;
        productSku?: string;
        buttonUrl?: string;
    };
    schedule: {
        sendDate: string | Moment;
    };
}

// @ts-ignore
const messageToFormFormat: (payload: any) => MessageFormData = createSelector(
    (state) => (typeof state === "undefined" ? emptyObject : state),
    createStructuredSelector({
        alert: createStructuredSelector({
            message: getMessage,
            urlToOpen: getUrlToOpen,
            description: getDescription,
        }),
        message: createStructuredSelector({
            height: getImageHeight,
            width: getImageWidth,
            backgroundUrl: getMessageImageUrl,
            buttonText: getMessageButtonText,
            buttonUrl: getButtonUrl,
            dismissButtonText: getDismissButtonText,
            linkAction: getLinkAction,
            productSku: getProductSku,
            actionParams: getActionParams,
            actionType: getActionType,
            text: getMessageText,
            wantFbNotification: getWantFbNotification,
        }),
        schedule: createStructuredSelector({
            sendDate: getSchedule,
        }),
    }),
);

export default messageToFormFormat;
