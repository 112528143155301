import React, {memo, useEffect, useState} from "react";
import {inject, observer} from "mobx-react";
import DropDown from "../../widget/DropDown";
import {Col, Row} from "react-bootstrap";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles({
    container: {
        float: "right",
    },
    dropdownContainer: {
        width: 373,
    },
    headingTopMargin: {
        marginTop: 0,
    },
});

interface Props {
    apiClient?: any;
    onChangeFilter: any;
}

const ChildrenTeamFilter = memo(({apiClient, onChangeFilter}: Props) => {
    const [metaTeams, setMetaTeams] = useState([{value: "", text: "All"}]);
    const [selectedMetaTeam, setSelectedMetaTeam] = useState("");
    const classes = useStyles();

    useEffect(() => {
        onFetchMetaTeams();
    }, []);

    const onFetchMetaTeams = async () => {
        const metaTeams = await apiClient!.invokeGetEndpoint("metaTeams");
        const metaTeamsData = metaTeams.map(
            (item: {teamNumber: number; teamName: string}) => ({
                value: item.teamNumber,
                text: item.teamName,
            }),
        );

        setMetaTeams([{value: "", text: "All"}, ...metaTeamsData]);
    };

    const setMetaFilter = (value: string) => {
        setSelectedMetaTeam(value);
        onChangeFilter(value);
    };

    return (
        <Row className={classes.container}>
            <Col md={12} className={classes.dropdownContainer}>
                <h4 className={classes.headingTopMargin}>Wholesaler Filter</h4>
                <div className={classes.dropdownContainer}>
                    <DropDown
                        placeholder=""
                        items={metaTeams}
                        onChange={setMetaFilter}
                        propLabel="text"
                        value={selectedMetaTeam}
                    />
                </div>
            </Col>
        </Row>
    );
});

@inject("apiClient")
@observer
export default class ChildrenTeamFilterContainer extends React.Component<
    Props
> {
    render() {
        const {apiClient, onChangeFilter} = this.props;

        return (
            <ChildrenTeamFilter
                apiClient={apiClient}
                onChangeFilter={onChangeFilter}
            />
        );
    }
}
