import * as R from "ramda";
import {addSchemeToUrl} from "../common/processUrlScheme";
// @ts-ignore
import {linkActions} from "./PushNotification";
// @ts-ignore
import {loadUser} from "../main/repository";
import TEAM_NUMBERS, {BCP_TEAMS} from "../teamNumbers";
import URI from "urijs";
import {
    ACTION_NONE,
    ACTION_VIEW_WEBSITE,
    ACTION_VIEW_PRODUCT_DETAILS,
    ACTION_VIEW_PRODUCT_LIST,
    ACTION_ADD_PRODUCT_TO_BASKET,
    ACTION_ADD_PRODUCT_TO_LIST,
} from "../advert/constants";

export const isBCPTeam = () => {
    const user = loadUser();
    const {teamNumber} = user.activeTeam || {};

    return Boolean(BCP_TEAMS.includes(teamNumber));
};

const getTeamName = (teamNumber: number): string => {
    return (
        Object.keys(TEAM_NUMBERS).find(
            (key) => TEAM_NUMBERS[key] === teamNumber,
        ) || ""
    );
};

export const getAppName = (): string => {
    const user = loadUser();

    if (!user || !user.activeTeam) return "";
    const {teamNumber} = user.activeTeam;

    if (teamNumber === 8888888 || teamNumber === 1111111) {
        return "beamDemo";
    }

    return getTeamName(teamNumber);
};

export const generateBCPViewProductListUrl = (skus: string) => {
    const newSkusArray = URI.encode(skus);
    const screenTitle = "Featured Products";
    return `/featured-products/?screenTitle=${screenTitle}&query=${newSkusArray}`;
};

export const generateBCPViewProductDetailsUrl = (sku: string) => {
    return `/products/${sku}/`;
};

export const generateBCPAddProductToBasketUrl = (skus: string): string => {
    return `/cart/?addToBasket=${skus}`;
};

export const generateAddProductUrl = (
    sku: string,
    teamName: string,
): string => {
    const uriSku = URI.encode(sku);

    return isBCPTeam()
        ? generateBCPAddProductToBasketUrl(uriSku)
        : `${teamName}://product/add/${uriSku}`;
};

export const generateAddProductToList = (
    skus: string,
    teamName: string,
): string => {
    const sku = skus.split(",")[0].trim() || "";
    const uriSku = URI.encode(sku);

    return `${teamName}://list/add/${uriSku}`;
};

export const generateViewProductUrl = (
    sku: string,
    teamName: string,
): string => {
    const uriSku = URI.encode(sku);

    if (isBCPTeam()) {
        return generateBCPViewProductDetailsUrl(uriSku);
    }

    return `${teamName}://product/view/${uriSku}`;
};

export const generateViewProductListUrl = (
    skus: string = "",
    teamName: string,
): string => {
    const formattedProductSkus = skus
        .split(",")
        .map((productSku: string): string => URI.encode(productSku.trim()));
    const filteredProductSkus = formattedProductSkus.filter(
        (productSku: string): boolean => !R.isEmpty(productSku),
    );

    if (isBCPTeam()) {
        return generateBCPViewProductListUrl(filteredProductSkus.join(","));
    }

    return `${teamName}://product/list/${filteredProductSkus.join(",")}`;
};

export const generateViewWebsiteUrl = (url: string): string =>
    addSchemeToUrl(url);

interface Args {
    linkAction: string;
    productSku: string;
    buttonUrl: string;
}

export const getLinkActionType = (linkAction: string) => {
    switch (linkAction) {
        case linkActions.VIEW_PRODUCT_DETAILS:
            return ACTION_VIEW_PRODUCT_DETAILS;
        case linkActions.ADD_PRODUCT_TO_BASKET:
            return ACTION_ADD_PRODUCT_TO_BASKET;
        case linkActions.ADD_PRODUCT_TO_LIST:
            return ACTION_ADD_PRODUCT_TO_LIST;
        case linkActions.VIEW_PRODUCT_LIST:
            return ACTION_VIEW_PRODUCT_LIST;
        case linkActions.VISIT_WEBSITE:
            return ACTION_VIEW_WEBSITE;
        default:
            return ACTION_NONE;
    }
};

export default ({linkAction, productSku, buttonUrl}: Args): string => {
    const teamName = getAppName();

    switch (linkAction) {
        case linkActions.VIEW_PRODUCT_DETAILS:
            return generateViewProductUrl(productSku, teamName);
        case linkActions.ADD_PRODUCT_TO_BASKET:
            return generateAddProductUrl(productSku, teamName);
        case linkActions.ADD_PRODUCT_TO_LIST:
            return generateAddProductToList(productSku, teamName);
        case linkActions.VIEW_PRODUCT_LIST:
            return generateViewProductListUrl(productSku, teamName);
        default:
            return generateViewWebsiteUrl(buttonUrl);
    }
};
