import moment from "moment";

export default (date: Date | null) => {
    if (!date) {
        return null;
    }

    const momentDate = moment(date); // eslint-disable-line moment-utc/no-moment-without-utc
    const now = moment(); // eslint-disable-line moment-utc/no-moment-without-utc

    if (momentDate.isBefore(now)) {
        return now;
    }

    return momentDate;
};
