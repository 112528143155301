interface Params {
    part: number;
    total: number;
    withDecimalPercentage?: boolean;
}

const getPercentage = ({
    part,
    total,
    withDecimalPercentage,
}: Params): number => {
    if (total === 0 || part === 0) {
        return 0;
    }

    const percentage = (part / total) * 100;

    if (withDecimalPercentage) {
        return Number(percentage.toFixed(2));
    }

    return Math.floor(percentage);
};

export default getPercentage;
