import {Col, Row} from "react-bootstrap";
import bestwayProductCategory from "../../bestwayProductCategory";
import DetailRow from "../../../widget/DetailRow";
import filterListByProp from "../filterListByProp";
import getPurchaseActionNumOfDays from "../../getPurchaseActionNumOfDays";
import React, {memo} from "react";

interface PurchaseActionDetailRowProps {
    numberOfDays: number;
    purchaseAction: string;
    skus: string;
}

const SpentRow = ({spent}: any): JSX.Element => {
    const category =
        //@ts-ignore
        bestwayProductCategory[spent.getIn(["value", "categoryIds"]).first()];

    return (
        <DetailRow label="Has spent in last 30 days:">
            {spent.getIn(["value", "minimumSpend"])}
            <strong> on </strong>
            <strong> {category} </strong>
        </DetailRow>
    );
};

const PurchaseActionDetailRow = ({
    numberOfDays,
    purchaseAction,
    skus,
}: PurchaseActionDetailRowProps) => (
    <DetailRow
        label={`Has ${purchaseAction} SKU in last ${numberOfDays} ${
            numberOfDays > 1 ? "days" : "day"
        }:`}
    >
        {skus}
    </DetailRow>
);

// eslint-disable-next-line complexity
export default memo(function Sales({sales}: any): JSX.Element {
    const filterSalesByOperation = filterListByProp(sales, "operation");
    //@ts-ignore
    const purchased = filterSalesByOperation("purchased").first();
    const purchasedNumOfDays = purchased
        ? Number(getPurchaseActionNumOfDays(purchased.get("value")))
        : null;
    //@ts-ignore
    const notPurchased = filterSalesByOperation("notPurchased").first();
    const notPurchasedNumOfDays = notPurchased
        ? Number(getPurchaseActionNumOfDays(notPurchased.get("value")))
        : null;
    //@ts-ignore
    const spent = filterSalesByOperation("spent").first();

    return (
        <Row>
            <hr />
            <Col md={8}>
                {purchased && purchasedNumOfDays && (
                    <PurchaseActionDetailRow
                        numberOfDays={purchasedNumOfDays}
                        purchaseAction={"purchased"}
                        skus={purchased.getIn(["value", "skus"]).join(", ")}
                    />
                )}

                {notPurchased && notPurchasedNumOfDays && (
                    <PurchaseActionDetailRow
                        numberOfDays={notPurchasedNumOfDays}
                        purchaseAction={"not purchased"}
                        skus={notPurchased.getIn(["value", "skus"]).join(", ")}
                    />
                )}

                {spent ? <SpentRow spent={spent} /> : null}
            </Col>
        </Row>
    );
});
