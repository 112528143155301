import React, {memo} from "react";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles({
    container: {
        color: "#b8c7ce",
        width: "100%",
        textAlign: "center",
    },
});

export default memo(function BuildInfo({version}: {version: string}) {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <span>{version}</span>
        </div>
    );
});
