import {applyMiddleware, combineReducers, createStore} from "redux";
import thunk from "redux-thunk";

import advert from "./advert/reducer";
import dashboard from "./dashboard/DashboardDuck";
import main from "./main/reducer";
import message from "./message/reducer";
import rootReducer from "./rootReducer";
import selectTeam from "./selectTeam/team";
import notification from "./uiNotification/notificationDuck";

const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);

const appReducers = combineReducers({
    advert,

    dashboard,
    main,
    message,
    notification,
    selectTeam,
});

export default createStoreWithMiddleware(rootReducer(appReducers));
