/*  */

import {createAction, handleActions} from "redux-actions";
import {go} from "../main/eventHandlers";
import immutable from "immutable";

const initialState = immutable.fromJS({highlightedTeam: null});

const SET_HIGHLIGHTED_TEAM = "beams/selectTeam/SET_HIGHLIGHTED_TEAM";

export const setHighlightedTeam = createAction(SET_HIGHLIGHTED_TEAM);

export default handleActions(
    {
        [SET_HIGHLIGHTED_TEAM]: (state, {payload}) =>
            state.set("highlightedTeam", payload),
    },
    initialState,
);
