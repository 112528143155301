import * as R from "ramda";
import dateIsBefore from "../../date/isBefore";
import moment, {Moment} from "moment";

export default (momentDate: Object) => {
    const sendImmediatelyText = R.always("Specific date");
    const sendInText = (date: Moment) => `Send ${date.fromNow()}`;
    const now = moment(); // eslint-disable-line moment-utc/no-moment-without-utc

    const selectText = R.cond([
        [R.isNil, sendImmediatelyText],
        [R.isEmpty, sendImmediatelyText],
        [R.curry(dateIsBefore)(now), sendImmediatelyText],
        [R.T, sendInText],
    ]);

    return selectText(momentDate);
};
