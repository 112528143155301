import Icon from "react-fa";
import React, {CSSProperties} from "react";

interface Props {
    style: CSSProperties;
}
const ActivityIndicator = ({style}: Props): JSX.Element => {
    return (
        <Icon
            className="fa fa-circle-o-notch fa-spin activity-indicator"
            name="circle-o-notch"
            style={style}
        />
    );
};

export default ActivityIndicator;
