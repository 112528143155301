import PrivateRoute from "./PrivateRoute";
import React, {memo} from "react";
import useFeatureFlag from "../featureFlags/useFeatureFlag";
import useSession from "../main/useSession";

interface PrivateRouteProps {
    component: JSX.Element;
    path: string;
    requiredFeatureFlag?: string;
    subTitle?: string;
    title: string;
    upgradeMessage?: string;
}

export default memo(function PrivateRouteContainer(props: PrivateRouteProps) {
    const {featureFlags} = useFeatureFlag();
    const {isLoggedIn, userData} = useSession();
    return (
        <PrivateRoute
            featureFlags={featureFlags}
            {...props}
            isLoggedIn={isLoggedIn}
            userData={userData}
        />
    );
});
