import React, {memo} from "react";

export default memo(function CollapseButton(): JSX.Element {
    return (
        <button
            key="collapseButton"
            className="btn btn-box-tool"
            data-toggle="tooltip"
            data-widget="collapse"
            title="Collapse"
        >
            <i className="fa fa-minus" />
        </button>
    );
});
