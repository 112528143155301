import React from "react";

export default class Ellipsis extends React.Component {
    componentDidMount() {
        setTimeout(this.truncateText.bind(this), 0);
    }

    componentDidUpdate() {
        setTimeout(this.truncateText.bind(this), 0);
    }

    truncateText() {
        const domElement = this.ellipsis;

        if (!domElement) {
            return;
        }

        if (domElement.scrollHeight === domElement.clientHeight) {
            return;
        }

        domElement.innerText = domElement.innerText.replace(
            /.(\.\.\.)?$/,
            "...",
        );
        // this.truncateText()
    }

    render() {
        return (
            <div
                className={this.props.className}
                ref={(c) => (this.ellipsis = c)}
                {...this.props}
            />
        );
    }
}
