import React from "react";

// Ensures all items have the `key` property set so the list can be used in React components.
export default (nodes: any) => {
    if (typeof nodes === "undefined" || typeof nodes.map === "undefined") {
        return null;
    }

    return nodes.map((n: any, i: any) =>
        React.cloneElement(n, {
            ...n.props,
            key: i,
        }),
    );
};
