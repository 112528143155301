import moment from "moment";

const ISO_DATE_FORMAT: string = "YYYY-MM-DD";

interface DataParams {
    daysBefore: number;
    date: Date;
}

interface DateRange {
    fromDate: string;
    toDate: string;
}

export const getFormatedFromToDate = (startDate: Date, endDate: Date) => ({
    fromDate: moment(startDate).format(ISO_DATE_FORMAT),
    toDate: moment(endDate).format(ISO_DATE_FORMAT),
});

export default (params: DataParams): DateRange => ({
    // eslint-disable-next-line moment-utc/no-moment-without-utc
    fromDate: moment(params.date)
        .subtract(params.daysBefore, "days")
        .format(ISO_DATE_FORMAT),
    // eslint-disable-next-line moment-utc/no-moment-without-utc
    toDate: moment(params.date).format(ISO_DATE_FORMAT),
});
