/*  */

import * as R from "ramda";
import {} from "immutable";
import {
    Area,
    VictoryArea,
    VictoryAxis,
    VictoryChart,
    VictoryLine,
    VictoryTooltip,
    VictoryVoronoiContainer,
    VictoryGroup,
    VictoryClipContainer,
} from "victory";
import EngagementScatterGraph from "./EngagementScatterGraph";
import formatNumber from "../../common/formatNumber";
import React from "react";
import {Col, Row} from "react-bootstrap";
import LegendList from "./LegendList";
import ColorScheme from "color-scheme";
import {observer} from "mobx-react";
import {action, observable} from "mobx";

const defaults = {
    DEFAULT_STROKE_WIDTH: 4,
    DEFAULT_LABEL_SIZE: 14,
};

const axisStyle = Object.freeze({
    ticks: {
        stroke: "rgb(0, 0, 0)",
        size: 4,
    },
});
const styles = Object.freeze({
    label: {marginLeft: -40},
    parentVisible: {parent: {overflow: "visible"}},
    chartPadding: {
        top: 80,
        left: 100,
        bottom: 80,
    },
    verticalAxis: {
        ...axisStyle,
        axisLabel: {
            fontSize: 12,
            padding: 90,
        },
        tickLabels: {
            fontSize: 16,
        },
    },
    horizontalAxis: {
        ...axisStyle,
        axisLabel: {
            fontSize: 18,
            padding: 16,
        },
        tickLabels: {
            angle: -90,
            fontSize: 14,
            padding: 5,
            textAnchor: "end",
            verticalAnchor: "middle",
        },
    },
});

const shouldRenderDataComponent = (dataItem) =>
    dataItem.get("dataSets").count() > 0;

const isChartRenderable = (chartValues) =>
    Boolean(chartValues) && shouldRenderDataComponent(chartValues);

const scheme = new ColorScheme();
const formatColorScheme = (colorScheme) =>
    colorScheme.map((color) => `#${color}`);

@observer
export default class MultiLineChart extends React.Component {
    static defaultProps = {
        axisTitles: {},
        yAxis: {title: ""},
    };

    static sortDatasetWithMaxValueFirst = (datasets) =>
        datasets.sort((datasetA, datasetB) => datasetB.y - datasetA.y);

    static calculateCoordinateRange = (dataPoints) => {
        const sortedDataPoints = MultiLineChart.sortDatasetWithMaxValueFirst(
            dataPoints,
        );

        return {
            lower: sortedDataPoints[dataPoints.length - 1].y,
            upper: sortedDataPoints[0].y,
        };
    };

    @observable
    hideTooltip = true;

    @action.bound
    setIsActiveTooltip = (value) => {
        this.hideTooltip = value;
    };

    getGraphDataComponent() {
        return this.props.solidArea ? VictoryArea : VictoryLine;
    }

    getGraphInterpolationSetting() {
        return this.props.interpolate ? "catmullRom" : "linear";
    }

    renderDataGraph({
        chartValues,
        fillStyle,
        labelSize = defaults.DEFAULT_LABEL_SIZE,
        chartColour,
        strokeWidth = defaults.DEFAULT_STROKE_WIDTH,
    }) {
        const {
            axisTitles = {x: ""},
            verticalDomain,
            yAxis,
            chartDomain,
            statisticsData,
        } = this.props;
        const DataComponent = this.getGraphDataComponent();
        const dataSet = chartValues.get("dataSets").toJS();

        return (
            <VictoryChart>
                <DataComponent
                    groupComponent={
                        <VictoryClipContainer
                            clipPadding={{bottom: 2, top: 2, left: 2, right: 2}}
                        />
                    }
                    dataComponent={
                        <Area
                            events={{
                                onMouseOver: () => {
                                    this.setIsActiveTooltip(false);
                                },
                                onMouseLeave: (e) => {
                                    this.setIsActiveTooltip(true);
                                },
                            }}
                        />
                    }
                    key={chartValues.hashCode()}
                    data={dataSet}
                    domain={chartDomain}
                    interpolation={this.getGraphInterpolationSetting()}
                    style={{
                        data: {
                            ...fillStyle,
                            stroke: chartColour,
                            strokeWidth,
                        },
                        labels: {
                            fontSize: labelSize,
                            fill: chartColour,
                        },
                    }}
                />
                <VictoryAxis
                    fixLabelOverlap
                    label={axisTitles.x}
                    style={styles.horizontalAxis}
                />
                <VictoryAxis
                    dependentAxis
                    domain={verticalDomain}
                    label={axisTitles.y}
                    style={styles.verticalAxis}
                    tickFormat={
                        yAxis.tickFormat ? yAxis.tickFormat : R.identity
                    }
                />
                {statisticsData ? (
                    <VictoryLine
                        style={{
                            data: {stroke: "#4286f4"},
                            parent: {border: "3px solid #4286f4"},
                            labels: {
                                fontSize: labelSize,
                                fill: "#4286f4",
                            },
                        }}
                        data={dataSet.map((data) => ({
                            x: data.x,
                            y: statisticsData.average,
                        }))}
                    />
                ) : null}
                {statisticsData ? (
                    <VictoryLine
                        style={{
                            data: {stroke: "#10ce13"},
                            parent: {border: "3px solid #10ce13"},
                            labels: {
                                fontSize: labelSize,
                                fill: "#10ce13",
                            },
                        }}
                        data={dataSet.map((data) => ({
                            x: data.x,
                            y: statisticsData.ucl,
                        }))}
                    />
                ) : null}
                {statisticsData ? (
                    <VictoryLine
                        style={{
                            data: {stroke: "#f44c41"},
                            parent: {border: "3px solid #f44c41"},
                            labels: {
                                fontSize: labelSize,
                                fill: "#f44c41",
                            },
                        }}
                        data={dataSet.map((data) => ({
                            x: data.x,
                            y: statisticsData.lcl,
                        }))}
                    />
                ) : null}
            </VictoryChart>
        );
    }

    renderEngagementGraph({chartValues, fillStyle}) {
        if (!this.props.showEngagement) {
            return null;
        }

        const dataSet = chartValues.get("dataSets").toJS();

        return (
            <EngagementScatterGraph
                calculateCoordinateRange={
                    MultiLineChart.calculateCoordinateRange
                }
                dataPoints={dataSet}
                fillStyle={fillStyle}
            />
        );
    }

    calculateChartRightPadding(chartValues) {
        const dataSets = chartValues.get("dataSets").toJS();
        const labelSize =
            chartValues.get("labelSize") || defaults.DEFAULT_LABEL_SIZE;
        const xLength = dataSets[dataSets.length - 1].x.length;
        const yLength = dataSets[dataSets.length - 1].y.toString().length;

        return (labelSize + xLength + yLength) * 2;
    }

    calculateMaxDomainY(chartValues) {
        const dataSets = chartValues.get("dataSets").toJS();
        const maxY = Math.floor(
            dataSets.reduce(
                (accumulatedSet, currentSet) =>
                    Math.max(accumulatedSet, currentSet.y),
                0,
            ),
        );
        const decimalLength = maxY.toString().length;
        const nearestWholeNumber = Math.pow(10, decimalLength - 1);
        const maxDomainY =
            Math.ceil(maxY / nearestWholeNumber) * nearestWholeNumber;

        return maxDomainY;
    }

    renderVictoryChart() {
        const {
            colorScheme,
            chartData,
            height,
            scale,
            width,
            chartPadding,
            solidArea,
            statisticsData,
        } = this.props;
        const chartValues = chartData.get(0);
        const chartColour = colorScheme[0];
        const fillStyle = solidArea ? {fill: chartColour} : {};
        const shouldRenderCharts = isChartRenderable(chartValues);
        let chartRightPadding = 0;
        let maxDomainY = 0;

        if (shouldRenderCharts) {
            chartRightPadding = this.calculateChartRightPadding(chartValues);
            maxDomainY = this.calculateMaxDomainY(chartValues);
        }

        return (
            <VictoryChart
                height={height}
                maxDomain={{y: maxDomainY}}
                padding={{
                    ...styles.chartPadding,
                    ...chartPadding,
                    ...{right: chartRightPadding},
                }}
                scale={scale}
                style={styles.parentVisible}
                width={width}
                containerComponent={
                    <VictoryVoronoiContainer
                        dimension="x"
                        labelComponent={
                            <VictoryTooltip
                                active={this.isActiveTooltip}
                                renderInPortal
                                cornerRadius={0}
                                flyoutStyle={
                                    this.hideTooltip
                                        ? {opacity: 0, color: "transparent"}
                                        : {fill: "rgb(255, 255, 255)"}
                                }
                            />
                        }
                        labels={(data) => {
                            return this.hideTooltip
                                ? ""
                                : `${data.x} - ${
                                      this.props.yAxis.title
                                  }${formatNumber(data.y)}`;
                        }}
                    />
                }
            >
                {shouldRenderCharts &&
                    this.renderDataGraph({
                        chartValues,
                        fillStyle,
                        labelSize: chartValues.get("labelSize"),
                        chartColour,
                        strokeWidth: chartValues.get("strokeWidth"),
                    })}
                {shouldRenderCharts &&
                    this.renderEngagementGraph({chartValues, fillStyle})}
            </VictoryChart>
        );
    }

    render() {
        const {statisticsData} = this.props;

        if (statisticsData) {
            return (
                <Row>
                    <Col md={11}>{this.renderVictoryChart()}</Col>
                    <Col md={1} style={styles.label}>
                        {this.props.legends ? (
                            <LegendList
                                colorScheme={["#10ce13", "#4286f4", "#f44c41"]}
                                legends={this.props.legends}
                            />
                        ) : null}
                    </Col>
                </Row>
            );
        }

        return this.renderVictoryChart();
    }
}
