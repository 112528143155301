/*  */

export const getNotification = (
    httpAgent,
    {
        notificationId,
        shouldGetActionedEvents = false,
        shouldGetOpenedEvents = false,
        selectedTeamNumber = "",
    },
) => {
    return httpAgent.get(`/notification/${notificationId}`).query({
        shouldGetActionedEvents,
        shouldGetOpenedEvents,
        selectedTeamNumber,
    });
};

export const postNotification = (httpAgent, notification) =>
    httpAgent.post("/notification").send(notification);

export const putNotification = (httpAgent, notification) =>
    httpAgent.put(`/notification/${notification.id}`).send(notification);

export const deleteNotification = (httpAgent, {groupId, notificationId}) =>
    httpAgent.delete("/notification").query({groupId, notificationId});

export const getNotifications = (httpAgent, filters) =>
    httpAgent.get("/notification").query(filters);
