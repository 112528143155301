/*  */

import {action, observable} from "mobx";
import {autobind} from "core-decorators";
import {Col, Row} from "react-bootstrap";
import {inject, observer} from "mobx-react";
import BeamAPIClient from "../../apiClient/BeamAPIClient";
import DashboardWidget from "./DashboardWidget";
import FilteredChart from "../charts/defaultDashboard/FilteredChart";
import formatNumber from "../../common/formatNumber";
import getPercentage from "../../common/getPercentage";
import immutable from "immutable";
import ImmutablePropTypes from "react-immutable-proptypes";
import InfoBox from "../../widget/InfoBox";
import InfoBoxProgress from "../../widget/InfoBoxProgress";
import PropTypes from "prop-types";
import React from "react";

const ACTIVE_USER_INFO = `The total number of Active Users (AU) during the selected time period.

An AU is a unique user who has opened the app at least once during the selected time period.`;

const PUSH_NOTIFICATIONS_ENABLED_INFO =
    "The estimated total number of Active Users during the selected time period who are potentially reachable via a push notification.";

const ActiveUsers = ({numberOfActiveUsers = 0}) => (
    <InfoBox
        boldText={
            numberOfActiveUsers === 0
                ? "N/A"
                : formatNumber(numberOfActiveUsers)
        }
        boxColor="light-green"
        iconNode={<i className="ion ion-person" />}
        infoPopOverText={ACTIVE_USER_INFO}
        text={"Active Users"}
    />
);

ActiveUsers.propTypes = {numberOfActiveUsers: PropTypes.number};

const categories = immutable.fromJS({
    PUSH_NOTIFICATIONS_ENABLED: {
        name: "Push Notification Enabled",
        color: "orange",
        icon: "ion ion-android-notifications",
        info: PUSH_NOTIFICATIONS_ENABLED_INFO,
    },
});

const FIXED_PERCENTAGE_PUSHNOTIFICATIONS_ENABLED = 83;

const ActiveUsersPercentage = ({
    category,
    totalActiveUsers = 0,
    usersCount = 0,
}) => {
    // TODO: Exceptionally we are returning 83% for calculating the activie users because we are not sure how we want this functionality and what it should be (users or devices)
    const percentage =
        category.get("name") === "Push Notification Enabled"
            ? FIXED_PERCENTAGE_PUSHNOTIFICATIONS_ENABLED
            : getPercentage({
                  part: usersCount,
                  total: totalActiveUsers,
              });

    return (
        <InfoBoxProgress
            boldText={
                usersCount === 0
                    ? "N/A"
                    : formatNumber(
                          category.get("name") === "Push Notification Enabled"
                              ? (totalActiveUsers *
                                    FIXED_PERCENTAGE_PUSHNOTIFICATIONS_ENABLED) /
                                    100
                              : usersCount,
                      )
            }
            color={category.get("color")}
            icon={category.get("icon")}
            infoPopOverText={category.get("info")}
            progressPercentage={percentage}
            progressText={`${percentage}% of Active Users`}
            text={category.get("name")}
        />
    );
};

const getHiddenWidgetsCategoryName = (hiddenWidgets) =>
    hiddenWidgets.map((widget) => categories.getIn([widget, "name"]));

const isCategoryHidden = (currentCategoryName, hiddenWidgetsCategoryName) =>
    hiddenWidgetsCategoryName.indexOf(currentCategoryName) > -1;

ActiveUsersPercentage.propTypes = {
    category: ImmutablePropTypes.mapContains({
        name: PropTypes.string,
        icon: PropTypes.string,
        color: PropTypes.string,
    }).isRequired,
    totalActiveUsers: PropTypes.number,
    usersCount: PropTypes.number,
};

@inject("apiClient")
@observer
export default class AudienceLandscape extends FilteredChart {
    @action
    onDataDidLoad({activeUsers, pushNotificationEnabled}) {
        this.activeUsers = activeUsers;
        this.pushNotificationEnabled = pushNotificationEnabled;
    }

    @autobind
    dataLoader(query) {
        return this.props.apiClient
            ? this.props.apiClient.invokeGetEndpoint("audienceLandscape", query)
            : Promise.reject(new Error("No API client available"));
    }

    render() {
        const {hiddenWidgets} = this.props;
        const {activeUsers, pushNotificationEnabled, status} = this;

        const hiddenWidgetsCategoryName = getHiddenWidgetsCategoryName(
            hiddenWidgets,
        );
        const usersCountPerCategory = {
            [categories.getIn([
                "PUSH_NOTIFICATIONS_ENABLED",
                "name",
            ])]: pushNotificationEnabled,
        };

        return (
            <DashboardWidget status={status}>
                <Row>
                    <Col md={6}>
                        <ActiveUsers numberOfActiveUsers={activeUsers} />
                    </Col>
                    {categories.valueSeq().map((category) => {
                        const categoryName = category.get("name");

                        if (
                            isCategoryHidden(
                                categoryName,
                                hiddenWidgetsCategoryName,
                            )
                        ) {
                            return null;
                        }

                        return (
                            <Col key={categoryName} md={6} style={{padding: 0}}>
                                <ActiveUsersPercentage
                                    category={category}
                                    totalActiveUsers={activeUsers}
                                    usersCount={
                                        usersCountPerCategory[categoryName]
                                    }
                                />
                            </Col>
                        );
                    })}
                </Row>
            </DashboardWidget>
        );
    }
}
