import * as R from "ramda";
import {
    setChartFilter,
    setChartMultiFilter,
    setCurrentTab,
    widgetData,
    widgetStateError,
    widgetStateLoading,
    widgetStateNormal,
} from "./DashboardDuck";
import apiClientFromState from "../apiClient/apiClientFromState";
import formatAppSalesFigureData from "./formatAppSalesFigureData";
import formatDate from "../date/formatDate";
import immutable from "immutable";
import widgetConfiguration from "./widgetConfiguration";
import widgetDataLoader from "./widgetDataLoader";

export const loadWidgetData = (widgetId, ...args) => (dispatch, getState) => {
    const dataLoader = widgetDataLoader({
        apiClient: apiClientFromState(getState()),
        widgetConfiguration,
    });

    dispatch(widgetStateLoading(widgetId));

    dataLoader(widgetId, ...args)
        .then(({body}) => {
            dispatch(
                widgetData({
                    widgetId,
                    widgetData: immutable.fromJS(body),
                }),
            );
            dispatch(widgetStateNormal(widgetId));
        })
        .catch(() => dispatch(widgetStateError(widgetId)));
};

export const onActiveFilterChanged = (chartIdentifier, filter) => (dispatch) =>
    dispatch(
        setChartFilter({
            chartIdentifier,
            ...filter,
        }),
    );

export const onMultiFilterChanged = (chartIdentifier, filter) => (dispatch) =>
    dispatch(
        setChartMultiFilter({
            chartIdentifier,
            filter,
        }),
    );

export const onTabChanged = (categoryName) => (dispatch) =>
    dispatch(setCurrentTab(categoryName));

export const getTotalAppTransactions = ({
    fromDate,
    toDate,
    childrenTeamFilter,
}) => async (dispatch, getState) => {
    const apiClient = apiClientFromState(getState());
    const formatTotalAppTransaction = R.map(({label, value}) => ({
        x: formatDate(label),
        y: value,
    }));

    const {body} = await apiClient.getTotalAppTransactions({
        fromDate,
        toDate,
        childrenTeamFilter,
    });

    return formatTotalAppTransaction(body);
};

export const getActivePurchasersAverageOrderSize = ({
    fromDate,
    toDate,
    childrenTeamFilter,
}) => async (dispatch, getState) => {
    const apiClient = apiClientFromState(getState());
    const formatTotalAppTransaction = R.map(({label, value}) => ({
        x: formatDate(label),
        y: value,
    }));

    const {body} = await apiClient.getAverageBasketSize({
        fromDate,
        toDate,
        childrenTeamFilter,
    });

    return formatTotalAppTransaction(body);
};

export const getTotalAppDownloads = ({
    fromDate,
    toDate,
    childrenTeamFilter,
}) => async (dispatch, getState) => {
    const apiClient = apiClientFromState(getState());
    const formatTotalAppDownloadsChartData = (total, chartData) => {
        let sum = total;

        const totalAppDownloads = R.map(({label, value}) => {
            sum -= value;

            return {
                x: formatDate(label),
                y: sum >= 0 ? sum : 0,
            };
        })(R.reverse(chartData));

        return R.reverse(totalAppDownloads);
    };

    const {body} = await apiClient.getTotalAppDownloads({
        fromDate,
        toDate,
        childrenTeamFilter,
    });

    return {
        ...body,
        chartData: formatTotalAppDownloadsChartData(
            body.totalDownload,
            body.chartData,
        ),
    };
};

export const getAppSalesFigures = (childrenTeamFilter) => async (
    dispatch,
    getState,
) => {
    const apiClient = apiClientFromState(getState());
    const {body} = await apiClient.getAppSalesFigures({childrenTeamFilter});

    return formatAppSalesFigureData(body);
};
