import * as R from "ramda";
import {PRODUCT_LIST_PLACEMENTS} from "./PlacementConstants";
import {connect} from "react-redux";

// @ts-ignore
import {getLoadingStatus} from "../main/selectors";
import immutable from "immutable";
import listMove from "../common/listMove";
import LoadingWidget from "../widget/LoadingWidget";
import mapIndexToOrderId from "./mapIndexToOrderId";
import React, {Component, memo, useEffect, useState} from "react";
import AdvertTable, {EmptyList, AdvertTableContainerProps} from "./AdvertTable";
import useFeatureFlag from "../featureFlags/useFeatureFlag";

const HooksContainer = memo(function AdvertTableContainer(
    props: AdvertTableContainerProps,
) {
    const [items, setItems] = useState(immutable.List());
    const {featureFlags} = useFeatureFlag();
    const {
        advertChildrenTeamFilter: childrenTeamFilterEnabled,
        advertDeepLinking: deepLinkingEnabled,
        scheduledAdvert: scheduledAdvertEnabled,
        placements: advertFlags,
        allowCSVExport: CSVExportEnabled,
    } = featureFlags;

    useEffect(() => {
        setItems(props.advertList);
    }, [props.advertList]);

    const onSortEnd = ({
        oldIndex,
        newIndex,
    }: {
        newIndex: number;
        oldIndex: number;
    }) => {
        if (newIndex === oldIndex) {
            return;
        }

        const newItems: any = listMove(items, oldIndex, newIndex);
        const placements: any = mapIndexToOrderId(newItems.toJS());

        setItems(newItems);
        props.onUpdatePlacements(placements);
    };

    const bannerAdvertDeepLinking = false; // When working: props.placementName === BANNER && deepLinkingEnabled
    const seenCountEnabled =
        R.path(["trackingAdvertsInfo"])(advertFlags) &&
        !Boolean(PRODUCT_LIST_PLACEMENTS.includes(props.placementName));

    const {isDone, isError} = props;

    return (
        <LoadingWidget
            emptyList={items.isEmpty() && <EmptyList />}
            errorLabel={"Placements are not available at this time."}
            isDone={isDone ? true : false}
            isError={isError ? true : false}
            loadingLabel={"Loading placements..."}
        >
            <AdvertTable
                {...props}
                CSVExportEnabled={CSVExportEnabled}
                childrenTeamFilterEnabled={childrenTeamFilterEnabled}
                deepLinkingEnabled={deepLinkingEnabled}
                scheduledAdvertEnabled={scheduledAdvertEnabled}
                items={items}
                bannerAdvertDeepLinking={bannerAdvertDeepLinking}
                seenCountEnabled={seenCountEnabled}
                onSortEnd={onSortEnd}
                onMultiDeletePressed={props.onMultiDeleteAdvertPresed}
                advertFlags={advertFlags}
                title={props.title}
            />
        </LoadingWidget>
    );
});

class AdvertTableClass extends Component<AdvertTableContainerProps> {
    render() {
        const {
            onDeleteAdvertPressed,
            onMultiDeleteAdvertPresed,
            placementName,
            limit,
            isDone,
            isError,
            advertList,
            onUpdatePlacements,
            placementType,
            adFrequencyConfigEnabled,
            title,
        } = this.props;
        return (
            <HooksContainer
                advertList={advertList}
                onDeleteAdvertPressed={onDeleteAdvertPressed}
                placementName={placementName}
                limit={limit}
                isDone={isDone}
                isError={isError}
                hideButtons={false}
                hideMultiSelect={false}
                onUpdatePlacements={onUpdatePlacements}
                placementType={placementType}
                adFrequencyConfigEnabled={adFrequencyConfigEnabled}
                onMultiDeleteAdvertPresed={onMultiDeleteAdvertPresed}
                title={title}
            />
        );
    }
}

export default connect(getLoadingStatus)(AdvertTableClass);
