import React, {memo} from "react";
import useSession from "./useSession";
import Header from "./Header";
import Sidebar from "./Sidebar";

interface Props {
    children: JSX.Element;
    logout: () => void;
}

export default memo(function MainSideBarHeader({children, logout}: Props) {
    const {userData, isLoggedIn} = useSession();

    const activeTeam = userData.activeTeam
        ? userData.activeTeam
        : {teamNumber: 0, name: ""};
    const marginLeft =
        isLoggedIn && activeTeam.teamNumber ? {} : {marginLeft: 0};
    return (
        <div>
            {isLoggedIn && (
                <Header
                    emailAddress={userData.emailAddress}
                    firstName={userData.firstName}
                    isLoading={false}
                    onLogout={logout}
                    showSideBarToggle={Boolean(
                        isLoggedIn && activeTeam.teamNumber,
                    )}
                    teamName={activeTeam.name}
                />
            )}

            {isLoggedIn && activeTeam.teamNumber && (
                <Sidebar
                    emailAddress={userData.emailAddress}
                    firstName={userData.firstName}
                    teamName={activeTeam.name}
                />
            )}

            <div className="content-wrapper" style={{...marginLeft}}>
                {children}
            </div>
        </div>
    );
});
