import {Col, Row, Table} from "react-bootstrap";
import {PLATFORM_MAP} from "./constants";
import ButtonWithIcon from "../common/buttons/ButtonWithIcon";
// @ts-ignore
import Ellipsis from "../common/Ellipsis";
import formatDateTime from "../date/formatDateTime";
import LinkButton from "../common/buttons/LinkButton";
import React, {memo} from "react";
import TableData from "../common/TableData";
import {SpecificLocation} from "./types";
import {makeStyles} from "@material-ui/styles";
import classnames from "classnames";
import * as R from "ramda";

const useStyles = makeStyles({
    tableContainer: {
        background: "rgb(255,255,255)",
    },
    headerContainer: {
        backgroundColor: "rgb(70,70,70)",
    },
    addItemButton: {
        textAlign: "center",
    },
    headerText: {
        verticalAlign: "middle",
        color: "rgb(255,255,255)",
        padding: "10px",
    },
    headerContent: {
        padding: 4,
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
    },
    sortActive: {
        color: "rgb(173, 221, 21)",
        cursor: "pointer",
    },
    sortInactive: {
        cursor: "pointer",
    },
    addButton: {
        width: "80px",
    },
    rowItem: {
        padding: "10px",
    },
    tdButtons: {
        textAlign: "center",
    },
    buttonContainer: {
        display: "inline-flex",
    },
    button: {
        margin: "0 5px",
        width: "100px",
    },
    titleRow: {
        color: "rgb(68, 68, 68)",
        fontWeight: "bold",
    },
});

type AudienceSelection = {
    _id: string;
    name: string;
    devicePlatform: string;
    location?: {
        wantSpecificLocation: boolean;
        specificLocation: SpecificLocation;
    };
    createdAt: string;
    teamName: string;
};

export const SORT_OPTIONS = {
    name: "name",
    teamName: "teamName",
    devicePlatform: "devicePlatform",
    createdAt: "createdAt",
};

interface TableRowProps extends AudienceSelection {
    onDelete: (_id: string) => Promise<void>;
    audienceBuilderChildrenTeamFilterEnabled: boolean;
}

export const PLACEHOLDER = "All";

interface Props {
    audienceSelection: Array<AudienceSelection>;
    activeSort: string;
    setActiveSort: (activeSort: string) => void;
    onDelete: (_id: string) => Promise<void>;
    featureFlags: any;
}

interface HeaderTextProps {
    text: string;
    sort: string;
    setActiveSort: (activeSort: string) => void;
    activeSort: string;
}

function HeaderText({text, sort, setActiveSort, activeSort}: HeaderTextProps) {
    const classes = useStyles();
    const sortActiveClass = classnames(
        "fa fa-sort pull-right",
        classes.sortActive,
    );
    const sortInactiveClass = classnames(
        "fa fa-sort pull-right",
        classes.sortInactive,
    );

    return (
        <th onClick={() => setActiveSort(sort)} className={classes.headerText}>
            <div className={classes.headerContent}>
                <div>{text}</div>
                <i
                    className={
                        activeSort === sort
                            ? sortActiveClass
                            : sortInactiveClass
                    }
                />
            </div>
        </th>
    );
}

function TableRow({
    _id,
    name,
    devicePlatform,
    createdAt,
    teamName,
    onDelete,
    audienceBuilderChildrenTeamFilterEnabled,
}: TableRowProps): JSX.Element {
    const classes = useStyles();
    const tableTitleClass = classnames("message-table-title", classes.titleRow);

    return (
        <tr key={_id}>
            <TableData>
                <a
                    className={tableTitleClass}
                    href={`#/audience-builder/${_id}`}
                >
                    <Ellipsis>{name}</Ellipsis>
                </a>
            </TableData>
            {audienceBuilderChildrenTeamFilterEnabled && (
                <TableData>{teamName || PLACEHOLDER}</TableData>
            )}
            <TableData>{PLATFORM_MAP[devicePlatform] || ""}</TableData>
            <TableData>{formatDateTime(createdAt, "N/A")}</TableData>
            <TableData className={classes.tdButtons}>
                <div className={classes.buttonContainer}>
                    <ButtonWithIcon
                        iconName="trash"
                        onClick={(event: Event) => {
                            onDelete(_id);
                            event.stopPropagation();
                        }}
                        size="sm"
                        className={classes.button}
                        title="Archive"
                        type="warning"
                    />
                </div>
            </TableData>
        </tr>
    );
}

export default memo(function AudienceSelectionList({
    audienceSelection = [],
    activeSort,
    setActiveSort,
    onDelete,
    featureFlags,
}: Props) {
    const classes = useStyles();
    const audienceBuilderChildrenTeamFilter = Boolean(
        R.path(["audienceBuilderChildrenTeamFilterEnabled"], featureFlags),
    );

    return (
        <Row>
            <Col md={12}>
                <Table
                    bordered
                    hover
                    responsive
                    striped
                    className={classes.tableContainer}
                >
                    <thead className={classes.headerContainer}>
                        <tr>
                            <HeaderText
                                text="Name"
                                sort={SORT_OPTIONS.name}
                                activeSort={activeSort}
                                setActiveSort={setActiveSort}
                            />
                            {audienceBuilderChildrenTeamFilter && (
                                <HeaderText
                                    text="Member App"
                                    sort={SORT_OPTIONS.teamName}
                                    activeSort={activeSort}
                                    setActiveSort={setActiveSort}
                                />
                            )}
                            <HeaderText
                                text="Device Platform"
                                sort={SORT_OPTIONS.devicePlatform}
                                activeSort={activeSort}
                                setActiveSort={setActiveSort}
                            />
                            <HeaderText
                                text="Created At"
                                sort={SORT_OPTIONS.createdAt}
                                activeSort={activeSort}
                                setActiveSort={setActiveSort}
                            />
                            <th>
                                <div className={classes.addItemButton}>
                                    {
                                        // @ts-ignore
                                        <LinkButton
                                            relativeDestination="add"
                                            className={classes.addButton}
                                        />
                                    }
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {audienceSelection.map(
                            ({
                                _id,
                                name,
                                devicePlatform,
                                createdAt,
                                teamName,
                            }: AudienceSelection) => (
                                <TableRow
                                    key={_id}
                                    _id={_id}
                                    name={name}
                                    devicePlatform={devicePlatform}
                                    createdAt={createdAt}
                                    teamName={teamName}
                                    onDelete={onDelete}
                                    audienceBuilderChildrenTeamFilterEnabled={
                                        audienceBuilderChildrenTeamFilter
                                    }
                                />
                            ),
                        )}
                    </tbody>
                </Table>
            </Col>
        </Row>
    );
});
