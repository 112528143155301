import React, {memo, useState, useEffect} from "react";
import SessionContext, {DEFAULT_USER, UserData} from "./SessionContext";
// @ts-ignore
import {saveUser, loadUser} from "./repository";
// @ts-ignore
import base64 from "base-64";
// @ts-ignore
import accountToLocalUser from "../login/accountToLocalUser";
import useNavigate from "@beam/use-navigate";

import useAlert from "../alert/useAlert";

const ERROR_MESSAGES = {
    unauthorized:
        "Unable to login. Please check your username and password and try again. Otherwise contact RNF Digital support.",
    default:
        "Something went wrong. Please try again later. Otherwise contact RNF Digital support.",
};

export default memo(function SessionProvider({children}) {
    const [userData, setUserData] = useState(loadUser() || DEFAULT_USER);
    const [isLoggedIn, setIsLoggedIn] = useState(
        Boolean(userData.emailAddress && userData.password),
    );
    const {navigate} = useNavigate();
    const {addSuccessAlert, addErrorAlert} = useAlert();
    useEffect(() => {
        setIsLoggedIn(Boolean(userData.emailAddress && userData.password));
    }, [userData]);

    const onLoginUnauthorized = () => {
        addErrorAlert(ERROR_MESSAGES.unauthorized);
        setIsLoggedIn(false);
        return {success: false, nextRoute: "/login", activeTeam: undefined};
    };

    const login = async (emailAddress: string, password: string) => {
        try {
            const response = await fetch("/v1/account", {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Basic ${base64.encode(
                        `${emailAddress}:${password}`,
                    )}`,
                },
            });

            if (response.status === 401) {
                onLoginUnauthorized();
                return {
                    success: false,
                    nextRoute: "/login",
                    activeTeam: undefined,
                };
            }
            const result = await response.json();

            const authenticatedUser = accountToLocalUser(result, password);

            const hasMultipleTeams =
                authenticatedUser && authenticatedUser.teams.length > 1;
            const nextRoute = hasMultipleTeams ? "/select-team" : "/dashboard";

            const firstTeam =
                (authenticatedUser &&
                    authenticatedUser.teams &&
                    authenticatedUser.teams.length > 0 &&
                    authenticatedUser.teams[0]) ||
                undefined;
            const activeTeam = hasMultipleTeams ? undefined : firstTeam;

            const updateUserData = {
                ...authenticatedUser,
                activeTeam,
            };

            setUserData(updateUserData);
            saveUser(updateUserData);
            addSuccessAlert("Login Success");
            return {success: true, nextRoute, activeTeam};
        } catch (exception) {
            addErrorAlert(ERROR_MESSAGES.default);
            setIsLoggedIn(false);
            return {success: false, nextRoute: "/login", activeTeam: undefined};
        }
    };

    const onSetUserData = (userData: UserData) => {
        setUserData(userData);
        saveUser(userData);
    };

    const logout = () => {
        setUserData(DEFAULT_USER);
        saveUser(DEFAULT_USER);
        navigate("/login");
    };

    return (
        <SessionContext.Provider
            value={{
                isLoggedIn: isLoggedIn,
                userData,
                setUserData: onSetUserData,
                login,
                logout,
            }}
        >
            {children}
        </SessionContext.Provider>
    );
});
