import moment from "moment";
import {Map} from "immutable";

export default (purchaseAction?: Map<string, string>) => {
    if (
        !purchaseAction ||
        !purchaseAction.get("fromDate") ||
        !purchaseAction.get("toDate")
    ) {
        return null;
    }

    const fromDate: string = purchaseAction.get("fromDate");
    const toDate: string = purchaseAction.get("toDate");

    // eslint-disable-next-line moment-utc/no-moment-without-utc
    if (!moment(fromDate, "YYYY-MM-DD").isValid()) {
        throw new Error("From Date format must be YYYY-MM-DD");
    }

    // eslint-disable-next-line moment-utc/no-moment-without-utc
    if (!moment(toDate, "YYYY-MM-DD").isValid()) {
        throw new Error("To Date format must be YYYY-MM-DD");
    }

    const fromDateVal = moment.utc(purchaseAction.get("fromDate"));
    const toDateVal = moment.utc(purchaseAction.get("toDate"));

    return toDateVal.diff(fromDateVal, "days") + 1;
};
