import React, {memo, useState, CSSProperties} from "react";
import {Col, Row, Button, ButtonToolbar} from "react-bootstrap";
// @ts-ignore
import {DropdownList} from "react-widgets";
import useSession from "../main/useSession";
import {Link} from "react-router-dom";
// @ts-ignore
import t from "tcomb-form";
import useNavigate from "@beam/use-navigate";

type Team = {name: string; teamNumber: number};

const FormSchema = t.struct({activeTeam: t.maybe(t.enums({}))});

const formOptions = (teams: Array<Team> = []) => {
    const teamOptions = teams.map(({name, teamNumber}) => ({
        text: name,
        value: JSON.stringify({
            name,
            teamNumber,
        }),
    }));

    return {
        fields: {
            activeTeam: {
                label: "Teams",
                nullOption: {
                    value: "",
                    text: "Select your team",
                },
                options: teamOptions,
            },
        },
    };
};

interface FormProps {
    formOptions: any;
    onChange: React.Dispatch<
        React.SetStateAction<{
            activeTeam: string;
        }>
    >;
    value: {activeTeam: string};
}

const Form = (props: FormProps) => {
    const {formOptions, onChange, value} = props;

    return (
        <t.form.Form
            onChange={onChange}
            options={formOptions}
            type={FormSchema}
            value={value}
        />
    );
};

const styles = Object.freeze({
    cancel: {
        marginRight: 10,
    },
    rowStyle: {
        float: "right",
        marginTop: "15px",
    } as CSSProperties,
});

export default memo(function SelectTeam() {
    const {userData, setUserData, logout} = useSession();
    const [selectedTeam, setSelectedTeam] = useState({activeTeam: ""});
    const teams: Array<Team> = userData.teams || [];
    const {navigate} = useNavigate();

    const onSubmit = () => {
        const activeTeam = JSON.parse(selectedTeam.activeTeam);

        const userDataUpdated = {
            ...userData,
            activeTeam,
        };

        setUserData(userDataUpdated);
        navigate("/dashboard");
    };

    return (
        <div className="form-group">
            <div className="container">
                <Col md={6}>
                    <Row>
                        <Form
                            onChange={setSelectedTeam}
                            formOptions={formOptions(teams)}
                            value={selectedTeam}
                        />
                    </Row>
                    <Row style={styles.rowStyle}>
                        <ButtonToolbar>
                            <Link to={"/login"}>
                                <Button type="reset" onClick={logout}>
                                    Cancel
                                </Button>
                            </Link>
                            <Button
                                bsStyle="primary"
                                disabled={!selectedTeam.activeTeam}
                                type="submit"
                                onClick={onSubmit}
                            >
                                Continue
                            </Button>
                        </ButtonToolbar>
                    </Row>
                </Col>
                <Col md={6}>
                    <div>
                        <b>Multiple Team Membership</b>
                    </div>
                    <div>
                        <p>
                            Our records indicate that you belong to more than
                            one team. Select the team you wish to access from
                            the drop-down list and click Continue.
                        </p>
                    </div>
                    <div>
                        <p>
                            Note: You will be asked to choose the Team you wish
                            to access each time you log in to Beam. If you'd
                            like to select an alternative Team once you have
                            entered the Member Center, simply log out and log in
                            to return to this page.
                        </p>
                    </div>
                </Col>
            </div>
        </div>
    );
});
