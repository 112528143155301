import DisabledOverlay from "../../widget/DisabledOverlay";
import LoadingOverlay from "../../widget/LoadingOverlay";
import React, {memo} from "react";

export const STATUSES = {
    normal: 1,
    disabled: 2,
    dataLoading: 3,
    dataUnavailable: 4,
};

export type LoadingStatus = 1 | 2 | 3 | 4;

const WRAPPERS: {[property: string]: any} = {
    [STATUSES.normal]: "span",
    [STATUSES.disabled]: DisabledOverlay,
    [STATUSES.dataLoading]: LoadingOverlay,
    [STATUSES.dataUnavailable]: "span",
};

export default memo(function DashboardWidget({
    children,
    status = STATUSES.normal,
    ...props
}: any) {
    const Wrapper = WRAPPERS[status];

    return <Wrapper {...props}>{children}</Wrapper>;
});
