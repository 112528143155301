// @ts-ignore
import {connect} from "react-redux";
// @ts-ignore
import {LOADING_STATUSES} from "../main/constants";
import {messageGroupEventHandlers as mapDispatchToProps} from "./eventHandlers";
import {messageListSelector as mapStateToProps} from "./selector";
import isNotificationsEmpty from "./isNotificationsEmpty";
import React, {memo, useEffect} from "react";
import MessageGroupedList from "./MessageGroupedList";
import LoadingWidget from "../widget/LoadingWidget";
import {makeStyles} from "@material-ui/styles";
import LinkButton from "../common/buttons/LinkButton";
import {NotificationGroup} from "./types";
import useFeatureFlag from "../featureFlags/useFeatureFlag";

const useStyles = makeStyles(() => {
    return {
        emptyMessageList: {
            height: "200px",
            border: "1px solid rgb(211, 211, 211)",
            width: "100%",
            textAlign: "center" as "center",
            padding: "30px",
        },
        noMessage: {
            fontSize: "20px",
        },
        noMessageAddItemButton: {
            textAlign: "center",
            marginTop: "30px",
        },
    };
});

interface Props {
    getGroupedNotifications: (filters?: any) => Promise<any>;
    getNotifications: (filters: any) => Promise<any>;
    hasMessageGroupingFeature: boolean;
    loadingStatus: string;
    messageGroupList: NotificationGroup;
    messageList: Array<any>;
    onCreateNotificationGrouping: () => void;
    onDeleteMessagePressed: () => void;
    onRenameNotificationGroup: (id: string, name: string) => void;
}

const emptyMessage: string = "You haven't created any push notifications";

const EmptyList = (): JSX.Element => {
    const classes = useStyles();
    return (
        <div className={classes.emptyMessageList}>
            <p className={classes.noMessage}>{emptyMessage}</p>
            <p>
                The Push Notifications you create will show up here. To create a
                new push notification, click the <strong>Add</strong> button
                below
            </p>

            {<LinkButton relativeDestination="create" />}
        </div>
    );
};

const HooksContainer = memo(function ({
    messageGroupList,
    messageList,
    onDeleteMessagePressed,
    loadingStatus,
    onCreateNotificationGrouping,
    onRenameNotificationGroup,
    getGroupedNotifications,
    getNotifications,
}: any) {
    const {featureFlags} = useFeatureFlag();
    const hasMessageGroupingFeature = featureFlags.messageList.messageGrouping;

    useEffect(() => {
        fetchGroupedNotifications();
    }, []);

    const fetchGroupedNotifications = async () => {
        if (!hasMessageGroupingFeature) {
            await getNotifications();

            return;
        }

        await getGroupedNotifications();
    };

    const isLoadingDone = loadingStatus === LOADING_STATUSES.DONE;
    const isLoadingError = loadingStatus === LOADING_STATUSES.ERROR;
    const isEmpty = hasMessageGroupingFeature
        ? isNotificationsEmpty(hasMessageGroupingFeature, messageGroupList)
        : isNotificationsEmpty(hasMessageGroupingFeature, messageList);

    return (
        <LoadingWidget
            emptyList={isEmpty && <EmptyList />}
            errorLabel="Push Notifications are unavailable at this time."
            isDone={isLoadingDone}
            isError={isLoadingError}
            loadingLabel="Loading Push Notifications..."
        >
            <MessageGroupedList
                getGroupedNotifications={getGroupedNotifications}
                messageGroupList={messageGroupList}
                onCreateNotificationGrouping={onCreateNotificationGrouping}
                onDeleteMessagePressed={async (params) => {
                    await onDeleteMessagePressed(params);
                    fetchGroupedNotifications();
                }}
                onRenameNotificationGroup={onRenameNotificationGroup}
            />
        </LoadingWidget>
    );
});

class MessageListContainer extends React.Component<Props> {
    render() {
        const {
            messageGroupList,
            messageList,
            onDeleteMessagePressed,
            loadingStatus,
            onCreateNotificationGrouping,
            onRenameNotificationGroup,
            getGroupedNotifications,
            getNotifications,
        } = this.props;

        return (
            <HooksContainer
                messageGroupList={messageGroupList}
                messageList={messageList}
                onDeleteMessagePressed={onDeleteMessagePressed}
                loadingStatus={loadingStatus}
                onCreateNotificationGrouping={onCreateNotificationGrouping}
                onRenameNotificationGroup={onRenameNotificationGroup}
                getGroupedNotifications={getGroupedNotifications}
                getNotifications={getNotifications}
            />
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(
    //@ts-ignore
    MessageListContainer,
);
