import * as R from "ramda";

const getAppFigure = (field: string, rawData: Object): any =>
    R.map((item: any) => R.view(R.lensProp(field), item), rawData);

interface AppFigures {
    appSales: number;
    averageOrderSize: number;
    totalOrderSize: number;
}

interface AppSalesFiguresRaw {
    currentMonth: AppFigures;
    currentWeek: AppFigures;
    currentYear: AppFigures;
    previousMonth: AppFigures;
    previousWeek: AppFigures;
    previousYear: AppFigures;
}

interface Salesfigures {
    currentMonth: number;
    currentWeek: number;
    currentYear: number;
    previousMonth: number;
    previousWeek: number;
    previousYear: number;
}
interface FormattedAppSalesFigures {
    appSales: Object | Salesfigures;
    averageOrderSize: Object | Salesfigures;
    totalOrderSize: Object | Salesfigures;
}

export default (rawData: AppSalesFiguresRaw): FormattedAppSalesFigures => {
    if (R.isNil(rawData)) {
        return {
            appSales: {},
            averageOrderSize: {},
            totalOrderSize: {},
        };
    }

    return {
        appSales: getAppFigure("appSales", rawData),
        averageOrderSize: getAppFigure("averageOrderSize", rawData),
        totalOrderSize: getAppFigure("totalOrderSize", rawData),
    };
};
