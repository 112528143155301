/*  */

import {} from "immutable";
import {
    VictoryAxis,
    VictoryBar,
    VictoryChart,
    VictoryScatter,
    VictoryStack,
    VictoryTooltip,
} from "victory";
import EngagementScatterGraph from "./EngagementScatterGraph";
import moment from "moment";
import React, {PureComponent} from "react";

const getXYAxisLabels = (xLabels, yData, legendIndex) =>
    yData.map((data, index) => ({
        x: index + 1,
        y: data.get(legendIndex),
    }));

const axisStyle = Object.freeze({
    tickLabels: {fontSize: 14},
    ticks: {
        stroke: "rgb(0, 0, 0)",
        size: 4,
    },
});

const horizontalAxis = Object.freeze({
    ...axisStyle,
    axisLabel: {
        fontSize: 15,
        padding: 40,
    },
});

const styles = Object.freeze({
    chartPadding: {
        left: 90,
        right: 75,
        top: 30,
        bottom: 110,
    },
    verticalAxis: {
        ...axisStyle,
        axisLabel: {
            fontSize: 15,
            padding: 75,
        },
    },
    horizontalAxis,
    dateHorizontalAxis: {
        ...horizontalAxis,
        tickLabels: {
            angle: -90,
            fontSize: 13,
            textAnchor: "end",
            verticalAnchor: "middle",
            padding: 5,
        },
    },
    scatter: {data: {opacity: 0}},
    tooltip: {
        opacity: 1,
        fontSize: 13,
    },
});

const toggleAbsoluteValues = (absoluteValueStyles, isVisible) => [
    {
        target: "labels",
        mutation: () => ({active: isVisible}),
    },
    {
        mutation: () => ({
            style: {
                opacity: isVisible ? 1 : 0,
                ...absoluteValueStyles,
            },
        }),
    },
];

// eslint-disable-next-line max-params
const absoluteValueTooltips = (
    data,
    axisLabels,
    legends,
    colorScale,
    isDate,
) => {
    const bardata = legends.map((value, legendIndex) =>
        getXYAxisLabels(axisLabels, data, legendIndex),
    );
    const axis = axisLabels.toJS();

    return bardata
        .map((oneDataSet, index) => {
            const scatterData = oneDataSet.map((item) => {
                const xValue = item.x;
                const yValue = item.y;
                const xLabel = isDate
                    ? axis[xValue - 1]
                    : parseFloat(axis[xValue - 1]);
                const dataName = legends.toJS()[index];

                return {
                    x: xValue,
                    y: yValue,
                    label: `${dataName}\nx: ${xLabel} y: ${yValue}`,
                };
            });
            const absoluteValueColors = colorScale[index];
            const activeAbsoluteValueStyles = {
                fill: absoluteValueColors,
                stroke: "rgb(0, 0, 0",
                strokeWidth: 2,
            };

            return (
                <VictoryScatter
                    key={index}
                    data={scatterData.toJS()}
                    events={[
                        {
                            target: "data",
                            eventHandlers: {
                                onMouseOver: () =>
                                    toggleAbsoluteValues(
                                        activeAbsoluteValueStyles,
                                        true,
                                    ),
                                onMouseOut: () =>
                                    toggleAbsoluteValues(
                                        activeAbsoluteValueStyles,
                                        false,
                                    ),
                            },
                        },
                    ]}
                    labelComponent={<VictoryTooltip style={styles.tooltip} />}
                    style={styles.scatter}
                />
            );
        })
        .toJS();
};

export default class StackedBarChart extends PureComponent {
    static defaultProps = {
        axisTitles: {},
        barColors: [],
        width: 500,
    };

    static sortDatasetWithMaxValueFirst = (datasets) =>
        datasets.sort((datasetA, datasetB) => {
            const totalUsersForA = datasetA.get(0) + datasetA.get(1);
            const totalUsersForB = datasetB.get(0) + datasetB.get(1);

            return totalUsersForB - totalUsersForA;
        });

    static calculateCoordinateRange = (dataPoints) => {
        const sortedDataSet = StackedBarChart.sortDatasetWithMaxValueFirst(
            dataPoints,
        );
        const dataPointWithTotalMaxValue = sortedDataSet.first();
        const dataPointWithLowestValue = sortedDataSet.last();

        return {
            lower: dataPointWithLowestValue.get(0),
            upper:
                dataPointWithTotalMaxValue.get(0) +
                dataPointWithTotalMaxValue.get(1),
        };
    };

    calculateVictoryBars(barWidth) {
        const {legends, axisLabels, datasets, barColors} = this.props;

        return legends.map((legend, index) => {
            const bardata = getXYAxisLabels(axisLabels, datasets, index).toJS();

            const barStyle = Object.freeze({
                data: {
                    width: barWidth,
                    fill: barColors[index],
                },
            });

            return <VictoryBar key={index} data={bardata} style={barStyle} />;
        });
    }

    calculateHorizontalLabels() {
        const {axisLabels, tickIsDate} = this.props;

        // eslint-disable-next-line moment-utc/no-moment-without-utc
        return tickIsDate
            ? axisLabels.map((date) => moment(date).format("ddd MMM DD YYYY"))
            : axisLabels;
    }

    renderVerticalAxis() {
        const {axisTitles} = this.props;

        return (
            <VictoryAxis
                dependentAxis
                label={axisTitles.y}
                style={styles.verticalAxis}
            />
        );
    }

    renderHorizontalAxis(horizontalLabels) {
        const {axisTitles, tickIsDate} = this.props;

        return (
            <VictoryAxis
                fixLabelOverlap
                label={axisTitles.x}
                style={
                    tickIsDate
                        ? styles.dateHorizontalAxis
                        : styles.horizontalAxis
                }
                tickValues={horizontalLabels.toJS()}
            />
        );
    }

    renderVictoryStack(victoryBars) {
        return (
            <VictoryStack colorScale="qualitative">{victoryBars}</VictoryStack>
        );
    }

    renderVictoryStackAbsoluteValue(horizontalLabels) {
        const {
            showAbsoluteValue,
            datasets,
            legends,
            barColors,
            tickIsDate,
        } = this.props;

        if (!showAbsoluteValue) {
            return null;
        }

        return (
            <VictoryStack>
                {absoluteValueTooltips(
                    datasets,
                    horizontalLabels,
                    legends,
                    barColors,
                    tickIsDate,
                )}
            </VictoryStack>
        );
    }

    renderEngagementGraph() {
        if (!this.props.showEngagement) {
            return null;
        }

        return (
            <EngagementScatterGraph
                calculateCoordinateRange={
                    StackedBarChart.calculateCoordinateRange
                }
                dataPoints={this.props.datasets}
                fillStyle={{fill: this.props.barColors[0]}}
            />
        );
    }

    render() {
        const {datasets, width, ...props} = this.props;

        if (datasets.isEmpty()) {
            return null;
        }

        const barWidth = Math.floor(width / 2 / datasets.count());
        const domainPadding = Object.freeze({x: barWidth});
        const victoryBars = this.calculateVictoryBars(barWidth);
        const horizontalLabels = this.calculateHorizontalLabels();

        return (
            <VictoryChart
                {...props}
                domainPadding={domainPadding}
                padding={styles.chartPadding}
                width={width}
            >
                {this.renderVerticalAxis()}
                {this.renderHorizontalAxis(horizontalLabels)}
                {this.renderVictoryStack(victoryBars)}
                {this.renderVictoryStackAbsoluteValue(horizontalLabels)}
                {this.renderEngagementGraph()}
            </VictoryChart>
        );
    }
}
