interface GroupedBarChartItem {
    label: string;
    value: any;
}

export default (
    chartData: Array<Array<GroupedBarChartItem>>,
    {groupLabel, dataLabels}: {groupLabel: string; dataLabels: Array<string>},
): Array<{[key: string]: any}> =>
    chartData.reduce(
        (
            csvData: Array<{[key: string]: any}>,
            chartDataItem: Array<GroupedBarChartItem>,
        ) => {
            const groupedData = chartDataItem.reduce(
                (
                    itemData: {[property: string]: string},
                    itemValue: any,
                    index: number,
                ) => ({
                    ...itemData,
                    [groupLabel]: itemData[groupLabel]
                        ? `${itemData[groupLabel]} and ${itemValue.label}`
                        : itemValue.label,
                    [dataLabels[index]]: itemValue.value,
                }),
                {},
            );

            csvData.push(groupedData);

            return csvData;
        },
        [],
    );
