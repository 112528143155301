import * as R from "ramda";
import {action, computed, observable} from "mobx";
import {getIn, setIn} from "../common/ramdaDataUtils";
import {isValidUrl} from "../common/isValidUrl";
// @ts-ignore
import AudienceSelectorRepository from "../audienceSelector/AudienceSelectorRepository";
// @ts-ignore
import BeamAPIClient from "../apiClient/BeamAPIClient";
// @ts-ignore
import MessageAudienceSelectionRepository from "./audience/MessageAudienceSelectionRepository";
import messageToFormFormat from "./messageToFormFormat";
import moment, {Moment} from "moment";
import PushNotification, {linkActions} from "./PushNotification";
import stateToPayload from "./stateToPayload";
import {
    StepContainerForm,
    MessageAlert,
    RichPushNotifications,
    Schedule,
} from "./types";

const getDefaultPushNotificationValues = R.always(new PushNotification());

export interface OnSubmitFormParams {
    groupId?: string;
    notificationId?: string;
    saveAsDraft?: boolean;
}

export default class PushNotificationRepository {
    apiClient: BeamAPIClient;

    messageAudienceSelectionRepository: MessageAudienceSelectionRepository;
    audienceSelectorRepository: AudienceSelectorRepository;

    constructor({
        apiClient,
        messageAudienceSelectionRepository,
        audienceSelectorRepository,
    }: {
        apiClient: BeamAPIClient;
        messageAudienceSelectionRepository: MessageAudienceSelectionRepository;
        audienceSelectorRepository: AudienceSelectorRepository;
    }) {
        this.apiClient = apiClient;
        this.messageAudienceSelectionRepository = messageAudienceSelectionRepository;
        this.audienceSelectorRepository = audienceSelectorRepository;
    }

    @observable
    isLoading = false;
    @observable
    stepContainerForm: StepContainerForm = getDefaultPushNotificationValues()
        .stepContainerForm;
    @observable
    messageAlert: MessageAlert = getDefaultPushNotificationValues()
        .messageAlert;
    @observable
    richPushNotifications: RichPushNotifications = getDefaultPushNotificationValues()
        .richPushNotifications;
    @observable
    schedule: Schedule = getDefaultPushNotificationValues().schedule;

    @observable
    campaignList: Array<{value: string; text: string}> = [];

    @action.bound
    onSetMessageAlert(path: Array<string>, value: any) {
        if (R.isEmpty(path)) {
            this.messageAlert = value;

            return;
        }

        this.messageAlert = setIn(path, value, this.messageAlert);
    }

    @action.bound
    onSetRichPushNotifications(path: Array<string>, value: any) {
        if (R.isEmpty(path)) {
            this.richPushNotifications = value;

            return;
        }

        if (path[0] === "imageFile") {
            this.richPushNotifications = setIn(
                ["image", "value"],
                undefined,
                this.richPushNotifications,
            );
        }

        this.richPushNotifications = setIn(
            path,
            value,
            this.richPushNotifications,
        );
    }

    @action.bound
    onSetSchedule(path: Array<string>, value: any) {
        if (R.isEmpty(path)) {
            this.schedule = value;

            return;
        }
        this.schedule = setIn(path, value, this.schedule);
    }

    @action.bound
    onToggleStep(step: string) {
        // @ts-ignore
        const currentStatus = this.stepContainerForm[step];
        const newStepContainer = setIn(
            [step],
            !currentStatus,
            this.stepContainerForm,
        );

        this.stepContainerForm = newStepContainer;
    }

    @action.bound
    openNextStep({current, next}: {current: string; next: string}) {
        const updateCurrent = setIn([current], false, this.stepContainerForm);
        const updatedNext = setIn([next], true, updateCurrent);

        this.stepContainerForm = updatedNext;
    }

    @computed
    get richPushNotificationErrors(): Object {
        const {richPushNotifications} = this;
        const linkAction = getIn(
            ["linkAction", "value"],
            richPushNotifications,
        );
        const hasLinkAction = linkAction !== linkActions.NONE;
        const isWebSite =
            getIn(["linkAction", "value"], richPushNotifications) ===
            linkActions.VISIT_WEBSITE;
        const deepLinkingErrors = isWebSite
            ? {
                  buttonUrl:
                      R.isEmpty(
                          getIn(["buttonUrl", "value"], richPushNotifications),
                      ) ||
                      !isValidUrl(
                          getIn(["buttonUrl", "value"], richPushNotifications),
                      ),
              }
            : {
                  productSku:
                      hasLinkAction &&
                      R.isEmpty(
                          getIn(["productSku", "value"], richPushNotifications),
                      ),
              };

        return {
            dismissButtonText: R.isEmpty(
                getIn(["dismissButtonText", "value"], richPushNotifications),
            ),
            body: R.isEmpty(getIn(["body", "value"], richPushNotifications)),
            buttonText:
                hasLinkAction &&
                R.isEmpty(
                    getIn(["buttonText", "value"], richPushNotifications),
                ),
            ...deepLinkingErrors,
        };
    }

    @computed
    get formErrors(): {
        audience: boolean;
        messageAlert: boolean;
        schedule: boolean;
        urlToOpen: boolean;
        fbNotification: boolean;
    } {
        const {messageAlert} = this;
        const fbNotificationHasError =
            this.richPushNotifications.wantFbNotification &&
            R.compose(
                R.contains(true),
                // @ts-ignore
                R.values,
            )(this.richPushNotificationErrors);

        return {
            audience: R.isEmpty(
                this.messageAudienceSelectionRepository
                    .currentAudienceSelectionId,
            ),
            messageAlert: R.isEmpty(messageAlert.value),
            urlToOpen: !isValidUrl(messageAlert.urlToOpen.value),
            fbNotification: fbNotificationHasError,
            schedule: !this.schedule.sendImmediately && !this.schedule.value,
        };
    }

    returnRemainingValue(field: string, isValidValue: boolean): Array<string> {
        return isValidValue ? [field] : [];
    }

    @computed
    get remaining(): Array<string> {
        const {
            audience,
            messageAlert,
            schedule,
            urlToOpen,
            fbNotification,
        } = this.formErrors;

        return [
            ...this.returnRemainingValue("audience", audience),
            ...this.returnRemainingValue("message", messageAlert),
            ...this.returnRemainingValue("schedule", schedule),
            ...this.returnRemainingValue("url", urlToOpen),
            ...this.returnRemainingValue("fbNotification", fbNotification),
        ];
    }

    @action.bound
    async uploadImage(
        imageFile: Object,
    ): Promise<{success: boolean; imageUrl: string}> {
        this.isLoading = true;
        try {
            const response = await this.apiClient.invokePostWithFormData(
                "campaignImages",
                {
                    image: imageFile,
                },
            );

            return {
                success: true,
                imageUrl: response.imageUrl,
            };
        } catch (exception) {
            this.isLoading = false;
            return {
                success: false,
                imageUrl: "",
            };
        }
    }

    @action.bound
    async onSubmitForm({
        groupId,
        notificationId,
        saveAsDraft = false,
    }: OnSubmitFormParams) {
        const {
            audienceSelectorRepository,
            messageAudienceSelectionRepository,
            richPushNotifications,
            schedule,
            messageAlert,
            uploadImage,
        } = this;

        const {
            sales,
            audienceSelection,
            audienceLocation,
        } = audienceSelectorRepository;
        const audienceSelectionId =
            messageAudienceSelectionRepository.currentAudienceSelectionId;
        const audienceState = {
            ...audienceSelection,
            sales,
            location: audienceLocation,
        };

        const imageFile = getIn(["imageFile", "value"], richPushNotifications);
        const wantFbNotification = richPushNotifications.wantFbNotification;

        if (wantFbNotification && imageFile) {
            const {success, imageUrl} = await uploadImage(imageFile);

            if (!success) {
                return {success: false, isImageUploadError: true};
            }

            this.onSetRichPushNotifications(["image", "value"], imageUrl);
        }

        const payload = stateToPayload({
            schedule,
            message: messageAlert,
            audience: audienceState,
            fbNotification: this.richPushNotifications,
            groupId,
            saveAsDraft,
            audienceSelectionId,
        });

        const potentitalReach =
            R.path(
                ["predictiveAudienceSize", "potentialReach"],
                messageAudienceSelectionRepository,
            ) || 0;

        return notificationId
            ? this.onUpdatePushNotification(
                  {...payload, potentialReach: potentitalReach},
                  notificationId,
              )
            : this.onSubmitPushNotification({
                  ...payload,
                  potentialReach: potentitalReach,
                  childrenTeamFilter: audienceState.childrenTeamFilter,
              });
    }

    @action.bound
    async onSubmitPushNotification(payload: Object) {
        try {
            this.isLoading = true;
            await this.apiClient.invokePostEndpoint("notification", payload);

            return {success: true};
        } catch (exception) {
            this.isLoading = false;

            return {success: false};
        }
    }

    @action.bound
    onUpdatePushNotification(payload: Object, notificationId: string) {
        try {
            this.isLoading = true;
            this.apiClient.invokePutEndpoint(
                `notification/${notificationId}`,
                payload,
            );
            this.onResetRepository();
            return {success: true};
        } catch (exception) {
            this.isLoading = false;
            return {success: false};
        }
    }

    @action.bound
    onResetRepository() {
        this.isLoading = false;
        this.stepContainerForm = getDefaultPushNotificationValues().stepContainerForm;
        this.messageAlert = getDefaultPushNotificationValues().messageAlert;
        this.richPushNotifications = getDefaultPushNotificationValues().richPushNotifications;
        this.schedule = getDefaultPushNotificationValues().schedule;
        this.messageAudienceSelectionRepository.onResetCurrentAudienceSelection();
    }

    @action.bound
    onDuplicateRichNotifcation(richNotification: any) {
        const {
            backgroundUrl,
            buttonText,
            buttonUrl,
            dismissButtonText,
            linkAction,
            productSku,
            text,
            wantFbNotification,
            height,
            width,
            actionType,
            actionParams,
        } = richNotification;

        // @ts-ignore
        const linkActionValue = linkActions[actionType] ?? linkAction;
        const linkParamsValue = actionParams ?? productSku;

        if (wantFbNotification) {
            this.richPushNotifications = {
                linkAction: {
                    value: linkActionValue,
                },
                body: {
                    value: text,
                },
                image: {
                    value: backgroundUrl,
                },
                imageFile: {
                    value: "",
                    dimension: {height: height, width: width},
                },
                dismissButtonText: {
                    value: dismissButtonText,
                },
                buttonText: {
                    value: buttonText,
                },
                buttonUrl: {
                    value: buttonUrl,
                },
                productSku: {
                    value: linkParamsValue,
                },
                wantFbNotification: true,
            };
        }
    }

    @action.bound
    onDuplicateMessageAlert(messageAlert: {
        message?: string;
        urlToOpen?: string;
        description?: string;
    }) {
        const {message, urlToOpen, description} = messageAlert;

        this.messageAlert = {
            description: {
                value: description,
            },
            value: message || "",
            urlToOpen: {
                value: urlToOpen || "",
            },
        };
    }

    @action.bound
    onDuplicateSchedule(schedule: {sendDate: string | Moment}) {
        const sendDateData = schedule.sendDate;

        if (!sendDateData) {
            return;
        }

        const sendDate = moment.utc(schedule.sendDate);
        const dateNow = moment.utc();
        const dateToSet = sendDate.isSameOrAfter(dateNow) ? sendDate : dateNow;

        this.schedule = {
            value: dateToSet,
            sendImmediately: false,
        };
    }

    @action.bound
    onDuplicateAudienceSelection(id: string) {
        if (R.isEmpty(id)) {
            return;
        }
        this.messageAudienceSelectionRepository.onDropDownChange(id);
    }

    @action.bound
    async getNotificationDetail(id: string) {
        try {
            this.isLoading = true;
            //@ts-ignore
            const response = await this.apiClient.invokeGetEndpoint(
                `notification/${id}`,
            );

            this.isLoading = false;
            const {alert, message, schedule} = messageToFormFormat(response);

            this.onDuplicateRichNotifcation(message);
            this.onDuplicateMessageAlert(alert);
            this.onDuplicateSchedule(schedule);
            this.onDuplicateAudienceSelection(response.audienceSelectionId);
        } catch (exception) {
            this.isLoading = false;
        }
    }
}
