import {Col, Row} from "react-bootstrap";
import React, {memo} from "react";

const styles = Object.freeze({
    inputRow: {
        marginTop: 5,
        marginBottom: 5,
    },
    label: {
        color: "rgb(34, 45, 50)",
        fontSize: 14,
        fontWeight: 700,
    },
    sublabel: {
        color: "rgb(34, 45, 50)",
        fontSize: 12,
        fontWeight: 400,
    },
});

interface Props {
    children?: JSX.Element;
    label: string;
    sublabel?: string;
    labelGrid?: number;
    valueGrid?: number;
}

export default memo(function InputRow({
    label,
    sublabel,
    children,
    labelGrid = 4,
    valueGrid = 8,
}: Props): JSX.Element {
    return (
        <Row style={styles.inputRow}>
            <Col xs={labelGrid}>
                <h4 style={styles.label}>{label}</h4>
                {sublabel && <h4 style={styles.sublabel}>{sublabel}</h4>}
            </Col>
            <Col xs={valueGrid}>{children}</Col>
        </Row>
    );
});
