import {Col, Row} from "react-bootstrap";
import AudienceInputRow from "./AudienceInputRow";
import Box from "../widget/Box";
// @ts-ignore
import Location from "./location/Location";
// @ts-ignore
import PredictiveAudienceSize from "../pushNotification/audience/PredictiveAudienceSize";
import React, {memo} from "react";
// @ts-ignore
import Sales from "./sales/Sales";
// @ts-ignore
import ChildrenTeamFilter from "../common/ChildrenTeamFilter";
import SubmitAndNavigateButton from "../common/SubmitAndNavigateButton";
import {makeStyles} from "@material-ui/styles";
import AudienceSelection from "./AudienceSelection";

const useStyles = makeStyles({
    container: {
        padding: 10,
    },
    selectorArea: {
        paddingBottom: 10,
    },
});

export interface AddAudienceSelectionProps {
    audienceSelection: AudienceSelection;
    onSaveAudienceSelection: () => Promise<{success: boolean}>;
}

interface AddAudienceSelectionContainer extends AddAudienceSelectionProps {
    onSetAudienceSelectionByPath: (
        path: string[],
        value: string | Object,
    ) => void;
    onSetUserIdentifiers: (userIdentifiers: string) => void;
    hasReachedMaxUserIdentifiers: boolean;
}

export default memo(function AddAudienceSelection({
    audienceSelection,
    onSetAudienceSelectionByPath,
    onSetUserIdentifiers,
    onSaveAudienceSelection,
    hasReachedMaxUserIdentifiers,
}: AddAudienceSelectionContainer) {
    const classes = useStyles();
    return (
        <Row className={classes.container}>
            <Box title="Audience Criteria">
                <>
                    <Col md={9}>
                        <Row className={classes.selectorArea}>
                            <Col md={12}>
                                <AudienceInputRow
                                    errorMessage="The name field is required"
                                    isError={!Boolean(audienceSelection.name)}
                                    isFirst
                                    label="Name"
                                    onChangeValue={(name) =>
                                        onSetAudienceSelectionByPath(
                                            ["name"],
                                            name,
                                        )
                                    }
                                    placeholder="Add a name to identify this audience"
                                    value={audienceSelection.name}
                                />
                                <AudienceInputRow
                                    errorMessage="You can only put up to 100 IDs"
                                    isError={hasReachedMaxUserIdentifiers}
                                    label="User Identifiers"
                                    onChangeValue={onSetUserIdentifiers}
                                    placeholder="Add user IDs separated by comma e.g 990001,990002"
                                />
                            </Col>
                            <Col md={6}>
                                <hr />

                                <SubmitAndNavigateButton
                                    disabled={
                                        !audienceSelection.name ||
                                        hasReachedMaxUserIdentifiers
                                    }
                                    path="/audience-builder"
                                    onSubmit={async () =>
                                        await onSaveAudienceSelection()
                                    }
                                >
                                    Save
                                </SubmitAndNavigateButton>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={3}>
                        <PredictiveAudienceSize />
                    </Col>
                </>
            </Box>
        </Row>
    );
});
