// @ts-ignore
import {CSVLink} from "react-csv";
import {NavLink} from "react-router-dom";
import Box from "../../widget/Box";
import InfoPopOverText from "../../common/InfoPopOverText";
import PopOverButton from "../../common/buttons/PopOverButton";
import React, {memo, CSSProperties} from "react";
import useFeatureFlag from "../../featureFlags/useFeatureFlag";

const styles = Object.freeze({
    exportButton: {
        paddingLeft: 5,
        paddingRight: 5,
    },
    exportButtonContainer: {
        float: "right",
    } as CSSProperties,
});

interface Props {
    children?: JSX.Element;
    csvData: Array<any>;
    infoPopOverText?: string;
    style?: Object;
    title: string;
}

interface ButtonProps {
    isExportEnabled: boolean;
    fileName: string;
    csvData: any;
}

const ExportCSVButton = ({
    isExportEnabled,
    fileName,
    csvData = [],
}: ButtonProps) => {
    return (
        <PopOverButton
            buttonIconName="download"
            buttonStyle={styles.exportButton}
            noBorder
            placement="left"
            popOverTitle="Export CSV"
            size="sm"
            style={styles.exportButtonContainer}
        >
            {isExportEnabled ? (
                <CSVLink data={csvData} filename={fileName}>
                    {fileName}
                </CSVLink>
            ) : (
                <span>
                    <InfoPopOverText info="For Enterprise users only." />
                    <NavLink to="/contact-us">Contact us</NavLink> to enable CSV
                    export.
                </span>
            )}
        </PopOverButton>
    );
};

const ExportableChart = memo(
    ({
        children,
        csvData,
        infoPopOverText,
        style,
        title,
        isExportEnabled,
    }: any) => {
        return (
            <Box
                buttons={[
                    <ExportCSVButton
                        csvData={csvData}
                        isExportEnabled={isExportEnabled}
                        fileName={`${title}.csv`}
                    />,
                ]}
                infoPopOverText={infoPopOverText}
                style={style}
                title={title}
            >
                {children}
            </Box>
        );
    },
);

export default memo(function ExportableChartContainer(props: Props) {
    const {children, csvData, infoPopOverText, style, title} = props;
    const {featureFlags} = useFeatureFlag();
    const {exportCSV: isExportEnabled} = featureFlags;

    return (
        <ExportableChart
            children={children}
            csvData={csvData}
            infoPopOverText={infoPopOverText}
            style={style}
            title={title}
            isExportEnabled={isExportEnabled}
        />
    );
});
