import * as R from "ramda";
import {urlHasHttpsScheme} from "./processUrlScheme";

// eslint-disable-next-line no-useless-escape
const validRfc3986UrlRegex = /^([a-z][a-z0-9+\-.]*:(\/\/([a-z0-9\-._~%!$&'()*+,;=]+@)?([a-z0-9\-._~%]+|\[[a-f0-9:.]+\]|\[v[a-f0-9][a-z0-9\-._~%!$&'()*+,;=:]+\])(:[0-9]+)?(\/[a-z0-9\-._~%!$&'()*+,;=:@]+)*\/?|(\/?[a-z0-9\-._~%!$&'()*+,;=:@]+(\/[a-z0-9\-._~%!$&'()*+,;=:@]+)*\/?)?)|([a-z0-9\-._~%!$&'()*+,;=@]+(\/[a-z0-9\-._~%!$&'()*+,;=:@]+)*\/?|(\/[a-z0-9\-._~%!$&'()*+,;=:@]+)+\/?))(\?[a-z0-9\-._~%!$&'()*+,;=:@\/?]*)?(#[a-z0-9\-._~%!$&'()*+,;=:@\/?]*)?$/i;
const urlValidator = R.curry((regex: any, url: any) =>
    R.ifElse(R.isEmpty, R.T, R.ifElse(R.test(regex), R.T, R.F))(url),
);

export const isValidUrl = urlValidator(validRfc3986UrlRegex);
export const isValidImageUrl = (url: string): boolean => {
    if (!url) {
        return true;
    }

    return urlHasHttpsScheme(url) && urlValidator(validRfc3986UrlRegex)(url);
};
