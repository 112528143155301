/*  */

import {Col, Row} from "react-bootstrap";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import moment from "moment";
import momentLocalizer from "react-widgets/lib/localizers/moment";
import React, {Component} from "react";

const styles = Object.freeze({
    datePicker: {
        borderRadius: 0,
    },
});

momentLocalizer(moment);

export default class LabelledDatePicker extends Component {
    renderLabel() {
        return <h4>{this.props.label}</h4>;
    }

    renderDatePicker() {
        const {
            datePickerStyle,
            disabled,
            max,
            min,
            onChange,
            open,
            onToggle,
            value,
            onClick,
        } = this.props;

        return (
            <DateTimePicker
                defaultOpen={open}
                disabled={disabled}
                editFormat="DD/MM/YYYY"
                format="DD/MM/YYYY"
                max={max}
                min={min}
                onClick={onClick}
                onChange={onChange}
                onToggle={onToggle}
                open={open}
                style={datePickerStyle || styles.datePicker}
                time={false}
                value={value}
            />
        );
    }

    render() {
        return (
            <Row>
                <Col md={6}>{this.renderLabel()}</Col>
                <Col md={6}>{this.renderDatePicker()}</Col>
            </Row>
        );
    }
}
