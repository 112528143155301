import * as R from "ramda";
import {handleActions} from "redux-actions";
import {
    setMessageDetail,
    setMessageGroupList,
    setMessageList,
} from "./actionCreators";
import immutable from "immutable";

interface Payload {
    payload: any;
}

const getInitialState = R.always(
    immutable.Map({
        // $FlowIssue
        messageList: immutable.List([]),
        messageDetails: immutable.Map({}),
        messageGroupList: [],
    }),
);

const handleSetMessageList = (state: any, {payload}: Payload): any =>
    state.set("messageList", payload);

const handleSetMessageDetail = (state: any, {payload}: Payload): any =>
    state.set("messageDetails", payload);

const handleSetGroupList = (state: any, {payload}: Payload) =>
    state.set("messageGroupList", payload);

export default handleActions(
    {
        //@ts-ignore
        [setMessageList]: handleSetMessageList,
        //@ts-ignore
        [setMessageDetail]: handleSetMessageDetail,
        //@ts-ignore
        [setMessageGroupList]: handleSetGroupList,
    },
    getInitialState(),
);
