import * as R from "ramda";

const urlWithSchemeRegex = /^[a-z][a-z0-9+\-.]*:\/\/.*/;

export const urlHasScheme = (url: string): boolean =>
    R.test(urlWithSchemeRegex, url);
export const urlHasHttpsScheme = (url: string): boolean =>
    R.test(/^(https:\/\/).*$/, url);
export const addSchemeToUrl = (
    url?: string,
    scheme: string = "http",
): string => {
    if (!url) {
        return "";
    }

    return urlHasScheme(url) ? url : `${scheme}://${url}`;
};
