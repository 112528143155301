import {List} from "immutable";

export default (list: List<any>, previousIndex: number, newIndex: number) => {
    if (newIndex >= list.count()) {
        return list
            .delete(previousIndex)
            .set(newIndex, list.get(previousIndex));
    }

    return list
        .delete(previousIndex)
        .splice(newIndex, 0, list.get(previousIndex));
};
