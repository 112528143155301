import React, {useState} from "react";
import useSession from "../main/useSession";

import CollapsibleBox from "../widget/CollapsibleBox";
import getAdvertTitles from "./getAdvertTitles";
import {fromJS} from "immutable";
import AdvertTableContainer from "./AdvertTableContainer";
import {PlacementType} from "./types";

const styles = Object.freeze({
    total: {
        margin: "auto 10px",
    },
});

const HeaderTitle = ({title, total}: {title: string; total: string}) => (
    <div>
        {title}
        <div className="pull-right">
            <div style={styles.total}>Total: {total || 0}</div>
        </div>
    </div>
);

const CategoryAdvertTableContainer = ({
    advertsForSpecificCategory,
    title,
    placementType,
    onUpdatePlacements,
    onDeleteAdvertPressed,
    onMultiDeleteAdvertPresed,
}: {
    advertsForSpecificCategory: Array<any>;
    title: string;
    placementType: PlacementType;
    onUpdatePlacements: (placements: any) => void;
    onMultiDeleteAdvertPresed: (ids: Array<string>) => void;
    onDeleteAdvertPressed: (id: string) => any;
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const advertList = fromJS(advertsForSpecificCategory);

    return (
        <CollapsibleBox
            header={<HeaderTitle title={title} total={advertList.count()} />}
            onHeaderClick={(): void =>
                setIsOpen((currentValue) => !currentValue)
            }
            open={isOpen}
            removeBodyPadding
        >
            <AdvertTableContainer
                title=""
                advertList={advertList}
                limit={0}
                onDeleteAdvertPressed={onDeleteAdvertPressed}
                onUpdatePlacements={onUpdatePlacements}
                placementName={placementType}
                placementType={placementType}
                onMultiDeleteAdvertPresed={onMultiDeleteAdvertPresed}
            />
        </CollapsibleBox>
    );
};

interface Props {
    categoryAdvertsEnabled: Array<PlacementType>;
    allCategoryAdverts: Array<any>;
    onUpdatePlacements: (placements: any) => void;
    onMultiDeleteAdvertPresed: (ids: Array<string>) => void;
    onDeleteAdvertPressed: (id: string) => any;
}

export default function CategoryAdverts({
    categoryAdvertsEnabled,
    allCategoryAdverts = [],
    onMultiDeleteAdvertPresed,
    onDeleteAdvertPressed,
    onUpdatePlacements,
}: Props) {
    const {userData} = useSession();
    // @ts-ignore
    const adTitles: any = getAdvertTitles(userData?.activeTeam?.teamNumber);
    return (
        <>
            {categoryAdvertsEnabled.map((item) => {
                const advertsForSpecificCategory = allCategoryAdverts.filter(
                    (categoryAdvert) => categoryAdvert.placement === item,
                );

                return (
                    <CategoryAdvertTableContainer
                        title={adTitles[item]}
                        placementType={item}
                        advertsForSpecificCategory={advertsForSpecificCategory}
                        onUpdatePlacements={onUpdatePlacements}
                        onDeleteAdvertPressed={onDeleteAdvertPressed}
                        onMultiDeleteAdvertPresed={onMultiDeleteAdvertPresed}
                    />
                );
            })}
        </>
    );
}
