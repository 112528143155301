import "react-widgets/dist/css/react-widgets.css";
import {Col, ControlLabel, FormGroup, HelpBlock, Row} from "react-bootstrap";
// @ts-ignore
import LabelledDatePicker from "../common/forms/LabelledDatePicker";
import moment from "moment";
import React, {useState, useEffect, memo} from "react";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import {makeStyles} from "@material-ui/styles";
import classnames from "classnames";

const useStyles = makeStyles(() => {
    return {
        container: {
            padding: "0 14px",
        },
    };
});

interface CampaignDatePickerProps {
    labelStyle?: Object;
    errorMessage?: string;
    label: string;
    datePickerProps: any;
}

const CampaignDatePicker = ({
    labelStyle = {},
    errorMessage,
    label,
    datePickerProps,
}: CampaignDatePickerProps): JSX.Element => (
    <div>
        <Col sm={4} style={labelStyle}>
            <ControlLabel>{label}</ControlLabel>
        </Col>
        <Col sm={8}>
            <DateTimePicker format={"DD/MM/YYYY"} {...datePickerProps} />
            <HelpBlock>{errorMessage}</HelpBlock>
        </Col>
    </div>
);

interface DatePickerContainerProps {
    errorMessage?: string;
    label: string;
    validationState: "success" | "warning" | "error" | null | undefined;
    min?: Date;
    onChange: (date: Date) => void;
    value?: Date;
    labelStyle?: Object;
    openOnInputClick?: boolean;
}

function DatePickerContainer({
    label,
    validationState,
    min,
    onChange,
    errorMessage,
    value,
    labelStyle,
    openOnInputClick,
}: DatePickerContainerProps) {
    const [open, setOpen] = useState("");
    const classes = useStyles();
    const formContainerClass = classnames(classes.container, "form-horizontal");

    const handleInputClick = (event: any) => {
        if (event.target.tagName === "INPUT") {
            setOpen("calendar");
        }
    };

    const onOpenDatePicker = (isOpen: boolean) => {
        setOpen(isOpen ? "calendar" : "");
    };

    return (
        <div className={formContainerClass}>
            <FormGroup validationState={validationState}>
                <CampaignDatePicker
                    errorMessage={errorMessage}
                    label={label}
                    labelStyle={labelStyle}
                    datePickerProps={{
                        min: min,
                        onChange: onChange,
                        onClick: openOnInputClick && handleInputClick,
                        onToggle: onOpenDatePicker,
                        open: open,
                        value: value,
                        max: moment.utc().add(1, "year").toDate(),
                        showLabel: true,
                        time: false,
                    }}
                />
            </FormGroup>
        </div>
    );
}

const getMinMaxDates = (
    previousDatesOnly?: boolean,
    startDate: Date = new Date(),
    endDate: Date = new Date(),
) => {
    const currentDate = new Date();

    return previousDatesOnly
        ? {
              startDateProps: {
                  max: endDate,
              },
              endDateProps: {
                  min: startDate,
                  max: currentDate,
              },
          }
        : {
              startDateProps: {
                  min: currentDate,
              },
              endDateProps: {
                  min: startDate,
              },
          };
};

interface Props {
    endDateValue?: Date;
    onChange: (keyProp: string, date: Date) => void;
    startDateValue?: Date;
    labelStyle?: Object;
    previousDatesOnly?: boolean;
    openOnInputClick?: boolean;
    errors?: {startDate: boolean; endDate: boolean};
}

export const canUpdateStartDate = (date: Date | string): boolean => {
    const startDateValue = moment(date);
    const currentDate = moment();

    return (
        startDateValue.isSame(currentDate, "day") ||
        startDateValue.isAfter(currentDate)
    );
};

export const canUpdateEndDate = (
    date: Date | string | undefined,
    endDate: Date | string | undefined,
): boolean => {
    if (!date || !endDate) {
        return false;
    }

    return moment(date).isAfter(endDate);
};

export const returnValidEndDate = (
    date: Date | string,
    endDate: Date | string,
): Date | String | any => {
    if (moment(date).isAfter(endDate)) {
        return date;
    } else {
        return endDate;
    }
};

export default memo(function StartEndDatePicker({
    endDateValue,
    onChange,
    startDateValue,
    labelStyle,
    previousDatesOnly,
    openOnInputClick = false,
    errors = {startDate: false, endDate: false},
}: Props) {
    const [endDate, setEndDate] = useState(new Date());
    const {startDateProps, endDateProps} = getMinMaxDates(
        previousDatesOnly,
        startDateValue,
        endDateValue,
    );

    useEffect(() => {
        setEndDate(endDateValue || new Date());
    }, []);

    return (
        <Row>
            <Col sm={6}>
                <DatePickerContainer
                    errorMessage="A start date is required"
                    label="Start Date:"
                    labelStyle={labelStyle}
                    onChange={(date: Date) => {
                        if (!canUpdateStartDate(date)) {
                            return;
                        }

                        onChange("startDate", date);

                        if (canUpdateEndDate(date, endDateValue)) {
                            onChange("endDate", date);
                            setEndDate(date);
                        }
                    }}
                    validationState={errors.startDate ? "error" : null}
                    value={startDateValue}
                    {...startDateProps}
                    openOnInputClick={openOnInputClick}
                />
            </Col>
            <Col sm={6}>
                <DatePickerContainer
                    errorMessage={"An end date is required"}
                    label="End Date:"
                    {...endDateProps}
                    labelStyle={labelStyle}
                    onChange={(date: Date) => {
                        if (!startDateValue) {
                            return;
                        }

                        const validEndDate = returnValidEndDate(
                            startDateValue,
                            date,
                        );

                        setEndDate(validEndDate);
                        onChange("endDate", validEndDate);
                    }}
                    validationState={errors.endDate ? "error" : null}
                    value={endDateValue ? endDateValue : endDate}
                    openOnInputClick={openOnInputClick}
                />
            </Col>
        </Row>
    );
});
