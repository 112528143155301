import * as R from "ramda";
import {createSelector, createStructuredSelector} from "reselect";
import {NotificationGroups} from "./types";

export const getLoadingStatus = (state: any) => state.main.get("loadingStatus");
export const getMessageList = (state: any) => state.message.get("messageList");
export const getMessageGroupList = (state: any) =>
    state.message.get("messageGroupList");

interface SelectComponentItemsProps {
    args: Array<{
        label: string;
        value: string | number;
    }>;
}

const formatGroupItems = R.map(
    ({groupName, _id}: {groupName: string; _id: string}) => ({
        label: groupName,
        value: _id,
    }),
);

const getMessageGroupSelectItems = createSelector(
    getMessageGroupList,

    (groups: NotificationGroups): SelectComponentItemsProps =>
        //@ts-ignore
        formatGroupItems(groups),
);

export const messageListSelector = createStructuredSelector({
    loadingStatus: getLoadingStatus,
    messageList: getMessageList,
    messageGroupList: getMessageGroupList,
    messageGroupSelectItems: getMessageGroupSelectItems,
});
