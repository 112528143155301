import {SpecificLocation} from "../types";
const toFixed = (value: string | number): string =>
    value ? Number(value).toFixed(4) : "";

export default (specificLocation?: SpecificLocation): string => {
    if (!specificLocation) {
        return "";
    }

    const latitude = toFixed(specificLocation.latitude || "");
    const longitude = toFixed(specificLocation.longitude || "");
    const distanceInMeters = specificLocation.maxDistanceInMeters;

    return `${latitude}, ${longitude}, ${distanceInMeters} meters`;
};
