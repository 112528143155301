import getPercentage from "../../common/getPercentage";

export default (current: number, previous: number): string => {
    if (!previous) {
        return "N/A";
    }

    const difference = current - previous;
    const partOfTotal = Math.abs(difference);
    const percentage = getPercentage({
        part: partOfTotal,
        total: previous,
        withDecimalPercentage: true,
    });

    const negativeIndicator = difference < 0 ? "-" : "";

    return `   (${negativeIndicator}${percentage}%)`;
};
