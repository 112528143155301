import React, {memo, CSSProperties} from "react";

const styles = Object.freeze({
    emptyMessageList: {
        height: "200px",
        border: "1px solid rgb(211, 211, 211)",
        width: "100%",
        textAlign: "center",
        padding: "30px",
    } as CSSProperties,
    noMessage: {
        fontSize: "20px",
    } as CSSProperties,
});
interface Props {
    message: string;
    subMessage: string;
}

export default memo(function EmptyList({
    message,
    subMessage,
}: Props): JSX.Element {
    return (
        <div style={styles.emptyMessageList}>
            <p style={styles.noMessage}>{message}</p>
            <p>{subMessage}</p>
        </div>
    );
});
