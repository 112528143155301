/*  */

import * as R from "ramda";
import {getIn} from "../common/ramdaDataUtils";

const splitToArray = R.compose(R.map(R.trim), R.split(","));

const makeSalesSKUPurchaseActionsSelector = (audience) => {
    const purchased =
        getIn(["sales", "salesSKUPurchaseActions", "purchased"], audience) ||
        {};
    const notPurchased =
        getIn(["sales", "salesSKUPurchaseActions", "notPurchased"], audience) ||
        {};

    const purchasedEntity = purchased.skus
        ? [
              {
                  entity: "Sales",
                  operation: "purchased",
                  value: {
                      fromDate: purchased.fromDate,
                      toDate: purchased.toDate,
                      skus: splitToArray(purchased.skus),
                  },
              },
          ]
        : [];

    const notPurchasedEntity = notPurchased.skus
        ? [
              {
                  entity: "Sales",
                  operation: "notPurchased",
                  value: {
                      fromDate: notPurchased.fromDate,
                      toDate: notPurchased.toDate,
                      skus: splitToArray(notPurchased.skus),
                  },
              },
          ]
        : [];

    return [...purchasedEntity, ...notPurchasedEntity];
};

const makeSalesSpentSelector = (audience) => {
    const spent = getIn(
        ["sales", "salesProductSpent", "minimumSpend"],
        audience,
    );
    const minimumSpend = parseInt(
        getIn(["sales", "salesProductSpent", "minimumSpend"], audience),
        10,
    );

    if (!spent || !minimumSpend) {
        return [];
    }

    const fromDate = getIn(
        ["sales", "salesProductSpent", "fromDate"],
        audience,
    );
    const toDate = getIn(["sales", "salesProductSpent", "toDate"], audience);
    const categoryIds = getIn(
        ["sales", "salesProductSpent", "categoryIds"],
        audience,
    );

    return [
        {
            entity: "Sales",
            operation: "spent",
            value: {
                fromDate,
                toDate,
                categoryIds,
                minimumSpend,
            },
        },
    ];
};

const makeUserGeoSelector = (audience) => {
    const wantSpecificLocation = getIn(
        ["location", "wantSpecificLocation"],
        audience,
    );

    if (!wantSpecificLocation) {
        return [];
    }

    const latitude = parseFloat(
        getIn(["location", "specificLocation", "latitude"], audience),
    );
    const longitude = parseFloat(
        getIn(["location", "specificLocation", "longitude"], audience),
    );
    const maxDistanceInMeters = parseInt(
        getIn(
            ["location", "specificLocation", "maxDistanceInMeters"],
            audience,
        ),
        10,
    );

    return [
        {
            entity: "UserGeo",
            operation: "latestCoordinate",
            value: {
                latitude,
                longitude,
                maxDistanceInMeters,
            },
        },
    ];
};

const makeUserSegmentSelector = (audience) => {
    const hasUserSegment = R.allPass([
        R.has("userSegment"),
        (audience) => audience.userSegment.selectedUserSegment !== "",
    ]);

    const getUserSegment = R.compose(
        (userSegment) => userSegment.selectedUserSegment,
        R.prop("userSegment"),
    );

    const hasSelectedUserSegment = R.compose(R.not, R.isEmpty, getUserSegment);

    const userSegmentRequired = R.allPass([
        R.complement(R.isNil),
        hasUserSegment,
        hasSelectedUserSegment,
    ]);

    const userSegmentToSelector = (audience) => [
        {
            entity: "UserSegment",
            operation: "matchAny",
            value: [getUserSegment(audience)],
        },
    ];

    const result = R.ifElse(
        userSegmentRequired,
        userSegmentToSelector,
        R.always([]),
    )(audience);

    return result;
};

const makeUserIdentifierSelector = (audience = {}) => {
    const userIdentifiers = audience.userIdentifiers || [];

    return userIdentifiers.length > 0
        ? [
              {
                  entity: "UserIdentifier",
                  operation: "matchAny",
                  value: audience.userIdentifiers,
              },
          ]
        : [];
};

const makeUserNotificationActionedSelector = (audience) => {
    const hasNotificationActioned = R.allPass([
        R.has("userNotificationActioned"),
        (audience) =>
            getIn(
                [
                    "userNotificationActioned",
                    "selectedNotification",
                    "notificationSpecId",
                ],
                audience,
            ) !== "",
    ]);

    const getNotificationActioned = R.compose(
        (userNotificationActioned) =>
            userNotificationActioned.selectedNotification,
        R.prop("userNotificationActioned"),
    );
    const hasSelectedNotification = R.compose(
        R.not,
        (selectedNotification) =>
            R.isEmpty(selectedNotification.notificationSpecId),
        getNotificationActioned,
    );

    const notificationActionedRequired = R.allPass([
        R.complement(R.isNil),
        hasNotificationActioned,
        hasSelectedNotification,
    ]);

    const notificationActionedToSelector = (audience) => [
        {
            entity: "NotificationActioned",
            operation: "notActioned",
            value: getNotificationActioned(audience),
        },
    ];

    const result = R.ifElse(
        notificationActionedRequired,
        notificationActionedToSelector,
        R.always([]),
    )(audience);

    return result;
};

const makeLogTransactionSelector = (audience) => {
    const numberOfDaysWithoutTransaction =
        getIn(
            ["logTransactions", "numberOfDaysWithoutTransaction"],
            audience,
        ) || "";

    const numberOfDays = numberOfDaysWithoutTransaction.trim();

    if (R.isEmpty(numberOfDays)) {
        return [];
    }

    return [
        {
            entity: "LogTransaction",
            operation: "matchAny",
            value: numberOfDays,
        },
    ];
};

const makeDevicePlatformSelector = (audience = {}) => {
    const devicePlaform = audience.devicePlatform || "all";

    if (devicePlaform === "all") {
        return [];
    }

    return [
        {
            entity: "Platform",
            operation: "matchAny",
            value: devicePlaform,
        },
    ];
};

const makeInacitveUserSelector = (audience) => {
    const numberOfDaysInactive = getIn(["numberOfDaysInactive"], audience) || 0;
    if (!numberOfDaysInactive) {
        return [];
    }

    return [
        {
            entity: "InactiveUsers",
            operation: "matchAny",
            value: numberOfDaysInactive,
        },
    ];
};

export default (audience) => {
    const potentialSelectors = [
        ...makeSalesSKUPurchaseActionsSelector(audience),
        ...makeSalesSpentSelector(audience),
        ...makeUserGeoSelector(audience),
        ...makeUserSegmentSelector(audience),
        ...makeUserIdentifierSelector(audience),
        ...makeUserNotificationActionedSelector(audience),
        ...makeLogTransactionSelector(audience),
        ...makeDevicePlatformSelector(audience),
        ...makeInacitveUserSelector(audience),
    ];

    if (R.isEmpty(potentialSelectors)) {
        return {};
    }

    return {
        userSelector: {
            match: "ALL",
            selectors: potentialSelectors,
        },
    };
};
