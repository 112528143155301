import Notification from "./Notification";
//@ts-ignore
import PhoneStock from "./stockphone.png";
import React, {memo} from "react";
import {makeStyles} from "@material-ui/core";
import classNames from "classnames";

const useStyles = makeStyles({
    visualisation: {
        backgroundImage: `url("${PhoneStock}")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "left",
        marginRight: -16,
        minHeight: "540px",
        width: "273px",
    },
    bottomBorder: {
        width: "100%",
        borderBottom: "solid rgba(255, 255, 255, 0.1)",
        borderWidth: 1,
        marginLeft: 0,
    },
    notifcationContainer: {
        paddingTop: 160,
        marginLeft: "10%",
        marginRight: "10%",
    },
});

interface Action {
    title: string;
    message?: string;
    url: string;
    prompt?: string;
}
interface Props {
    action1?: Action;
    action2?: Action;
}

const shouldNotificationBlockRender = (
    notification?: Action,
): string | undefined => notification && notification.message;

const OptionalNotification = ({
    action,
}: {
    action?: Action;
}): JSX.Element | null =>
    shouldNotificationBlockRender(action) ? (
        <Notification notification={action} />
    ) : null;

const shouldBottomBorderRender = (action1?: Action, action2?: Action) =>
    (action1 && action1.message) || (action2 && action2.message);

const OptionalBottomBorder = ({
    action1,
    action2,
}: Props): JSX.Element | null => {
    const classes = useStyles();
    return shouldBottomBorderRender(action1, action2) ? (
        <div className={classes.bottomBorder}></div>
    ) : null;
};
export default memo(function Visualisation({
    action1,
    action2,
}: Props): JSX.Element {
    const classes = useStyles();
    const parentContainer = classNames("image", classes.visualisation);
    return (
        <div className={parentContainer}>
            <div className={classes.notifcationContainer}>
                <OptionalNotification action={action2} />
                <OptionalNotification action={action1} />
                <OptionalBottomBorder action1={action1} action2={action2} />
            </div>
        </div>
    );
});
