import * as R from "ramda";

export const setIn = R.curry(
    (
        path: Array<string | number> | string,
        payload: any,
        existingState: Object,
    ): any =>
        //@ts-ignore
        R.set(R.lensPath(path))(payload)(existingState),
);

export const getIn = R.curry(
    (
        path: Array<string | number> | string,
        propsToRead: Object | Array<any>,
    ): any =>
        //@ts-ignore
        R.view(R.lensPath(path))(propsToRead),
);
