import * as React from "react";
import {inject, observer} from "mobx-react";
import {action, observable} from "mobx";
import DropDown from "../widget/DropDown";
import InputRow from "../widget/InputRow";
import {Col, Row, FormGroup} from "react-bootstrap";

const styles = Object.freeze({
    container: {
        float: "right",
    },
    dropdownContainer: {
        width: 410,
    },
});

@inject("apiClient")
@observer
export default class ChildrenTeamFilter extends React.Component {
    @observable
    metaTeams = [{value: 0, text: "All"}];
    @observable
    selectedMetaTeam = "";

    componentDidMount() {
        this.onFetchMetaTeams();
    }

    @action
    onFetchMetaTeams = async () => {
        const metaTeams = await this.props.apiClient.invokeGetEndpoint(
            "metaTeams",
        );
        const metaTeamsData = metaTeams.map((item) => ({
            value: item.teamNumber,
            text: item.teamName,
        }));

        this.metaTeams = [{value: "", text: "All"}, ...metaTeamsData];
    };

    setMetaFilter = (value) => {
        this.selectedMetaTeam = value;
        this.props.onChangeFilter(value);
    };

    render() {
        return (
            <InputRow label="Member App:">
                <FormGroup>
                    <DropDown
                        items={this.metaTeams}
                        onChange={this.setMetaFilter}
                        propLabel="text"
                        value={this.selectedMetaTeam || this.props.defaultValue}
                    />
                </FormGroup>
            </InputRow>
        );
    }
}
