import {Col, Row} from "react-bootstrap";
import React, {memo, CSSProperties} from "react";

interface Props {
    children: Array<JSX.Element> | JSX.Element | string | number;
    label: string;
}

const styles = Object.freeze({
    row: {
        paddingTop: 5,
        paddingBottom: 10,
    } as CSSProperties,
    wrapText: {
        overflowWrap: "break-word",
    } as CSSProperties,
});

export default memo(function DetailRow({children, label}: Props): JSX.Element {
    return (
        <Row style={styles.row}>
            <Col md={5}>
                <strong>{label}</strong>
            </Col>
            <Col style={styles.wrapText} md={7}>
                {children}
            </Col>
        </Row>
    );
});
