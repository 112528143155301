import {connect} from "react-redux";
import {createSelector} from "reselect";
import {
    getAdverts,
    onDeleteAdvertPressed,
    onTogglePlacement,
    onUpdatePlacements,
    onMultiDeleteAdvert,
} from "./advertEventHandlers";
import React, {Component, memo, useState, useEffect} from "react";
// @ts-ignore
import stateSelector from "./selectors";
import {Map} from "immutable";
import {PlacementsUpdateBody, PlacementType} from "./types";
import Advert, {AdvertProps} from "./Advert";
import useFeatureFlag from "../featureFlags/useFeatureFlag";
import useAlert from "../alert/useAlert";
import useSession from "../main/useSession";

interface HooksContainerProps extends AdvertProps {
    onDeleteAdvertPressed: (params: {
        id: string;
        childrenTeamFilter?: string;
        placementFeatures: any;
    }) => Promise<{success: boolean} | undefined>;
    onMultiDeleteAdvertPresed: (params: {
        ids: Array<string>;
        childrenTeamFilter?: string;
        placementFeatures: any;
    }) => Promise<{success: boolean} | undefined>;
}

const HooksContainer = memo(function AdvertWithHooks(
    props: HooksContainerProps,
) {
    const {featureFlags} = useFeatureFlag();
    const {userData} = useSession();

    const [childrenTeamFilter, setChildrenTeamFilter] = useState("");
    const {addErrorAlert, addSuccessAlert} = useAlert();
    const teamNumber =
        (userData && userData.activeTeam && userData.activeTeam.teamNumber) ||
        0;
    const placementFeatures = featureFlags.placements;
    const onDeleteAdvertPressed = async (
        id: string,
    ): Promise<{success: boolean} | undefined> => {
        const results = await props.onDeleteAdvertPressed({
            placementFeatures,
            id,
            childrenTeamFilter,
        });

        if (results) {
            if (results.success) {
                addSuccessAlert("Successfully archived placement");
                props.getAdverts();
            } else {
                addErrorAlert(
                    "An error occured while trying to archive placement. Please try again.",
                );
            }
        }

        return undefined;
    };

    const onMultiDeleteAdvertPresed = async (
        ids: Array<string>,
    ): Promise<{success: boolean} | undefined> => {
        const results = await props.onMultiDeleteAdvertPresed({
            ids,
            placementFeatures,
            childrenTeamFilter,
        });

        if (results) {
            if (results.success) {
                addSuccessAlert("Successfully archived placement");
                props.getAdverts();
            } else {
                addErrorAlert(
                    "An error occured while trying to archive placement. Please try again.",
                );
            }
        }

        return undefined;
    };

    const onChangeTeamFilter = async (value: string) => {
        setChildrenTeamFilter(value);
        await props.getAdverts({
            childrenTeamFilter: value,
            placementsFeatures: featureFlags.placements,
        });
    };

    useEffect(() => {
        props.getAdverts({placementsFeatures: featureFlags.placements});
    }, []);

    return (
        <Advert
            {...props}
            childrenTeamFilter={childrenTeamFilter}
            onChangeTeamFilter={onChangeTeamFilter}
            onDeleteAdvertPressed={onDeleteAdvertPressed}
            featureFlags={featureFlags}
            teamNumber={teamNumber}
            onMultiDeleteAdvertPresed={onMultiDeleteAdvertPresed}
        />
    );
});

interface Props {
    onDeleteAdvertPressed: (params: {
        id: string;
        childrenTeamFilter?: string;
        placementFeatures: any;
    }) => Promise<{success: boolean} | undefined>;
    onTogglePlacement: (placementType: PlacementType) => void;
    onUpdatePlacements: (body: PlacementsUpdateBody) => void;
    placementLimits: Map<string, string | number>;
    placements: Map<string, string>;
    advertDeepLinkingEnabled: boolean;
    scheduledAdvertEnabled: boolean;
    childrenTeamFilterEnabled: boolean;
    getAdverts: (
        filter?:
            | {childrenTeamFilter?: string | undefined; placementsFeatures: any}
            | undefined,
    ) => void;
    childrenTeamFilter: string;
    onMultiDeleteAdvertPresed: (params: {
        ids: Array<string>;
        childrenTeamFilter?: string;
        placementFeatures: any;
    }) => Promise<{success: boolean} | undefined>;
}

class ActiveAdvertsContainer extends Component<Props> {
    render() {
        const {
            onTogglePlacement,
            onUpdatePlacements,
            placements,
            onDeleteAdvertPressed,
            getAdverts,
            onMultiDeleteAdvertPresed,
        }: Props = this.props;

        return (
            <HooksContainer
                getAdverts={getAdverts}
                onDeleteAdvertPressed={onDeleteAdvertPressed}
                onTogglePlacement={onTogglePlacement}
                placements={placements}
                onUpdatePlacements={onUpdatePlacements}
                onMultiDeleteAdvertPresed={onMultiDeleteAdvertPresed}
            />
        );
    }
}

const dispatchSelector = createSelector(
    (dispatch: Function): Function => dispatch,
    (dispatch: Function): Object => ({
        onDeleteAdvertPressed: (params: {
            id: string;
            childrenTeamFilter?: string;
        }): Promise<{success: boolean} | undefined> =>
            // @ts-ignore
            dispatch(onDeleteAdvertPressed(params)),
        onMultiDeleteAdvertPresed: (params: {
            ids: Array<string>;
            childrenTeamFilter?: string;
        }): Promise<{success: boolean} | undefined> =>
            // @ts-ignore
            dispatch(onMultiDeleteAdvert(params)),
        getAdverts: (filter: any, placementFeatures: any): Object =>
            // @ts-ignore
            dispatch(getAdverts(filter, placementFeatures)),
        onTogglePlacement: (placementName: string) =>
            dispatch(onTogglePlacement(placementName)),
        onUpdatePlacements: (body: PlacementsUpdateBody) =>
            dispatch(onUpdatePlacements(body)),
    }),
);

const ReduxActiveAdvertsContainer = connect(
    stateSelector,
    dispatchSelector,
)(ActiveAdvertsContainer);

export default ReduxActiveAdvertsContainer;
