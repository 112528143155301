import {makeStyles} from "@material-ui/styles";
import React, {memo} from "react";

import Box from "../../widget/Box";
// @ts-ignore
import skuMarketShareOverTime from "./skuMarketShareOverTime.png";
// @ts-ignore
import skuSalesOverTime from "./skuSalesOverTime.png";
// @ts-ignore
import supplierMarketShare from "./supplierMarketShare.png";
// @ts-ignore
import supplierSalesOverTime from "./supplierSalesOverTime.png";
// @ts-ignore
import suppliersMarketShareOverTime from "./suppliersMarketShareOverTime.png";
// @ts-ignore
import topSpendingBySupplier from "./topSpendingBySupplier.png";
// @ts-ignore";

const useStyles = makeStyles({
    image: {
        width: "100%",
    },
});

const charts = [
    {
        title:
            "Top Spending Soft Drinks Customers By Supplier - Last 60 Days (iOS App)",
        image: topSpendingBySupplier,
    },
    {
        title:
            "Soft Drinks Suppliers Market Share Over Time - Last 60 Days (iOS App)",
        image: suppliersMarketShareOverTime,
    },
    {
        title:
            "Soft Drinks SKUs Market Share Over Time- Last 60 Days (iOS App)",
        image: skuMarketShareOverTime,
    },
    {
        title: "Soft Drinks SKUs Sales Over Time - Last 60 Days (iOS App)",
        image: skuSalesOverTime,
    },
    {
        title: "Soft Drinks Supplier Sales Over Time - Last 60 Days (iOS App)",
        image: supplierSalesOverTime,
    },
    {
        title: "Soft Drink Supplier Market Share - Last 60 Days (iOS App)",
        image: supplierMarketShare,
    },
];

export default memo(function SalesDemoDashboard() {
    const classes = useStyles();

    return (
        <div>
            {charts.map((chart) => (
                <Box key={chart.title} title={chart.title}>
                    <img src={chart.image} className={classes.image} />
                </Box>
            ))}
        </div>
    );
});
