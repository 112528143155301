import React, {memo} from "react";
import {Well} from "react-bootstrap";
import {linkActions} from "../pushNotification/PushNotification";

const styles = Object.freeze({
    guidelines: {
        marginTop: 10,
        fontSize: 12,
    },
});

const guidelinesExists = (actionName: string, actionsList: Array<string>) =>
    Boolean(actionsList.find((item: string) => item === actionName));

export default memo(function ProductSkuGuidelines({
    actionsList,
}: {
    actionsList: Array<string>;
}): JSX.Element {
    return (
        <Well>
            <b>Product SKU Guidelines: </b>
            <ul style={styles.guidelines}>
                {guidelinesExists(
                    linkActions.VIEW_PRODUCT_LIST,
                    actionsList,
                ) && (
                    <li>
                        View Product List: Ensure each valid SKU is entered
                        without any spaces, followed by a comma, then the next
                        SKU (e.g. 12345,98765,59687)
                    </li>
                )}
                {guidelinesExists(
                    linkActions.VIEW_PRODUCT_DETAILS,
                    actionsList,
                ) && (
                    <li>
                        View Product Details: Ensure only a valid single SKU is
                        entered, without any spaces.
                    </li>
                )}
                {guidelinesExists(
                    linkActions.ADD_PRODUCT_TO_BASKET,
                    actionsList,
                ) && (
                    <li>
                        Add Product To Basket: Ensure only a valid single SKU is
                        entered, without any spaces.
                    </li>
                )}

                {guidelinesExists(
                    linkActions.ADD_PRODUCT_TO_LIST,
                    actionsList,
                ) && (
                    <li>
                        Add Product To List: Ensure only a valid single SKU is
                        entered, without any spaces.
                    </li>
                )}

                <li>
                    Before entering SKUs, it is advisable to first use the app
                    to search for the SKU(s) to ensure the product(s) will be
                    displayed in the app.
                </li>
            </ul>
        </Well>
    );
});
