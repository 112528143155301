import {addError} from "../uiNotification/notificationDuck";
import {advertList, togglePlacement} from "./reducer";
import {promiseAlert} from "promise-alert";
import apiClientFromState from "../apiClient/apiClientFromState";
import {Dispatch} from "react";
import * as R from "ramda";

interface Filter {
    childrenTeamFilter?: string;
    // @ts-ignore
    placementsFeatures: any;
}

export const onTogglePlacement = (placementName: string) => (
    dispatch: Function,
): Object => dispatch(togglePlacement(placementName));

export const getAdverts = (filter: Filter): Object => (
    dispatch: Function,
    getState: Function,
): Object => {
    const childrenTeamFilter = R.path(["childrenTeamFilter"], filter);

    return apiClientFromState(getState())
        .getAdverts({
            placement: "all",
            childrenTeamFilter,
        })
        .then(({body}: {body: any}) => dispatch(advertList(body)));
};
export const clearAdverts = (): Object => (dispatch: Function): void =>
    dispatch(advertList([]));

export const confirmAdvertDelete = ({
    id,
    childrenTeamFilter = "",
}: {
    id: string;
    childrenTeamFilter?: string;
}) => (dispatch: Function, getState: Function) => {
    const apiClient = apiClientFromState(getState());

    const deletePromise = apiClient.archiveAdvert({id});

    return deletePromise
        .then(() => {
            // @ts-ignore
            dispatch(getAdverts({childrenTeamFilter}));
            return {success: true};
        })
        .catch((error: {message: string}) => {
            // @ts-ignore
            dispatch(addError(error.message));
            return {success: false};
        });
};

export const onDeleteAdvertPressed = ({
    id,
    childrenTeamFilter,
}: {
    id: string;
    childrenTeamFilter?: string;
}) => (dispatch: Function) => {
    return promiseAlert({
        title: `Archive Ad Placement`,
        text: `Are you sure that you want to archive the selected ad placement?`,
        type: "warning",
        showCancelButton: true,
    }).then((confirmed: boolean) => {
        if (confirmed) {
            return dispatch(
                confirmAdvertDelete({
                    id,
                    childrenTeamFilter,
                }),
            );
        }

        return undefined;
    });
};

export const onUpdatePlacements = (placements: Array<any>) => (
    dispatch: Dispatch<any>,
    getState: Function,
) => {
    const apiClient = apiClientFromState(getState());

    return apiClient
        .updatePlacements({
            placements,
        })
        .catch((error: {message: string}) => {
            // @ts-ignore
            dispatch(addError(error.message));
        });
};

export const confirmMultiAdvertDelete = ({
    ids,
    childrenTeamFilter,
}: {
    ids: Array<string>;
    childrenTeamFilter?: string;
}) => (dispatch: Function, getState: Function) => {
    const apiClient = apiClientFromState(getState());

    const deletePromise = apiClient.multiDeleteAdvert({
        ids,
    });
    return deletePromise
        .then(() => {
            // @ts-ignore
            dispatch(getAdverts({childrenTeamFilter}));
            return {success: true};
        })
        .catch((error: {message: string}) => {
            // @ts-ignore
            dispatch(addError(error.message));
            return {success: false};
        });
};

export const onMultiDeleteAdvert = ({
    ids,
    childrenTeamFilter,
}: {
    ids: Array<string>;
    childrenTeamFilter?: string;
}) => (dispatch: Function) => {
    return promiseAlert({
        title: `Archive Ad Placement`,
        text: `Are you sure that you want to archive the selected ad placement?`,
        type: "warning",
        showCancelButton: true,
    }).then((confirmed: boolean) => {
        if (confirmed) {
            return dispatch(
                confirmMultiAdvertDelete({
                    ids,
                    childrenTeamFilter,
                }),
            );
        }

        return undefined;
    });
};
