/*  */

import {action, computed, observable} from "mobx";
import {BESTWAY_COLOUR_SET} from "../../../widget/chart/colourSets";
import {observer} from "mobx-react";
import ContainerDimensions from "react-container-dimensions";
import createCSVDataFromStackedBarChart from "../createCSVDataFromStackedBarChart";
import DashboardWidget, {STATUSES} from "../../widgets/DashboardWidget";
import FilteredChart from "./FilteredChart";
import ExportableChartContainer from "../ExportableChartContainer";
import formatNumber from "../../../common/formatNumber";
import immutable from "immutable";
import parseApiResponseToStackedBarChartFormat from "../parseApiResponseToStackedBarChartFormat";
import React from "react";
import StackedBarChartWithLegend from "../../../widget/chart/StackedBarChartWithLegend";

class Change extends React.PureComponent {
    static styles = Object.freeze({
        changePositive: {
            color: "rgb(0, 166, 90)",
        },
        changeNegative: {
            color: "rgb(221, 75, 57)",
        },
    });

    render() {
        const {change, lastMonth} = this.props;
        const percentage = formatNumber((Math.abs(change) / lastMonth) * 100);

        return (
            <span
                style={
                    change < 0
                        ? Change.styles.changeNegative
                        : Change.styles.changePositive
                }
            >
                {`${change < 0 ? "-" : "+"} ${percentage}%`}
            </span>
        );
    }
}

@observer
export default class StackedUserChart extends FilteredChart {
    static CONTAINER_HEIGHT = 400;

    static styles = Object.freeze({
        topLabel: {
            fontSize: 18,
            textAlign: "center",
            marginBottom: 8,
        },
    });

    @observable
    chartData = immutable.Map();

    @action
    onDataDidLoad(chartData) {
        this.chartData = immutable.fromJS(chartData);
    }

    @computed
    get stackedBarChartData() {
        return parseApiResponseToStackedBarChartFormat(
            this.chartData.get("chartData"),
        );
    }

    @computed
    get csvChartData() {
        return createCSVDataFromStackedBarChart(
            this.stackedBarChartData.toJS(),
            "Date",
        );
    }

    render() {
        const {chartData, csvChartData, status, stackedBarChartData} = this;
        const {
            infoPopOverText,
            showEngagement,
            title,
            lastMonthKey,
            changeKey,
            axisTitles,
        } = this.props;
        const isResultEmpty = chartData.get(lastMonthKey) === 0;
        const showChart = !isResultEmpty && STATUSES.normal === status;

        return (
            <ContainerDimensions>
                {({width}) => (
                    <DashboardWidget
                        status={
                            isResultEmpty ? STATUSES.dataUnavailable : status
                        }
                    >
                        <ExportableChartContainer
                            csvData={csvChartData}
                            infoPopOverText={infoPopOverText}
                            title={title}
                        >
                            {showChart && (
                                <div>
                                    <div
                                        style={StackedUserChart.styles.topLabel}
                                    >
                                        {`${
                                            this.props.changeTitle
                                        } ${formatNumber(
                                            chartData.get(lastMonthKey),
                                        )} `}
                                        <Change
                                            change={chartData.get(changeKey)}
                                            lastMonth={chartData.get(
                                                lastMonthKey,
                                            )}
                                        />
                                    </div>
                                    <StackedBarChartWithLegend
                                        axisLabels={stackedBarChartData.get(
                                            "axisLabels",
                                        )}
                                        axisTitles={axisTitles}
                                        colorScheme={BESTWAY_COLOUR_SET}
                                        datasets={stackedBarChartData.get(
                                            "datasets",
                                        )}
                                        height={
                                            StackedUserChart.CONTAINER_HEIGHT
                                        }
                                        legends={stackedBarChartData.get(
                                            "legends",
                                        )}
                                        showAbsoluteValue
                                        showEngagement={showEngagement}
                                        tickIsDate
                                        width={width}
                                    />
                                </div>
                            )}
                        </ExportableChartContainer>
                    </DashboardWidget>
                )}
            </ContainerDimensions>
        );
    }
}
