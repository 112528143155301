import React, {memo, useState, useEffect} from "react";
import {Table} from "react-bootstrap";
import {makeStyles} from "@material-ui/styles";
import LinkButton from "../common/buttons/LinkButton";
import RemoveItemButton from "../common/buttons/RemoveItemButton";
import formatDate from "../date/formatDate";
import {inject, observer} from "mobx-react";
import {promiseAlert} from "promise-alert";

interface frequencyConfigurationProps {
    _id: string | null;
    periodLength: number | string;
    maxShowings: number | string;
    order: string;
    skippable: boolean | string;
    timestamp: string;
}

const useStyles = makeStyles({
    editBtn: {
        backgroundColor: "rgb(85, 153, 153) !important",
        borderColor: "rgb(85, 153, 153) !important",
        color: "rgb(255, 255, 255)",
    },
    frequencyButtonContainer: {
        width: 100,
        textAlign: "center",
    },
    emptyMessageList: {
        height: "200px",
        border: "1px solid rgb(211, 211, 211)",
        width: "100%",
        textAlign: "center",
        padding: "30px",
    },
    noMessage: {
        fontSize: "20px",
    },
});

const styles = Object.freeze({
    tableHeader: {
        verticalAlign: "top",
    },
    tableHeaderSubtext: {
        fontWeight: 400,
    },
});

const FREQUENCY_DEFAULTVALUE = "-";
const DEFAULT_CONFIG_VALUE = {
    _id: null,
    periodLength: FREQUENCY_DEFAULTVALUE,
    maxShowings: FREQUENCY_DEFAULTVALUE,
    order: FREQUENCY_DEFAULTVALUE,
    skippable: FREQUENCY_DEFAULTVALUE,
    timestamp: FREQUENCY_DEFAULTVALUE,
};

const EmptyFrequencyConfiguration = () => {
    const classes = useStyles();

    return (
        <div className={classes.emptyMessageList}>
            <p className={classes.noMessage}>
                {
                    "You haven't created a Full Screen (Interstitial) Ad Frequency Rule"
                }
            </p>
            <p>
                The frequency rule you create will show up here. Click the{" "}
                <strong>Add</strong> button to create a new rule.
            </p>
        </div>
    );
};

const ConfigTableHeader = ({children}: any) => (
    <th style={styles.tableHeader}>{children}</th>
);

const FREQUENCY_CONFIGURATION_REMOVAL_PROMPT_TITLE =
    "Remove Frequency Configuration?";
const FREQUENCY_CONFIGURATION_REMOVAL_PROMPT_BODY =
    "If this Frequency Configuration is removed, all users of this app will see interstitial adverts the next time they open the app.";
const FREQUENCY_CONFIGURATION_REMOVAL_ERROR_TITLE = "Removal Failed";
const FREQUENCY_CONFIGURATION_REMOVAL_ERROR_BODY =
    "Your Frequency Configuration could not be removed.\nPlease try again later.";

const HooksContainer = memo(function AdvertConfigsTable({
    placementName,
    apiClient,
}: {
    placementName: string;
    apiClient: {
        invokeGetEndpoint: (path: string, params: any) => Promise<any>;
        invokeDeleteEndpoint: (path: string) => Promise<any>;
    };
}): JSX.Element {
    const [advertConfigsData, setAdvertConfigsData]: [
        frequencyConfigurationProps,
        (response: any) => void,
    ] = useState(DEFAULT_CONFIG_VALUE);

    const onGetAdvertConfigs = async () => {
        try {
            if (apiClient) {
                const response = await apiClient.invokeGetEndpoint(
                    "advertConfigs",
                    {placement: placementName},
                );

                if (response && response.length > 0) {
                    setAdvertConfigsData(response[0]);

                    return;
                }

                setAdvertConfigsData(DEFAULT_CONFIG_VALUE);
            }
        } catch (error) {
            setAdvertConfigsData(DEFAULT_CONFIG_VALUE);
        }
    };

    const classes = useStyles();

    useEffect(() => {
        onGetAdvertConfigs();
    }, []);

    const {
        _id,
        periodLength,
        maxShowings,
        order,
        skippable,
        timestamp,
    } = advertConfigsData;
    const dismissibleValue = skippable === "-" ? "-" : skippable ? "Yes" : "No";

    return (
        <div>
            <Table bordered responsive striped>
                <thead>
                    <tr>
                        <ConfigTableHeader>Number of Days</ConfigTableHeader>
                        <ConfigTableHeader>
                            Max Times Displayed
                            <br />
                            <div style={styles.tableHeaderSubtext}>
                                (No more than once per day)
                            </div>
                        </ConfigTableHeader>
                        <ConfigTableHeader>Display Order</ConfigTableHeader>
                        <ConfigTableHeader>Can Dismiss?</ConfigTableHeader>
                        <ConfigTableHeader>
                            Configuration Start Date
                        </ConfigTableHeader>
                        <th className={classes.frequencyButtonContainer}>
                            {_id ? (
                                <RemoveItemButton
                                    onClick={() => {
                                        return promiseAlert({
                                            title: FREQUENCY_CONFIGURATION_REMOVAL_PROMPT_TITLE,
                                            text: FREQUENCY_CONFIGURATION_REMOVAL_PROMPT_BODY,
                                            type: "warning",
                                            showCancelButton: true,
                                        }).then(async (confirmed: boolean) => {
                                            if (confirmed) {
                                                try {
                                                    await apiClient.invokeDeleteEndpoint(
                                                        `advertConfigs/${_id}`,
                                                    );

                                                    onGetAdvertConfigs();

                                                    return {success: true};
                                                } catch (exception) {
                                                    promiseAlert({
                                                        title: FREQUENCY_CONFIGURATION_REMOVAL_ERROR_TITLE,
                                                        text: FREQUENCY_CONFIGURATION_REMOVAL_ERROR_BODY,
                                                        type: "error",
                                                    });

                                                    return {success: false};
                                                }
                                            }

                                            return null;
                                        });
                                    }}
                                />
                            ) : (
                                <LinkButton
                                    disabled={false}
                                    relativeDestination={
                                        "configure/interstitial/"
                                    }
                                    size="sm"
                                    title={"Add"}
                                />
                            )}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{periodLength}</td>
                        <td>{maxShowings}</td>
                        <td>{order}</td>
                        <td>{dismissibleValue}</td>
                        <td>{formatDate(timestamp, "N/A")}</td>
                        <td />
                    </tr>
                </tbody>
            </Table>
            {!_id && <EmptyFrequencyConfiguration />}
            <br />
        </div>
    );
});

interface Props {
    apiClient?: any;
    placementName: string;
}

@inject("apiClient")
@observer
class AdvertConfigsTable extends React.Component<Props> {
    render() {
        return (
            <HooksContainer
                placementName={this.props.placementName}
                apiClient={this.props.apiClient}
            />
        );
    }
}

export default AdvertConfigsTable;
