import {Box, Button, Grid, makeStyles} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useMemo} from "react";
import useFeatureFlag from "../featureFlags/useFeatureFlag";
import history from "../router/history";
import ActiveAdvertsContainer from "./ActiveAdvertsContainer";
import ArchivedAdvertsLists from "./ArchivedAdvertsLists";
import CategoryAdvertsContainer from "./CategoryAdvertsContainer";

export enum AdvertContentView {
    ARCHIVED_BANNER,
    ARCHIVED_FULLSCREEN,
    ACTIVE_ADVERTS,
    CATEGORY_ADVERTS,
}

const useStyle = makeStyles({
    buttonContainer: {
        marginBottom: 20,
    },
    button: {
        marginRight: 10,
    },
});

type AdvertContentMappingType = {
    [key: string]: {
        title: string;
        renderView: () => JSX.Element;
    };
};

const AdvertContentsMapping: AdvertContentMappingType = {
    [AdvertContentView.ARCHIVED_BANNER]: {
        title: "Archived Banner Ads",
        renderView: () => (
            <ArchivedAdvertsLists
                advertContentView={AdvertContentView.ARCHIVED_BANNER}
            />
        ),
    },
    [AdvertContentView.ARCHIVED_FULLSCREEN]: {
        title: "Archived Full Screen Ads",
        renderView: () => (
            <ArchivedAdvertsLists
                advertContentView={AdvertContentView.ARCHIVED_FULLSCREEN}
            />
        ),
    },
    [AdvertContentView.ACTIVE_ADVERTS]: {
        title: "Ads and Product Placements",
        renderView: () => (
            // @ts-ignore
            <ActiveAdvertsContainer />
        ),
    },
    [AdvertContentView.CATEGORY_ADVERTS]: {
        title: "Category Placements",
        renderView: () => <CategoryAdvertsContainer />,
    },
};

const activeTabRouteMapping: {[key: string]: AdvertContentView} = {
    activeAdverts: AdvertContentView.ACTIVE_ADVERTS,
    archivedBanner: AdvertContentView.ARCHIVED_BANNER,
    archivedFullscreen: AdvertContentView.ARCHIVED_FULLSCREEN,
    categoryAdverts: AdvertContentView.CATEGORY_ADVERTS,
};

export default function AdvertsContainer(props: any): JSX.Element {
    const [contentView, setContentView] = useState<AdvertContentView>(
        AdvertContentView.ACTIVE_ADVERTS,
    );
    const {featureFlags} = useFeatureFlag();
    const classes = useStyle();

    const categoryAdvertsEnabled = useMemo(() => {
        return Boolean(
            Object.keys(featureFlags).find((featureKey) => {
                return featureKey.match(/categoryAdverts/g);
            }),
        );
    }, [featureFlags]);

    useEffect(() => {
        const activeTabRoute = props.match.params.activeTab;
        const routeExists = Object.keys(activeTabRouteMapping).includes(
            activeTabRoute,
        );
        if (routeExists) {
            const newContentView = activeTabRouteMapping[activeTabRoute];

            setContentView(newContentView);
        } else {
            setContentView(AdvertContentView.ACTIVE_ADVERTS);
        }
    }, [props.match]);

    return (
        <Box>
            <Grid direction="row" className={classes.buttonContainer} container>
                <Grid item>
                    <Button
                        variant="contained"
                        color={
                            contentView === AdvertContentView.ACTIVE_ADVERTS
                                ? "secondary"
                                : "default"
                        }
                        className={classes.button}
                        onClick={() =>
                            history.push("/placements/activeAdverts")
                        }
                    >
                        <i
                            className={`fa fa-file-image-o`}
                            style={{fontSize: 16, marginRight: 10}}
                        />
                        Ads and Product Placements
                    </Button>
                </Grid>
                {categoryAdvertsEnabled && (
                    <Grid item>
                        <Button
                            variant="contained"
                            color={
                                contentView ===
                                AdvertContentView.CATEGORY_ADVERTS
                                    ? "secondary"
                                    : "default"
                            }
                            className={classes.button}
                            onClick={() =>
                                history.push("/placements/categoryAdverts")
                            }
                        >
                            <i
                                className={`fa fa-list-ul`}
                                style={{fontSize: 16, marginRight: 10}}
                            />
                            Category Adverts
                        </Button>
                    </Grid>
                )}

                <Grid item>
                    <Button
                        variant="contained"
                        color={
                            contentView ===
                            AdvertContentView.ARCHIVED_FULLSCREEN
                                ? "secondary"
                                : "default"
                        }
                        className={classes.button}
                        onClick={() =>
                            history.push("/placements/archivedFullscreen")
                        }
                    >
                        <i
                            className={`fa fa-archive`}
                            style={{fontSize: 16, marginRight: 10}}
                        />
                        Archived Full Screen Ads
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color={
                            contentView === AdvertContentView.ARCHIVED_BANNER
                                ? "secondary"
                                : "default"
                        }
                        className={classes.button}
                        onClick={() =>
                            history.push("/placements/archivedBanner")
                        }
                    >
                        <i
                            className={`fa fa-archive`}
                            style={{fontSize: 16, marginRight: 10}}
                        />
                        Archived Banner Ads
                    </Button>
                </Grid>
            </Grid>
            <Grid>{AdvertContentsMapping[contentView].renderView()}</Grid>
        </Box>
    );
}
