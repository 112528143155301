import {Col} from "react-bootstrap";
import DetailRow from "../../widget/DetailRow";
import React, {memo} from "react";

interface Props {
    message: string;
    urlToOpen: string;
}

export default memo(function Alert({message, urlToOpen}: Props): JSX.Element {
    return (
        <Col md={8}>
            <DetailRow label="MESSAGE:">{message}</DetailRow>
            {Boolean(urlToOpen) ? (
                <DetailRow label="URL:">{urlToOpen}</DetailRow>
            ) : (
                <DetailRow label="URL:">{"N/A"}</DetailRow>
            )}
        </Col>
    );
});
