import ActivityIndicator from "../widget/ActivityIndicator";
import Gravatar from "react-gravatar";
import React, {CSSProperties} from "react";
import useSession from "./useSession";

const styles = Object.freeze({
    activityIndicator: {
        color: "rgb(255, 255, 255)",
        display: "block",
        position: "fixed",
        zIndex: 2000,
        top: "15px",
        right: "50%",
        width: "14px",
        height: "14px",
    } as CSSProperties,
    userMenu: {
        cursor: "pointer",
    } as CSSProperties,
});

interface NavBarProps {
    children?: any;
    showSideBarToggle: boolean;
}

function NavBar({showSideBarToggle, children}: NavBarProps) {
    return (
        <nav className="navbar navbar-static-top">
            {showSideBarToggle && (
                <a
                    className="sidebar-toggle"
                    data-toggle="offcanvas"
                    role="button"
                >
                    <span className="sr-only">Toggle navigation</span>
                </a>
            )}
            {children}
        </nav>
    );
}

interface Props {
    emailAddress: string;
    firstName: string;
    isLoading: boolean;
    showSideBarToggle: boolean;
    teamName: string;
    onLogout: () => void;
}

function Header({
    emailAddress,
    firstName,
    isLoading,
    teamName,
    showSideBarToggle,
}: Props): JSX.Element {
    const {logout} = useSession();
    return (
        <header className="main-header">
            <a className="logo">
                <span className="logo-mini">
                    <small>BEAM</small>
                </span>
                <span className="logo-lg">
                    <b>BEAM</b>
                </span>
            </a>
            <NavBar showSideBarToggle={showSideBarToggle}>
                {isLoading && (
                    <ActivityIndicator style={styles.activityIndicator} />
                )}

                <div className="navbar-custom-menu">
                    <ul className="nav navbar-nav">
                        <li className="dropdown user user-menu">
                            <a
                                aria-expanded="true"
                                className="dropdown-toggle"
                                style={styles.userMenu}
                                data-toggle="dropdown"
                            >
                                <Gravatar
                                    className="user-image"
                                    email={emailAddress}
                                    size={24}
                                />
                                <span className="hidden-xs">
                                    {firstName} | {teamName}{" "}
                                </span>
                            </a>
                            <ul className="dropdown-menu">
                                <li className="user-header">
                                    <Gravatar
                                        className="user-image"
                                        email={emailAddress}
                                        size={90}
                                    />
                                    <p>
                                        {firstName} | {teamName}
                                    </p>
                                </li>
                                <li className="user-footer">
                                    <div className="pull-right">
                                        <a
                                            className="btn btn-default btn-flat"
                                            onClick={() => {
                                                logout();
                                            }}
                                        >
                                            <i className="fa fa-lock fa-fw" />
                                            Logout
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </NavBar>
        </header>
    );
}

export default Header;
