import React, {memo} from "react";

interface Props {
    info?: string;
}

const splitInfoStringOnNewLine = (infoString: string): Array<string> =>
    infoString.split("\n");

export default memo(function infoPopOverText({
    info,
}: Props): JSX.Element | null {
    if (!info) {
        return null;
    }

    return (
        <div>
            {splitInfoStringOnNewLine(info).map((infoSubString, key) => (
                <span key={key}>
                    {infoSubString}
                    <br />
                </span>
            ))}
        </div>
    );
});
