import PropTypes from "prop-types";

export const states = {
    default: "default",
    primary: "primary",
    info: "info",
    warning: "warning",
    success: "success",
    danger: "danger",
};

export type statesType =
    | "default"
    | "primary"
    | "info"
    | "warning"
    | "success"
    | "danger";

export const statesPropType = PropTypes.oneOf(Object.keys(states));
