import * as stateToDispatch from "../DashboardEventHandlers";
import {action, observable} from "mobx";
import {connect} from "react-redux";
import {inject, observer} from "mobx-react";
import {STATUSES} from "../widgets/DashboardWidget";
import getChartDateRange, {
    getFormatedFromToDate,
} from "../../date/getDateRange";
import getValidDateForDashboard from "./getValidDateForDashboard";
import moment from "moment";
import React, {Component, memo} from "react";
import DefaultDashboard, {DefaultDashboardProps} from "./DefaultDashboard";
import stateSelectors from "../selectors";
import useFeatureFlag from "../../featureFlags/useFeatureFlag";

const WEEK_DATE_RANGE = {
    daysBefore: 14,
    date: moment.utc().startOf("day").subtract(1, "days").toDate(),
};

export interface ChartFilter {
    fromDate: string;
    toDate: string;
    childrenTeamFilter?: number;
}

interface Props {
    loadWidgetData: any;
    getAppSalesFigures: (childrenTeamFilter: number) => Promise<any>;
    tooltipRepository: any;
    widgets: any;
    getTotalAppDownloads: (chartFilter: ChartFilter) => Promise<any>;
    getActivePurchasersAverageOrderSize: (
        chartFilter: ChartFilter,
    ) => Promise<any>;
    getTotalAppTransactions: (chartFilter: ChartFilter) => Promise<any>;
}

const HooksContainer = memo(function DefaultDashboardWithHooks(
    props: DefaultDashboardProps,
) {
    const {featureFlags} = useFeatureFlag();

    return <DefaultDashboard {...props} featureFlags={featureFlags} />;
});

@inject("tooltipRepository")
@observer
class DefaultDashboardContainer extends Component<Props> {
    componentDidMount() {
        this.initialiseDateRange();
        this.loadWidgetsData();
    }

    loadWidgetsData() {
        const {loadWidgetData, getAppSalesFigures} = this.props;
        const params = {
            ...getChartDateRange(WEEK_DATE_RANGE),
            childrenTeamFilter: this.childrenTeamFilter,
        };

        loadWidgetData("AppDownloads", {
            ...getFormatedFromToDate(this.startDate, this.endDate),
            childrenTeamFilter: this.childrenTeamFilter,
        });
        loadWidgetData("AppReviews", {
            childrenTeamFilter: this.childrenTeamFilter,
        });
        loadWidgetData("TotalNumberOfOrders", params);
        loadWidgetData("AverageBasketSize", params);
        loadWidgetData("TotalTransactionValue", params);

        this.setAppSalesFiguresData(() =>
            getAppSalesFigures(this.childrenTeamFilter),
        );
    }

    @observable
    startDate: Date = new Date();
    @observable
    endDate: Date = new Date();
    @observable
    childrenTeamFilter: number = 0;
    @observable
    appSalesFiguresData: object = {};
    @observable
    appSalesFiguresStatus: string | number = STATUSES.normal;

    @action
    initialiseDateRange() {
        this.startDate = moment
            .utc()
            .startOf("day")
            .subtract(31, "days")
            .toDate();
        this.endDate = moment.utc().startOf("day").subtract(1, "days").toDate();

        this.props.tooltipRepository.fetch({
            startDate: this.startDate,
            endDate: this.endDate,
        });
    }

    @action.bound
    async setAppSalesFiguresData(request: () => Object) {
        this.setAppSalesFiguresStatus(STATUSES.dataLoading);

        try {
            const appSalesData = await request();
            this.appSalesFiguresData = appSalesData;
            this.setAppSalesFiguresStatus(STATUSES.normal);
        } catch (exception) {
            this.setAppSalesFiguresStatus(STATUSES.dataUnavailable);
        }
    }

    @action.bound
    setAppSalesFiguresStatus(status: string | number) {
        this.appSalesFiguresStatus = status;
    }

    @action.bound
    onStartDateSelected(date: Date) {
        const startDate = getValidDateForDashboard(date);

        if (!startDate) {
            return;
        } else if (startDate > this.endDate) {
            this.startDate = new Date(this.endDate);
        } else {
            this.startDate = startDate;
        }

        this.props.tooltipRepository.fetch({
            startDate: this.startDate,
            endDate: this.endDate,
            childrenTeamFilter: this.childrenTeamFilter,
        });

        this.props.loadWidgetData("AppDownloads", {
            ...getFormatedFromToDate(this.startDate, this.endDate),
            childrenTeamFilter: this.childrenTeamFilter,
        });
    }

    @action.bound
    onEndDateSelected(date: Date) {
        const endDate = getValidDateForDashboard(date);

        if (!endDate) {
            return;
        } else if (this.startDate > endDate) {
            this.endDate = new Date(this.startDate);
        } else {
            this.endDate = endDate;
        }

        this.props.tooltipRepository.fetch({
            startDate: this.startDate,
            endDate: this.endDate,
            childrenTeamFilter: this.childrenTeamFilter,
        });

        this.props.loadWidgetData("AppDownloads", {
            ...getFormatedFromToDate(this.startDate, this.endDate),
            childrenTeamFilter: this.childrenTeamFilter,
        });
    }

    @action.bound
    onSetChildrenTeamFilter(filter: number) {
        this.childrenTeamFilter = filter;

        this.props.tooltipRepository.fetch({
            startDate: this.startDate,
            endDate: this.endDate,
            childrenTeamFilter: this.childrenTeamFilter,
        });

        this.loadWidgetsData();
    }

    render() {
        return (
            <HooksContainer
                widgets={this.props.widgets}
                onSetChildrenTeamFilter={this.onSetChildrenTeamFilter}
                getTotalAppDownloads={this.props.getTotalAppDownloads}
                getActivePurchasersAverageOrderSize={
                    this.props.getActivePurchasersAverageOrderSize
                }
                appSalesFiguresData={this.appSalesFiguresData}
                appSalesFiguresStatus={this.appSalesFiguresStatus}
                getTotalAppTransactions={this.props.getTotalAppTransactions}
                childrenTeamFilter={this.childrenTeamFilter}
                startDate={this.startDate}
                onStartDateSelected={this.onStartDateSelected}
                onEndDateSelected={this.onEndDateSelected}
                endDate={this.endDate}
            />
        );
    }
}

export default connect(
    stateSelectors,
    stateToDispatch,
    // @ts-ignore
)(DefaultDashboardContainer);
