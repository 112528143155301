import {Button} from "@material-ui/core";
import React, {memo} from "react";
import useNavigate from "@beam/use-navigate";

interface Props {
    disabled?: boolean;
    onSubmit: (params?: any) => {success: true} | Promise<{success: boolean}>;
    path?: string;
    children: Array<JSX.Element> | JSX.Element | string | number | any;
    className?: string;
    color?: "default" | "inherit" | "primary" | "secondary";
}

export default memo(function SubmitAndNavigateButton({
    disabled,
    onSubmit,
    path,
    children,
    className,
    color,
}: Props): JSX.Element {
    const {navigate} = useNavigate();

    return (
        <Button
            variant="contained"
            color={color || "primary"}
            className={className}
            disabled={disabled}
            onClick={async () => {
                const {success} = await onSubmit();
                if (success && path) {
                    navigate(path);
                }
            }}
        >
            {children}
        </Button>
    );
});
