/*  */

import {createStructuredSelector} from "reselect";
import apiClientFromState from "../apiClient/apiClientFromState";

const getWidgets = (state) => state.dashboard.get("widgets");

export default createStructuredSelector({
    apiClient: apiClientFromState,
    widgets: getWidgets,
});
