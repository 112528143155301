/*  */

import {setLoadingStatus} from "./actionCreators";
import {handleActions} from "redux-actions";
import {LOADING_STATUSES} from "./constants";

import history from "../router/history";
import immutable from "immutable";

export const go = history.push;

const getInitialState = () => {
    return immutable.fromJS({
        loadingStatus: LOADING_STATUSES.DONE,
    });
};

const handleSetProperty = (property) => (state, {payload}) =>
    state.set(property, payload);

export default handleActions(
    {
        [setLoadingStatus]: handleSetProperty("loadingStatus"),
    },
    getInitialState(),
);
