import React, {memo} from "react";
import Alert from "./Alert";
import AssociatedCampaign from "./AssociatedCampaign";
import Audience from "./audience/Audience";
// @ts-ignore
import Box from "../../widget/Box";
// @ts-ignore
import DetailRow from "../../widget/DetailRow";
import FbNotification from "./FbNotification";
import Schedule from "./Schedule";
import immutable from "immutable";

const BoxContainer = ({
    title,
    children,
}: {
    title: string;
    children: JSX.Element;
}) => {
    return (
        <Box
            removeBodyPadding
            removeHeaderBorder
            solid
            styleState="default"
            title={title}
        >
            {children}
        </Box>
    );
};

interface Props {
    messageDetails: any;
}

export default memo(function MessageDetails({
    messageDetails: mutableMessageDetails,
}: Props) {
    const messageDetails = immutable.fromJS(mutableMessageDetails);

    return (
        <div>
            <BoxContainer title="Audience Selectors">
                <Audience
                    selectors={messageDetails.getIn([
                        "userSelector",
                        "selectors",
                    ])}
                />
            </BoxContainer>
            {messageDetails.get("associatedCampaignId") && (
                <BoxContainer title="Audience Selectors">
                    <AssociatedCampaign
                        associatedCampaign={messageDetails.getIn([
                            "associatedCampaignId",
                        ])}
                    />
                </BoxContainer>
            )}

            <BoxContainer title="Alert">
                <Alert
                    message={messageDetails.get("message")}
                    urlToOpen={messageDetails.get("urlToOpen")}
                />
            </BoxContainer>
            {messageDetails.has("fbNotification") && (
                <BoxContainer title="Message">
                    <FbNotification
                        fbNotification={messageDetails.get("fbNotification")}
                    />
                </BoxContainer>
            )}

            <BoxContainer title="Schedule">
                <Schedule
                    createdAt={messageDetails.get("createdAt")}
                    schedule={messageDetails.get("globalRule")}
                />
            </BoxContainer>
        </div>
    );
});
