import * as R from "ramda";
//@ts-ignore
import {addError, addSuccess} from "../uiNotification/notificationDuck";
import {bindActionCreators} from "redux";
import {promiseAlert} from "promise-alert";
import {
    setMessageDetail,
    setMessageGroupList,
    setMessageList,
} from "./actionCreators";
//@ts-ignore
import apiClientFromState from "../apiClient/apiClientFromState";
import immutable from "immutable";
import {
    InjectedHooks,
    MoveToGroupProps,
    NotificationGroup,
    NotificationGroups,
} from "./types";

interface Body {
    body: Object;
}

interface GetNotificationDetailsQuery {
    notificationId: string;
    selectedTeamNumber?: string;
    shouldGetOpenedEvents?: boolean;
    shouldGetActionedEvents?: boolean;
}

interface RemoveGroupingOfNotificationsQuery {
    groupId: string;
    notificationIds: string;
}

export const getNotificationDetails = ({
    notificationId,
    selectedTeamNumber,
    shouldGetOpenedEvents,
    shouldGetActionedEvents,
}: GetNotificationDetailsQuery) => async (_: any, getState: Function) => {
    try {
        const apiClient = apiClientFromState(getState());
        try {
            const {body} = await apiClient.getNotification({
                notificationId,
                selectedTeamNumber,
                shouldGetOpenedEvents,
                shouldGetActionedEvents,
            });

            return {success: true, results: body};
        } catch (error) {
            return {success: false};
        }
    } catch (error) {
        return {success: false};
    }
};

export const getNotifications = (filters?: any) => (
    dispatch: Function,
    getState: Function,
) => {
    const apiClient = apiClientFromState(getState());

    return apiClient
        .getNotifications(filters)
        .then(({body}: Body) => {
            const immutableNotifications = immutable.fromJS(body);

            dispatch(setMessageList(immutableNotifications));
        })
        .catch((error: any) => {
            // @ts-ignore
            dispatch(addError(error.message));
        });
};

export const getGroupedNotifications = (filters?: any) => async (
    dispatch: Function,
    getState: Function,
): Promise<NotificationGroups> => {
    const apiClient = apiClientFromState(getState());

    try {
        const {body} = await apiClient.getGroupedNotifications(filters);

        dispatch(setMessageGroupList(body));

        return body;
    } catch (error) {
        // @ts-ignore
        dispatch(addError(error.message));

        return Promise.reject(error);
    }
};

export const resetMessageDetailsToEmpty = () => (dispatch: Function) => {
    dispatch(setMessageDetail(immutable.fromJS({})));
};

export const confirmMessageDelete = ({
    groupId,
    notificationId,
}: {
    groupId: string;
    notificationId: string;
}) => (dispatch: Function, getState: Function) => {
    const apiClient = apiClientFromState(getState());

    return apiClient
        .deleteNotification({groupId, notificationId})
        .then(() => {
            return;
        })
        .catch((error: any) => {
            // @ts-ignore
            dispatch(addError(error.message));
        });
};

export const onCreateNotificationGrouping = (group: NotificationGroup) => (
    dispatch: Function,
    getState: Function,
) => {
    const apiClient = apiClientFromState(getState());

    return apiClient
        .postGroupNotification(group)
        .then(() =>
            dispatch(
                // @ts-ignore
                addSuccess(`Successfully created group ${group.groupName}`),
            ),
        )
        .catch((error: any) => {
            // @ts-ignore
            dispatch(addError(error.message));
        });
};

export const onMoveNotification = ({
    fromGroupId,
    toGroupId,
    notifications,
}: MoveToGroupProps) => (dispatch: Function, getState: Function) => {
    const apiClient = apiClientFromState(getState());

    return apiClient
        .updateGroupNotifications({
            _id: fromGroupId,
            toGroupId,
            notifications,
            updateType: "move",
        })
        .then(() => {
            // @ts-ignore
            dispatch(addSuccess(`Successfully updated`));
            dispatch(getGroupedNotifications());
        })
        .catch((error: any) => {
            // @ts-ignore
            dispatch(addError(error.message));

            return Promise.reject(error);
        });
};

export const onRenameNotificationGroup = (id: string, groupName: string) => (
    dispatch: Function,
    getState: Function,
) => {
    const apiClient = apiClientFromState(getState());

    return apiClient
        .updateGroupNotifications({
            _id: id,
            groupName,
        })
        .then(() => {
            // @ts-ignore
            dispatch(addSuccess(`Successfully renamed`));
            dispatch(getGroupedNotifications());
        })
        .catch((error: any) => {
            // @ts-ignore
            dispatch(addError(error.message));

            return Promise.reject(error);
        });
};

export const onDeleteMessagePressed = ({
    groupId,
    notificationId,
    onDeleteCallback,
}: {
    groupId: string;
    notificationId: string;
    onDeleteCallback?: () => void;
}) => (dispatch: Function) =>
    promiseAlert({
        title: "Remove Push Notification",
        text:
            "Are you sure that you want to remove the selected push notification?",
        type: "warning",
        showCancelButton: true,
    }).then((confirmed: boolean) => {
        if (confirmed) {
            if (onDeleteCallback) {
                onDeleteCallback();
            }

            dispatch(
                confirmMessageDelete({
                    groupId,
                    notificationId,
                }),
            );
        }
    });

export const onChecked = ({getState, setState}: InjectedHooks) => (
    id: string,
    name: string,
) => {
    const {currentChecked} = getState();
    setState({
        currentChecked: {
            ...currentChecked,
            [id]: {
                id,
                checked: currentChecked[id]
                    ? !currentChecked[id].checked
                    : true,
                name,
            },
        },
    });
};

export const onFinishGrouping = ({
    setState,
    getProps,
}: InjectedHooks) => async () => {
    setState({currentChecked: {}});

    await getProps().getGroupedNotifications();
};

export const onGroupedHeaderClick = ({setState}: InjectedHooks) => (
    id?: string,
) => {
    setState({
        currentOpened: id || "",
    });
};

export const onRemoveGroupingOfNotifications = ({
    groupId,
    notificationIds,
}: RemoveGroupingOfNotificationsQuery) => async (
    dispatch: Function,
    getState: Function,
) => {
    try {
        const apiClient = apiClientFromState(getState());

        await apiClient.deleteGroupNotification({groupId, notificationIds});
        // @ts-ignore
        return dispatch(addSuccess(`Successfully removed grouping`));
    } catch (exception) {
        // @ts-ignore
        dispatch(addError(exception.message));

        return Promise.reject(exception);
    }
};

export const onRemovingGroupingWasPressed = (
    query: RemoveGroupingOfNotificationsQuery,
) => async (dispatch: Function, getState: Function) => {
    const {message} = getState();
    const {notifications} = message
        .get("messageGroupList")
        .find(({_id}: {_id: string}) => _id === query.groupId);

    const isAllNotificationSelected = R.compose(
        R.equals(R.length(notifications)),
        R.length,
        R.split(","),
    )(query.notificationIds);

    const textMessage = isAllNotificationSelected
        ? "Are you sure that you want to remove the all notification in this group? we will delete the group and move the notification to Ungroup"
        : "Are you sure you want to ungroup the selected push notification(s)?";

    const confirmed = await promiseAlert({
        title: "Ungroup Push Notifications",
        text: textMessage,
        type: "warning",
        showCancelButton: true,
    });

    if (confirmed) {
        return dispatch(onRemoveGroupingOfNotifications(query));
    }

    return Promise.resolve();
};

export const onMoveNotificationButtonClick = ({
    setState,
    getProps,
    getState,
}: InjectedHooks) => async () => {
    const {
        onMoveNotification,
        rowId,
        notifications,
        onFinishGrouping,
    } = getProps();
    const {selectedGroup} = getState();

    try {
        setState({
            isSubmitting: true,
        });
        await onMoveNotification({
            notifications,
            fromGroupId: rowId,
            toGroupId: selectedGroup,
        });
        setState({
            selectedGroup: "",
            isSubmitting: false,
        });
    } catch (e) {
        setState({
            selectedGroup: "",
            isSubmitting: false,
        });
    }

    onFinishGrouping();
};

const removeByValueId = (id: string) => R.reject(R.propEq("value", id));

export const onMessageGroupItemsUpdate = ({
    setState,
    getProps,
}: InjectedHooks) => () => {
    const {rowId, messageGroupSelectItems} = getProps();

    const items = R.compose(
        removeByValueId("UNGROUPED_NOTIFICATIONS"),
        removeByValueId(rowId),
    )(messageGroupSelectItems);

    setState({items});
};

export const messageGroupEventHandlers = (dispatch: any) =>
    bindActionCreators(
        {
            getGroupedNotifications,
            onCreateNotificationGrouping,
            onDeleteMessagePressed,
            getNotifications,
            onRenameNotificationGroup,
        },
        dispatch,
    );
