//@ts-ignore
import contactUsImage from "../common/images/contact-us.png";
//@ts-ignore
import logoImage from "../common/images/logo.png";
import React, {memo} from "react";
import {makeStyles} from "@material-ui/core";
//@ts-ignore
import supportImage from "../common/images/support.png";
import classNames from "classnames";

const useStyles = makeStyles({
    beamDescriptionContainer: {
        marginBottom: "2em",
        width: "720px",
        maxWidth: "none",
    },
    container: {
        margin: "auto",
        padding: "2em 1em",
        width: "720px",
        maxWidth: "none",
    },
    logo: {
        display: "block",
        margin: "2em auto",
        width: "200px",
    },
    paragraphs: {
        marginBottom: "1em",
    },
    image: {
        width: "80%",
    },
    contactUsContainer: {
        width: "100%",
        background: "rgb(206, 206, 206)",
        boxShadow: "15px 0 0 rgb(206, 206, 206), -15px 0 0 rgb(206, 206, 206)",
        padding: "1em",
    },
    supportContainer: {
        padding: "1em",
    },
    hyperLink: {
        color: "rgb(255, 34, 85)",
    },
});

export default memo(function ContactUs(): JSX.Element {
    const CONTACT_US_EMAIL_SUBJECT = "Beam>ContactUs: ";
    const SUPPORT_EMAIL_SUBJECT = "Beam>Support: ";
    const classes = useStyles();
    const beamDescriptionStyle = classNames(
        "container",
        classes.beamDescriptionContainer,
    );
    const beamImageStyle = classNames("center-block", classes.image);
    return (
        <div>
            <div className={beamDescriptionStyle}>
                <img src={logoImage} className={classes.logo} />
                <p className={classes.paragraphs}>
                    Beam is RNF Digital Innovation's powerful mobile engagement
                    platform. Understand your mobile audience with our
                    insightful dashboards. Drive engagement by reaching the
                    right people through the use of our flexible audience
                    targeting system: segment your audience based on purchase
                    history, spending preferences, customer types and location
                    using the latest location-based technologies such as
                    iBeacons and geofences. For more information see the Contact
                    Us section below.
                </p>
            </div>
            <div className={classes.contactUsContainer}>
                <div className="container">
                    <div className="row clearfix">
                        <div className="pull-left col-md-6 text-center">
                            <img
                                className={beamImageStyle}
                                src={contactUsImage}
                            />
                        </div>
                        <div className="pull-right col-md-6">
                            <h2>CONTACT US</h2>
                            <address>
                                <strong>RNF Digital Innovation</strong>
                                <br />
                                Frederick House,
                                <br />1 Augusta Place,
                                <br />
                                Leamington Spa,
                                <br />
                                CV32 5EL
                                <br />
                                United Kingdom
                                <br />
                                <abbr title="Main Telephone Number">
                                    Main Tel:
                                </abbr>{" "}
                                +44(0)1926 298 867
                                <br />
                                <abbr title="Support Telephone Number">
                                    Support Tel:
                                </abbr>{" "}
                                +44(0)1926 679 578
                                <br />
                                <abbr title="Email Address">Email:</abbr>
                                <a
                                    className={classes.hyperLink}
                                    href={`mailto:enquiries@rnfdigital.com?subject=${CONTACT_US_EMAIL_SUBJECT}`}
                                >
                                    &nbsp;enquiries@rnfdigital.com
                                </a>
                            </address>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.supportContainer}>
                <div className="container">
                    <div className="row clearfix">
                        <div className="pull-right col-md-6 text-center">
                            <img
                                className={beamImageStyle}
                                src={supportImage}
                            />
                        </div>
                        <div className="pull-left col-md-6">
                            <h2>SUPPORT</h2>
                            <p>
                                Please send an email with the details of your
                                issue to
                                <a
                                    className={classes.hyperLink}
                                    href={`mailto:support@rnfdigital.com?subject=${SUPPORT_EMAIL_SUBJECT}`}
                                >
                                    &nbsp;support@rnfdigital.com
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});
