import {Col, Row} from "react-bootstrap";
//@ts-ignore
import PromiseFileReader from "promise-file-reader";
import React, {memo, useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core";
import RemoveItemButton from "../common/buttons/RemoveItemButton";

const useStyles = makeStyles({
    previewLabel: {display: "block"},
    buttonToolbar: {paddingTop: 10},
    container: {
        marginBottom: 30,
    },
    label: {
        color: "rgb(34, 45, 50)",
        fontSize: 14,
        fontWeight: 700,
    },
    sublabel: {
        color: "rgb(34, 45, 50)",
        fontSize: 12,
        fontWeight: 400,
    },
    buttonContainer: {
        marginTop: 10,
        width: "100%",
    },
});

interface Props {
    source: string | object;
    isUrl?: boolean;
    onRemoveMedia: () => void;
    isVideo?: boolean;
}
export default memo(function MediaPreview({
    source,
    isUrl,
    onRemoveMedia,
    isVideo,
}: Props): JSX.Element {
    const classes = useStyles();
    const [imageSource, setImageSource] = useState<string>();
    const [error, setError] = useState(false);

    useEffect(() => {
        const readFile = async () => {
            if (isUrl) {
                setImageSource(source as string);
                return;
            }

            try {
                const image = await PromiseFileReader.readAsDataURL(source);
                setImageSource(image);
            } catch (e) {
                setError(true);
            }
        };
        readFile();
    }, [source, isUrl, error]);

    return (
        <Row className={classes.container}>
            <Col xs={4} />
            <Col xs={8}>
                {Boolean(source) && isVideo ? (
                    // @ts-ignore
                    <video src={source} controls width={400} />
                ) : (
                    <img src={imageSource} width={400} />
                )}
                {Boolean(error) && "Unable to load the image"}
                <div className={classes.buttonContainer}>
                    <RemoveItemButton onClick={onRemoveMedia} />
                </div>
            </Col>
        </Row>
    );
});
