import {Collapse} from "react-bootstrap";
import classNames from "classnames";
import React, {memo, ReactNode} from "react";

const styles = Object.freeze({
    headerButtonContainer: {
        zIndex: 10,
    },
});

interface CollapsibleBoxProps {
    boxStyle?: "default" | "primary" | "info" | "warning";
    children?: JSX.Element | ReactNode;
    header: JSX.Element | string;
    headerButton?: JSX.Element;
    onHeaderClick?: any;
    open?: boolean;
    removeBodyPadding?: boolean;
    removeHeaderBorder?: boolean;
    solid?: boolean;
}

export default memo(function CollapsibleBox({
    children,
    onHeaderClick,
    header,
    removeHeaderBorder,
    removeBodyPadding,
    open = false,
    boxStyle = "default",
    solid = false,
    headerButton,
}: CollapsibleBoxProps): JSX.Element {
    const contentInCollapseStyle = {padding: 10};

    const boxBodyClassNames = classNames("box-body", {
        "no-padding": removeBodyPadding,
    });

    const boxHeaderClassNames = classNames("box-header", {
        "with-border": !removeHeaderBorder,
    });

    const boxHeaderStyle = onHeaderClick ? {cursor: "pointer"} : {};

    const boxClasses = classNames("box", {
        [`box-${boxStyle}`]: boxStyle,
        "box-solid": solid,
    });

    return (
        <div className={boxClasses}>
            <div
                className={boxHeaderClassNames}
                onClick={onHeaderClick}
                style={boxHeaderStyle}
            >
                {header}
                {headerButton ? (
                    <div
                        className="box-tools pull-right"
                        style={styles.headerButtonContainer}
                    >
                        {headerButton}
                    </div>
                ) : null}
            </div>
            <div className={boxBodyClassNames}>
                <Collapse in={open}>
                    <div>
                        <div style={contentInCollapseStyle}>{children}</div>
                    </div>
                </Collapse>
            </div>
        </div>
    );
});
