import React, {memo} from "react";

interface Props {
    size: number;
}

export default memo(function Loading({size = 2}: Props): JSX.Element {
    return (
        <div>
            <i
                className={`fa fa-circle-o-notch fa-spin fa-${size}x fa-fw margin-bottom`}
            />
            <span className="sr-only">Loading...</span>
        </div>
    );
});
