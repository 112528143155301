import {Badge as BSBadge} from "react-bootstrap";
import classNames from "classnames";
import React, {memo, CSSProperties} from "react";

interface Props {
    children: JSX.Element | string;
    state: any;
    style?: CSSProperties;
}

export default memo(function Badge({
    children,
    state,
    ...props
}: Props): JSX.Element {
    const className = classNames({[`progress-bar-${state}`]: state});

    return (
        <BSBadge className={className} {...props}>
            {children}
        </BSBadge>
    );
});
