/*  */

import {action, computed, observable, runInAction} from "mobx";
import moment from "moment";

export default class TooltipRepository {
    static mapEngagementRecordsToDate = (recordsToAdd, allDatedRecords, type) =>
        recordsToAdd
            .filter((record) => record.status === "ARCHIVED")
            .reduce((engagementDataPerDate, {sendTime, _id: id}) => {
                const key = moment(sendTime).format("ddd MMM DD YYYY");
                const notification = {sendTime, id, type};
                const currentEntry = engagementDataPerDate[key] || [];

                return {
                    ...engagementDataPerDate,
                    [key]: [...currentEntry, notification],
                };
            }, allDatedRecords);

    static sortDatedEngagementRecordsLatestFirst = (allDatedRecords) => {
        const engagementDataKeys = Object.keys(allDatedRecords);

        return engagementDataKeys.reduce((datedEngagementData, key) => {
            const sortedEntryForKey = allDatedRecords[key].sort((a, b) => {
                const sendTimeForA = moment.utc(a.sendTime);
                const sendTimeForB = moment.utc(b.sendTime);

                if (sendTimeForA.isSame(sendTimeForB)) {
                    return 0;
                }

                return sendTimeForA.isBefore(sendTimeForB) ? 1 : -1;
            });

            return {
                ...datedEngagementData,
                [key]: sortedEntryForKey,
            };
        }, {});
    };

    constructor(apiClient) {
        this.apiClient = apiClient;
    }

    @observable
    notifications = [];

    @observable
    recentNotifications = [];
    @observable
    isFetching = false;

    @computed
    get dataForDateRange() {
        const notificationsByDate = TooltipRepository.mapEngagementRecordsToDate(
            this.notifications,
            {},
            "message",
        );

        const sortedEngagementData = TooltipRepository.sortDatedEngagementRecordsLatestFirst(
            notificationsByDate,
        );

        return sortedEngagementData;
    }

    @computed
    get dataForLastSevenDays() {
        const notificationsByDate = TooltipRepository.mapEngagementRecordsToDate(
            this.recentNotifications,
            {},
            "message",
        );

        return TooltipRepository.sortDatedEngagementRecordsLatestFirst(
            notificationsByDate,
        );
    }

    @action.bound
    async fetch(dates) {
        this.isFetching = true;

        const query = {
            scheduledFromDate: moment.utc(dates.startDate).format("YYYY-MM-DD"),
            scheduledToDate: moment
                .utc(dates.endDate)
                .add(1, "days")
                .format("YYYY-MM-DD"),
        };

        const lastSevenDaysQuery = {
            scheduledFromDate: moment
                .utc()
                .subtract(7, "days")
                .format("YYYY-MM-DD"),
            scheduledToDate: moment.utc().format("YYYY-MM-DD"),
        };

        const [notifications, recentNotifications] = await Promise.all([
            this.apiClient
                .invokeGetEndpoint("notification", query)
                .catch(() => []),

            this.apiClient
                .invokeGetEndpoint("notification", lastSevenDaysQuery)
                .catch(() => []),
        ]);

        runInAction(() => {
            this.notifications = notifications;

            this.recentNotifications = recentNotifications;
            this.isFetching = false;
        });
    }

    @action.bound
    clearToolTipRepository() {
        this.notifications = [];

        this.recentNotifications = [];
        this.isFetching = false;
    }
}
