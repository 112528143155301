// @ts-ignore
import Entity from "@beam/entity";
import {
    MessageAlert,
    RichPushNotifications,
    Schedule,
    StepContainerForm,
} from "./types";
import {
    ACTION_NONE,
    ACTION_VIEW_WEBSITE,
    ACTION_VIEW_PRODUCT_DETAILS,
    ACTION_VIEW_PRODUCT_LIST,
    ACTION_ADD_PRODUCT_TO_BASKET,
    ACTION_ADD_PRODUCT_TO_LIST,
} from "../advert/constants";

export const linkActions = Object.freeze({
    NONE: "None",
    VISIT_WEBSITE: "Visit Website",
    VIEW_PRODUCT_LIST: "View Product List",
    VIEW_PRODUCT_DETAILS: "View Product Details",
    ADD_PRODUCT_TO_BASKET: "Add Product To Basket",
    ADD_PRODUCT_TO_LIST: "Add Product To List",
});

export const linkActionsMapping = Object.freeze({
    [ACTION_NONE]: linkActions.NONE,
    [ACTION_VIEW_WEBSITE]: linkActions.VISIT_WEBSITE,
    [ACTION_VIEW_PRODUCT_DETAILS]: linkActions.VIEW_PRODUCT_DETAILS,
    [ACTION_VIEW_PRODUCT_LIST]: linkActions.VIEW_PRODUCT_LIST,
    [ACTION_ADD_PRODUCT_TO_BASKET]: linkActions.ADD_PRODUCT_TO_BASKET,
    [ACTION_ADD_PRODUCT_TO_LIST]: linkActions.ADD_PRODUCT_TO_LIST,
});

export const CREATE_MSG_PATH = "/message/create/:groupId?";
export const DUPLICATE_MSG_PATH = "/message/:id/duplicate/:groupId?";
export const EDIT_MSG_PATH = "/message/:id/edit/:groupId?";

export default class PushNotification extends Entity {
    stepContainerForm: StepContainerForm = {
        audience: true,
        mesasge: false,
        schedule: false,
        fbNotification: false,
        send: false,
    };

    messageAlert: MessageAlert = {
        description: {
            value: "",
        },
        value: "",
        urlToOpen: {
            value: "",
        },
    };

    richPushNotifications: RichPushNotifications = {
        linkAction: {
            value: linkActions.NONE,
        },
        body: {
            value: "",
        },
        imageFile: {
            value: "",
            errors: false,
            errorMessage: "",
            dimension: {height: 0, width: 0},
        },
        buttonText: {
            value: "",
        },
        dismissButtonText: {
            value: "OK",
        },
        buttonUrl: {
            value: "",
        },
        productSku: {
            value: "",
        },
        wantFbNotification: false,
    };

    schedule: Schedule = {
        value: "",
        sendImmediately: false,
    };
}
