import Overlay from "./Overlay";
import OverlayLabel from "./OverlayLabel";
import React, {memo} from "react";

interface Props {
    children: JSX.Element;
}
export default memo(function DisabledOverlay({children}: Props): JSX.Element {
    return (
        <Overlay overlayContent={<OverlayLabel>Available soon</OverlayLabel>}>
            {children}
        </Overlay>
    );
});
