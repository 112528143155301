export default (
    chartData: {axisLabels: Array<any>; datasets: any; legends: any},
    axisLabelCategoryName: string,
): Array<any> => {
    const axisLabels = chartData.axisLabels;
    const datasets = chartData.datasets;
    const legends = chartData.legends;

    return axisLabels.reduce(
        (csvData: Array<any>, axisLabel: string, axisLabelsIndex: number) => {
            const subcategoriesData = legends.reduce(
                (
                    subcategoriesMap: Object,
                    legend: string,
                    legendsIndex: number,
                ) => ({
                    ...subcategoriesMap,
                    [legend]: datasets[axisLabelsIndex][legendsIndex],
                }),
                {},
            );

            csvData.push({
                [axisLabelCategoryName]: axisLabel,
                ...subcategoriesData,
            });

            return csvData;
        },
        [],
    );
};
