import React, {memo} from "react";
import {makeStyles} from "@material-ui/styles";
import classnames from "classnames";

interface NotificationItem {
    message: string;
    onClick: () => void;
    type: "error" | "success";
}

const useStyles = makeStyles({
    shared: {
        alignItems: "flex-start",
        borderRadius: 2,
        color: "white",
        cursor: "pointer",
        display: "flex",
        flexWrap: "nowrap",
        marginBottom: 2,
        padding: 10,
        width: 300,
    },
    message: {
        flexGrow: 80,
        paddingRight: 5,
    },
    close: {
        flexGrow: 10,
        textAlign: "right",
    },
    error: {
        backgroundColor: "rgb(255,34,85)",
        border: "1px solid rgb(232,10,61)",
    },
    success: {
        backgroundColor: "rgb(173,221,21)",
        border: "1px solid rgb(143,188,0)",
    },
});

export default memo(function Notification({
    message,
    onClick,
    type,
}: NotificationItem): JSX.Element {
    const classes = useStyles();

    const containerClass = classnames(classes.shared, classes[type]);

    return (
        <div onClick={onClick} className={containerClass}>
            <div className={classes.message}>{message}</div>
            <div className={classes.close}>✕</div>
        </div>
    );
});
