import {createContext} from "react";

export interface AlertContextParams {
    alerts: Array<any>;
    setAlerts: (alerts: Array<any>) => void;
}

export default createContext<AlertContextParams>({
    alerts: [],
    setAlerts: () => null,
});
