import React, {memo} from "react";

export default memo(function RemoveButton(): JSX.Element {
    return (
        <button
            key="removeButton"
            className="btn btn-box-tool"
            data-toggle="tooltip"
            data-widget="remove"
            title="Remove"
        >
            <i className="fa fa-times" />
        </button>
    );
});
