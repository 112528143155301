import {createContext} from "react";
import {BETA, ENTERPRISE, ENTRY, PREMIUM} from "./featureFlagsLevel";

export const FEATURE_FLAG_MAP = Object.freeze({
    BETA,
    ENTERPRISE,
    ENTRY,
    PREMIUM,
});

export default createContext<{
    featureFlags: any;
    setFeatureFlags: (featureFlag: any) => any;
}>({
    featureFlags: {},
    setFeatureFlags: () => {},
});
