import {action, computed, observable} from "mobx";

export enum METHODS {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    DELETE = "DELETE",
}

export default class APIClient {
    public static METHODS = METHODS;

    @observable
    public requestCount: number = 0;

    @action
    public incrementRequestCount(): void {
        this.requestCount += 1;
    }

    @action
    public decrementRequestCount(): void {
        this.requestCount -= 1;
    }

    @computed
    public get hasNetworkActivity(): boolean {
        return this.requestCount > 0;
    }

    public async fetch(
        input: string,
        init?: {[key: string]: any},
    ): Promise<Response> {
        this.incrementRequestCount();

        try {
            return await fetch(input, init);
        } catch (error) {
            console.log(`Request failed: ${error.message}`); // tslint:disable-line no-console
            throw error;
        } finally {
            this.decrementRequestCount();
        }
    }
}
