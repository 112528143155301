import React, {memo, useEffect, useState} from "react";
import AddAdvert, {AddAdvertProps} from "./AddAdvert";
import {List, fromJS} from "immutable";
import {action, computed, observable} from "mobx";
import {inject, observer} from "mobx-react";
//@ts-ignore
import {getIn} from "../common/ramdaDataUtils";
//@ts-ignore
import {addAdvertSelector as stateSelector} from "./selectors";
//@ts-ignore
import getImageValidationState from "../common/getImageValidationState";
import useFeatureFlag from "../featureFlags/useFeatureFlag";
import useAlert from "../alert/useAlert";
import useNavigate from "@beam/use-navigate";
import * as R from "ramda";
import {
    ACTION_NONE,
    ACTION_VIEW_WEBSITE,
    ACTION_VIEW_PRODUCT_DETAILS,
    ACTION_VIEW_PRODUCT_LIST,
    ACTION_ADD_PRODUCT_TO_BASKET,
    ACTION_ADD_PRODUCT_TO_LIST,
    ACTION_PATH,
} from "./constants";
import {FormControl} from "react-bootstrap";
import {isBCPTeam} from "../pushNotification/getNotificationLinkUrl";
import getBCPActionAndValue from "./getBCPActionAndValue";
import {getAdverts} from "./advertEventHandlers";
import {connect} from "react-redux";
import {createSelector} from "reselect";
import {isLimitReached} from "./AdvertTable";

interface DimensionLimits {
    [key: string]: {maxWidth: number; maxHeight: number};
}

const DIMENSION_LIMITS: DimensionLimits = {
    interstitial: {maxWidth: 800, maxHeight: 1000},
    interstitialEntrance: {maxWidth: 800, maxHeight: 1000},
    banner: {maxWidth: 460, maxHeight: 90},
    "Home Banner 1": {maxWidth: 460, maxHeight: 90},
    "Home Banner 2": {maxWidth: 460, maxHeight: 90},
    "Home Banner 3": {maxWidth: 460, maxHeight: 90},
};

interface AddAdvertWithHooksProps extends AddAdvertProps {
    onSaveAdverts: (advertId?: string) => Promise<{success: boolean}>;
    invokeGetEndpoint: (path: string, parameters?: any) => Promise<any>;
    advertId?: string;
    placement: string;
    onBulkChangeAdvertFields: (formFields: any) => void;
    onSelectImage: (
        event: React.FormEvent<FormControl>,
        placementName: string,
        relaxRuleEnabled: boolean,
        isVideoPreviewImage: boolean,
    ) => void;
    getAdverts: (
        filter?:
            | {childrenTeamFilter?: string | undefined; placementsFeatures: any}
            | undefined,
    ) => {
        payload: Array<{
            placement: string;
        }>;
        type: string;
    };
    params: {placementName: string; teamChildrenFilter: string};
    onSetFullStop: (value: boolean) => void;
}

interface CheckPlacementLimitParams {
    placementName?: string;
    isEditMode: boolean;
    placementLimit?: number | null;
    placementsFeatures: Array<string>;
    getAdvertsData: (
        filter?:
            | {childrenTeamFilter?: string | undefined; placementsFeatures: any}
            | undefined,
    ) => {
        payload: Array<{
            placement: string;
        }>;
        type: string;
    };
    setPlacementLimitReached: React.Dispatch<React.SetStateAction<boolean>>;
}

export const checkPlacementLimit = async ({
    placementName,
    isEditMode,
    placementLimit,
    placementsFeatures,
    getAdvertsData,
    setPlacementLimitReached,
}: CheckPlacementLimitParams): Promise<void> => {
    if (isEditMode || !placementName) {
        return;
    }

    if (!placementLimit) {
        return;
    }

    const adverts = await getAdvertsData({
        placementsFeatures,
    });

    const advertsCount =
        adverts?.payload?.filter(({placement}) => placement === placementName)
            ?.length || 0;

    setPlacementLimitReached(isLimitReached(advertsCount, placementLimit));
};

export const getSkuFromActionUrl = (url: string) => {
    return url.substring(url.lastIndexOf("/") + 1);
};

export const determineAction = (actionLink: string, path: string) => {
    return actionLink.indexOf(path) >= 0;
};

const getActionAndActionValue = (actionLink?: string) => {
    let actionAndActionValue = {action: ACTION_NONE, actionValue: ""};

    if (!actionLink) {
        return actionAndActionValue;
    }

    if (isBCPTeam()) {
        return getBCPActionAndValue(actionLink);
    }
    if (determineAction(actionLink, ACTION_PATH[ACTION_VIEW_PRODUCT_LIST])) {
        actionAndActionValue = {
            action: ACTION_VIEW_PRODUCT_LIST,
            actionValue: getSkuFromActionUrl(actionLink),
        };
    } else if (
        determineAction(actionLink, ACTION_PATH[ACTION_ADD_PRODUCT_TO_BASKET])
    ) {
        actionAndActionValue = {
            action: ACTION_ADD_PRODUCT_TO_BASKET,
            actionValue: getSkuFromActionUrl(actionLink),
        };
    } else if (
        determineAction(actionLink, ACTION_PATH[ACTION_ADD_PRODUCT_TO_LIST])
    ) {
        actionAndActionValue = {
            action: ACTION_ADD_PRODUCT_TO_LIST,
            actionValue: getSkuFromActionUrl(actionLink),
        };
    } else if (
        determineAction(actionLink, ACTION_PATH[ACTION_VIEW_PRODUCT_DETAILS])
    ) {
        actionAndActionValue = {
            action: ACTION_VIEW_PRODUCT_DETAILS,
            actionValue: getSkuFromActionUrl(actionLink),
        };
    } else {
        actionAndActionValue = {
            action: ACTION_VIEW_WEBSITE,
            actionValue: actionLink,
        };
    }

    return actionAndActionValue;
};

const getActionList = (featureFlags: any = {}) => {
    const actions = fromJS({
        actionNone: {
            label: "None",
            value: "none",
        },
        actionViewWebsite: {
            label: "View Website",
            value: ACTION_VIEW_WEBSITE,
        },
        actionViewProductList: {
            label: "View Product List",
            value: ACTION_VIEW_PRODUCT_LIST,
        },
        actionViewProductDetails: {
            label: "View Product Details",
            value: ACTION_VIEW_PRODUCT_DETAILS,
        },
        actionAddProductToBasket: {
            label: "Add Product To Basket",
            value: ACTION_ADD_PRODUCT_TO_BASKET,
        },
        actionAddProductToList: {
            label: "Add Product To List",
            value: ACTION_ADD_PRODUCT_TO_LIST,
        },
    });

    return actions.reduce((list: Array<any>, item: any, flag: string) => {
        return featureFlags[flag] || flag === "actionNone"
            ? list.push(item)
            : list;
    }, List());
};

const HooksContainer = memo(function AddAdvertWithHooks(
    props: AddAdvertWithHooksProps,
) {
    const [isPlacementLimitReached, setPlacementLimitReached] = useState(false);
    const {featureFlags} = useFeatureFlag();
    const {navigate} = useNavigate();
    const {addErrorAlert, addSuccessAlert} = useAlert();
    const {advertId, onBulkChangeAdvertFields, getAdverts, params} = props;
    const isEditMode = Boolean(advertId);

    useEffect(() => {
        if (advertId) {
            fetchAdvertConfigs();
        }

        props.onSetFullStop(featureFlags?.adActions?.allowSKUsWithFullStops);

        const paramsPlacementName = params?.placementName;

        checkPlacementLimit({
            placementName: paramsPlacementName,
            isEditMode,
            placementLimit: featureFlags?.placementLimits[paramsPlacementName],
            placementsFeatures: featureFlags.placements,
            getAdvertsData: getAdverts,
            setPlacementLimitReached,
        });

        return () => {
            props.onSetIsVideoInterstitials(false);
        };
    }, []);

    const onGetAdvertById = async (id: string) => {
        return await props.invokeGetEndpoint(`placements/${id}`);
    };

    const fetchAdvertConfigs = async () => {
        try {
            const advert = await onGetAdvertById(advertId!);
            const {
                placement,
                description,
                linkedProduct,
                relatedProducts,
                endDate,
                startDate,
                imageUrl,
                actionLabel,
                actionLink,
                actionType,
                actionParams,
                teamNumber = "",
                videoUrl,
                videoPreviewImageUrl,
            } = advert;

            let action = "";
            let actionValue = "";

            if (!actionParams || !actionType) {
                const actionData = getActionAndActionValue(actionLink);

                action = actionData.action;
                actionValue = actionData.actionValue;
            } else {
                action = actionType;
                actionValue = actionParams;
            }

            onBulkChangeAdvertFields({
                placement,
                description,
                sku: linkedProduct,
                relatedProducts,
                endDate: new Date(endDate),
                startDate: new Date(startDate),
                image: imageUrl,
                actionLabel,
                actionLink,
                action,
                actionValue,
                childrenTeamFilter: teamNumber,
                videoUrl,
                videoPreviewImageUrl,
            });
        } catch (e) {}
    };

    const onSuccess = (successMsg: string) => {
        addSuccessAlert(successMsg);
        const paramsPlacementName = params?.placementName;
        const backRoute = paramsPlacementName.includes("category")
            ? "/placements/categoryAdverts"
            : "/placements";
        navigate(backRoute);
    };

    const onSaveAdverts = async () => {
        const {success} = await props.onSaveAdverts(advertId);

        if (success && !isEditMode) {
            onSuccess("Successfully saved advert");
        } else if (success && isEditMode) {
            onSuccess("Successfully updated advert");
        } else {
            addErrorAlert(
                "An error occurred while saving placement. Please try again.",
            );
        }

        return {success};
    };

    return (
        <AddAdvert
            {...props}
            onSelectImage={(
                event: React.FormEvent<FormControl>,
                placementName: string,
                isVideoPreviewImage: boolean,
            ) => {
                props.onSelectImage(
                    event,
                    placementName,
                    featureFlags.relaxImageUploadRule,
                    isVideoPreviewImage,
                );
            }}
            featureFlags={featureFlags}
            onSaveAdverts={onSaveAdverts}
            isEditMode={isEditMode}
            actionsList={getActionList(featureFlags.adActions)}
            isPlacementLimitReached={isPlacementLimitReached}
            onSetIsVideoInterstitials={props.onSetIsVideoInterstitials}
            isVideoInterstitials={props.isVideoInterstitials}
        />
    );
});

interface AddAdvertContainerProps {
    advertRepository: any;
    childrenTeamFilterEnabled: boolean;
    match: any;
    imageDimensionsLimitEnabled: boolean;
    apiClient: any;
    placements: Map<string, string>;
    getAdverts: (
        filter?:
            | {childrenTeamFilter?: string | undefined; placementsFeatures: any}
            | undefined,
    ) => {
        payload: Array<{
            placement: string;
        }>;
        type: string;
    };
}

@inject("advertRepository", "apiClient")
@observer
class AddAdvertContainer extends React.Component<AddAdvertContainerProps> {
    componentDidMount() {
        const {advertRepository, match} = this.props;
        const placementName = match.params.placementName;
        advertRepository.onResetAdvertFields();
        advertRepository.onChangeAdvertFields(placementName, "placement");
    }

    @observable
    isImageValid: boolean = Boolean(
        R.path(["match", "params", "advertId"], this.props),
    );
    @observable
    imageErrorMessage: string = this.props.advertRepository.isVideoInterstitials
        ? ""
        : "Image is required";

    @action.bound
    async onSelectImage(
        event: any,
        placementName: string,
        relaxRuleEnabled: boolean,
        isVideoPreviewImage: boolean,
    ): Promise<void> {
        const imageFile = event.target.files[0];
        const maxDimension = DIMENSION_LIMITS[placementName];
        const {
            errorMessage,
            isError,
            dimension,
        } = await getImageValidationState({
            image: imageFile,
            ...maxDimension,
            imageDimensionsLimitEnabled: this.props.imageDimensionsLimitEnabled,
            relaxRuleEnabled,
        });

        if (isError) {
            this.isImageValid = false;
            this.imageErrorMessage = errorMessage;
            return;
        }

        this.isImageValid = true;
        this.props.advertRepository.onChangeAdvertFields(
            imageFile,
            isVideoPreviewImage ? "videoPreviewImageUrl" : "image",
        );
        this.props.advertRepository.onChangeAdvertFields(
            dimension,
            "imageDimension",
        );
    }

    @computed
    get imageValidationState(): Object {
        return this.isImageValid ? {} : {validationState: "error"};
    }

    @action.bound
    onSetIsVideoInterstitials(value: boolean) {
        const {advertRepository} = this.props;
        advertRepository.onSetIsVideoInterstitials(value);
        if (
            value &&
            !advertRepository.validatedAdvertForm.fields.videoPreviewImageUrl
        ) {
            this.imageErrorMessage = "";
        } else if (!advertRepository.validatedAdvertForm.fields.image) {
            this.imageErrorMessage = "Image is required";
        }
    }

    render() {
        const {
            advertRepository,
            childrenTeamFilterEnabled,
            apiClient,
            getAdverts,
            match,
        } = this.props;

        const formValues = advertRepository.validatedAdvertForm;
        const fieldValues =
            getIn(["validatedAdvertForm", "fields"], advertRepository) || {};
        const placementName = fieldValues.placement;
        const action = fieldValues.action || "none";
        const advertId: string | undefined = R.path(
            ["match", "params", "advertId"],
            this.props,
        );
        const {invokeGetEndpoint} = apiClient;

        return (
            <HooksContainer
                params={match?.params}
                getAdverts={getAdverts}
                formValues={formValues}
                fieldValues={fieldValues}
                placementName={placementName}
                action={action}
                childrenTeamFilterEnabled={childrenTeamFilterEnabled}
                imageValidationState={this.imageValidationState}
                imageErrorMessage={this.imageErrorMessage}
                onSelectImage={this.onSelectImage}
                onChangeAdvertFields={advertRepository.onChangeAdvertFields}
                isFormDisabled={advertRepository.isFormDisabled}
                isFormLoading={advertRepository.isFormLoading}
                onSaveAdverts={advertRepository.onSaveAdverts}
                onBulkChangeAdvertFields={
                    advertRepository.onBulkChangeAdvertFields
                }
                invokeGetEndpoint={invokeGetEndpoint}
                advertId={advertId}
                placement={placementName}
                onSetIsVideoInterstitials={this.onSetIsVideoInterstitials}
                isVideoInterstitials={advertRepository.isVideoInterstitials}
                onSetFullStop={advertRepository.onSetFullStopOnSku}
            />
        );
    }
}

const dispatchSelector = createSelector(
    (dispatch: Function): Function => dispatch,
    (dispatch: Function): Object => ({
        getAdverts: (filter: any, placementFeatures: any): Object =>
            // @ts-ignore
            dispatch(getAdverts(filter, placementFeatures)),
    }),
);

export default connect(stateSelector, dispatchSelector)(AddAdvertContainer);
