import {Circle as MapCircle} from "react-google-maps";
import React, {PureComponent} from "react";

const styles = Object.freeze({
    circleOption: {
        fillColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillOpacity: 0.35,
        clickable: false,
        zIndex: 1,
    },
});

class Circle extends PureComponent {
    constructor(props, context) {
        super(props, context);

        this.onRadiusChanged = this.onRadiusChanged.bind(this);
        this.onCenterChanged = this.onCenterChanged.bind(this);
    }

    onCenterChanged() {
        this.props.onCenterChanged(this.circle.getCenter());
    }

    onRadiusChanged() {
        this.props.onRadiusChanged(Math.round(this.circle.getRadius()));
    }

    render() {
        return (
            <MapCircle
                {...this.props}
                ref={(c) => (this.circle = c)}
                onCenterChanged={this.onCenterChanged}
                onRadiusChanged={this.onRadiusChanged}
                options={styles.circleOption}
                visible
            />
        );
    }
}

export default Circle;
