import React, {memo, createElement} from "react";
import {Redirect, Route} from "react-router-dom";
// @ts-ignore
import NoFeatureAccessPage from "./NoFeatureAccessPage";
// @ts-ignore
import Page from "./Page";
import {UserData} from "../main/SessionContext";

interface PrivateRouteProps {
    component: any;
    path: string;
    requiredFeatureFlag?: string;
    subTitle?: string;
    title: string;
    upgradeMessage?: string;
    featureFlags: any;
    isLoggedIn: boolean;
    userData: UserData;
}

export default memo(function PrivateRoute({
    path,
    requiredFeatureFlag,
    subTitle,
    title,
    upgradeMessage,
    component,
    featureFlags,
    isLoggedIn,
    userData,
    ...rest
}: PrivateRouteProps): JSX.Element {
    const teamNumber =
        userData && userData.activeTeam && userData.activeTeam.teamNumber;
    if (!isLoggedIn) {
        return <Redirect to="/login" />;
    } else if (!teamNumber && path !== "/select-team") {
        return <Redirect to="/select-team" />;
    }
    // @ts-ignore
    const hasFeatureAccess = requiredFeatureFlag
        ? featureFlags[requiredFeatureFlag]
        : true;
    return (
        <Route
            {...rest}
            render={(props) => {
                if (!hasFeatureAccess) {
                    return (
                        <NoFeatureAccessPage
                            subTitle={subTitle}
                            title={title}
                            upgradeMessage={upgradeMessage}
                        />
                    );
                }
                return (
                    <Page {...props} subTitle={subTitle} title={title}>
                        {createElement(component, props)}
                    </Page>
                );
            }}
        />
    );
});
