import PageHeader from "./PageHeader";
import React, {memo} from "react";

interface Props {
    children: JSX.Element;
    subTitle?: string;
    title: string;
}

export default memo(function page({
    title,
    subTitle,
    children,
}: Props): JSX.Element {
    return (
        <div>
            <PageHeader subTitle={subTitle} title={title} />
            <section className="content">{children}</section>
        </div>
    );
});
