import * as R from "ramda";
import {PLATFORM_MAP} from "../../../audienceSelector/constants";
import AudienceSelectorItem from "./AudienceSelectorItem";
import filterListByProp from "../filterListByProp";
import immutable from "immutable";
import Location from "./Location";
import NotificationActioned from "./NotificationActioned";
import React, {memo} from "react";
import Sales from "./Sales";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles({content: {padding: 10}});
const ensureSelectorDefined = (selectors: immutable.Map<string, string>) =>
    selectors || immutable.List([]);

export default memo(function Audience({
    selectors,
}: {
    selectors: immutable.Map<string, string>;
}): JSX.Element {
    const classes = useStyles();
    const userSelectors = ensureSelectorDefined(selectors);
    const filterByEntity = filterListByProp(userSelectors, "entity");

    const userGeo = filterByEntity("UserGeo");
    const sales: any = filterByEntity("Sales");
    const userIdentifierEntity: any = filterByEntity("UserIdentifier");
    const userSegment: any = filterByEntity("UserSegment");
    const notificationActioned =
        filterByEntity("NotificationActioned") || immutable.Map();
    const deviceTypeEntity = filterByEntity("Platform");

    const deviceType =
        //@ts-ignore
        PLATFORM_MAP[deviceTypeEntity.getIn([0, "value"], "all")];

    const userIdentifier = R.join(", ")(
        userIdentifierEntity.getIn([0, "value"], []),
    );

    //@ts-ignore
    const notificationSpecId = notificationActioned.getIn(
        [0, "value", "notificationSpecId"],
        "",
    );

    return (
        <div className={classes.content}>
            <AudienceSelectorItem
                isFirstRow
                label="DEVICE TYPE"
                value={deviceType}
            />
            <Location userGeo={userGeo} />
            {R.not(sales.isEmpty()) && <Sales sales={sales} />}

            {R.not(userIdentifierEntity.isEmpty()) && (
                <AudienceSelectorItem
                    label="USER IDENTIFIER"
                    value={userIdentifier}
                />
            )}
            {R.not(userSegment.isEmpty()) && (
                <AudienceSelectorItem
                    label="USER SEGMENT"
                    value={userSegment.getIn([0, "value"])}
                />
            )}

            {notificationSpecId && (
                <NotificationActioned notificationSpecId={notificationSpecId} />
            )}
        </div>
    );
});
