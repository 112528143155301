export default Object.freeze({
    id: undefined,
    username: undefined,
    authToken: undefined,
    userType: undefined,
}) as Readonly<{
    id: string | undefined;
    username: string | undefined;
    authToken: string | undefined;
    userType: string | undefined;
}>;
