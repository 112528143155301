/*  */

import AdvertRepository from "./advert/AdvertRepository";
import AudienceSelectorRepository from "./audienceSelector/AudienceSelectorRepository";

import BeamAPIClient from "./apiClient/BeamAPIClient";

import MessageAudienceSelection from "./pushNotification/audience/MessageAudienceSelectionRepository";
import PredictiveAudienceRepository from "./pushNotification/audience/PredictiveAudienceRepository";
import PushNotificationRepository from "./pushNotification/PushNotificationRepository";
import TooltipRepository from "./dashboard/default/TooltipRepository";
import UserRepository from "./main/UserRepository";

const userRepository = new UserRepository();
const apiClient = new BeamAPIClient(userRepository);
const predictiveAudienceRepository = new PredictiveAudienceRepository(
    apiClient,
);
const audienceSelectorRepository = new AudienceSelectorRepository(
    apiClient,
    predictiveAudienceRepository,
);
const messageAudienceSelectionRepository = new MessageAudienceSelection(
    audienceSelectorRepository,
);
const advertRepository = new AdvertRepository(apiClient);

userRepository.apiClient = apiClient;

export default {
    advertRepository,
    apiClient,
    tooltipRepository: new TooltipRepository(apiClient),
    userRepository,
    predictiveAudienceRepository,
    messageAudienceSelectionRepository,
    audienceSelectorRepository,
    pushNotificationRepository: new PushNotificationRepository({
        apiClient,
        messageAudienceSelectionRepository,
        audienceSelectorRepository,
    }),
};
