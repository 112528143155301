import React, {memo} from "react";
import {makeStyles} from "@material-ui/styles";
import classNames from "classnames";
import {Alert} from "./AlertProvider";

const Z_INDEX_OVER_NAVBAR = 1100;

interface Styles {
    [key: string]: any;
}

const useStyles = makeStyles<Styles>({
    container: {
        position: "fixed",
        top: 10,
        right: 100,
        zIndex: Z_INDEX_OVER_NAVBAR,
    },
    shared: {
        alignItems: "flex-start",
        borderRadius: 2,
        color: "white",
        cursor: "pointer",
        display: "flex",
        flexWrap: "nowrap",
        marginBottom: 2,
        padding: 10,
        width: 300,
    },
    message: {
        flexGrow: 80,
        paddingRight: 5,
    },
    close: {
        flexGrow: 10,
        textAlign: "right",
    },
    error: {
        backgroundColor: "rgb(255,34,85)",
        border: "1px solid rgb(232,10,61)",
    },
    success: {
        backgroundColor: "rgb(173,221,21)",
        border: "1px solid rgb(143,188,0)",
    },
});

interface Props {
    alerts: Array<Alert>;
    removeAlert: (id: string) => void;
}

export default memo(function Alert({removeAlert, alerts}: Props): JSX.Element {
    const classes = useStyles();

    const containerClass = (type: string) =>
        classNames(classes.shared, classes[type]);

    return (
        <div className={classes.container}>
            {alerts.map(({message, type, id}: Alert, index: number) => (
                <div
                    key={index}
                    onClick={() => removeAlert(id)}
                    className={containerClass(type)}
                >
                    <div className={classes.message}>{message}</div>
                    <div className={classes.close}>✕</div>
                </div>
            ))}
        </div>
    );
});
