import {GoogleMap, Marker, withGoogleMap} from "react-google-maps";
import PropTypes from "prop-types";
import React from "react";

const AsyncGoogleMap = withGoogleMap(({markers, children, ...props}) => (
    <GoogleMap
        {...props}
        defaultCenter={{
            lat: 51.5287718,
            lng: -0.2416801,
        }}
    >
        {markers.map((marker, key) => (
            <Marker key={key} {...marker} />
        ))}
        {children}
    </GoogleMap>
));

const SimpleMap = ({height = "100%", width, ...props}) => (
    <section style={{height: "100%"}}>
        <AsyncGoogleMap
            {...props}
            containerElement={<div style={{height, width}} />}
            mapElement={<div style={{height: `100%`}} />}
        />
    </section>
);

export const propTypes = {
    children: PropTypes.node,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    markers: PropTypes.arrayOf(
        PropTypes.shape({
            position: PropTypes.shape({
                lat: PropTypes.number,
                lng: PropTypes.number,
            }),
            key: PropTypes.string,
            defaultAnimation: PropTypes.number,
        }),
    ).isRequired,
    props: PropTypes.array,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

SimpleMap.propTypes = propTypes;

export default SimpleMap;
