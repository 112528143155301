import React, {memo} from "react";
import {Row, Col} from "react-bootstrap";
import PageHeader from "../../router/PageHeader";

// @ts-ignore
import ChildrenTeamFilter from "../widgets/ChildrenTeamFilter";
// @ts-ignore
import DashboardDateRangePicker from "../widgets/DashboardDateRangePicker";
// @ts-ignore
import AudienceLandscape from "../widgets/AudienceLandscape";
// @ts-ignore
import getHiddenAudienceLandscapeByFeature from "./getHiddenAudienceLandscapeByFeature";
// @ts-ignore
import TotalActiveDevice from "../charts/defaultDashboard/TotalActiveDevice";
// @ts-ignore
import TotalTransactionChart from "../charts/defaultDashboard/TotalTransactionChart";
// @ts-ignore
import AppSalesFigures from "../widgets/AppSalesFigures";
// @ts-ignore
import HistogramChart from "../charts/HistogramChart";
import formatNumber from "../../common/formatNumber";
// @ts-ignore
import TotalPurchasers from "../charts/defaultDashboard/TotalPurchasers";
// @ts-ignore
import ActivePurchasersAverageOrderSize from "../charts/defaultDashboard/ActivePurchasersAverageOrderSize";
// @ts-ignore
import DashboardWidget from "../widgets/DashboardWidget";
// @ts-ignore
import AppDownloads from "../widgets/AppDownloads";
// @ts-ignore
import TotalDownloadChart from "../charts/defaultDashboard/TotalDownloadChart";
import formatDate from "../../date/formatDate";
// @ts-ignore
import safeToJs from "../../immutable/safeToJs";
// @ts-ignore
import AppReviews from "../widgets/AppReviews";
// @ts-ignore
import Box from "../../widget/Box";
// @ts-ignore
import WeatherForecast from "../../widget/WeatherForecast";
import {ChartFilter} from "./DefaultDashboardContainer";
import {STATUSES} from "../widgets/DashboardWidget";

const styles = Object.freeze({
    totalAppTransactAndSalesRow: {
        height: "380px",
        paddingBottom: "10px",
        marginBottom: "10px",
    },
    appTransactCol: {
        height: "100%",
    },
    appSalesCol: {
        height: "100%",
    },
    headerContainer: {
        position: "relative",
        top: "-20px",
    },
    metaTeamFilter: {
        justifyContent: "flex-start",
        display: "flex",
    },
    filterContainer: {
        display: "flex",
        justifyContent: "flex-end",
    },
    marginTop: {
        marginTop: 16,
    },
});

const TOTAL_NUMBER_OF_ORDERS_INFO =
    "Illustrates a breakdown of the total number of orders submitted via your app on specific days of the week vs. the last time period.";
const AVERAGE_BASKET_SIZE_INFO =
    "Illustrates a breakdown of the average basket size value submitted via your app on specific days of the week vs. the last time period.";
const TOTAL_TRANSACTION_VALUE_INFO =
    "Illustrates a breakdown of the total value of the transactions submitted via your app on specific days of the week vs. the last time period.";

const getMonetaryLabel = (tick: number) => `£${formatNumber(tick)}`;
// $FlowIgnore
const toolTipFormat = ({
    legendName,
    barLabel,
    y,
}: {
    legendName: string;
    barLabel: string;
    y: number | string;
}) => `${legendName} \n ${formatDate(barLabel)}: \n £${formatNumber(y)}`;

export interface DateFilterProps {
    onSetChildrenTeamFilter: (filter: number) => void;
    endDate: Date;
    onEndDateSelected: (date: Date) => void;
    onStartDateSelected: (date: Date) => void;
    startDate: Date;
    featureFlags: any;
}
const DashboardFilters = ({
    endDate,
    onEndDateSelected,
    onStartDateSelected,
    startDate,
}: DateFilterProps): JSX.Element => {
    return (
        <div>
            <DashboardDateRangePicker
                endDate={endDate}
                onEndDateSelected={onEndDateSelected}
                onStartDateSelected={onStartDateSelected}
                startDate={startDate}
            />
        </div>
    );
};

export interface FortNightComparisonChartProps {
    widgets: any;
    dashboardFeature: any;
}
const FortNightComparisonCharts = ({
    widgets,
    dashboardFeature,
}: FortNightComparisonChartProps): JSX.Element | null => {
    if (!Boolean(dashboardFeature.fortnightComparisonCharts)) {
        return null;
    }

    return (
        <Row>
            <Col md={4}>
                <HistogramChart
                    axisTitles={{
                        x: "",
                        y: "Number Orders",
                    }}
                    chartData={widgets.getIn([
                        "TotalNumberOfOrders",
                        "widgetData",
                    ])}
                    filter={widgets.getIn([
                        "TotalNumberOfOrders",
                        "activeFilters",
                        0,
                    ])}
                    infoPopOverText={TOTAL_NUMBER_OF_ORDERS_INFO}
                    showEngagement
                    title="Total Number of Orders - This Week vs Last Week"
                    useRecentEngagementData
                    widgetStatus={widgets.getIn([
                        "TotalNumberOfOrders",
                        "widgetStatus",
                    ])}
                />
            </Col>
            <Col md={4}>
                <HistogramChart
                    axisTitles={{
                        x: "",
                        y: "Total Transaction Value (£)",
                    }}
                    chartData={widgets.getIn([
                        "TotalTransactionValue",
                        "widgetData",
                    ])}
                    filter={widgets.getIn([
                        "TotalTransactionValue",
                        "activeFilters",
                        0,
                    ])}
                    infoPopOverText={TOTAL_TRANSACTION_VALUE_INFO}
                    showEngagement
                    tickFormat={getMonetaryLabel}
                    title="Total App Transactions - This Week vs Last Week"
                    toolTipFormat={toolTipFormat}
                    useRecentEngagementData
                    widgetStatus={widgets.getIn([
                        "TotalTransactionValue",
                        "widgetStatus",
                    ])}
                />
            </Col>
            <Col md={4}>
                <HistogramChart
                    axisTitles={{
                        x: "",
                        y: "Average Basket Size (£)",
                    }}
                    chartData={widgets.getIn([
                        "AverageBasketSize",
                        "widgetData",
                    ])}
                    filter={widgets.getIn([
                        "AverageBasketSize",
                        "activeFilters",
                        0,
                    ])}
                    infoPopOverText={AVERAGE_BASKET_SIZE_INFO}
                    showEngagement
                    tickFormat={getMonetaryLabel}
                    title="Average Basket Value - This Week vs Last Week"
                    toolTipFormat={toolTipFormat}
                    useRecentEngagementData
                    widgetStatus={widgets.getIn([
                        "AverageBasketSize",
                        "widgetStatus",
                    ])}
                />
            </Col>
        </Row>
    );
};

const ActivePurchasersChart = ({
    dashboardFeature,
    filterChartProps,
}: {
    dashboardFeature: any;
    filterChartProps: any;
}) => {
    if (!dashboardFeature.activePurchasersChart) {
        return null;
    }

    return (
        <Row>
            <Col md={12}>
                <TotalPurchasers {...filterChartProps} />
            </Col>
        </Row>
    );
};

const ActivePurchasersAverageOrderSizeChart = ({
    dashboardFeature,
    chartFilter,
    getActivePurchasersAverageOrderSize,
}: {
    dashboardFeature: any;
    chartFilter: {
        startDate: Date;
        endDate: Date;
        childrenTeamFilter: number;
    };
    getActivePurchasersAverageOrderSize: (
        chartFilter: ChartFilter,
    ) => Promise<any>;
}) => {
    if (!dashboardFeature.activePurchasersAverageOrderSizeChart) {
        return null;
    }

    return (
        <Row>
            <Col md={12}>
                <ActivePurchasersAverageOrderSize
                    {...chartFilter}
                    dataLoader={getActivePurchasersAverageOrderSize}
                />
            </Col>
        </Row>
    );
};

const hideWidgetStatus = (widgets: any, widgetName: string): boolean =>
    widgets.getIn([widgetName, "widgetStatus"]) === STATUSES.dataLoading ||
    widgets.getIn([widgetName, "widgetStatus"]) === STATUSES.dataUnavailable;

const WithAppMsgOverlay = ({
    children,
    widgets,
}: {
    children: JSX.Element;
    widgets: any;
}) => {
    return (
        <DashboardWidget
            status={widgets.getIn(["AppDownloads", "widgetStatus"])}
        >
            {children}
        </DashboardWidget>
    );
};

const TotalDownloadChartContainer = ({
    dashboardFeature,
    widgets,
    chartFilter,
    getTotalAppDownloads,
}: {
    dashboardFeature: any;
    widgets: any;
    chartFilter: {
        startDate: Date;
        endDate: Date;
        childrenTeamFilter: number;
    };
    getTotalAppDownloads: (chartFilter: ChartFilter) => Promise<any>;
}) => {
    const isAppReviewsEnabled = dashboardFeature.appReviews;

    if (!dashboardFeature.totalDownloadChart) {
        return null;
    }

    return (
        <Row>
            <Col md={isAppReviewsEnabled ? 12 : 9}>
                <TotalDownloadChart
                    {...chartFilter}
                    dataLoader={getTotalAppDownloads}
                />
            </Col>
            {isAppReviewsEnabled ? null : (
                <Col md={3}>
                    <WithAppMsgOverlay widgets={widgets}>
                        <AppDownloads
                            apple={widgets.getIn([
                                "AppDownloads",
                                "widgetData",
                                "apple",
                            ])}
                            googlePlay={widgets.getIn([
                                "AppDownloads",
                                "widgetData",
                                "googlePlay",
                            ])}
                        />
                    </WithAppMsgOverlay>
                </Col>
            )}
        </Row>
    );
};

const AppStoreStatisticsCharts = ({
    dashboardFeature,
    widgets,
}: {
    dashboardFeature: any;
    widgets: any;
}) => {
    if (!dashboardFeature.appReviews) {
        return null;
    }

    return (
        <Row>
            <Col md={8}>
                <WithAppMsgOverlay widgets={widgets}>
                    <AppReviews
                        reviews={safeToJs(
                            widgets.getIn([
                                "AppReviews",
                                "widgetData",
                                "reviews",
                            ]),
                        )}
                    />
                </WithAppMsgOverlay>
            </Col>
            <Col md={4}>
                <WithAppMsgOverlay widgets={widgets}>
                    <AppDownloads
                        apple={widgets.getIn([
                            "AppDownloads",
                            "widgetData",
                            "apple",
                        ])}
                        googlePlay={widgets.getIn([
                            "AppDownloads",
                            "widgetData",
                            "googlePlay",
                        ])}
                    />
                </WithAppMsgOverlay>
            </Col>
        </Row>
    );
};

const WeatherForecastContainer = ({featureFlags}: {featureFlags: any}) => {
    if (!featureFlags.weatherForecast) {
        return null;
    }

    return (
        <Row>
            <Col md={12}>
                <Box title="Weather Forecast">
                    <WeatherForecast color={"#3c8dbc"} />
                </Box>
            </Col>
        </Row>
    );
};

const AudienceLandscapeContainer = ({
    featureFlags,
    chartFilterProps,
}: {
    featureFlags: any;
    chartFilterProps: any;
}) => {
    if (!featureFlags.dashboard) {
        return null;
    }

    return (
        <AudienceLandscape
            {...chartFilterProps}
            hiddenWidgets={getHiddenAudienceLandscapeByFeature(
                featureFlags.dashboard,
            )}
        />
    );
};

const ActiveUsersChart = ({
    featureFlags,
    chartFilterProps,
}: {
    featureFlags: any;
    chartFilterProps: any;
}) => {
    if (!featureFlags.monthlyActiveDevicesChart) {
        return null;
    }

    return (
        <Row>
            <Col md={12}>
                <TotalActiveDevice {...chartFilterProps} />
            </Col>
        </Row>
    );
};

const AppTransactionAndSalesRow = ({
    dashboardFeature,
    chartFilterProps,
    getTotalAppTransactions,
    appSalesFiguresStatus,
    appSalesFiguresData,
}: {
    dashboardFeature: any;
    chartFilterProps: {
        startDate: Date;
        endDate: Date;
        childrenTeamFilter?: number;
    };
    getTotalAppTransactions: (chartFilter: ChartFilter) => Promise<any>;
    appSalesFiguresStatus: any;
    appSalesFiguresData: any;
}) => {
    if (!dashboardFeature.appTransactionAndSalesChart) {
        return null;
    }

    return (
        <Row style={styles.totalAppTransactAndSalesRow}>
            <Col md={7} style={styles.appTransactCol}>
                <TotalTransactionChart
                    {...chartFilterProps}
                    dataLoader={getTotalAppTransactions}
                />
            </Col>
            <Col md={5} style={styles.appSalesCol}>
                <AppSalesFigures
                    widgetStatus={appSalesFiguresStatus}
                    appSalesFigures={appSalesFiguresData}
                />
            </Col>
        </Row>
    );
};

export interface DefaultDashboardProps {
    onSetChildrenTeamFilter: (filter: number) => void;
    endDate: Date;
    onEndDateSelected: (date: Date) => void;
    onStartDateSelected: (date: Date) => void;
    startDate: Date;
    childrenTeamFilter: number;
    appSalesFiguresStatus: any;
    appSalesFiguresData: any;
    widgets: any;
    getTotalAppDownloads: (chartFilter: ChartFilter) => Promise<any>;
    getActivePurchasersAverageOrderSize: (
        chartFilter: ChartFilter,
    ) => Promise<any>;
    getTotalAppTransactions: (chartFilter: ChartFilter) => Promise<any>;
}

const APP_REVIEWS = "AppReviews";
const APP_DOWNLOADS = "AppDownloads";

export interface DefaultDashboardPropsWithHooks extends DefaultDashboardProps {
    featureFlags: any;
}

export default memo(function DefaultDashboard({
    onSetChildrenTeamFilter,
    endDate,
    onEndDateSelected,
    onStartDateSelected,
    startDate,
    getTotalAppTransactions,
    appSalesFiguresStatus,
    appSalesFiguresData,
    widgets,
    getActivePurchasersAverageOrderSize,
    getTotalAppDownloads,
    featureFlags,
    childrenTeamFilter,
}: DefaultDashboardPropsWithHooks) {
    const dashboardFeature = featureFlags.dashboard || {};
    const isMetaTeamenabled = featureFlags.metaTeam;
    const chartFilter = {
        startDate,
        endDate,
        childrenTeamFilter,
    };

    const hideWidgets =
        hideWidgetStatus(widgets, APP_REVIEWS) ||
        hideWidgetStatus(widgets, APP_DOWNLOADS);

    return (
        <div>
            <Row>
                <Col md={8}>
                    <div
                        // @ts-ignore
                        style={styles.headerContainer}
                    >
                        <PageHeader title={"Dashboard"} />
                    </div>
                </Col>

                {isMetaTeamenabled && (
                    <Col md={4} style={styles.metaTeamFilter}>
                        <div>
                            <ChildrenTeamFilter
                                onChangeFilter={onSetChildrenTeamFilter}
                            />
                        </div>
                    </Col>
                )}
            </Row>
            <Row>
                <Col md={8}>
                    <AudienceLandscapeContainer
                        featureFlags={featureFlags}
                        chartFilterProps={chartFilter}
                    />
                </Col>

                <Col md={4} style={styles.marginTop}>
                    <DashboardFilters
                        onSetChildrenTeamFilter={onSetChildrenTeamFilter}
                        endDate={endDate}
                        onEndDateSelected={onEndDateSelected}
                        onStartDateSelected={onStartDateSelected}
                        startDate={startDate}
                        featureFlags={featureFlags}
                    />
                </Col>
            </Row>
            <ActiveUsersChart
                featureFlags={featureFlags}
                chartFilterProps={chartFilter}
            />

            <AppTransactionAndSalesRow
                getTotalAppTransactions={getTotalAppTransactions}
                dashboardFeature={dashboardFeature}
                appSalesFiguresStatus={appSalesFiguresStatus}
                appSalesFiguresData={appSalesFiguresData}
                chartFilterProps={chartFilter}
            />
            <FortNightComparisonCharts
                widgets={widgets}
                dashboardFeature={dashboardFeature}
            />
            <ActivePurchasersChart
                dashboardFeature={dashboardFeature}
                filterChartProps={chartFilter}
            />

            <ActivePurchasersAverageOrderSizeChart
                dashboardFeature={dashboardFeature}
                chartFilter={chartFilter}
                getActivePurchasersAverageOrderSize={
                    getActivePurchasersAverageOrderSize
                }
            />

            <TotalDownloadChartContainer
                dashboardFeature={dashboardFeature}
                widgets={widgets}
                chartFilter={chartFilter}
                getTotalAppDownloads={getTotalAppDownloads}
            />

            {!hideWidgets && (
                <AppStoreStatisticsCharts
                    dashboardFeature={dashboardFeature}
                    widgets={widgets}
                />
            )}

            <WeatherForecastContainer featureFlags={featureFlags} />
        </div>
    );
});
