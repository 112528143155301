/*  */

import LoadingOverlay from "./LoadingOverlay";
import moment from "moment";
import React, {PureComponent} from "react";
import superagent from "../apiClient/httpAgent";

const DEFAULT_ERROR_MESSAGE = "There was an error loading local weather.";
const API_KEY = "27e3f98a6733bdb831c2f8da4da1ab3e";

const styles = Object.freeze({
    weatherToday: {
        textAlign: "center",
        fontSize: "18px",
    },
    todayTemp: {
        fontWeight: "bold",
        fontSize: "32px",
        margin: "0",
    },
    weatherDaily: {
        float: "left",
        width: "20%",
        boxSizing: "border-box",
        padding: "5px",
        textAlign: "center",
    },
    dailyTemp: {
        display: "inline-block",
        width: "35%",
        background: "rgb(204, 204, 204)",
        borderRadius: "5px",
        margin: "0 5px",
        lineHeight: "14px",
        padding: "3px",
    },
    day: {
        textAlign: "center",
        fontWeight: "bold",
    },
    dailyDescription: {
        textTransform: "capitalize",
    },
});

const getErrorMessageFromErrorCode = (errorCode) => {
    switch (errorCode) {
        case 1:
            return "Browser Geolocation disabled. Please enable then refresh the page.";
        default:
            return DEFAULT_ERROR_MESSAGE;
    }
};

const getWeatherIcon = (iconCode) =>
    `http://openweathermap.org/img/w/${iconCode}.png`;

const extractWeatherForecast = (forecasts) => {
    const weatherForecast = {};

    forecasts.forEach((forecast, index) => {
        const date = forecast.dt_txt.split(" ")[0];
        const day = index === 0 ? "Today" : moment.utc(date).format("ddd");

        if (!weatherForecast[date]) {
            weatherForecast[date] = {
                ...forecast.weather[0],
                day,
                temp: forecast.main.temp,
                tempMin: forecast.main.temp_min,
                tempMax: forecast.main.temp_max,
            };
        }
    });

    return weatherForecast;
};

class WeatherForecast extends PureComponent {
    constructor() {
        super();
        this.state = {
            allowRetry: null,
            cityName: "",
            errorMessage: null,
            isLoading: null,
            loadingFailed: null,
            latitude: null,
            longitude: null,
            weatherForecast: {},
        };
    }

    componentDidMount() {
        const isGeolocationSupported =
            window.navigator && window.navigator.geolocation;

        if (isGeolocationSupported) {
            this.initialize();
        }
    }

    initialize() {
        this.setState(
            {
                isLoading: true,
                errorMessage: null,
                loadingFailed: false,
            },
            this.loadGeolocation,
        );
    }

    loadGeolocation() {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                this.setState(
                    {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    },
                    this.loadWeatherForecast,
                );
            },
            (error) => this.geolocationErrorCallback(error),
        );
    }

    geolocationErrorCallback(error) {
        const errorMessage = getErrorMessageFromErrorCode(error.code);
        const allowRetry = error.code !== 1 && error.code !== 2;

        this.setState({
            allowRetry,
            errorMessage,
            isLoading: false,
            loadingFailed: true,
        });
    }

    async loadWeatherForecast() {
        const {latitude, longitude} = this.state;

        if (!latitude || !longitude) return;
        const url = `http://api.openweathermap.org/data/2.5/forecast?lat=${latitude}&lon=${longitude}&appid=${API_KEY}&units=metric`;

        const response = await superagent.get(url);
        const responseJSON = JSON.parse(response.text);

        if (responseJSON.cod === "200") {
            this.setState({
                cityName: `${responseJSON.city.name}`,
                weatherForecast: extractWeatherForecast(responseJSON.list),
                isLoading: false,
            });
        } else {
            this.loadWeatherForecastErrorCallback();
        }
    }

    loadWeatherForecastErrorCallback() {
        this.setState({
            allowRetry: true,
            errorMessage: DEFAULT_ERROR_MESSAGE,
            isLoading: false,
            loadingFailed: true,
        });
    }

    renderRetryButton() {
        return (
            <button
                className="btn btn-primary"
                onClick={() => this.initialize()}
            >
                Retry
            </button>
        );
    }

    renderDailyForecast() {
        const {weatherForecast} = this.state;

        return Object.keys(weatherForecast).map((key, index) => {
            const forecast = weatherForecast[key];

            return (
                <div key={index} style={styles.weatherDaily}>
                    <p style={styles.day}>{forecast.day}</p>
                    <img src={getWeatherIcon(forecast.icon)} />
                    <div>
                        <span style={styles.dailyTemp}>
                            {parseInt(forecast.tempMax, 10)}℃<br />
                            max
                        </span>
                        <span style={styles.dailyTemp}>
                            {parseInt(forecast.tempMin, 10)}℃<br />
                            min
                        </span>
                    </div>
                    <p style={styles.dailyDescription}>
                        {forecast.description}
                    </p>
                </div>
            );
        });
    }

    renderWeatherForecast() {
        const {cityName, weatherForecast} = this.state;
        const today = weatherForecast[Object.keys(weatherForecast)[0]];

        return (
            <div>
                <h4>Weather for {cityName}</h4>
                <section className="row">
                    <div className="col-md-3" style={styles.weatherToday}>
                        <img src={getWeatherIcon(today.icon)} />
                        <p style={styles.todayTemp}>
                            {parseInt(today.temp, 10)}℃
                        </p>
                        <p>{today.description}</p>
                    </div>
                    <div className="col-md-9">
                        <div className="row">{this.renderDailyForecast()}</div>
                    </div>
                </section>
            </div>
        );
    }

    renderLoadedComponent() {
        const {isLoading, loadingFailed, errorMessage, allowRetry} = this.state;
        let componentToRender = (
            <LoadingOverlay>
                <p>Weather Forecast</p>
            </LoadingOverlay>
        );

        if (loadingFailed) {
            componentToRender = (
                <p>
                    {errorMessage}
                    {allowRetry && this.renderRetryButton()}
                </p>
            );
        } else if (loadingFailed === false && isLoading === false) {
            componentToRender = this.renderWeatherForecast();
        }

        return componentToRender;
    }

    render() {
        return <div>{this.renderLoadedComponent()}</div>;
    }
}

export default WeatherForecast;
