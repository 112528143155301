/*  */

import * as R from "ramda";
import {action, autorun, computed, observable, toJS} from "mobx";
import AudienceSelectorRepository from "../../audienceSelector/AudienceSelectorRepository";
import BeamAPIClient from "../../apiClient/BeamAPIClient";
import PredictiveAudienceSize from "./PredictiveAudienceRepository";

class MessageAudienceSelection {
    constructor(audienceSelector) {
        this.audienceSelector = audienceSelector;
        this.predictiveAudienceSize = audienceSelector.predictiveAudienceSize;
        this.apiClient = audienceSelector.apiClient;
    }

    @observable
    audienceSelections = [];
    @observable
    currentAudienceSelectionId = "";

    @action
    onSetCurrentAudienceSelectionId(audienceSelectionId) {
        this.currentAudienceSelectionId = audienceSelectionId;
    }

    @action
    onResetCurrentAudienceSelection() {
        this.currentAudienceSelectionId = "";
        this.predictiveAudienceSize.clearAudienceOverView();
    }

    @action.bound
    async getAudienceSelections() {
        this.audienceSelections = await this.apiClient.invokeGetEndpoint(
            "audienceSelections",
        );
    }

    handleAudienceSelectionChange = autorun(() => {
        const audienceSelection = R.find(
            // eslint-disable-next-line no-underscore-dangle
            (item) => item._id === this.currentAudienceSelectionId,
            toJS(this.audienceSelections),
        );

        if (audienceSelection) {
            const {audienceSelector, predictiveAudienceSize} = this;
            const audienceOverView = audienceSelector.getAudienceOverview(
                audienceSelection,
            );

            audienceSelector.onSetAudienceSelection({
                ...audienceSelection,
                userNotificationActioned: {
                    selectedNotification: {
                        notificationSpecId: R.path(
                            ["userNotificationActioned", "id"],
                            audienceSelection,
                        ),
                    },
                },
            });

            // eslint-disable-next-line no-underscore-dangle
            this.onSetCurrentAudienceSelectionId(audienceSelection._id);
            predictiveAudienceSize.setAudienceOverView(audienceOverView);
            audienceSelector.onGetPotentialReach();
        }
    });

    @action.bound
    onDropDownChange(selected) {
        this.currentAudienceSelectionId = selected;
    }

    @computed
    get selection() {
        // eslint-disable-next-line no-underscore-dangle
        return R.compose(
            R.sortBy(R.prop("text")),
            R.map((item) => ({value: item._id, text: item.name})),
        )(this.audienceSelections);
    }
}

export default MessageAudienceSelection;
