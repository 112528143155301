export default (date?: Date): Date | null => {
    const now = new Date();

    if (!date) {
        return null;
    }

    if (date > now) {
        return now;
    }

    return date;
};
