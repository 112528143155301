import {
    ACTION_VIEW_PRODUCT_LIST,
    ACTION_VIEW_PRODUCT_DETAILS,
    ACTION_ADD_PRODUCT_TO_BASKET,
    ACTION_ADD_PRODUCT_TO_LIST,
    ACTION_NONE,
    ACTION_VIEW_WEBSITE,
} from "./constants";

export default (
    actionLink: string,
): {
    action: string;
    actionValue: string;
} => {
    const actionLinkSplitBySlash = actionLink.split("/");
    const basePath = actionLinkSplitBySlash.length
        ? actionLinkSplitBySlash[1]
        : "";
    const deepLinkValue = actionLinkSplitBySlash[2] ?? "";
    const deepLinkAction = actionLinkSplitBySlash[3] ?? "";

    if (!actionLink) {
        return {action: ACTION_NONE, actionValue: ""};
    }

    if (basePath === "featured-products") {
        const skuKey = "query=";
        const skus = actionLink.substring(
            actionLink.indexOf(skuKey) + skuKey.length,
        );
        const actionValue = skus.replace(/%2C/g, ",");
        return {
            action: ACTION_VIEW_PRODUCT_LIST,
            actionValue,
        };
    }

    if (basePath === "products") {
        return {
            action: ACTION_VIEW_PRODUCT_DETAILS,
            actionValue: actionLinkSplitBySlash[2],
        };
    }

    if (basePath === "cart") {
        const skuKey = "addToBasket=";
        return {
            action: ACTION_ADD_PRODUCT_TO_BASKET,
            actionValue: actionLink.substring(
                actionLink.indexOf(skuKey) + skuKey.length,
            ),
        };
    }

    if (deepLinkValue === "list" && deepLinkAction === "add") {
        return {
            action: ACTION_ADD_PRODUCT_TO_LIST,
            actionValue:
                actionLinkSplitBySlash[actionLinkSplitBySlash.length - 1],
        };
    }

    return {
        action: ACTION_VIEW_WEBSITE,
        actionValue: actionLink,
    };
};
