import React, {memo, ReactNode} from "react";
import useFeatureFlag from "../featureFlags/useFeatureFlag";
import {makeStyles} from "@material-ui/styles";
import EditableText from "./editableText/EditableText";
import hasNotificationCheckedInRow from "./hasNotificationCheckedInRow";
import RemoveGroupButton from "./RemoveGroupButton/index";
import {Notifications} from "./types";
import formatNumber from "../common/formatNumber";

interface HeaderProps {
    name: string;
    notifications: Notifications | any;
    onClick: () => void;
    onFinishGrouping: () => void;
    onEditName: Function;
    rowId: string;
    totalActioned?: number;
    totalOpened?: number;
    totalSent?: number;
}

const useStyles = makeStyles(() => ({
    columnContainer: {
        display: "inline-flex",
    },
    column: {
        margin: "auto 10px",
    },
    columnNumber: {
        margin: "auto 10px",
        fontWeight: "bold",
    },
    container: {
        cursor: "pointer",
    },
}));

const Divider = ({children}: {children: ReactNode}) => {
    const classes = useStyles();
    return <div className={classes.column}>{children}</div>;
};

export default memo(function PanelHeader({
    name,
    onEditName,
    rowId,
    onFinishGrouping,
    notifications,
    onClick,
    totalSent = 0,
    totalOpened = 0,
    totalActioned = 0,
}: HeaderProps) {
    const classes = useStyles();
    const {featureFlags = {}} = useFeatureFlag();
    const showRemoveButton = hasNotificationCheckedInRow(rowId, notifications);
    return (
        <div onClick={onClick} className={classes.container}>
            <EditableText onSubmit={onEditName}>{name}</EditableText>
            <div className="pull-right">
                <div className={classes.columnContainer}>
                    {showRemoveButton && (
                        <Divider>
                            <RemoveGroupButton
                                notifications={notifications}
                                onFinishGrouping={onFinishGrouping}
                                rowId={rowId}
                            />
                        </Divider>
                    )}
                    <Divider>
                        Total Estimated Sent
                        <span className={classes.columnNumber}>
                            {totalSent ? formatNumber(totalSent) : "-"}
                        </span>
                    </Divider>
                    <Divider>
                        Total Opened
                        <span className={classes.columnNumber}>
                            {formatNumber(totalOpened)}
                        </span>
                    </Divider>
                    {featureFlags.messaging.fbNotification && (
                        <Divider>
                            Total Actioned
                            <span className={classes.columnNumber}>
                                {formatNumber(totalActioned)}
                            </span>
                        </Divider>
                    )}
                </div>
            </div>
        </div>
    );
});
