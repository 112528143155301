import {SortableHandle} from "react-sortable-hoc";
import Icon from "react-fa";
import React, {CSSProperties} from "react";
const styles = Object.freeze({
    dragHandle: {
        color: "rgb(51, 51, 51)",
        position: "absolute",
        left: 0,
        top: 0,
        bottom: 0,
        width: "25px",
        background: "rgb(206, 206, 206)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "row-resize",
        opacity: 0.25,
    } as CSSProperties,
});

export default SortableHandle(
    (): JSX.Element => {
        return (
            <div style={styles.dragHandle}>
                <Icon name="reorder" />
            </div>
        );
    },
);
