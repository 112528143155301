// @ts-ignore
import Entity from "@beam/entity";
import getDateRange from "../date/getDateRange";

import {UserSegment, Location, Sales, UserNotificationActioned} from "./types";

const THIRTY_DAYS_BEFORE_TODAY = {
    daysBefore: 30,
    date: new Date(),
};

const TODAY = {
    daysBefore: 0,
    date: new Date(),
};

const CATEGORY_IDS = [""];

export default class AudienceSelection extends Entity {
    childrenTeamFilter = "";
    name: string = "";
    devicePlatform: string = "all";
    numberOfDaysWithoutTransaction: number = 0;
    userSegment: UserSegment = {
        selectedUserSegment: "",
    };

    userIdentifiers: Array<string> = [];
    userNotificationActioned: UserNotificationActioned = {
        selectedNotification: {
            notificationSpecId: "",
        },
        notifications: [],
    };

    sales: Sales = {
        salesSKUPurchaseActions: {
            purchased: {
                skus: "",
                ...getDateRange(TODAY),
            },
            notPurchased: {
                skus: "",
                ...getDateRange(TODAY),
            },
        },
        salesProductSpent: {
            ...getDateRange(THIRTY_DAYS_BEFORE_TODAY),
            categoryIds: CATEGORY_IDS,
            minimumSpend: 0,
        },
    };

    location: Location = {
        wantSpecificLocation: false,
        specificLocation: {
            placeName: "",
            latitude: 52.2854678,
            longitude: -1.5273028,
            maxDistanceInMeters: 500,
        },
        errors: {
            latitude: false,
            longitude: false,
            maxDistanceInMeters: false,
        },
        done: true,
    };

    numberOfDaysInactive: number = 0;
}
