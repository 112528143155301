const TEAM_NUMBERS: {[property: string]: number} = Object.freeze({
    development: 1111111,
    bestway: 63576357,
    rnfdigital: 123456789,
    chefcentral: 1239461283,
    wharfedale: 1837797193,
    singhs: 1261640258,
    yachtbuoy: 1272274563,
    filshill: 2845342921,
    sanderson: 8249939350,
    blakemoreWholesale: 73816062,
    blakemoreFineFoods: 68029618,
    blakemoreFoodservice: 11049931,
    blakemoreTradePartners: 35246785,
    bxr: 35492585,
    abra: 38279217,
    thames: 18238378,
    bestwayFoodservice: 75784898,
    bestPets: 16491769,
    peach: 27302659,
    hbClark: 95278054,
    threePears: 19911517,
    mjBaker: 93436107,
    mummysocial: 25622826,
    kitwave: 38204179,
    chappleAndJenkins: 17916605,
    htDrinks: 464948230,
    brigdestonetpc: 57986873,
    firestonetpc: 65468362,
    confex: 65414626,
    confexDistribution: 13420645,
    giro: 54227376,
    eastAndWest: 80578453,
    iainHill: 12424473,
    beamCommerceDemo: 77777777,
    chadds: 39421668,
    rowan: 59848779,
    woodsFoodservice: 94641411,
    chappleAndJenkinsSanderson: 42191265,
    goodwinsDirect: 15347843,
    chaddsFoodCollection: 83268610,
    kcFoods: 76232755,
    barnard: 10479690,
    paulBentleyWholesale: 19043953,
    beamCommerceDev: 48831040,
    wholesaleDemoAll: 8888888,
    wholesaleDemo: 9999999,
    staxTradeCentres: 79117027,
    brewBroker: 73771692,
    crowndale: 10237169,
    mpos: 85522828,
    rAndIJones: 11925767,
    rowanInternationalEu: 52055004,
    b2bStore1: 22374330,
    b2bStore2: 78703942,
    stlSolutions: 55693710,
    b2bStore4: 44800260,
    b2bStore5: 48276765,
    b2bStore6: 88712371,
    b2bStore7: 54077313,
    b2bStore8: 23340042,
    b2bStore9: 19164696,
    b2bStore10: 43493444,
    b2bStore11: 14414581,
    b2bStore12: 81539621,
    b2bStore13: 99411043,
    b2bStore14: 28021848,
    b2bStore15: 26232088,
    b2bStore16: 80444138,
    qCatering: 79575120,
    chappleAndJenkinsB2bStore: 85348385,
    foodStoreInternational: 62542199,
    paulBurtonDrinksB2C: 98401614,
    b2bStore21: 70378920,
    b2bStore22: 82471488,
    b2bStore23: 72603002,
    b2bStore24: 50230588,
    b2bStore25: 52131288,
    b2bStore26: 92275321,
    b2bStore27: 34977327,
    b2bStore28: 60539682,
    b2bStore29: 11618290,
    b2bStore30: 83603727,
    b2bStore31: 12563345,
    b2bStore32: 93397079,
    b2bStore33: 20855951,
    b2bStore34: 59529326,
    b2bStore35: 16126883,
    b2bStore36: 24622108,
    b2bStore37: 12497702,
    b2bStore38: 64150944,
    b2bStore39: 60517439,
    b2bStore40: 85326686,
    b2bStore41: 24909844,
    b2bStore42: 62374871,
    b2bStore43: 81040945,
    b2bStore44: 18325234,
    b2bStore45: 73480930,
    b2bStore46: 40886035,
    b2bStore47: 93947616,
    b2bStore48: 86852981,
    b2bStore49: 75185592,
    b2bStore50: 99367735,
    b2bStore51: 43160786,
    b2bStore52: 71676902,
    b2bStore53: 12669373,
    b2bStore54: 84145234,
    b2bStore55: 87236045,
    b2bStore56: 91141124,
    b2bStore57: 81460059,
    b2bStore58: 74111927,
    b2bStore59: 90990427,
    b2bStore60: 50867760,
    b2bStore61: 38358670,
    b2bStore62: 13355500,
    b2bStore63: 51668722,
    b2bStore64: 31149730,
    b2bStore65: 75923555,
    b2bStore66: 79545720,
    b2bStore67: 12763603,
    b2bStore68: 11572806,
    b2bStore69: 22461169,
    b2bStore70: 82597564,
    b2bStore71: 27104352,
    b2bStore72: 81390119,
    b2bStore73: 56604587,
    b2bStore74: 22069372,
    b2bStore75: 24984129,
    b2bStore76: 28995524,
    b2bStore77: 51936387,
    b2bStore78: 14880759,
    b2bStore79: 15486005,
    b2bStore80: 43331574,
    b2bStore81: 34107581,
    b2bStore82: 56739834,
    b2bStore83: 60394696,
    b2bStore84: 96291484,
    b2bStore85: 74927221,
    b2bStore86: 63617794,
    b2bStore87: 69119946,
    b2bStore88: 16021824,
    b2bStore89: 49495236,
    b2bStore90: 19269102,
    b2bStore91: 94676211,
    b2bStore92: 68112260,
    b2bStore93: 91447665,
    b2bStore94: 40655929,
    b2bStore95: 93349982,
    b2bStore96: 34985327,
    sugro: 87680452,
    oreillys: 47382992,
});

export const BCP_TEAMS = [
    TEAM_NUMBERS.beamCommerceDemo,
    TEAM_NUMBERS.chadds,
    TEAM_NUMBERS.rowan,
    TEAM_NUMBERS.goodwinsDirect,
    TEAM_NUMBERS.chaddsFoodCollection,
    TEAM_NUMBERS.kcFoods,
    TEAM_NUMBERS.barnard,
    TEAM_NUMBERS.paulBentleyWholesale,
    TEAM_NUMBERS.beamCommerceDev,
    TEAM_NUMBERS.staxTradeCentres,
    TEAM_NUMBERS.brewBroker,
    TEAM_NUMBERS.rAndIJones,
    TEAM_NUMBERS.rowanInternationalEu,
    TEAM_NUMBERS.b2bStore1,
    TEAM_NUMBERS.b2bStore2,
    TEAM_NUMBERS.stlSolutions,
    TEAM_NUMBERS.b2bStore4,
    TEAM_NUMBERS.b2bStore5,
    TEAM_NUMBERS.b2bStore6,
    TEAM_NUMBERS.b2bStore7,
    TEAM_NUMBERS.b2bStore8,
    TEAM_NUMBERS.b2bStore9,
    TEAM_NUMBERS.b2bStore10,
    TEAM_NUMBERS.b2bStore11,
    TEAM_NUMBERS.b2bStore12,
    TEAM_NUMBERS.b2bStore13,
    TEAM_NUMBERS.b2bStore14,
    TEAM_NUMBERS.b2bStore15,
    TEAM_NUMBERS.b2bStore16,
    TEAM_NUMBERS.qCatering,
    TEAM_NUMBERS.chappleAndJenkinsB2bStore,
    TEAM_NUMBERS.foodStoreInternational,
    TEAM_NUMBERS.paulBurtonDrinksB2C,
    TEAM_NUMBERS.b2bStore21,
    TEAM_NUMBERS.b2bStore22,
    TEAM_NUMBERS.b2bStore23,
    TEAM_NUMBERS.b2bStore24,
    TEAM_NUMBERS.b2bStore25,
    TEAM_NUMBERS.b2bStore26,
    TEAM_NUMBERS.b2bStore27,
    TEAM_NUMBERS.b2bStore28,
    TEAM_NUMBERS.b2bStore29,
    TEAM_NUMBERS.b2bStore31,
    TEAM_NUMBERS.b2bStore32,
    TEAM_NUMBERS.b2bStore33,
    TEAM_NUMBERS.b2bStore34,
    TEAM_NUMBERS.b2bStore35,
    TEAM_NUMBERS.b2bStore36,
    TEAM_NUMBERS.b2bStore37,
    TEAM_NUMBERS.b2bStore38,
    TEAM_NUMBERS.b2bStore39,
    TEAM_NUMBERS.b2bStore40,
    TEAM_NUMBERS.b2bStore41,
    TEAM_NUMBERS.b2bStore42,
    TEAM_NUMBERS.b2bStore43,
    TEAM_NUMBERS.b2bStore44,
    TEAM_NUMBERS.b2bStore45,
    TEAM_NUMBERS.b2bStore46,
    TEAM_NUMBERS.b2bStore47,
    TEAM_NUMBERS.b2bStore48,
    TEAM_NUMBERS.b2bStore49,
    TEAM_NUMBERS.b2bStore50,
    TEAM_NUMBERS.b2bStore51,
    TEAM_NUMBERS.b2bStore52,
    TEAM_NUMBERS.b2bStore53,
    TEAM_NUMBERS.b2bStore54,
    TEAM_NUMBERS.b2bStore55,
    TEAM_NUMBERS.b2bStore56,
    TEAM_NUMBERS.b2bStore57,
    TEAM_NUMBERS.b2bStore58,
    TEAM_NUMBERS.b2bStore59,
    TEAM_NUMBERS.b2bStore60,
    TEAM_NUMBERS.b2bStore61,
    TEAM_NUMBERS.b2bStore62,
    TEAM_NUMBERS.b2bStore63,
    TEAM_NUMBERS.b2bStore64,
    TEAM_NUMBERS.b2bStore65,
    TEAM_NUMBERS.b2bStore66,
    TEAM_NUMBERS.b2bStore67,
    TEAM_NUMBERS.b2bStore68,
    TEAM_NUMBERS.b2bStore69,
    TEAM_NUMBERS.b2bStore70,
    TEAM_NUMBERS.b2bStore71,
    TEAM_NUMBERS.b2bStore72,
    TEAM_NUMBERS.b2bStore73,
    TEAM_NUMBERS.b2bStore74,
    TEAM_NUMBERS.b2bStore75,
    TEAM_NUMBERS.b2bStore76,
    TEAM_NUMBERS.b2bStore77,
    TEAM_NUMBERS.b2bStore78,
    TEAM_NUMBERS.b2bStore79,
    TEAM_NUMBERS.b2bStore80,
    TEAM_NUMBERS.b2bStore81,
    TEAM_NUMBERS.b2bStore82,
    TEAM_NUMBERS.b2bStore83,
    TEAM_NUMBERS.b2bStore84,
    TEAM_NUMBERS.b2bStore85,
    TEAM_NUMBERS.b2bStore86,
    TEAM_NUMBERS.b2bStore87,
    TEAM_NUMBERS.b2bStore88,
    TEAM_NUMBERS.b2bStore89,
    TEAM_NUMBERS.b2bStore90,
    TEAM_NUMBERS.b2bStore91,
    TEAM_NUMBERS.b2bStore92,
    TEAM_NUMBERS.b2bStore93,
    TEAM_NUMBERS.b2bStore94,
    TEAM_NUMBERS.b2bStore95,
    TEAM_NUMBERS.b2bStore96,
    TEAM_NUMBERS.sugro,
    TEAM_NUMBERS.crowndale,
];

export default TEAM_NUMBERS;
