import {Notifications} from "./types";

interface CurrentChecked {
    [id: string]: {
        checked: boolean;
        id: string;
        name: string;
    };
}

export default (currentChecked: CurrentChecked): Notifications => {
    const keys = Object.keys(currentChecked);

    return keys.reduce((acc, prev) => {
        if (currentChecked[prev].checked) {
            const {id, name} = currentChecked[prev];

            return acc.concat({
                //@ts-ignore
                _id: id,
                name,
            });
        }

        return acc;
    }, []);
};
