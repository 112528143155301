import {FormControl, FormGroup, HelpBlock} from "react-bootstrap";
import React, {memo, useState, useEffect} from "react";

interface PurchaseActionNumOfDaysProps {
    disabled: boolean;
    numberOfDays: number;
    setNumberOfDays: (numOfDays: number | string) => void;
}

export default memo(function PurchaseActionNumOfDays(
    props: PurchaseActionNumOfDaysProps,
) {
    const [numberOfDays, setNumberOfDays] = useState<number | string>();

    const [isError, setIsError] = useState<
        "error" | "success" | "warning" | null | undefined
    >(null);

    useEffect(() => {
        if (props.numberOfDays < 1 || props.numberOfDays > 30) {
            return setIsError("error");
        }

        return setIsError(null);
    }, [props.numberOfDays]);

    const onChangeNumberOfDays = (
        event: React.FormEvent<FormControl>,
    ): void => {
        const value: number | string = (event.target as HTMLInputElement).value;
        const numValue = parseInt(value, 10);

        if (!numValue || numValue > 30 || numValue < 1) {
            return;
        }

        setNumberOfDays(value);
        props.setNumberOfDays(value);
    };

    return (
        <FormGroup validationState={isError}>
            <FormControl
                disabled={props.disabled}
                max={30}
                min={1}
                onChange={onChangeNumberOfDays}
                type="number"
                value={props.disabled ? undefined : numberOfDays} // eslint-disable-line no-undefined
            />
            <HelpBlock>Days can only be from 1 to 30</HelpBlock>
        </FormGroup>
    );
});
