import {Icon} from "react-fa";
import React, {useState} from "react";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles({
    form: {display: "inline"},
    input: {width: "300px"},
    close: {marginLeft: "8px"},
});

interface Common {
    setEditing: (args: boolean) => any;
}

interface Props {
    children: string;
    onSubmit: Function;
}

interface OnEnableEditingProps extends Common {
    onSubmit: Function | boolean;
}

interface OnFormSubmitProps extends Common, OnEnableEditingProps, Event {
    children?: string | JSX.Element;
    event?: any;
}

interface Event {
    preventDefault?: () => void;
    stopPropagation?: () => void;
    target?: {text: {value: string}};
}

export const onEnableEditing = ({
    onSubmit,
    setEditing,
}: OnEnableEditingProps) => {
    if (typeof onSubmit !== "function") {
        return;
    }
    setEditing(true);
};

export const onFormSubmit = async ({
    onSubmit,
    children,
    setEditing,
    event,
}: OnFormSubmitProps): Promise<any> => {
    event.preventDefault();
    event.stopPropagation();

    const value = event.target.text.value;
    if (typeof onSubmit !== "function" || !value.length) {
        return;
    }

    if (value === children) {
        setEditing(false);
        return;
    }
    try {
        await onSubmit(value);
        setEditing(false);
    } catch (e) {
        return;
    }
};

export default function EditableText({children, onSubmit}: Props): JSX.Element {
    const [editing, setEditing] = useState(false);
    const classes = useStyles();
    if (editing) {
        return (
            <form
                onSubmit={(event) =>
                    onFormSubmit({onSubmit, children, setEditing, event})
                }
                className={classes.form}
            >
                <input
                    defaultValue={children}
                    name="text"
                    className={classes.input}
                />
                <Icon
                    name="times-circle-o"
                    onClick={() => setEditing(false)}
                    className={classes.close}
                />
            </form>
        );
    }
    return (
        <span onClick={() => onEnableEditing({onSubmit, setEditing})}>
            {children}
        </span>
    );
}
