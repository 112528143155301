import React, {memo, CSSProperties} from "react";

interface Props {
    children: Array<JSX.Element> | JSX.Element | string | number;
    style?: CSSProperties;
    className?: any;
}

const styles = Object.freeze({td: {verticalAlign: "middle"}});

export default memo(function tableData({
    children,
    style,
    className = "",
}: Props): Array<JSX.Element> | JSX.Element | any {
    return (
        <td
            className={className}
            style={{
                ...styles.td,
                ...style,
            }}
        >
            {children}
        </td>
    );
});
