import * as R from "ramda";
import {addSchemeToUrl} from "../common/processUrlScheme";
import {getIn} from "../common/ramdaDataUtils";
// @ts-ignore
import {linkActions} from "./PushNotification";
import getNotificationLinkUrl, {
    getLinkActionType,
} from "./getNotificationLinkUrl";
// @ts-ignore
import makeUserSelector from "../audienceSelector/makeUserSelector";
import {Moment} from "moment";
import {RichPushNotifications} from "./types";
import {Location} from "../audienceSelector/types";

const makeServerDateCondition = (momentDate: Moment): Object => {
    if (!momentDate) {
        return {};
    }

    return {
        property: "DateTime/ServerDate",
        comparison: "after",
        value: momentDate.toISOString(),
    };
};

const makeGlobalRule = (scheduleMomentDate: Moment): Object => {
    if (!scheduleMomentDate) {
        return {};
    }

    return {
        globalRule: {
            match: "ANY",
            conditions: [makeServerDateCondition(scheduleMomentDate)],
        },
    };
};

const makeUrlToOpen = R.ifElse(
    R.isNil,
    R.always({}),
    R.compose(R.objOf("urlToOpen"), addSchemeToUrl),
);

// eslint-disable-next-line complexity
const makeFbNotification = (fbNotification: any) => {
    if (!fbNotification || !fbNotification.wantFbNotification) {
        return {};
    }

    const linkAction: string = getIn(["linkAction", "value"], fbNotification);
    const productSku: string = getIn(["productSku", "value"], fbNotification);
    const buttonUrl: string = getIn(["buttonUrl", "value"], fbNotification);
    const dismissButtonText = getIn(
        ["dismissButtonText", "value"],
        fbNotification,
    );
    const {width, height} = getIn(
        ["imageFile", "dimension"],
        fbNotification,
    ) || {width: 0, height: 0};

    const dismissAction = {content: {text: dismissButtonText}};

    const primaryAction = {
        content: {text: getIn(["buttonText", "value"], fbNotification)},
        url: getNotificationLinkUrl({linkAction, productSku, buttonUrl}),
        actionType: getLinkActionType(linkAction),
        actionParams: productSku,
    };

    const actions =
        linkAction === linkActions.NONE
            ? [dismissAction]
            : [dismissAction, primaryAction];

    return {
        fbNotification: {
            hero: {
                background: {
                    url: getIn(["image", "value"], fbNotification) || "",
                    width,
                    height,
                },
            },
            body: {content: {text: getIn(["body", "value"], fbNotification)}},
            actions,
        },
    };
};

const getPayloadWithDefault = (field: string, value?: string) =>
    value ? {[field]: value} : {};

interface StateToPayload {
    schedule?: {
        value: any;
    };
    message: {
        value: string;
    };
    audience?: {
        location: Location;
    };
    fbNotification?: RichPushNotifications;
    notificationType?: string;
    groupId?: string;
    saveAsDraft?: any;
    audienceSelectionId?: string;
    associatedCampaignId?: string;
}

export default ({
    schedule,
    message,
    audience,
    fbNotification,
    notificationType,
    groupId,
    saveAsDraft,
    audienceSelectionId,
    associatedCampaignId,
}: StateToPayload): Object => {
    const globalRule = schedule ? makeGlobalRule(schedule.value) : {};
    // @ts-ignore
    const urlToOpen = makeUrlToOpen(getIn(["urlToOpen", "value"], message));
    const description = getIn(["description", "value"], message);

    const userSelector = makeUserSelector(audience);
    const fbNotificationSelector = makeFbNotification(fbNotification);
    const notificationTypeSelector = getPayloadWithDefault(
        "notificationType",
        notificationType,
    );
    const groupIdSelector = getPayloadWithDefault("groupId", groupId);
    const saveAsDraftSelector = getPayloadWithDefault(
        "saveAsDraft",
        saveAsDraft,
    );
    const audienceSelection = getPayloadWithDefault(
        "audienceSelectionId",
        audienceSelectionId,
    );
    const associatedCampaign = getPayloadWithDefault(
        "associatedCampaignId",
        associatedCampaignId,
    );

    return {
        ...(description && {description}),
        ...globalRule,
        ...urlToOpen,
        ...userSelector,
        ...fbNotificationSelector,
        message: message.value,
        ...notificationTypeSelector,
        ...groupIdSelector,
        ...saveAsDraftSelector,
        ...audienceSelection,
        ...associatedCampaign,
    };
};

export const forAudienceEstimate = R.compose(
    R.assoc("forAudienceEstimate", true),
    // @ts-ignore
    R.assoc("message", ""),
);
