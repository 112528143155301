import * as R from "ramda";
import {ChartValue} from "./types";

const mapLabel: any = R.map(R.prop("label"));
const mapValue: any = R.map(R.prop("value"));

type Result = {
    axisLabels: Array<string>;
    datasets: Array<Array<ChartValue>>;
};

export default (apiResponse: Array<ChartValue>): Result => {
    if (!apiResponse) {
        return {
            axisLabels: [],
            datasets: [],
        };
    }

    const axisLabels = mapLabel(apiResponse);
    const datasets = mapValue(apiResponse);

    return {
        axisLabels,
        datasets,
    };
};
