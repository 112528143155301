import {createContext} from "react";

export const DEFAULT_USER = {
    activeTeam: {
        name: "",
        teamNumber: 0,
    },
    emailAddress: "",
    firstName: "",
    password: "",
    surname: "",
    teams: [],
};

export interface ActiveTeam {
    name: string;
    teamNumber: number;
}
export interface UserData {
    activeTeam: ActiveTeam | undefined;
    emailAddress: string;
    firstName: string;
    password: string;
    surname: string;
    teams: Array<any>;
}

export interface LoginResponse {
    success: boolean;
    activeTeam: ActiveTeam | undefined;
    nextRoute: string;
}

export interface SessionContextParams {
    isLoggedIn: boolean;
    userData: UserData;
    login: (emailAddress: string, password: string) => Promise<LoginResponse>;
    logout: () => void;
    setUserData: (userData: UserData) => void;
}

export default createContext<SessionContextParams>({
    isLoggedIn: false,
    userData: DEFAULT_USER,
    setUserData: () => {},
    login: () =>
        Promise.resolve({success: false, activeTeam: undefined, nextRoute: ""}),
    logout: () => {},
});
