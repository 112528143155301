import * as R from "ramda";
import {Col, Row} from "react-bootstrap";
import {getIn} from "../common/ramdaDataUtils";
import {PLATFORM_MAP} from "./constants";
import Box from "../widget/Box";
import DetailRow from "../widget/DetailRow";
import React, {memo} from "react";

import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(() => {
    return {
        content: {
            padding: 20,
        },
    };
});

const MESSAGE_PATH = "#/message/";
const MESSAGE = "View message";

interface Props {
    selection: any;
    location: string;
    userNotificationActioned: {
        id: number;
    };
    spent: string;
    featureFlags: any;
}

interface FieldProps {
    label: string;
    children: JSX.Element | string | number;
    featureFlagEnabled?: boolean;
    isFirstRow?: boolean;
}

const Field = ({
    label,
    children,
    featureFlagEnabled,
    isFirstRow,
}: FieldProps): JSX.Element | null => {
    return featureFlagEnabled ? (
        <Row>
            {isFirstRow ? null : <hr />}
            <Col md={8}>
                <DetailRow label={label}>{children || "N/A"}</DetailRow>
            </Col>
        </Row>
    ) : null;
};

export default memo(function AudienceSelectionDetail({
    selection,
    location,
    userNotificationActioned,
    spent,
    featureFlags,
}: Props): JSX.Element {
    const classes = useStyles();

    const isFeatureFlagEnabled = (
        featureFlags: any,
        flagName: string,
    ): boolean => {
        return Boolean(
            R.path(["messaging", "audience", flagName], featureFlags),
        );
    };

    return (
        <div>
            <Box
                removeBodyPadding
                removeHeaderBorder
                solid
                styleState="primary"
                title={selection.name}
                infoPopOverText=""
            >
                <div className={classes.content}>
                    <Field
                        label="DEVICE PLATFORM:"
                        children={
                            PLATFORM_MAP[selection.devicePlatform || "all"]
                        }
                        featureFlagEnabled={isFeatureFlagEnabled(
                            featureFlags,
                            "devicePlatform",
                        )}
                        isFirstRow={true}
                    />

                    <Field
                        label="MEMBER APP"
                        children={selection.teamName || "All"}
                        featureFlagEnabled={isFeatureFlagEnabled(
                            featureFlags,
                            "childrenTeamFilter",
                        )}
                    />

                    <Field
                        label="LOCATION:"
                        children={location}
                        featureFlagEnabled={isFeatureFlagEnabled(
                            featureFlags,
                            "location",
                        )}
                    />

                    <Field
                        label="INACTIVE USERS FOR X NUMBER OF DAYS:"
                        children={selection.numberOfDaysInactive}
                        featureFlagEnabled={isFeatureFlagEnabled(
                            featureFlags,
                            "inactiveUsers",
                        )}
                    />

                    <Field
                        label="HAS PURCHASED SKU:"
                        //@ts-ignore
                        children={getIn(
                            [
                                "sales",
                                "salesSKUPurchaseActions",
                                "purchased",
                                "skus",
                            ],
                            selection,
                        )}
                        featureFlagEnabled={isFeatureFlagEnabled(
                            featureFlags,
                            "sales",
                        )}
                    />

                    <Field
                        label="HAS NOT PURCHASED SKU:"
                        //@ts-ignore
                        children={getIn(
                            [
                                "sales",
                                "salesSKUPurchaseActions",
                                "notPurchased",
                                "skus",
                            ],
                            selection,
                        )}
                        featureFlagEnabled={isFeatureFlagEnabled(
                            featureFlags,
                            "sales",
                        )}
                    />

                    <Field
                        label="HAS SPENT IN LAST 30 DAYS:"
                        children={spent}
                        featureFlagEnabled={isFeatureFlagEnabled(
                            featureFlags,
                            "sales",
                        )}
                    />

                    <Field
                        label="USERS WHO DID NOT PLACE ORDER IN X DAYS:"
                        children={selection.numberOfDaysWithoutTransaction}
                        featureFlagEnabled={isFeatureFlagEnabled(
                            featureFlags,
                            "userWithoutOrderInDays",
                        )}
                    />

                    <Field
                        label="USER SEGMENT:"
                        //@ts-ignore
                        children={getIn(
                            ["userSegment", "selectedUserSegment"],
                            selection,
                        )}
                        featureFlagEnabled={isFeatureFlagEnabled(
                            featureFlags,
                            "userSegment",
                        )}
                    />

                    <Field
                        label="USER IDENTIFIERS:"
                        children={R.join(",", selection.userIdentifiers || [])}
                        featureFlagEnabled={isFeatureFlagEnabled(
                            featureFlags,
                            "userIdentifier",
                        )}
                    />

                    <Field
                        label="USERS WHO DID NOT ACTION EXISTING CAMPAIGN:"
                        children={
                            userNotificationActioned.id && (
                                <a
                                    href={`${MESSAGE_PATH}${userNotificationActioned.id}`}
                                >
                                    {MESSAGE}
                                </a>
                            )
                        }
                        featureFlagEnabled={isFeatureFlagEnabled(
                            featureFlags,
                            "userNotificationNotActioned",
                        )}
                    />
                </div>
            </Box>
        </div>
    );
});
