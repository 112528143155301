import {connect} from "react-redux";
import {createSelector} from "reselect";
import EventsListView, {NotifyEventsObject} from "./EventsListView";
import React, {useEffect, useState} from "react";
//@ts-ignore
import {getNotificationDetails} from "../eventHandlers";

function OpenedEventsView({match, onGetNotificationDetails}: any): JSX.Element {
    const [notification, setNotification] = useState<{
        message: string;
        openedEvents: Array<NotifyEventsObject>;
    } | null>(null);
    const [isError, setIsError] = useState(false);

    const id = match.params.id;
    const teamNumber = match.params.teamNumber;

    const getNotification = async (id: string, teamNumber?: string) => {
        const {success, results} = await onGetNotificationDetails(
            id,
            teamNumber,
        );

        setNotification(results);
        setIsError(!success);
    };

    useEffect(() => {
        getNotification(id, teamNumber);
    }, [id, teamNumber]);

    if (isError || !notification) {
        return <div />;
    }

    return (
        <div>
            <EventsListView
                notifMessage={notification.message}
                notifEvents={notification && notification.openedEvents}
                eventsTableColumnNames={["User", "Opened Times"]}
                eventsTableTitle={"Opened"}
            />
        </div>
    );
}

const dispatchSelector = createSelector(
    (dispatch: Function): Function => dispatch,
    (dispatch: Function): Object => ({
        onGetNotificationDetails: (
            notificationId: string,
            selectedTeamNumber: string,
        ): Object =>
            dispatch(
                getNotificationDetails({
                    notificationId,
                    selectedTeamNumber,
                    shouldGetOpenedEvents: true,
                }),
            ),
    }),
);

export default connect(null, dispatchSelector)(OpenedEventsView);
