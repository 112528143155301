import {inject, observer} from "mobx-react";
import React, {Component, memo, useEffect, useState} from "react";
import AudienceSelectionList, {
    SORT_OPTIONS,
    PLACEHOLDER,
} from "./AudienceSelectionList";
import {FeatureFlags} from "../message/types";
import AudienceSelectorRepository from "./AudienceSelectorRepository";
import EmptyList from "../common/EmptyList";
import LoadingWidget from "../widget/LoadingWidget";
import * as R from "ramda";
import sortList from "../common/sortList";
import useFeatureFlag from "../featureFlags/useFeatureFlag";
import useAlert from "../alert/useAlert";

interface Props {
    featureFlags: FeatureFlags;
    audienceSelectorRepository: AudienceSelectorRepository;
}

interface AudienceSelectionListContainerProps {
    onGetAudienceSelections: () => Promise<{success: boolean; results: any}>;
    onDeleteAudienceSelections: (_id: string) => Promise<{success: true}>;
}

const HooksContainer = memo(function AudienceSelectionContainerWithHooks(
    props: AudienceSelectionListContainerProps,
) {
    const [audienceSelections, setAudienceSelections] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [activeSort, setActiveSort] = useState(SORT_OPTIONS.createdAt);
    const {featureFlags} = useFeatureFlag();
    const {addErrorAlert, addSuccessAlert} = useAlert();

    useEffect(() => {
        fetchAudienceSelections();
    }, []);

    const fetchAudienceSelections = async () => {
        setIsLoading(true);
        try {
            const {
                results: unfilteredAudienceSelections,
                success,
            } = await props.onGetAudienceSelections();

            if (!success) {
                addErrorAlert(
                    "An error occured while fetching audience selections. Please try again",
                );
            }

            setIsLoading(false);

            const byCreatedAt = R.descend(R.prop("createdAt"));

            setAudienceSelections(
                R.sort(byCreatedAt, unfilteredAudienceSelections),
            );
        } catch (exception) {
            setIsLoading(false);
            setIsError(true);
        }
    };

    const onDelete = async (_id: string) => {
        setIsLoading(true);

        try {
            const results = await props.onDeleteAudienceSelections(_id);
            if (results) {
                if (results.success) {
                    addSuccessAlert("Successfully deleted audience selections");
                    fetchAudienceSelections();
                } else {
                    addErrorAlert(
                        "An error occured while deleting audience selections. Please try again",
                    );
                }
            }

            setIsLoading(false);
        } catch (exception) {
            setIsLoading(false);
            setIsError(true);
        }
    };

    const isEmpty = audienceSelections.length === 0;
    let sortedAudienceSelection = audienceSelections;

    if (!isEmpty) {
        const isSortAscending = activeSort !== SORT_OPTIONS.createdAt;
        const sortingSequence = isSortAscending ? "ascend" : "descend";
        const sortAudienceSelections: any = sortList(
            activeSort,
            sortingSequence,
            PLACEHOLDER,
        );

        sortedAudienceSelection = sortAudienceSelections(audienceSelections);
    }

    return (
        <LoadingWidget
            emptyList={
                isEmpty && (
                    <EmptyList
                        message="You haven't created any audiences yet"
                        subMessage="The audiences you create will be shown here"
                    />
                )
            }
            errorLabel="Audiences are not available at this time."
            isDone={!isLoading}
            isError={isError}
            loadingLabel="Loading Audiences..."
        >
            <AudienceSelectionList
                audienceSelection={sortedAudienceSelection}
                activeSort={activeSort}
                setActiveSort={setActiveSort}
                onDelete={onDelete}
                featureFlags={featureFlags}
            />
        </LoadingWidget>
    );
});

@inject("audienceSelectorRepository")
@observer
export default class AudienceSelectionListContainer extends Component<Props> {
    render() {
        const {
            onGetAudienceSelections,
            onDeleteAudienceSelections,
        } = this.props.audienceSelectorRepository;

        return (
            <HooksContainer
                onGetAudienceSelections={onGetAudienceSelections}
                onDeleteAudienceSelections={onDeleteAudienceSelections}
            />
        );
    }
}
