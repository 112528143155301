import moment from "moment";

export default (purchaseAction: {fromDate: string; toDate: string}) => {
    if (!purchaseAction || !purchaseAction.fromDate || !purchaseAction.toDate) {
        return null;
    }

    const fromDate: string = purchaseAction.fromDate;
    const toDate: string = purchaseAction.toDate;

    // eslint-disable-next-line moment-utc/no-moment-without-utc
    if (!moment(fromDate, "YYYY-MM-DD").isValid()) {
        throw new Error("From Date format must be YYYY-MM-DD");
    }

    // eslint-disable-next-line moment-utc/no-moment-without-utc
    if (!moment(toDate, "YYYY-MM-DD").isValid()) {
        throw new Error("To Date format must be YYYY-MM-DD");
    }

    const fromDateVal = moment.utc(purchaseAction.fromDate);
    const toDateVal = moment.utc(purchaseAction.toDate);

    return toDateVal.diff(fromDateVal, "days") + 1;
};
