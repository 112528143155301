import React, {memo} from "react";
import {Typography, makeStyles} from "@material-ui/core";
import formatNumber from "../common/formatNumber";
import formatDateTime from "../date/formatDateTime";
import messageStatus from "./messageStatus";
import Image from "react-graceful-image";
import {Table} from "react-bootstrap";
import Checkbox from "../widget/Checkbox";
import classnames from "classnames";
import LinkButton from "../common/buttons/LinkButton";
import RemoveItemButton from "../common/buttons/RemoveItemButton";
import useFeatureFlag from "../featureFlags/useFeatureFlag";

const useStyles = makeStyles(() => {
    return {
        table: {
            marginTop: 10,
            width: "100%",
        },
        button: {
            margin: 10,
        },
        text: {
            fontSize: 14,
        },
        imageContainer: {
            textAlign: "center",
        },
        titleRow: {
            color: "rgb(68, 68, 68)",
            fontWeight: "bold",
        },
        addButton: {
            width: "80px",
        },
        headerText: {
            color: "rgb(255,255,255)",
            verticalAlign: "middle",
            cursor: "pointer",
            padding: "10px",
        },
        headerContainer: {
            backgroundColor: "rgb(70,70,70)",
        },
        image: {
            height: 80,
            width: 80,
            objectFit: "contain",
        },
        sortActive: {
            color: "rgb(173, 221, 21)",
            cursor: "pointer",
            marginTop: 4,
        },
        addButtonContainer: {
            textAlign: "center",
        },
        tableData: {
            verticalAlign: "middle",
            textAlign: "center",
        },
        sortInactive: {
            marginTop: 4,
            cursor: "pointer",
        },
    };
});

export const SORT_OPTIONS = {
    image: "image",
    title: "message",
    description: "description",
    estimatedSent: "numberOfNotificationsPublishedToSns",
    numberOfOpens: "opened",
    actioned: "computedActioned",
    sendTime: "sendTime",
    status: "status",
};

interface FbNotification {
    actions: Array<string>;
    hero: {
        background: {
            url: string;
        };
    };
}

export interface Notification {
    _id: string;
    actioned: number;
    createdAt: string;
    message: string;
    numberOfNotificationsPublishedToSns: number;
    numberOfOpens: number;
    status: string;
    teamName: string;
    teamNumber: number;
    sendTime: string;
    memberApp?: string;
    fbNotification?: FbNotification;
    imageUrl?: string;
    opened: number;
    computedActioned: number;
    description: string;
}

const MESSAGE_PATH = "#/message/";

const getMessagePath = ({
    id,
    groupId,
    status,
}: {
    id: string;
    groupId?: string;
    status: string;
}) => {
    const basePath = `${MESSAGE_PATH}${id}`;

    if (status !== "DRAFT") {
        return basePath;
    }

    return `${basePath}/edit/${groupId}`;
};

const TableData = ({
    children,
    className,
    align = "left",
    width,
}: {
    children: JSX.Element | string;
    className?: any;
    align?: "center" | "left" | "right";
    width?: string | number;
}) => {
    return (
        <td
            style={{verticalAlign: "middle", textAlign: align, width}}
            className={className}
        >
            {children}
        </td>
    );
};

export interface CurrentChecked {
    [id: string]: {
        checked: boolean;
        id: string;
        name: string;
    };
}

interface MessageRowProps {
    actionedCount: number;
    checked: CurrentChecked;
    featureFlags: any;
    groupId?: string;
    id: string;
    onChecked: (id: string, title: string) => void;
    onDeleteMessagePressed: (id: string) => void;
    openCount: number;
    sendTime: string;
    sentCount: number;
    status: string;
    title: string;
    type?: string;
    imageUrl?: string;
    fbNotification?: FbNotification;
    teamNumber: number;
    memberApp: string;
    opened: number;
    computedActioned: number;
    description: string;
}

const RowTable = ({item}: {item: MessageRowProps}, key: number) => {
    const {
        checked = {},
        groupId,
        id,
        teamNumber,
        onChecked,
        onDeleteMessagePressed,
        openCount,
        status,
        sentCount,
        sendTime,
        title,
        imageUrl,
        memberApp,
        fbNotification,
        featureFlags,
        opened,
        computedActioned,
        actionedCount,
        description,
    } = item;

    const isChecked = checked[id] ? checked[id].checked : false;
    const classes = useStyles();
    const columnHeaderStyle = classnames(
        "message-table-title",
        classes.titleRow,
    );
    return (
        <tr key={key}>
            {featureFlags.messageList &&
                featureFlags.messageList.messageGrouping && (
                    <TableData>
                        <Checkbox
                            checked={isChecked}
                            onChecked={() => onChecked(id, title)}
                        />
                    </TableData>
                )}
            <TableData className={classes.imageContainer} align="center">
                {<Image src={imageUrl} className={classes.image} />}
            </TableData>
            <TableData width={"30%"}>
                <a
                    className={columnHeaderStyle}
                    href={getMessagePath({id, groupId, status})}
                >
                    {title ? title : "(Untitled)"}
                </a>
            </TableData>
            <TableData width={"30%"}>
                {description ? description : ""}
            </TableData>
            <TableData align="center" width={"10%"}>
                {formatNumber(sentCount) || "-"}
            </TableData>
            <TableData align="center" width={"10%"}>
                <a
                    className="message-table-title"
                    href={`${MESSAGE_PATH}${id}/openedEvents/${teamNumber}`}
                >
                    <Typography variant="inherit" className={classes.titleRow}>
                        {`${formatNumber(openCount)} (${opened}%)`}
                    </Typography>
                </a>
            </TableData>
            {featureFlags.messaging && featureFlags.messaging.fbNotification && (
                <TableData align="center" width={"10%"}>
                    <a
                        className="message-table-title"
                        href={`${MESSAGE_PATH}${id}/actionedEvents/${teamNumber}`}
                    >
                        <Typography
                            variant="inherit"
                            className={classes.titleRow}
                        >
                            {!fbNotification ||
                            fbNotification.actions.length === 1
                                ? "N/A"
                                : `${formatNumber(
                                      actionedCount,
                                  )} (${computedActioned}%)`}
                        </Typography>
                    </a>
                </TableData>
            )}
            <TableData width={"10%"}>
                {formatDateTime(sendTime, "N/A")}
            </TableData>
            {featureFlags.pushNotificationMemberApp && (
                <TableData>
                    {memberApp === "Confex Distribution" ? "Confex" : memberApp}
                </TableData>
            )}
            <TableData align="center" width={"10%"}>
                {messageStatus[status]}
            </TableData>
            <TableData align="center">
                <div>
                    <RemoveItemButton
                        onClick={() => onDeleteMessagePressed(id)}
                    />
                    {featureFlags.duplicatePushNotification ? (
                        // @ts-ignore
                        <LinkButton
                            iconName="clone"
                            relativeDestination={`${id}/duplicate/${groupId}`}
                            size="sm"
                            className={classes.button}
                            title="Duplicate"
                            type="info"
                        />
                    ) : null}
                </div>
            </TableData>
        </tr>
    );
};

interface Props {
    currentChecked: CurrentChecked;
    groupId: string;
    featureFlags: any;
    notifications: Array<Notification>;
    onDeleteMessagePressed: (item: string) => void;
    onChecked: (id: string, title: string) => void;
    activeSort: string;
    onChangeActiveSort: (item: string) => void;
}

export default memo(function MessageTable({
    currentChecked,
    groupId = "",
    notifications,
    onDeleteMessagePressed,
    onChecked,
    activeSort,
    onChangeActiveSort,
}: Props): JSX.Element {
    const classes = useStyles();
    const {featureFlags = {}} = useFeatureFlag();

    const HeaderText = ({
        icon,
        text,
        sortName,
        width,
    }: {
        icon?: string;
        text?: string;
        sortName?: string;
        width?: string | number;
    }) => {
        const iconClassname = classnames(
            `fa fa-${icon} pull-right`,
            {[classes.sortActive]: activeSort === sortName},
            {[classes.sortInactive]: activeSort !== sortName},
        );
        return (
            <th
                onClick={() => sortName && onChangeActiveSort(sortName)}
                className={classes.headerText}
                style={{width, verticalAlign: "middle"}}
            >
                <i className={iconClassname} />
                {text}
            </th>
        );
    };

    return (
        <Table bordered hover responsive striped className={classes.table}>
            <thead className={classes.headerContainer}>
                <tr>
                    {featureFlags.messageList &&
                        featureFlags.messageList.messageGrouping && (
                            <HeaderText />
                        )}
                    <HeaderText text="Image" icon="image" />
                    <HeaderText
                        text="Title"
                        icon="envelope-o"
                        sortName={SORT_OPTIONS.title}
                    />
                    <HeaderText
                        text="Description"
                        icon="align-left"
                        sortName={SORT_OPTIONS.description}
                    />
                    <HeaderText
                        text="Estimated Sent"
                        icon="send"
                        sortName={SORT_OPTIONS.estimatedSent}
                    />
                    <HeaderText
                        text="Opened"
                        icon="eye"
                        sortName={SORT_OPTIONS.numberOfOpens}
                    />
                    <HeaderText
                        text="Actioned"
                        icon="mouse-pointer"
                        sortName={SORT_OPTIONS.actioned}
                    />
                    <HeaderText
                        text="Send Time"
                        icon="calendar"
                        sortName="sendTime"
                    />
                    {featureFlags.pushNotificationMemberApp && (
                        <HeaderText text="Member App" icon="users" />
                    )}
                    <HeaderText
                        text="Status"
                        icon="circle"
                        sortName={SORT_OPTIONS.status}
                    />
                    <th className={classes.addButtonContainer}>
                        {
                            // @ts-ignore
                            <LinkButton
                                relativeDestination={`create/${groupId}`}
                                className={classes.addButton}
                            />
                        }
                    </th>
                </tr>
            </thead>
            <tbody>
                {notifications.map(
                    ({
                        _id,
                        actioned,
                        message,
                        numberOfOpens,
                        teamName,
                        teamNumber,
                        numberOfNotificationsPublishedToSns,
                        sendTime,
                        status,
                        fbNotification,
                        opened,
                        computedActioned,
                        description,
                    }: Notification) => {
                        const imageUrl =
                            (fbNotification &&
                                fbNotification.hero &&
                                fbNotification.hero.background &&
                                fbNotification.hero.background.url) ||
                            "";

                        const dataItems = {
                            actionedCount: actioned,
                            checked: currentChecked,
                            featureFlags,
                            groupId,
                            id: _id,
                            teamNumber,
                            onChecked,
                            onDeleteMessagePressed,
                            openCount: numberOfOpens,
                            status,
                            sentCount: numberOfNotificationsPublishedToSns,
                            sendTime,
                            title: message,
                            imageUrl,
                            memberApp: teamName,
                            fbNotification,
                            opened,
                            computedActioned,
                            description,
                        };
                        return <RowTable item={dataItems} key={_id} />;
                    },
                )}
            </tbody>
        </Table>
    );
});
