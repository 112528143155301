import {
    ALCOHOL_PRODUCTS,
    BANNER,
    CONFECTIONERY_PRODUCTS,
    GROCERY_PRODUCTS,
    HOME_BANNER_1,
    HOME_BANNER_2,
    HOME_BANNER_3,
    HOUSEHOLD_PRODUCTS,
    INTERSTITIAL,
    INTERSTITIAL_ENTRANCE,
    NEW_PRODUCTS,
    OFFERS_FOR_YOU,
    BASKET_PRODUCT_PLACEMENT,
    PRODUCT_LIST,
    RELATED_PRODUCTS,
    SOFT_DRINKS_PRODUCTS,
    TOBACCO_PRODUCTS,
    TRENDING_PRODUCTS,
} from "./PlacementConstants";
// @ts-ignore
import AdvertTable, {AdvertTableContainerProps} from "./AdvertTableContainer";
// @ts-ignore
import stateSelector from "./selectors";
import CollapsibleBox from "../widget/CollapsibleBox";
import getAdvertTitles from "./getAdvertTitles";
import React, {memo} from "react";

import immutable, {Map} from "immutable";
import {PlacementsUpdateBody, PlacementType} from "./types";
// @ts-ignore
import AdvertChildrenTeamFilter from "../common/ChildrenTeamFilter";

export interface AdvertProps {
    getAdverts: (
        filter?:
            | {childrenTeamFilter?: string | undefined; placementsFeatures: any}
            | undefined,
    ) => void;
    onTogglePlacement: (placementType: PlacementType) => void;
    onUpdatePlacements: (body: PlacementsUpdateBody) => void;
    placements: Map<string, string>;
    childrenTeamFilter?: string;
}

const styles = Object.freeze({
    total: {
        margin: "auto 10px",
    },
});

const HeaderTitle = ({title, total}: {title: string; total: string}) => (
    <div>
        {title}
        <div className="pull-right">
            <div style={styles.total}>Total: {total || 0}</div>
        </div>
    </div>
);

const AdvertTableContainers = ({
    title,
    placementType,
    placementName,
    isArchived,
    onTogglePlacement,
    placements,
    placementLimits = {},
    onUpdatePlacements,
    onDeleteAdvertPressed,
    childrenTeamFilter,
    adFrequencyConfigEnabled,
    onMultiDeleteAdvertPresed,
}: AdvertTableContainerProps) => {
    const advertList = placements.getIn(
        [placementType, "items"],
        immutable.List([]),
    );
    const placementTitle = placementName ? placementName : placementType;
    const limit = placementLimits[placementType];

    return (
        <CollapsibleBox
            header={<HeaderTitle title={title} total={advertList.count()} />}
            onHeaderClick={(): void => onTogglePlacement(placementType)}
            open={placements.getIn([placementType, "open"])}
            removeBodyPadding
        >
            <AdvertTable
                childrenTeamFilter={childrenTeamFilter}
                advertList={advertList}
                limit={limit}
                onDeleteAdvertPressed={onDeleteAdvertPressed}
                onUpdatePlacements={onUpdatePlacements}
                placementName={placementTitle}
                placementType={placementType}
                isArchived={isArchived}
                adFrequencyConfigEnabled={adFrequencyConfigEnabled}
                onMultiDeleteAdvertPresed={onMultiDeleteAdvertPresed}
                title={title}
            />
        </CollapsibleBox>
    );
};

interface AdvertPropsWithHooks extends AdvertProps {
    featureFlags: any;
    onChangeTeamFilter: (value: string) => Promise<void>;
}

interface Props extends AdvertPropsWithHooks {
    onDeleteAdvertPressed: (
        id: string,
    ) => Promise<{success: boolean} | undefined>;
    onMultiDeleteAdvertPresed: (
        ids: Array<string>,
        childrenTeamFilter?: string,
    ) => void;
    teamNumber: number;
}

export default memo(function Advert({
    onDeleteAdvertPressed,
    onChangeTeamFilter,
    onTogglePlacement,
    onUpdatePlacements,
    placements,
    teamNumber,
    childrenTeamFilter,
    featureFlags,
    onMultiDeleteAdvertPresed,
}: Props) {
    const {
        placements: placementFeatures,
        placementLimits: placementLimits,
        advertChildrenTeamFilter: childrenTeamFilterEnabled,
        advertDeepLinking: advertDeepLinkingEnabled,
        scheduledAdvert: scheduledAdvertEnabled,
    } = featureFlags;

    const props: any = {
        onTogglePlacement,
        onUpdatePlacements,
        placementFeatures,
        placementLimits,
        placements,
        advertDeepLinkingEnabled,
        scheduledAdvertEnabled,
        teamNumber,
        onDeleteAdvertPressed,
        childrenTeamFilter,
        childrenTeamFilterEnabled,
        onMultiDeleteAdvertPresed,
    };

    const adTitles: any = getAdvertTitles(teamNumber);

    return (
        <div>
            {childrenTeamFilterEnabled && (
                <AdvertChildrenTeamFilter
                    onChangeFilter={(value: string) => {
                        onChangeTeamFilter(value);
                    }}
                />
            )}

            {placementFeatures.interstitialAds && (
                <AdvertTableContainers
                    {...props}
                    title={adTitles.interstitialAds}
                    placementType={INTERSTITIAL}
                    adFrequencyConfigEnabled={
                        featureFlags.adFrequencyConfigEnabled
                    }
                />
            )}

            {placementFeatures.interstitialAdsEntrance && (
                <AdvertTableContainers
                    {...props}
                    title={adTitles.interstitialAdsEntrance}
                    placementType={INTERSTITIAL_ENTRANCE}
                />
            )}

            {placementFeatures.banner && (
                <AdvertTableContainers
                    {...props}
                    title={adTitles.banner}
                    placementType={BANNER}
                />
            )}

            {placementFeatures.productList && (
                <AdvertTableContainers
                    {...props}
                    title={adTitles.productList}
                    placementType={PRODUCT_LIST}
                />
            )}

            {placementFeatures["Home Banner 1"] && (
                <AdvertTableContainers
                    {...props}
                    title={adTitles.homeBanner1}
                    placementType={HOME_BANNER_1}
                />
            )}

            {placementFeatures.newProducts && (
                <AdvertTableContainers
                    {...props}
                    title={adTitles.newProducts}
                    placementType={NEW_PRODUCTS}
                />
            )}

            {placementFeatures["Home Banner 2"] && (
                <AdvertTableContainers
                    {...props}
                    title={adTitles.homeBanner2}
                    placementType={HOME_BANNER_2}
                />
            )}

            {placementFeatures.offersForYou && (
                <AdvertTableContainers
                    {...props}
                    title={adTitles.offersForYou}
                    placementType={OFFERS_FOR_YOU}
                />
            )}

            {placementFeatures.basketProductPlacement && (
                <AdvertTableContainers
                    {...props}
                    title={adTitles.basketProductPlacement}
                    placementType={BASKET_PRODUCT_PLACEMENT}
                />
            )}

            {placementFeatures["Home Banner 3"] && (
                <AdvertTableContainers
                    {...props}
                    title={adTitles.homeBanner3}
                    placementType={HOME_BANNER_3}
                />
            )}

            {placementFeatures.trendingProducts && (
                <AdvertTableContainers
                    {...props}
                    title={adTitles.trendingProducts}
                    placementType={TRENDING_PRODUCTS}
                />
            )}

            {placementFeatures.relatedProducts && (
                <AdvertTableContainers
                    {...props}
                    title={adTitles.relatedProducts}
                    placementType={RELATED_PRODUCTS}
                />
            )}

            {placementFeatures.softDrinksProducts && (
                <AdvertTableContainers
                    {...props}
                    title={adTitles.softDrinksProducts}
                    placementType={SOFT_DRINKS_PRODUCTS}
                />
            )}

            {placementFeatures.confectioneryProducts && (
                <AdvertTableContainers
                    {...props}
                    title={adTitles.confectioneryProducts}
                    placementType={CONFECTIONERY_PRODUCTS}
                />
            )}

            {placementFeatures.alcoholProducts && (
                <AdvertTableContainers
                    {...props}
                    title={adTitles.alcoholProducts}
                    placementType={ALCOHOL_PRODUCTS}
                />
            )}

            {placementFeatures.tobaccoProducts && (
                <AdvertTableContainers
                    {...props}
                    title={adTitles.tobaccoProducts}
                    placementType={TOBACCO_PRODUCTS}
                />
            )}

            {placementFeatures.groceryProducts && (
                <AdvertTableContainers
                    {...props}
                    title={adTitles.groceryProducts}
                    placementType={GROCERY_PRODUCTS}
                />
            )}

            {placementFeatures.householdProducts && (
                <AdvertTableContainers
                    {...props}
                    title={adTitles.householdProducts}
                    placementType={HOUSEHOLD_PRODUCTS}
                />
            )}
        </div>
    );
});
