import "react-widgets/dist/css/react-widgets.css";
import {FormGroup} from "react-bootstrap";
import {inject, observer} from "mobx-react";
import Checkbox from "../../widget/Checkbox";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import moment from "moment";
// @ts-ignore
import momentLocalizer from "react-widgets/lib/localizers/moment";
import React, {Component, memo} from "react";
import scheduleInfoText from "./scheduleInfoText";
import toValidNotificationDate from "../../common/toValidFutureDates";
import PushNotificationRepository from "../PushNotificationRepository";

const getOneYearFromNow = () =>
    moment() // eslint-disable-line moment-utc/no-moment-without-utc
        .add(1, "year")
        .toDate();

const getStartOfThisHour = () =>
    moment() // eslint-disable-line moment-utc/no-moment-without-utc
        .startOf("hour")
        .toDate();

const safeDateValue = (momentDate: Object): any => {
    if (!momentDate) {
        return null;
    }

    return moment(momentDate).toDate(); // eslint-disable-line moment-utc/no-moment-without-utc
};

const styles = {paddingRight: {paddingRight: 10}};

interface DateTimePickerOptions {
    min: Date | undefined;
    max: Date | undefined;
    step: number;
    format: string;
    editFormat: string;
    finalView: "year" | "month" | "decade" | "century" | undefined;
}

const ResetButton = ({onSetSchedule}: {onSetSchedule: Function}) => (
    <button
        className="btn btn-warning"
        onClick={() => onSetSchedule([], {value: null, sendImmediately: false})}
        type="button"
    >
        Reset
    </button>
);

const ScheduleDateTimePicker = memo(function ({schedule, onSetSchedule}: any) {
    const dateTimePickerOptions: DateTimePickerOptions = {
        min: getStartOfThisHour(),
        max: getOneYearFromNow(),
        step: 15,
        format: "D/MM/YYYY HH:mm",
        editFormat: "D/MM/YYYY HH:mm",
        finalView: "year",
    };

    momentLocalizer(moment);
    return (
        <div>
            <FormGroup>
                <Checkbox
                    checked={schedule.sendImmediately}
                    onChecked={(value) => {
                        if (value) {
                            onSetSchedule([], {
                                value: "",
                                sendImmediately: value,
                            });

                            return;
                        }

                        onSetSchedule(["sendImmediately"], value);
                    }}
                >
                    <div>Send immediately?</div>
                </Checkbox>
            </FormGroup>
            {!schedule.sendImmediately && (
                <div className="form-inline">
                    <div className="form-group" style={styles.paddingRight}>
                        {scheduleInfoText(schedule.value)}
                    </div>

                    <div className="form-group" style={styles.paddingRight}>
                        <DateTimePicker
                            onChange={(date: any) =>
                                onSetSchedule(
                                    ["value"],
                                    toValidNotificationDate(date),
                                )
                            }
                            value={safeDateValue(schedule.value)}
                            {...dateTimePickerOptions}
                        />
                    </div>

                    <div className="form-group">
                        {schedule.value && (
                            <ResetButton onSetSchedule={onSetSchedule} />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
});

interface ScheduleDateTimePickerContainerProps {
    pushNotificationRepository?: PushNotificationRepository;
}

@inject("pushNotificationRepository")
@observer
class ScheduleDateTimePickerContainer extends Component<
    ScheduleDateTimePickerContainerProps
> {
    render() {
        const {
            schedule,
            onSetSchedule,
        } = this.props.pushNotificationRepository!;

        return (
            <ScheduleDateTimePicker
                schedule={schedule}
                onSetSchedule={onSetSchedule}
            />
        );
    }
}

export default ScheduleDateTimePickerContainer;
