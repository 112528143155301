import React, {memo} from "react";
export default memo(function PageHeader({
    title,
    subTitle,
}: {
    title: string;
    subTitle?: string;
}): JSX.Element {
    return (
        <section className="content-header">
            <h1>
                {title}
                {subTitle ? <small>{subTitle}</small> : null}
            </h1>
        </section>
    );
});
