import * as R from "ramda";

const addFormField = R.curry((property, value, httpAgent) => {
    if (!R.isNil(value)) {
        return httpAgent.field(property, value);
    }

    return httpAgent;
});

export const getAdverts = (httpAgent, {placement, childrenTeamFilter}) =>
    httpAgent.get("/placements").query({
        placement,
        childrenTeamFilter,
        includeInActive: true,
        includeSeenAndClickCounts: true,
    });

export const postAdvert = (
    httpAgent,
    {
        actionLink,
        description,
        placement,
        linkedProduct,
        image,
        order,
        relatedProducts,
    },
) => {
    const httpPost = httpAgent.post("/placements").attach("image", image);

    return R.compose(
        addFormField("actionLink", actionLink),
        addFormField("description", description),
        addFormField("placement", placement),
        addFormField("linkedProduct", linkedProduct),
        addFormField("relatedProducts", relatedProducts),
        addFormField("order", order),
    )(httpPost);
};

export const deleteAdvert = (httpAgent, {id, metaTeamPlacementId}) =>
    httpAgent.delete("/placements").query({id, metaTeamPlacementId});

export const archiveAdvert = (httpAgent, {id, metaTeamPlacementId}) =>
    httpAgent.delete("/placements").query({id, metaTeamPlacementId});

export const updatePlacements = (httpAgent, body) =>
    httpAgent.put("/placements").send(body);

export const multiDeleteAdvert = (httpAgent, {ids}) => {
    return httpAgent.delete("/placements").query({id: ids[0]}).send({ids});
};
