import InfoBox, {Props as infoBoxProps} from "./InfoBox";
import React, {memo} from "react";

interface Props {
    progressPercentage: any;
    progressText: string;
    color: string;
    props: infoBoxProps;
    boldText: string;
}

export default memo(function infoBoxProgress({
    progressPercentage,
    progressText,
    color,
    ...props
}: Props): JSX.Element {
    return (
        <InfoBox {...props} boxColor={color}>
            <div className="progress">
                <div
                    className="progress-bar"
                    style={{width: `${progressPercentage}%`}}
                />
            </div>
            <span className="progress-description">{progressText}</span>
        </InfoBox>
    );
});
