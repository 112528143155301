/*  */

export const COLOUR_SET1 = [
    "#66c2a5",
    "#fc8d62",
    "#8da0cb",
    "#e78ac3",
    "#a6d854",
    "#ffd92f",
    "#e5c494",
    "#b3b3b3",
    "#8dd3c7",
    "#ffffb3",
    "#bebada",
    "#fb8072",
    "#80b1d3",
    "#fdb462",
    "#b3de69",
    "#fccde5",
    "#d9d9d9",
    "#bc80bd",
    "#ccebc5",
    "#ffed6f",
];

export const BESTWAY_COLOUR_SET = [
    "#4BB2CF",
    "#1C4A79",
    "#007EA7",
    "#A6A6A6",
    "#061A40",
    "#99BA67",
    "#FF2BF5",
    "#0C5165",
    "#B4E4F1",
    "#058AB0",
    "#ccebc5",
    "#FC8D62",
    "#E5C494",
    "#66C2A5",
];
