//@ts-ignore
import AppIcon from "./appicon.png";
//@ts-ignore
import Ellipsis from "../common/Ellipsis";
import React, {memo} from "react";
import {makeStyles} from "@material-ui/core";
import classNames from "classnames";

const TEXT_COLOUR_OPAQUE = "#222";
const TEXT_COLOUR_TRANSLUCENT = "#222";
const TEXT_SIZE_LARGE = 8.35;
const TEXT_SIZE_SMALL = 7.35;
const TEXT_FONT_FAMILY = "-apple-system, Helvetica Neue, Helvetica, sans-serif";

const useStyles = makeStyles({
    parent: {
        marginLeft: 0,
        borderWidth: "100%",
        borderRadius: 5,
        backgroundColor: "rgba(240, 240, 240, 0.7)",
    },
    topBorder: {
        borderTop: "solid rgba(255, 255, 255, 0.1)",
        borderWidth: 1,
    },
    notificationContent: {
        padding: 4,
        lineHeight: "85%",
    },
    title: {
        fontFamily: TEXT_FONT_FAMILY,
        fontWeight: 300,
        fontSize: TEXT_SIZE_LARGE,
        color: TEXT_COLOUR_OPAQUE,
        marginLeft: 6,
    },
    date: {
        fontFamily: TEXT_FONT_FAMILY,
        fontWeight: 300,
        fontSize: TEXT_SIZE_SMALL,
        color: TEXT_COLOUR_TRANSLUCENT,
        float: "right",
    },
    message: {
        fontFamily: TEXT_FONT_FAMILY,
        width: "100%",
        maxHeight: 60,
        fontSize: TEXT_SIZE_LARGE,
        fontWeight: 500,
        color: TEXT_COLOUR_OPAQUE,
        wordWrap: "break-word",
    },
    prompt: {
        fontFamily: TEXT_FONT_FAMILY,
        fontSize: TEXT_SIZE_SMALL,
        color: TEXT_COLOUR_TRANSLUCENT,
    },
});
interface Action {
    title: string;
    message?: string;
    url: string;
    prompt?: string;
}
const Icon = () => <img src={AppIcon} width="5.5%" />;

const Title = ({
    notification,
}: {
    notification: Action | undefined;
}): JSX.Element | null => {
    const classes = useStyles();
    if (!notification) {
        return null;
    }
    const title = notification.title || "Your App";

    return <span className={classes.title}>{title.toUpperCase()}</span>;
};

const DateField = (): JSX.Element => {
    const classes = useStyles();
    return <span className={classes.date}>now</span>;
};
const Message = ({
    notification,
}: {
    notification?: Action;
}): JSX.Element | null => {
    const classes = useStyles();
    if (!notification) {
        return null;
    }
    return (
        <Ellipsis className={classes.message}>{notification.message}</Ellipsis>
    );
};

const Prompt = ({
    notification,
}: {
    notification?: Action;
}): JSX.Element | null => {
    const classes = useStyles();
    if (!notification) {
        return null;
    }
    const prompt = notification.prompt
        ? notification.prompt.toLowerCase()
        : "view";

    return (
        <span className={classes.prompt}>
            <span>slide to {prompt}</span>
        </span>
    );
};

export default memo(function Notification({
    notification,
}: {
    marginLeft?: number;
    notification?: Action;
    width?: string | number;
}): JSX.Element {
    const classes = useStyles();
    const parentStyle = classNames(classes.topBorder, classes.parent);
    return (
        <div className={parentStyle}>
            <div className={classes.notificationContent}>
                <div>
                    <Icon />
                    <Title notification={notification} />
                    <DateField />
                </div>
                <div style={{marginTop: 2}}>
                    <Message notification={notification} />
                </div>
                <div>
                    <Prompt notification={notification} />
                </div>
            </div>
        </div>
    );
});
