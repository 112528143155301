import {Col, Row} from "react-bootstrap";
import DetailRow from "../../../widget/DetailRow";
import React, {memo} from "react";

interface Props {
    isFirstRow?: boolean;
    label: string;
    value: string | JSX.Element;
}

export default memo(function AudienceSelectorItem({
    isFirstRow,
    label,
    value,
}: Props): JSX.Element {
    return (
        <Row>
            {isFirstRow ? null : <hr />}
            <Col md={8}>
                <DetailRow label={label}>{value}</DetailRow>
            </Col>
        </Row>
    );
});
