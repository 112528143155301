import formatNumber from "../common/formatNumber";
import {Notification} from "./MessageTable";

export enum PushNotificationTableHeaders {
    IMAGE = "IMAGE",
    TITLE = "TITLE",
    DESCRIPTION = "DESCRIPTION",
    ESTIMATED_SENT = "ESTIMATED_SENT",
    OPENED = "OPENED",
    ACTIONED = "ACTIONED",
    SEND_TIME = "SEND_TIME",
    MEMBER_APP = "MEMBER_APP",
    STATUS = "STATUS",
}

const getDataForAHeader = (
    headerName: PushNotificationTableHeaders,
    item: Notification,
): any => {
    const fbNotification = item.fbNotification;
    if (headerName === PushNotificationTableHeaders.IMAGE) {
        return (
            (fbNotification &&
                fbNotification.hero &&
                fbNotification.hero.background &&
                fbNotification.hero.background.url) ||
            ""
        );
    }

    if (headerName === PushNotificationTableHeaders.TITLE) {
        return item.message || "(Untitled)";
    }

    if (headerName === PushNotificationTableHeaders.DESCRIPTION) {
        return item.description || "";
    }

    if (headerName === PushNotificationTableHeaders.ESTIMATED_SENT) {
        return formatNumber(item.numberOfNotificationsPublishedToSns) || "-";
    }

    if (headerName === PushNotificationTableHeaders.OPENED) {
        return `${formatNumber(item.numberOfOpens)} (${item.opened}%)`;
    }

    if (headerName === PushNotificationTableHeaders.ACTIONED) {
        return !fbNotification || fbNotification.actions.length === 1
            ? "N/A"
            : `${formatNumber(item.actioned)} (${item.computedActioned}%)`;
    }

    if (headerName === PushNotificationTableHeaders.SEND_TIME) {
        return item.sendTime || "";
    }

    if (headerName === PushNotificationTableHeaders.MEMBER_APP) {
        return item.teamName || "";
    }

    if (headerName === PushNotificationTableHeaders.STATUS) {
        return item.status || "";
    }
    return "";
};
export default function getPushNotificationTableDataForExport(
    tableData: Array<Notification>,
    headers: Array<PushNotificationTableHeaders>,
) {
    const csvData = tableData.map((item) => {
        return headers.map((header) => {
            return getDataForAHeader(header, item);
        });
    });

    return [[...headers], ...csvData];
}
