export const saveUser = (user) => {
    if (!window.localStorage) {
        return;
    }

    window.localStorage.user = JSON.stringify(user);
};

export const loadUser = () => {
    if (!window.localStorage || !window.localStorage.user) {
        return {};
    }

    return JSON.parse(window.localStorage.user);
};

export const saveSalesDemoFeature = (featureFlagLevel) => {
    if (!window.localStorage) {
        return;
    }

    window.localStorage.salesDemoFeatureLevel = featureFlagLevel;
};

export const loadUserLatestLoggedInVersion = () => {
    if (!window.localStorage) {
        return "";
    } else if (!window.localStorage.userLatestLoggedInVersion) {
        return "0.0.1";
    }
    return window.localStorage.userLatestLoggedInVersion;
};

export const saveUserLatestLoggedInVersion = (version) => {
    if (!window.localStorage) {
        return;
    }
    window.localStorage.userLatestLoggedInVersion = version;
};

export const loadSalesDemoFeatureLevel = () => {
    if (!window.localStorage || !window.localStorage.salesDemoFeatureLevel) {
        return "ENTRY";
    }

    return window.localStorage.salesDemoFeatureLevel;
};
