import {Checkbox} from "react-bootstrap";
import React, {memo} from "react";

interface Props {
    children?: JSX.Element;
    onChecked: (check: boolean) => void;
    checked: boolean;
    className?: any;
}

export default memo(function MyCheckBox({
    children,
    onChecked,
    checked,
    className,
}: Props): JSX.Element {
    return (
        <Checkbox
            checked={checked}
            onChange={() => {
                onChecked(!checked);
            }}
            className={className}
        >
            {children}
        </Checkbox>
    );
});
