const DASHBOARD = {
    href: "/dashboard",
    icon: "tachometer",
    name: "Dashboard",
};

const MESSAGING = {
    name: "Push Notifications",
    href: "/message",
    icon: "envelope-o",
};

const ADS = {
    name: "Ads & Product Placement",
    href: "/placements",
    icon: "file-image-o",
};

const AUDIENCE_BUILDER = {
    name: "Audience Builder",
    href: "/audience-builder",
    icon: "users",
};

export default [DASHBOARD, ADS, AUDIENCE_BUILDER, MESSAGING];
