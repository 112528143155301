/*  */

import * as R from "ramda";
import {inject, observer} from "mobx-react";
import {toJS} from "mobx";
import DropDown from "../../widget/DropDown";
import InputRow from "../../widget/InputRow";
import LinkButton from "../../common/buttons/LinkButton";
import React, {Component} from "react";

const styles = Object.freeze({
    container: {
        width: "100%",
    },
    emptyList: {
        textAlign: "center",
    },
    noMessage: {
        fontSize: "20px",
        textAlign: "center",
    },
});

@inject("messageAudienceSelectionRepository")
@observer
class MessageAudienceSelection extends Component {
    componentDidMount() {
        this.props.messageAudienceSelectionRepository.getAudienceSelections();
    }

    componentWillUnmount() {
        const {messageAudienceSelectionRepository} = this.props;

        messageAudienceSelectionRepository.audienceSelector.resetAudienceSelectionState();
        messageAudienceSelectionRepository.onSetCurrentAudienceSelectionId("");
    }

    renderEmptyList() {
        return (
            <div style={styles.emptyList}>
                <p style={styles.noMessage}>
                    You haven't created any audiences yet
                </p>
                <p>
                    The audiences you create will be shown as an option in a
                    dropdown. To create a new Audience, click the{" "}
                    <strong>Add</strong> button below
                </p>
                <LinkButton destinaton="/audience-builder/add" />
            </div>
        );
    }

    render() {
        const {
            audienceSelections,
            onDropDownChange,
            selection,
            currentAudienceSelectionId,
        } = this.props.messageAudienceSelectionRepository;

        return (
            <div style={styles.container}>
                {R.isEmpty(toJS(audienceSelections)) ? (
                    this.renderEmptyList()
                ) : (
                    <InputRow label="Target Audience:">
                        <DropDown
                            items={selection}
                            onChange={onDropDownChange}
                            placeholder={"Select a preset audience"}
                            propLabel="text"
                            value={currentAudienceSelectionId}
                        />
                    </InputRow>
                )}
            </div>
        );
    }
}

export default MessageAudienceSelection;
