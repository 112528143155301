/*  */

import {autobind} from "core-decorators";
import {} from "./FilteredChart";
import {inject} from "mobx-react";
import BeamAPIClient from "../../../apiClient/BeamAPIClient";
import React from "react";
import StackedUserChart from "./StackedUserChart";

@inject("apiClient")
export default class TotalActiveDevice extends React.PureComponent {
    static INFO_POP_OVER_TEXT = `Illustrates the total number of Active Users in the selected time period, alongside a daily breakdown of Returning vs. New Users.
    
A Returning user is a user who has previously used your app who has returned on a given day.
    
A New user is a user who has never used your app before.`;

    @autobind
    dataLoader(query) {
        return this.props.apiClient
            ? this.props.apiClient.invokeGetEndpoint(
                  "totalActiveOverNewDevices",
                  query,
              )
            : Promise.reject(new Error("No API client available"));
    }

    render() {
        return (
            <StackedUserChart
                axisTitles={{
                    y: "Active Users",
                }}
                changeKey="activeDevicesCountChange"
                changeTitle="ACTIVE USERS"
                dataLoader={this.dataLoader}
                startDate={this.props.startDate}
                endDate={this.props.endDate}
                childrenTeamFilter={this.props.childrenTeamFilter}
                infoPopOverText={TotalActiveDevice.INFO_POP_OVER_TEXT}
                lastMonthKey="totalActiveDevices"
                showEngagement
                title="Active Users"
            />
        );
    }
}
