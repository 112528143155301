import {createAction} from "redux-actions";

export const RESET_APP = "beam/RESET_APP";

export const resetApp = createAction(RESET_APP);

export default (appReducers) => {
    const defaultState = appReducers({}, {});

    return (state, action) => {
        if (action.type === RESET_APP) {
            localStorage.clear();

            return appReducers(defaultState, action);
        }

        return appReducers(state, action);
    };
};
