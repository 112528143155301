import React, {memo} from "react";
import {makeStyles} from "@material-ui/styles";
import {Map} from "immutable";
import {
    Button,
    Col,
    Form,
    FormControl,
    FormGroup,
    HelpBlock,
    Row,
} from "react-bootstrap";
// @ts-ignore
import {getIn} from "../common/ramdaDataUtils";
// @ts-ignore
import {
    CATEGORY_BANNER_ADVERTS,
    PRODUCT_LIST_PLACEMENTS,
} from "./PlacementConstants";
// @ts-ignore
import Box from "../widget/Box";
// @ts-ignore
import DropDown from "../widget/DropDown";
// @ts-ignore
import ImagePreview from "./ImagePreview";
// @ts-ignore
import InputRow from "../widget/InputRow";
// @ts-ignore
import StartEndDatePicker from "../date/StartEndDatePicker";
// @ts-ignore
import AdvertChildrenTeamFilter from "../common/ChildrenTeamFilter";
// @ts-ignore
import ProductSkuGuidelines from "../common/ProductSkuGuidelines";
import AddAdvertUploadMediaField from "./AddAdvertUploadMediaField";

const useStyles = makeStyles(() => {
    return {
        guidelines: {
            marginTop: 10,
            fontSize: 12,
        },
        placementErrorMessage: {
            color: "rgb(255, 34, 85)",
            fontSize: 12,
            marginLeft: 16,
        },
    };
});

const getInputValidationState = (formValues: Object, field: string): Object =>
    getIn(["error", field], formValues) ? {validationState: "error"} : {};

export interface AdvertFieldValues {
    sku: string;
    relatedProducts: Array<string>;
    image: string;
    startDate: Date;
    endDate: Date;
    placement: string;
    action: string;
    description: string;
    actionLabel: string;
    actionValue: string;
    videoUrl: string;
    videoPreviewImageUrl: string;
}

const DescriptionField = ({
    onChangeAdvertFields,
    fieldValues,
}: {
    onChangeAdvertFields: (value: string, fieldName: string) => void;
    fieldValues: AdvertFieldValues;
}) => {
    return (
        <InputRow label="Advertisement Description:">
            <FormGroup>
                <FormControl
                    onChange={(e) =>
                        onChangeAdvertFields(
                            (e.target as HTMLInputElement).value,
                            "description",
                        )
                    }
                    placeholder='e.g. "Check out these great offers!"'
                    type="text"
                    value={fieldValues.description}
                />
            </FormGroup>
        </InputRow>
    );
};

export const CHILDREN_TEAM_FILTER = "childrenTeamFilter";

const ChildrenTeamFilter = ({
    onChangeAdvertFields,
    childrenTeamFilterEnabled,
    teamNumber,
}: {
    onChangeAdvertFields: (value: string, fieldName: string) => void;
    childrenTeamFilterEnabled: boolean;
    teamNumber: string;
}) => {
    if (!childrenTeamFilterEnabled) {
        return null;
    }

    return (
        <AdvertChildrenTeamFilter
            defaultValue={teamNumber}
            onChangeFilter={(value: any) => {
                onChangeAdvertFields(value, CHILDREN_TEAM_FILTER);
            }}
        />
    );
};

const RelatedProductField = ({
    onChangeAdvertFields,
    fieldValues,
    formValues,
}: {
    onChangeAdvertFields: (value: string, fieldName: string) => void;
    fieldValues: AdvertFieldValues;
    formValues: any;
}) => (
    <InputRow label="Related Products">
        <FormGroup {...getInputValidationState(formValues, "relatedProducts")}>
            <FormControl
                onChange={(e) =>
                    onChangeAdvertFields(
                        (e.target as HTMLInputElement).value,
                        "relatedProducts",
                    )
                }
                placeholder="SKUs separated by comma. e.g. 12312-1,1234-1"
                type="text"
                value={fieldValues.relatedProducts}
            />
            <HelpBlock>Related Products is required</HelpBlock>
        </FormGroup>
    </InputRow>
);

const ActionFields = ({
    formValues,
    action,
    actionsList,
    onChangeAdvertFields,
}: {
    formValues: any;
    action: string;
    actionsList: Map<string, string>;
    onChangeAdvertFields: (value: string, fieldName: string) => void;
}) => {
    const actionListRaw = actionsList.toJS().map((item: any) => item.label);
    return (
        <div>
            <ProductSkuGuidelines actionsList={actionListRaw} />
            <InputRow label="Action">
                <FormGroup {...getInputValidationState(formValues, "action")}>
                    <DropDown
                        items={actionsList}
                        onChange={(value: string) =>
                            onChangeAdvertFields(value, "action")
                        }
                        placeholder="None"
                        value={action}
                    />
                </FormGroup>
            </InputRow>
        </div>
    );
};

const getProductSkuLabelAndPlaceholder = (action: string) => {
    switch (action) {
        case "viewWebsite":
            return {
                label: "URL",
                placeholder: "URL",
            };
        case "viewProductList":
            return {
                label: "Product SKUs",
                placeholder: "eg. 101-1,252-4,123-6",
            };
        default:
            return {
                label: "Product SKU",
                placeholder: "Product SKU",
            };
    }
};

const ProductSkuField = ({
    onChangeAdvertFields,
    formValues,
    action,
}: {
    onChangeAdvertFields: (value: string, fieldName: string) => void;
    formValues: any;
    action: string;
}) => {
    const {label, placeholder} = getProductSkuLabelAndPlaceholder(action);

    return (
        <InputRow label={label}>
            <FormGroup {...getInputValidationState(formValues, "actionValue")}>
                <FormControl
                    onChange={(e) => {
                        const filteredValue = (e.target as HTMLInputElement).value.replace(
                            /\s/g,
                            "",
                        );

                        onChangeAdvertFields(filteredValue, "actionValue");
                    }}
                    placeholder={placeholder}
                    type="text"
                    value={formValues.fields.actionValue}
                />
                <HelpBlock>{`${label} is required`}</HelpBlock>
            </FormGroup>
        </InputRow>
    );
};

const LabelField = ({
    fieldValues,
    formValues,
    onChangeAdvertFields,
}: {
    fieldValues: AdvertFieldValues;
    formValues: any;
    onChangeAdvertFields: (value: string | Date, fieldName: string) => void;
}) => (
    <InputRow label="Button Text">
        <FormGroup {...getInputValidationState(formValues, "actionLabel")}>
            <FormControl
                onChange={(e) =>
                    onChangeAdvertFields(
                        (e.target as HTMLInputElement).value,
                        "actionLabel",
                    )
                }
                placeholder="Claim this Deal!"
                type="text"
                value={fieldValues.actionLabel}
            />
            <HelpBlock>{`Three or more characters required`}</HelpBlock>
        </FormGroup>
    </InputRow>
);

const handleDateChange = (
    date: Date,
    field: string,
    onChangeAdvertFields: (value: string | Date, fieldName: string) => void,
) => {
    onChangeAdvertFields(date, field);
};

const ScheduleField = ({
    scheduledAdvertEnabled,
    fieldValues,
    formValues,
    onChangeAdvertFields,
}: {
    scheduledAdvertEnabled: boolean;
    fieldValues: AdvertFieldValues;
    formValues: any;
    onChangeAdvertFields: (value: string | Date, fieldName: string) => void;
}) => {
    return scheduledAdvertEnabled ? (
        <InputRow label="Scheduled Date">
            <FormGroup {...getInputValidationState(formValues, "date")}>
                <StartEndDatePicker
                    endDateValue={fieldValues.endDate}
                    errors={formValues.error}
                    labelStyle={{padding: 0}}
                    onChange={(field: string, value: Date) =>
                        handleDateChange(value, field, onChangeAdvertFields)
                    }
                    startDateValue={fieldValues.startDate}
                    openOnInputClick={true}
                />
                <HelpBlock>Past dates are not allowed</HelpBlock>
            </FormGroup>
        </InputRow>
    ) : null;
};

const SubmitButton = ({
    onSaveAdverts,
    isFormDisabled,
    isFormLoading,
    isPlacementLimitReached,
}: {
    onSaveAdverts: () => void;
    fieldValues: AdvertFieldValues;
    isFormDisabled: boolean;
    isFormLoading: boolean;
    isPlacementLimitReached: boolean;
}) => {
    return (
        <Col md={12}>
            <Button
                disabled={
                    isPlacementLimitReached || isFormLoading || isFormDisabled
                }
                onClick={() => onSaveAdverts()}
            >
                {isFormLoading ? "Submitting.." : "Submit"}
            </Button>
        </Col>
    );
};

const ProductListPlacementFields = ({
    onChangeAdvertFields,
    fieldValues,
    placementName,
    formValues,
    imageValidationState,
    onSelectImage,
    imageErrorMessage,
    featureFlags,
    onSetIsVideoInterstitials,
    isVideoInterstitials,
}: {
    onChangeAdvertFields: (
        value: string | Date | null,
        fieldName: string,
    ) => void;
    fieldValues: AdvertFieldValues;
    placementName: string;
    formValues: any;
    imageValidationState: any;
    imageErrorMessage: string;
    onSelectImage: (
        event: React.FormEvent<FormControl>,
        placementName: string,
        isVideoPreviewImage: boolean,
    ) => void;
    featureFlags: any;
    onSetIsVideoInterstitials: (value: boolean) => void;
    isVideoInterstitials: boolean;
}) => {
    return PRODUCT_LIST_PLACEMENTS.includes(placementName) ? (
        <InputRow label="SKU">
            <FormGroup {...getInputValidationState(formValues, "sku")}>
                <FormControl
                    onChange={(e) => {
                        const filteredValue = (e.target as HTMLInputElement).value.replace(
                            featureFlags?.adActions?.allowSKUsWithFullStops
                                ? /[^a-zA-Z0-9s\-.\,]/g
                                : /[^a-zA-Z0-9s\-\,]/g,
                            "",
                        );
                        onChangeAdvertFields(filteredValue, "sku");
                    }}
                    placeholder="Add SKUs separated by comma e.g 1234-1,2345-2"
                    type="text"
                    value={fieldValues.sku}
                />
                <HelpBlock>SKU is required</HelpBlock>
            </FormGroup>
        </InputRow>
    ) : (
        <AddAdvertUploadMediaField
            {...{
                featureFlags,
                imageValidationState,
                onSelectImage,
                placementName,
                imageErrorMessage,
                fieldValues,
                onChangeAdvertFields,
                onSetIsVideoInterstitials,
                isVideoInterstitials,
            }}
        />
    );
};

export interface AddAdvertProps {
    formValues: any;
    fieldValues: AdvertFieldValues;
    placementName: string;
    action: string;
    childrenTeamFilterEnabled: boolean;
    imageValidationState: any;
    imageErrorMessage: string;
    onChangeAdvertFields: (
        value: string | Date | null,
        fieldName: string,
    ) => void;
    isFormDisabled: boolean;
    isFormLoading: boolean;
    isEditMode?: boolean;
    onSetIsVideoInterstitials: (value: boolean) => void;
    isVideoInterstitials: boolean;
}

interface AddAdvertWithHooks extends AddAdvertProps {
    featureFlags: any;
    onSaveAdverts: () => void;
    onSelectImage: (
        event: React.FormEvent<FormControl>,
        placementName: string,
        isVideoPreviewImage: boolean,
    ) => void;
}

interface Props extends AddAdvertWithHooks {
    actionsList: Map<string, string>;
    isPlacementLimitReached: boolean;
}

export default memo(function AddAdvert({
    formValues,
    fieldValues,
    placementName,
    action,
    onChangeAdvertFields,
    imageValidationState,
    imageErrorMessage,
    onSelectImage,
    actionsList,
    featureFlags,
    isFormDisabled,
    isFormLoading,
    onSaveAdverts,
    isPlacementLimitReached,
    onSetIsVideoInterstitials,
    isVideoInterstitials,
}: Props) {
    const classes = useStyles();
    const {
        advertChildrenTeamFilter: childrenTeamFilterEnabled,
        advertDeepLinking: advertDeepLinkingEnabled,
        scheduledAdvert: scheduledAdvertEnabled,
        interstitialAdCTAEnabled: interstitialAdCTAEnabled,
    } = featureFlags;

    const hasActionsList = actionsList && actionsList.count() > 1;
    const isCategoryBanner = CATEGORY_BANNER_ADVERTS.find(
        (item) => item === placementName,
    );
    const isActionableBanner =
        (placementName === "banner" ||
            placementName.includes("Home Banner") ||
            isCategoryBanner) &&
        advertDeepLinkingEnabled;
    const isActionableInterstitial =
        placementName === "interstitial" && interstitialAdCTAEnabled;
    const shouldShowActionFields =
        (isActionableBanner || isActionableInterstitial) &&
        Boolean(hasActionsList);

    const shouldRenderProductSkuField =
        (placementName === "banner" ||
            isCategoryBanner ||
            (placementName === "interstitial" && interstitialAdCTAEnabled) ||
            placementName.includes("Home Banner")) &&
        Boolean(hasActionsList) &&
        action !== "none";

    const shouldRenderLabelField =
        isActionableInterstitial &&
        Boolean(hasActionsList) &&
        action !== "none";

    const teamNumber = formValues?.fields?.childrenTeamFilter;

    return (
        <Row>
            <Col md={12}>
                <Box
                    styleState="primary"
                    title="Add Ad Placement"
                    infoPopOverText=""
                >
                    <Col md={12}>
                        <Form>
                            <DescriptionField
                                onChangeAdvertFields={onChangeAdvertFields}
                                fieldValues={fieldValues}
                            />
                            <ChildrenTeamFilter
                                teamNumber={teamNumber}
                                onChangeAdvertFields={onChangeAdvertFields}
                                childrenTeamFilterEnabled={
                                    childrenTeamFilterEnabled
                                }
                            />

                            <ProductListPlacementFields
                                onChangeAdvertFields={onChangeAdvertFields}
                                fieldValues={fieldValues}
                                placementName={placementName}
                                formValues={formValues}
                                imageValidationState={imageValidationState}
                                imageErrorMessage={imageErrorMessage}
                                onSelectImage={onSelectImage}
                                featureFlags={featureFlags}
                                onSetIsVideoInterstitials={
                                    onSetIsVideoInterstitials
                                }
                                isVideoInterstitials={isVideoInterstitials}
                            />
                            {placementName === "relatedProducts" && (
                                <RelatedProductField
                                    onChangeAdvertFields={onChangeAdvertFields}
                                    fieldValues={fieldValues}
                                    formValues={formValues}
                                />
                            )}

                            {shouldShowActionFields && (
                                <ActionFields
                                    onChangeAdvertFields={onChangeAdvertFields}
                                    formValues={formValues}
                                    action={action}
                                    actionsList={actionsList}
                                />
                            )}

                            {shouldRenderLabelField && (
                                <LabelField
                                    fieldValues={fieldValues}
                                    formValues={formValues}
                                    onChangeAdvertFields={onChangeAdvertFields}
                                />
                            )}

                            {shouldRenderProductSkuField && (
                                <ProductSkuField
                                    onChangeAdvertFields={onChangeAdvertFields}
                                    formValues={formValues}
                                    action={action}
                                />
                            )}

                            <ScheduleField
                                scheduledAdvertEnabled={scheduledAdvertEnabled}
                                fieldValues={fieldValues}
                                formValues={formValues}
                                onChangeAdvertFields={onChangeAdvertFields}
                            />
                        </Form>
                        <SubmitButton
                            onSaveAdverts={onSaveAdverts}
                            fieldValues={fieldValues}
                            isFormDisabled={isFormDisabled}
                            isFormLoading={isFormLoading}
                            isPlacementLimitReached={isPlacementLimitReached}
                        />
                        {isPlacementLimitReached && (
                            <span className={classes.placementErrorMessage}>
                                You have reached the maximum adverts for your
                                account. Please remove an existing advert to add
                                a new one.
                            </span>
                        )}
                    </Col>
                </Box>
            </Col>
        </Row>
    );
});
