/*  */

import * as R from "ramda";
import SalesDemoDashboard from "./salesDemo/SalesDemoDashboard";
import TEAM_NUMBERS from "../teamNumbers";

export default (teamNumber) => {
    const teamDashboard = R.cond([
        [R.equals(TEAM_NUMBERS.wholesaleDemoAll), () => SalesDemoDashboard],
        [R.equals(TEAM_NUMBERS.wholesaleDemo), () => SalesDemoDashboard],
        [R.T, () => SalesDemoDashboard],
    ])(teamNumber);

    return Promise.resolve(teamDashboard);
};
