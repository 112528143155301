import {FormControl} from "react-bootstrap";
import React, {memo} from "react";

interface Option {
    label: string;
    value: number | string;
}

interface Props {
    items?: Array<Option>;
    placeholder?: string;
}

export default memo(function Select({
    items = [],
    placeholder,
    ...props
}: Props): JSX.Element | null {
    return (
        <FormControl componentClass="select" {...props}>
            {placeholder ? <option value={""}>{placeholder}</option> : null}
            {items.map(({label, value}) => (
                <option key={value} value={value}>
                    {label}
                </option>
            ))}
        </FormControl>
    );
});
