import Overlay from "./Overlay";
import OverlayLabel from "./OverlayLabel";
import React, {memo} from "react";

const loadingLabel = (
    <OverlayLabel>
        <i className="fa fa-cog fa-spin" /> Loading...
    </OverlayLabel>
);

export default memo(function LoadingOverlay({children}: any) {
    return <Overlay overlayContent={loadingLabel}>{children}</Overlay>;
});
