import {Col, FormControl, FormGroup, HelpBlock} from "react-bootstrap";
import {inject, observer} from "mobx-react";
import React, {Component, memo} from "react";
import Visualisation from "../../visualisation/Visualisation";
import PushNotificationRepository from "../PushNotificationRepository";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles({
    container: {
        display: "flex",
    },
    inputColumn: {
        flex: 1,
    },
    visualizationColumn: {
        paddingLeft: "15px",
        paddingRight: "15px",
    },
});

interface MessageAlertFormProps {
    onSetMessageAlert: any;
    messageAlert: {
        value: string;
        urlToOpen: {
            value: string;
        };
    };
    formErrors: {
        messageAlert: boolean;
        urlToOpen: boolean;
    };
}

const MessageAlertForm = memo(function ({
    onSetMessageAlert,
    messageAlert,
    formErrors,
}: MessageAlertFormProps) {
    const {value, urlToOpen = {value: ""}} = messageAlert;
    const messageValidationState = formErrors.messageAlert ? "error" : null;
    const urlToOpenValidationState = formErrors.urlToOpen ? "error" : null;
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Col className={classes.inputColumn}>
                <form>
                    <FormGroup validationState={messageValidationState}>
                        <FormControl
                            onChange={(event: any): any =>
                                onSetMessageAlert(["value"], event.target.value)
                            }
                            placeholder="Message"
                            type="text"
                            value={value}
                        />
                        <HelpBlock>This field is required</HelpBlock>
                    </FormGroup>
                    <FormGroup validationState={urlToOpenValidationState}>
                        <FormControl
                            onChange={(event: any): any =>
                                onSetMessageAlert(
                                    ["urlToOpen", "value"],
                                    event.target.value,
                                )
                            }
                            placeholder="URL. e.g. http://www.google.com"
                            type="text"
                            value={urlToOpen.value}
                        />
                        <HelpBlock>Please enter a valid URL</HelpBlock>
                    </FormGroup>
                </form>
            </Col>
            <Col className={classes.visualizationColumn}>
                <Visualisation
                    action1={{
                        title: "Name of App",
                        message: value || "Message here...",
                        url: urlToOpen.value,
                    }}
                />
            </Col>
        </div>
    );
});

interface Props {
    pushNotificationRepository?: PushNotificationRepository;
}

@inject("pushNotificationRepository")
@observer
class MessageAlertFormContainer extends Component<Props> {
    render() {
        const {
            onSetMessageAlert,
            messageAlert = {value: "", urlToOpen: {value: ""}},
            formErrors = {messageAlert: false, urlToOpen: false},
        } = this.props.pushNotificationRepository!;

        return (
            <MessageAlertForm
                onSetMessageAlert={onSetMessageAlert}
                messageAlert={messageAlert}
                formErrors={formErrors}
            />
        );
    }
}

export default MessageAlertFormContainer;
