import {inject, observer} from "mobx-react";
import React, {memo, Component} from "react";
import PushNotificationForm from "./PushNotificationForm";
import useFeatureFlag from "../featureFlags/useFeatureFlag";
import useAlert from "../alert/useAlert";
import {OnSubmitFormParams} from "./PushNotificationRepository";

interface Props {
    pushNotificationRepository: any;
    match: any;
}

export interface PushNotificationHooksContainerProps {
    stepContainerForm: any;
    openNextStep: (item: {current: string; next: any}) => void;
    onToggleStep: (step: string) => void;
    remaining: Array<any>;
    match: any;
    onSubmitForm: (
        item: OnSubmitFormParams,
    ) => Promise<{success: boolean; isImageUploadError?: boolean}>;
    isDuplicate: boolean;
    onSetMessageAlert: (path: Array<string>, value: any) => void;
    richPushNotifications: any;
    messageAlert: {
        description: {
            value: string;
        };
        value: string;
        urlToOpen: {
            value: string;
        };
    };
}

const HooksContainer = memo(function PushNotificationHooksContainer(
    props: PushNotificationHooksContainerProps,
) {
    const {featureFlags} = useFeatureFlag();
    const {addSuccessAlert, addErrorAlert} = useAlert();
    const onSubmitForm = async (params: OnSubmitFormParams) => {
        const {success, isImageUploadError} = await props.onSubmitForm(params);
        if (success) {
            addSuccessAlert("Successfully queued notification");
        } else {
            if (isImageUploadError) {
                addErrorAlert(
                    "There was a problem uploading your image. Please try again.",
                );
            } else {
                addErrorAlert(
                    "There was a problem sending notification. Please try again.",
                );
            }
        }

        return {success, isImageUploadError};
    };
    return (
        <PushNotificationForm
            {...props}
            featureFlags={featureFlags}
            onSubmitForm={onSubmitForm}
        />
    );
});

@inject("pushNotificationRepository")
@observer
class PushNotificationFormContainer extends Component<Props> {
    componentDidMount() {
        const id = this.props.match.params.id;

        this.props.pushNotificationRepository.onResetRepository();

        if (id) {
            this.loadNotificationData(id);
        }
    }

    componentWillUnmount() {
        this.props.pushNotificationRepository.onResetRepository();
    }

    async loadNotificationData(id: string) {
        await this.props.pushNotificationRepository.getNotificationDetail(id);
    }

    render() {
        const path = this.props.match.path || "";
        const splitPath = path.split("/");

        const isDuplicate =
            splitPath.length >= 4 ? splitPath[3] === "duplicate" : false;
        const {
            stepContainerForm = {},
            openNextStep,
            onToggleStep,
            remaining,
            onSubmitForm,
            richPushNotifications,
            messageAlert,
            onSetMessageAlert,
        } = this.props.pushNotificationRepository;

        return (
            <HooksContainer
                stepContainerForm={stepContainerForm}
                openNextStep={openNextStep}
                onToggleStep={onToggleStep}
                remaining={remaining}
                onSubmitForm={onSubmitForm}
                richPushNotifications={richPushNotifications}
                match={this.props.match}
                isDuplicate={isDuplicate}
                messageAlert={messageAlert}
                onSetMessageAlert={onSetMessageAlert}
            />
        );
    }
}

export default PushNotificationFormContainer;
