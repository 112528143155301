import * as R from "ramda";
//@ts-ignore
import emojiStrip from "emoji-strip";
const formatPropValue = R.ifElse(
    R.is(Number),
    R.identity,
    R.compose(emojiStrip, R.toLower, String),
);

const isNotOrder = R.compose(
    R.not,
    R.anyPass([R.equals("ascend"), R.equals("descend")]),
);

export default (
    propName: string,
    order: string = "ascend",
    replaceEmptyValue?: string,
) => {
    if (isNotOrder(order)) {
        throw new Error("must be ascend or descend");
    }

    if (replaceEmptyValue) {
        const formatEmptyValue = R.ifElse(
            R.is(Number),
            R.identity,
            R.compose(
                //@ts-ignore
                R.replace(undefined, replaceEmptyValue),
                //@ts-ignore
                R.replace(null, replaceEmptyValue),
                R.toLower,
                String,
            ),
        );

        return R.sortWith([
            //@ts-ignore
            R[order](
                R.compose(
                    formatEmptyValue,
                    //@ts-ignore
                    R.prop(propName),
                ),
            ),
        ]);
    }

    return R.sortWith([
        //@ts-ignore
        R[order](
            R.compose(
                formatPropValue,
                //@ts-ignore
                R.prop(propName),
            ),
        ),
    ]);
};
