import InfoBox from "../../widget/InfoBox";
import React, {memo} from "react";

interface Props {
    downloads: string;
    store: "googlePlay" | "apple" | "total";
}

export default memo(function AppDownload({store, downloads}: Props) {
    const ICONS = {
        total: (
            <span>
                <i className="ion ion-social-apple" />
                <i className="ion ion-android-playstore" />
            </span>
        ),
        apple: <i className="ion ion-social-apple" />,
        googlePlay: <i className="ion ion-android-playstore" />,
    };

    const TEXTS = {
        total: "Total",
        apple: "App Store",
        googlePlay: "Google Play",
    };

    return (
        <InfoBox
            boldText={downloads}
            boxColor="location-red"
            iconNode={ICONS[store]}
            text={TEXTS[store]}
        />
    );
});
