import ButtonWithIcon from "./ButtonWithIcon";
import React from "react";

interface Props {
    onClick: () => void;
}

export default function RemoveItemButton({onClick}: Props): JSX.Element {
    return (
        <ButtonWithIcon
            iconName="minus-circle"
            onClick={onClick}
            size="sm"
            title="Remove"
            type="danger"
        />
    );
}
