import React, {memo, CSSProperties} from "react";

interface Props {
    children: Array<JSX.Element> | JSX.Element | string | number;
    emptyLabel?: string;
    emptyList?: JSX.Element | boolean;
    errorLabel: string;
    isDone: boolean;
    isError: boolean;
    loadingLabel: string;
}

const styles = Object.freeze({
    loadingWrapper: {
        marginTop: "10px",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    } as CSSProperties,
    label: {textShadow: "1px 1px 1px rgb(90, 90, 90)"},
    wrapper: {position: "relative"} as CSSProperties,
});

export default memo(function loadingWidget({
    children,
    emptyLabel,
    emptyList,
    errorLabel,
    isDone,
    isError,
    loadingLabel,
}: Props) {
    let label: string | undefined = loadingLabel;

    if (isError) {
        label = errorLabel;
    } else if (isDone) {
        label = emptyLabel;
    }

    return (
        <div style={styles.wrapper}>
            {children}
            <div style={styles.loadingWrapper}>
                {emptyList && isDone ? (
                    emptyList
                ) : (
                    <span style={styles.label}>{label}</span>
                )}
            </div>
        </div>
    );
});
