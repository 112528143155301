import moment from "moment";

export default (
    originalDate: string | Date,
    defaultValue: string = "-",
    format: string = "ddd MMM DD YYYY",
): string => {
    if (!originalDate) {
        return defaultValue;
    }

    const date = new Date(originalDate);

    return moment.utc(date).isValid()
        ? moment.utc(originalDate).format(format)
        : defaultValue;
};
