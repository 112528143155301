import React, {memo, useState} from "react";
import PanelHeader from "./PanelHeader";
import convertCheckedListToArray from "./convertCheckedListToArray";
import {CurrentChecked, NotificationGroup} from "./types";
import CollapsibleBox from "../widget/CollapsibleBox";
import CreateNewGroupPopOver from "./CreateNewGroupPopOver";
import MessageTableContainer from "./MessageTableContainer";
import MoveToGroupPopOver from "./MoveToGroupPopOver";

export const UNGROUPED_NOTIFICATIONS = "UNGROUPED_NOTIFICATIONS";

const styles = Object.freeze({
    groupButton: {
        display: "inline-flex",
        marginBottom: 10,
        marginRight: 10,
    },
    ungroupedButtons: {
        display: "inline-flex",
    },
    button: {
        marginRight: 10,
    },
});

const UngroupedButtons = ({
    currentChecked,
    onFinishGrouping,
    onCreateNotificationGrouping,
    rowId,
}: {
    currentChecked: CurrentChecked;
    groupName?: string;
    onCreateNotificationGrouping: (group: NotificationGroup) => void;
    onFinishGrouping: () => void;
    rowId: string;
}) => {
    const notifications = convertCheckedListToArray(currentChecked);

    return (
        <div style={styles.ungroupedButtons}>
            <CreateNewGroupPopOver
                notifications={notifications}
                onCreateNotificationGrouping={onCreateNotificationGrouping}
                onFinishGrouping={onFinishGrouping}
                style={styles.groupButton}
            />
            {
                // @ts-ignore
                <MoveToGroupPopOver
                    // @ts-ignore
                    notifications={notifications}
                    onFinishGrouping={onFinishGrouping}
                    rowId={rowId}
                    style={styles.groupButton}
                />
            }
        </div>
    );
};

interface MessageGroupedRowProps {
    isCurrentOpened: boolean;
    messageGroupItem: NotificationGroup;
    onCreateNotificationGrouping: (group: NotificationGroup) => void;
    onDeleteMessagePressed: (item: {
        groupId: string;
        notificationId: string;
        onDeleteCallback?: () => void;
    }) => void;
    onHeaderClick: (id: string) => any;
    onRenameNotificationGroup: (id: string, name: string) => void;
    getGroupedNotifications: (filters?: any) => Promise<any>;
}

export default memo(function MessageGroupedRow({
    messageGroupItem,
    isCurrentOpened,
    onHeaderClick,
    onCreateNotificationGrouping,
    onDeleteMessagePressed,
    getGroupedNotifications,
    onRenameNotificationGroup,
}: MessageGroupedRowProps) {
    const [currentChecked, setCurrentChecked] = useState<CurrentChecked>({});
    const {
        _id,
        groupName,
        notifications,
        totalSent,
        totalOpened,
        totalActioned,
    } = messageGroupItem;
    const notificationsChecked = convertCheckedListToArray(currentChecked);
    const isUnGroup = _id === UNGROUPED_NOTIFICATIONS;

    const onChecked = (id: string, name: string) => {
        setCurrentChecked({
            ...currentChecked,
            [id]: {
                id,
                checked: currentChecked[id]
                    ? !currentChecked[id].checked
                    : true,
                name,
            },
        });
    };

    const onFinishGrouping = async () => {
        setCurrentChecked({});
        await getGroupedNotifications();
    };

    return (
        <div>
            <CollapsibleBox
                header={
                    <PanelHeader
                        name={groupName}
                        notifications={notificationsChecked}
                        onClick={() => onHeaderClick(_id)}
                        onEditName={
                            _id === UNGROUPED_NOTIFICATIONS
                                ? () => null
                                : (name: string) =>
                                      onRenameNotificationGroup(_id, name)
                        }
                        onFinishGrouping={onFinishGrouping}
                        rowId={_id}
                        totalActioned={totalActioned}
                        totalOpened={totalOpened}
                        totalSent={totalSent}
                    />
                }
                open={isCurrentOpened}
                removeBodyPadding
                solid
            >
                {isUnGroup ? (
                    <UngroupedButtons
                        currentChecked={currentChecked}
                        onCreateNotificationGrouping={
                            onCreateNotificationGrouping
                        }
                        onFinishGrouping={onFinishGrouping}
                        rowId={_id}
                    />
                ) : (
                    <div style={styles.groupButton}>
                        {
                            // @ts-ignore
                            <MoveToGroupPopOver
                                // @ts-ignore
                                notifications={notificationsChecked}
                                onCreateNotificationGrouping={
                                    onCreateNotificationGrouping
                                }
                                onFinishGrouping={onFinishGrouping}
                                rowId={_id}
                                style={styles.button}
                            />
                        }
                    </div>
                )}

                <MessageTableContainer
                    currentChecked={currentChecked}
                    groupId={isUnGroup ? "" : _id}
                    notifications={notifications}
                    onChecked={onChecked}
                    onDeleteMessagePressed={(notificationId: string) => {
                        onDeleteMessagePressed({
                            groupId: _id,
                            notificationId,
                            onDeleteCallback: () => {
                                if (currentChecked[notificationId]) {
                                    delete currentChecked[notificationId];
                                    setCurrentChecked(currentChecked);
                                }
                            },
                        });
                    }}
                    groupName={groupName}
                />
            </CollapsibleBox>
        </div>
    );
});
