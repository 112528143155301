import {withRouter} from "react-router-dom";
import ButtonWithIcon from "./ButtonWithIcon";
import React, {CSSProperties} from "react";

interface Location {
    pathname: string;
}

interface Props {
    history: {
        push: (path: string) => void;
    };
    destinaton?: string;
    iconName?: string;
    location: Location;
    relativeDestination: string;
    style?: CSSProperties;
    title?: string;
    type?: string;
    disabled?: boolean;
    className?: string;
}

function LinkButton({
    destinaton = "",
    location,
    history,
    relativeDestination = "edit",
    style,
    iconName = "plus-circle",
    title = "Add",
    type = "success",
    disabled = false,
    className,
}: Props): JSX.Element {
    const url = destinaton || `${location.pathname}/${relativeDestination}`;
    return (
        <ButtonWithIcon
            disabled={disabled}
            iconName={iconName}
            onClick={() => history.push(url)}
            size="sm"
            style={style}
            title={title}
            type={type}
            className={className}
        />
    );
}
export default withRouter<any>(LinkButton);
