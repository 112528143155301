import {Col, Row} from "react-bootstrap";
import AppDownload from "./AppDownload";
import Box from "../../widget/Box";
import formatNumber from "../../common/formatNumber";
import React, {memo} from "react";

interface Props {
    apple: number;
    googlePlay: number;
}

export default memo(function AppDownloads({apple = 0, googlePlay = 0}: Props) {
    const APP_DOWNLOADS_INFO =
        "A total cumulative summary of the downloads of your app, broken down by platform.";

    return (
        <Box infoPopOverText={APP_DOWNLOADS_INFO} title="App Downloads">
            <Row>
                <Col md={12}>
                    <AppDownload
                        downloads={formatNumber(apple)}
                        store="apple"
                    />
                </Col>
                <Col md={12}>
                    <AppDownload
                        downloads={formatNumber(googlePlay)}
                        store="googlePlay"
                    />
                </Col>
                <Col md={12}>
                    <AppDownload
                        downloads={formatNumber(googlePlay + apple)}
                        store="total"
                    />
                </Col>
            </Row>
        </Box>
    );
});
