import * as R from "ramda";
import {action, computed, observable, toJS} from "mobx";
import {getIn, setIn} from "../common/ramdaDataUtils";

import AudienceSelection from "./AudienceSelection";
import {PLATFORM_MAP} from "./constants";
// @ts-ignore
import {SALES_LIST} from "./sales/Sales";
// @ts-ignore
import {go} from "../main/eventHandlers";
// @ts-ignore
import {
    isDone,
    isValidLatitude,
    isValidLongitude,
    isValidMaxDistance,
    maxDistanceSelector,
} from "./location/validation";
// @ts-ignore
import {promiseAlert} from "promise-alert";
// @ts-ignore
import {Location, Sales, SalesProductSpent, Notification} from "./types";
// @ts-ignore
import BeamAPIClient from "../apiClient/BeamAPIClient";
// @ts-ignore
import coordinatesToPlaceName from "./location/coordinateToPlaceName";
// @ts-ignore
import debounce from "lodash.debounce";
// @ts-ignore
import makeUserSelector from "./makeUserSelector";
// @ts-ignore
import PredictiveAudienceRepository from "../pushNotification/audience/PredictiveAudienceRepository";
import sortList from "../common/sortList";
import formatDate from "../date/formatDate";
import getGeoLocation from "../common/getGeoLocation";
const getDefaultAudienceSelection = R.always(new AudienceSelection());

export default class AudienceSelectorRepository {
    apiClient: BeamAPIClient;
    predictiveAudienceSize: PredictiveAudienceRepository;

    constructor(
        apiClient: BeamAPIClient,
        predictiveAudienceSize: PredictiveAudienceRepository,
    ) {
        this.apiClient = apiClient;
        this.predictiveAudienceSize = predictiveAudienceSize;
    }

    @observable
    audienceSelection: AudienceSelection = getDefaultAudienceSelection();
    @observable
    sales: Sales = getDefaultAudienceSelection().sales;
    @observable
    audienceLocation: Location = getDefaultAudienceSelection().location;

    @action.bound
    onSetAudienceSelection(audienceSelection: AudienceSelection) {
        this.audienceSelection = audienceSelection;
        this.sales = audienceSelection.sales;
        this.audienceLocation = audienceSelection.location;
    }

    @action.bound
    resetAudienceSelectionState() {
        this.audienceSelection = getDefaultAudienceSelection();
        this.audienceLocation = getDefaultAudienceSelection().location;
        this.sales = getDefaultAudienceSelection().sales;
    }

    @action.bound
    onSetAudienceSelectionByPath(path: Array<string>, value: Object | string) {
        const currentAudienceSelection = setIn(
            path,
            value,
            this.audienceSelection,
        );

        this.audienceSelection = currentAudienceSelection;
        if (path[0] !== "name") {
            return this.onSelectionChange();
        }

        return {success: true};
    }

    @action.bound
    onSetLocation(path: Array<string>, value: Object | string) {
        const newLocation = setIn(path, value, this.audienceLocation);

        this.audienceLocation = newLocation;
        return this.onSelectionChange();
    }

    @action.bound
    async setSpecificPlaceName(placeName: string) {
        if (placeName.length) {
            this.onSetLocation(["specificLocation", "placeName"], placeName);
            const location = await getGeoLocation(placeName);
            const currentSpecificLocation = this.audienceLocation
                .specificLocation;

            const newSpecificLocation = {
                ...currentSpecificLocation,
                latitude: location.lat(),
                longitude: location.lng(),
            };

            this.onSetLocation(["specificLocation"], newSpecificLocation);

            return;
        }

        const defaultSpecificLocation = setIn(
            ["specificLocation", "placeName"],
            "",
            this.audienceLocation,
        );

        this.onSetLocation(["specificLocation"], defaultSpecificLocation);
    }

    @action.bound
    async getNotifications() {
        try {
            //@ts-ignore
            const notifications = await this.apiClient.invokeGetEndpoint(
                "notification",
            );

            const pickAndFormatDate: any = R.map(
                R.evolve({
                    message: R.trim,
                    createdAt: formatDate,
                    id: R.trim,
                }),
            );

            const sortedResponseByCreatedDate = sortList(
                "createdAt",
                "descend",
            );

            const formatResponse = R.compose(
                pickAndFormatDate,
                (response: any) => sortedResponseByCreatedDate(response),
                R.map((notification: Notification) => {
                    const {_id: id, message, createdAt, status} = notification;

                    return {
                        id,
                        message,
                        createdAt,
                        status,
                    };
                }),
            );

            const formattedNotification = formatResponse(notifications);

            // @ts-ignore
            this.audienceSelection.userNotificationActioned.notifications = formattedNotification;
            return {success: true};
        } catch (exception) {
            return {success: false};
        }
    }

    @action.bound
    onSetSales(path: Array<string>, value: Object | string) {
        const currentSales = this.sales;
        const newSales = setIn(path, value, currentSales);

        this.sales = newSales;
        return this.onSelectionChange();
    }

    @action.bound
    onSetUserIdentifiers(userIdentifiers: string) {
        const stringToArray = R.cond([
            [R.isEmpty, R.always([])],
            [R.T, R.compose(R.map(R.trim), R.split(","))],
        ]);

        this.audienceSelection.userIdentifiers = stringToArray(userIdentifiers);
        return this.onSelectionChange();
    }

    @computed
    get location(): Location {
        const currentLocation = this.audienceLocation;
        const specificLocation = {
            ...currentLocation.specificLocation,
            maxDistanceInMeters: maxDistanceSelector(
                getIn(
                    ["specificLocation", "maxDistanceInMeters"],
                    currentLocation,
                ),
            ),
        };

        const errors = {
            latitude: !isValidLatitude(
                getIn(["specificLocation", "latitude"], currentLocation),
            ),
            longitude: !isValidLongitude(
                getIn(["specificLocation", "longitude"], currentLocation),
            ),
            maxDistanceInMeters: !isValidMaxDistance(
                getIn(
                    ["specificLocation", "maxDistanceInMeters"],
                    currentLocation,
                ),
            ),
        };

        return {
            ...currentLocation,
            specificLocation,
            errors,
            done: isDone(currentLocation),
        };
    }

    @computed
    get audienceOverview(): Object {
        return this.getAudienceOverview({
            ...this.audienceSelection,
            sales: this.sales,
            location: this.audienceLocation,
        });
    }

    onGetPotentialReach = debounce(async () => {
        const userSelector = makeUserSelector({
            ...this.audienceSelection,
            sales: this.sales,
            location: this.audienceLocation,
        });

        const childrenTeamFilterQuery = this.audienceSelection
            .childrenTeamFilter
            ? {
                  childrenTeamFilter: toJS(
                      this.audienceSelection.childrenTeamFilter,
                  ),
              }
            : {};

        const results = await this.predictiveAudienceSize.fetch({
            ...userSelector,
            ...childrenTeamFilterQuery,
        });

        return results;
    }, 500);

    onGetUserSegment = () =>
        //@ts-ignore
        this.apiClient.invokeGetEndpoint("userSegmentsForApp");

    onSelectionChange = () => {
        this.predictiveAudienceSize.setAudienceOverView(this.audienceOverview);

        return this.onGetPotentialReach();
    };

    getLocationValue = (location: Location) => {
        if (!location.wantSpecificLocation) {
            return "All";
        }

        return (
            getIn(["specificLocation", "placeName"], location) ||
            coordinatesToPlaceName(location.specificLocation)
        );
    };

    getSpentInCategory = (salesProductSpent: SalesProductSpent) => {
        const selectedId = getIn(["categoryIds", 0], salesProductSpent);
        const categories: any = R.reduce(
            (
                productCategories: any,
                productType: {value: string; label: string},
            ) =>
                setIn(
                    [productType.value],
                    productType.label,
                    productCategories,
                ),
            {},
            SALES_LIST,
        );
        const category = categories[selectedId];
        const minimumSpend = salesProductSpent
            ? salesProductSpent.minimumSpend
            : 0;

        return category && minimumSpend
            ? `£${minimumSpend} on ${category}`
            : "";
    };

    getAudienceOverview(audienceSelection: AudienceSelection) {
        const getUserNotificationActioned = (
            userNotificationActioned: Object,
        ) => {
            const selectedMessage =
                getIn(
                    ["selectedNotification", "message"],
                    userNotificationActioned,
                ) || "";

            return selectedMessage;
        };

        const userNotificationActioned =
            getIn(["userNotificationActioned", "message"], audienceSelection) ||
            getUserNotificationActioned(
                audienceSelection.userNotificationActioned,
            );

        return {
            location: this.getLocationValue(audienceSelection.location),
            hasPurchased: getIn(
                ["sales", "salesSKUPurchaseActions", "purchased", "skus"],
                audienceSelection,
            ),
            hasNotPurchased: getIn(
                ["sales", "salesSKUPurchaseActions", "notPurchased", "skus"],
                audienceSelection,
            ),
            hasSpent: this.getSpentInCategory(
                getIn(["sales", "salesProductSpent"], audienceSelection),
            ),
            userSegments: getIn(
                ["userSegment", "selectedUserSegment"],
                audienceSelection,
            ),
            specificUserIdentifier: R.join(
                ",",
                audienceSelection.userIdentifiers,
            ),
            usersDidNotActionOnExistingCampaign: userNotificationActioned,
            devicePlatform:
                PLATFORM_MAP[audienceSelection.devicePlatform] || "",
            numberOfDaysInactive: audienceSelection.numberOfDaysInactive,
        };
    }

    onSaveAudienceSelection = async () => {
        try {
            const audienceSelection = this.audienceSelection;
            const specificLocation = this.audienceLocation.specificLocation!;
            const selectedNotificationId = getIn(
                [
                    "userNotificationActioned",
                    "selectedNotification",
                    "notificationSpecId",
                ],
                audienceSelection,
            );

            const notificationSpec:
                | {id: string; message: string}
                | undefined = R.find(
                (item: {id: string}) => item.id === selectedNotificationId,
                getIn(
                    ["userNotificationActioned", "notifications"],
                    audienceSelection,
                ),
            );

            const audienceSelectionWithNotificationActioned = notificationSpec
                ? {
                      ...audienceSelection,
                      userNotificationActioned: {
                          id: notificationSpec.id,
                          message: notificationSpec.message,
                      },
                  }
                : R.omit(["userNotificationActioned"], audienceSelection);

            const audienceSelectionPayload = {
                ...audienceSelectionWithNotificationActioned,
                sales: this.sales,
                location: {
                    wantSpecificLocation: this.audienceLocation
                        .wantSpecificLocation,
                    specificLocation: {
                        placeName: specificLocation.placeName,
                        latitude: Number(specificLocation.latitude),
                        longitude: Number(specificLocation.longitude),
                        maxDistanceInMeters: Number(
                            specificLocation.maxDistanceInMeters,
                        ),
                    },
                },
            };

            await this.apiClient.invokePostEndpoint(
                "audienceSelections",
                audienceSelectionPayload,
            );

            this.resetAudienceSelectionState();
            return {success: true};
        } catch (exception) {
            return {success: false};
        }
    };

    onGetAudienceSelections = async () => {
        try {
            //@ts-ignore
            const audienceSelection = await this.apiClient.invokeGetEndpoint(
                "audienceSelections",
            );

            return {success: true, results: audienceSelection};
        } catch (exception) {
            return {
                success: false,
                results: [],
            };
        }
    };

    onDeleteAudienceSelections = (_id: string) =>
        promiseAlert({
            title: "Archive Audience",
            text:
                "Are you sure that you want to archive the selected audience?",
            type: "warning",
            showCancelButton: true,
        }).then(async (confirmed: boolean) => {
            if (confirmed) {
                try {
                    await this.apiClient.invokeDeleteEndpoint(
                        "audienceSelections",
                        {_id},
                    );

                    return {success: true};
                } catch (exception) {
                    return {success: false};
                }
            }

            return null;
        });

    onGetAudienceSelectionDetails = async (id: string) => {
        try {
            //@ts-ignore
            const result = await this.apiClient.invokeGetEndpoint(
                `audienceSelections/${id}`,
            );

            return {success: true, result};
        } catch (exception) {
            return {success: false, result: {}};
        }
    };
}
