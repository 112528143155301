/*       */

import React from "react";

                             

                               
                                        
                        
                                         
                                        
                                          
                   
  

                                                           
                 
                      
         

const isNotStatelessComponent = (
    WrappedComponent                          ,
)          =>
    typeof WrappedComponent !== "function" ||
    (WrappedComponent.prototype && WrappedComponent.prototype.render);

// eslint-disable-next-line complexity
const checkParams = ({displayName, WrappedComponent, lifecycle}) => {
    if (!displayName) {
        console.error("displayName must be provided"); // eslint-disable-line no-console
    }

    if (!WrappedComponent) {
        console.error("WrappedComponent must be provided"); // eslint-disable-line no-console
    }

    if (isNotStatelessComponent(WrappedComponent)) {
        console.error("WrappedComponent must be stateless component"); // eslint-disable-line no-console
    }

    if (lifecycle) {
        const ALLOWABLE_LIFECYLE_METHODS = [
            "componentWillMount",
            "componentDidMount",
            "componentWillReceiveProps",
            "shouldComponentUpdate",
            "componentWillUpdate",
            "componentDidUpdate",
            "componentWillUnmount",
        ];

        Object.keys(lifecycle).forEach((name) => {
            if (!ALLOWABLE_LIFECYLE_METHODS.includes(name)) {
                console.error(`Lifecycle method ${name} not found`); // eslint-disable-line no-console
            }
        });
    }
};

/**
 * Function that wraps a stateless component to make it pure and have display name.
 * @param {Object} $0 Configurations.
 * @param {Object} [$0.defaultProps] Default props.
 * @param {string} $0.displayName Wrapped component's display name.
 * @param {Object} [$0.handlers] Event handlers that are made available to the component.
 * @param {Object} [$0.initialState] Initial state of the component.
 * @param {Object} [$0.lifecycle] Lifecycle methods.
 * @param {boolean} [$0.pure=false] Flag if component should be pure.
 * @param {StatelessComponent} WrappedComponent Stateless component to be wrapped.
 * @returns {React$Component} React Component.
 * @example
 * createComponent({
 *     displayName: 'BlueBox',
 *     pure: true,
 * }, (props) => (
 *     <View style={{backgroundColor: 'blue'}} {...props} />
 * ))
 *
 * createComponent({
 *     displayName: 'AlertButton',
 *     handlers: {
 *         onPress: ({getProps}) => () => alert(getProps().message)
 *     }
 * }, ({onPress, message}) => (
 *     <Button
 *         title={message}
 *         onPress={onPress}
 *     />
 * ))
 *
 * createComponent({
 *    displayName: 'Counter',
 *    initialState: {count: 0},
 *    lifecycle: {
 *        componentDidMount: ({setState}) => () => {
 *            setInterval(() => {
 *                setState(({count}) => ({count: count + 1}))
 *            }, 1000)
 *        }
 *    }
 * }, ({count}) => (
 *     <Text>Count {count}</Text>
 * ))
 */
// eslint-disable-next-line complexity, max-statements
export default (
    {
        defaultProps,
        displayName,
        pure = false,
        handlers,
        lifecycle,
        initialState,
    }                        ,
    WrappedComponent                          ,
)                                       => {
    if (__DEV__) {
        checkParams({displayName, WrappedComponent, lifecycle});
    }

    if (!pure && !handlers && !lifecycle && !initialState) {
        WrappedComponent.displayName = displayName; // eslint-disable-line no-param-reassign
        WrappedComponent.defaultProps = defaultProps; // eslint-disable-line no-param-reassign

        return WrappedComponent;
    }

    const BaseComponent = pure ? React.PureComponent : React.Component;

    class Wrapper extends BaseComponent          {
        static displayName = displayName;
        static propTypes = WrappedComponent.propTypes; // eslint-disable-line react/forbid-foreign-prop-types
        static contextTypes = WrappedComponent.contextTypes;
        static defaultProps = defaultProps;

        boundHandlers                           ;
        instanceVariables = {};

        constructor() {
            super();

            this.state = initialState;

            const injectedHooks = {
                getProps: () => this.props,
                getState: () => this.state,
                setState: this.setState.bind(this),
                setInstanceVariables: (variables) => {
                    Object.assign(this.instanceVariables, variables);
                },
                getInstanceVariables: () => this.instanceVariables,
            };

            if (lifecycle) {
                Object.entries(lifecycle).forEach(([name, method]) => {
                    if (typeof method !== "function") {
                        return;
                    }

                    const boundMethod = method(injectedHooks);

                    if (__DEV__ && typeof boundMethod !== "function") {
                        console.error("Lifecycle method should be a function"); // eslint-disable-line no-console

                        return;
                    }

                    // $FlowIgnore
                    this[name] = boundMethod;
                });
            }

            if (handlers) {
                this.boundHandlers = Object.entries(handlers).reduce(
                    (methods        , [name, method]) => {
                        if (typeof method !== "function") {
                            return methods;
                        }

                        const boundMethod = method(injectedHooks);

                        if (__DEV__ && typeof boundMethod !== "function") {
                            console.error(
                                "Handler method should be a function",
                            ); // eslint-disable-line no-console

                            return methods;
                        }

                        methods[name] = boundMethod; // eslint-disable-line no-param-reassign

                        return methods;
                    },
                    {},
                );
            }
        }

        render() {
            return WrappedComponent(
                {...this.props, ...this.boundHandlers, ...this.state},
                this.context,
            );
        }
    }

    if (__DEV__) {
        // $FlowIgnore
        Wrapper.initialState = initialState;
        // $FlowIgnore
        Wrapper.handlers = handlers;
        // $FlowIgnore
        Wrapper.lifecycle = lifecycle;
    }

    return Wrapper;
};
