import React, {memo, useState} from "react";
import MessageGroupedRow from "./MessageGroupedRow";
import {NotificationGroup, NotificationGroups} from "./types";
// @ts-ignore
import sortList from "../common/sortList";

interface Props {
    getGroupedNotifications: (filters?: any) => Promise<any>;
    messageGroupList: any;
    onCreateNotificationGrouping: (group: NotificationGroup) => void;
    onDeleteMessagePressed: (item: {
        groupId: string;
        notificationId: string;
    }) => void;
    onRenameNotificationGroup: (id: string, name: string) => void;
}

const getSortedGroupedList = (messageGroupList: NotificationGroups) => {
    const sortOrder = sortList("groupName", "ascend");
    //@ts-ignore
    return sortOrder(messageGroupList);
};

export default memo(function MessageGroupList({
    messageGroupList,
    onDeleteMessagePressed,
    onCreateNotificationGrouping,
    getGroupedNotifications,
    onRenameNotificationGroup,
}: Props) {
    const sortedMessageGroup = getSortedGroupedList(messageGroupList);
    const [currentOpened, setCurrentOpened] = useState(
        "UNGROUPED_NOTIFICATIONS",
    );
    return (
        <div>
            {sortedMessageGroup.map((messageGroupItem: NotificationGroup) => {
                const messageGroupItemId = messageGroupItem._id;

                const isCurrentOpened = currentOpened === messageGroupItemId;

                return (
                    <MessageGroupedRow
                        key={messageGroupItemId}
                        getGroupedNotifications={getGroupedNotifications}
                        isCurrentOpened={isCurrentOpened}
                        messageGroupItem={messageGroupItem}
                        onCreateNotificationGrouping={
                            onCreateNotificationGrouping
                        }
                        onDeleteMessagePressed={onDeleteMessagePressed}
                        onHeaderClick={(messageGroupId: string) =>
                            setCurrentOpened(messageGroupId)
                        }
                        onRenameNotificationGroup={onRenameNotificationGroup}
                    />
                );
            })}
        </div>
    );
});
