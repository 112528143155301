import {Button, ButtonProps} from "react-bootstrap";
import Icon, {IconProps} from "react-fa";
import React, {memo, CSSProperties} from "react";
import classnames from "classnames";

export default memo(function ButtonWithIcon({
    disabled,
    iconName,
    onClick,
    size = "sm",
    style,
    title,
    type,
    iconStyle,
    iconSize = "lg",
    transparent = false,
    className,
}: {
    disabled?: boolean;
    iconName: string;
    onClick?: ((event: any) => void) | undefined;
    size?: ButtonProps["bsSize"];
    style?: CSSProperties;
    title: string;
    type?: string;
    iconStyle?: CSSProperties;
    iconSize?: IconProps["size"];
    transparent?: boolean;
    className?: string;
}): JSX.Element {
    return (
        <Button
            className={classnames(
                transparent ? "transparent-background" : "",
                className,
            )}
            bsSize={size}
            bsStyle={type}
            disabled={disabled}
            onClick={onClick}
            style={style}
        >
            <Icon
                fixedWidth
                name={iconName}
                size={iconSize}
                style={iconStyle}
            />{" "}
            <span>{title}</span>
        </Button>
    );
});
