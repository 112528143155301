import {Col, FormControl, FormGroup, InputGroup, Row} from "react-bootstrap";
import {getIn, setIn} from "../../common/ramdaDataUtils";
import dateRange from "../../date/getDateRange";
import DropDown from "../../widget/DropDown";
import getPurchaseActionNumOfDays from "./getPurchaseActionNumOfDays";
import InputRow from "../../widget/InputRow";
import PurchaseActionNumOfDays from "./PurchaseActionNumOfDays";
import React, {memo} from "react";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles({
    onLabel: {
        color: "rgb(34, 45, 50)",
        fontSize: 12,
        fontWeight: 700,
        textTransform: "uppercase",
        letterSpacing: 1,
    },
    inLastLabel: {
        color: "rgb(34, 45, 50)",
        fontSize: 12,
        fontWeight: 700,
        textTransform: "uppercase",
        letterSpacing: 1,
        textAlign: "center",
    },
});

interface PurchaseActionInputRowProps {
    label: string;
    numberOfDays: number;
    setNumberOfDays: any;
    setSKU: (value: string) => void;
    skuValues: string;
}

const PurchaseActionInputRow = ({
    label,
    setSKU,
    skuValues,
    numberOfDays,
    setNumberOfDays,
}: PurchaseActionInputRowProps) => {
    const classes = useStyles();

    return (
        <InputRow label={label}>
            <Row>
                <Col md={7}>
                    <FormGroup>
                        <FormControl
                            onChange={(
                                event: React.FormEvent<FormControl>,
                            ): void =>
                                setSKU((event.target as HTMLInputElement).value)
                            }
                            placeholder="Add SKUs separated by comma e.g 1234-1,2345-2"
                            type="text"
                            value={skuValues}
                        />
                    </FormGroup>
                </Col>
                <Col md={1}>
                    <h4 className={classes.inLastLabel}>in last</h4>
                </Col>
                <Col md={2}>
                    <PurchaseActionNumOfDays
                        disabled={!skuValues.trim()}
                        numberOfDays={numberOfDays}
                        setNumberOfDays={setNumberOfDays}
                    />
                </Col>
                <Col md={1}>
                    <h4 className={classes.onLabel}>day(s)</h4>
                </Col>
            </Row>
        </InputRow>
    );
};

export const SALES_LIST = [
    {
        label: "Tobacco",
        value: "91",
    },
    {
        label: "Soft Drinks",
        value: "1,171",
    },
];

interface SalesProps {
    sales: {
        salesSKUPurchaseActions: any;
        salesProductSpent: {
            minimumSpend: string | number;
        };
        minimumSpend?: number;
    };
    salesProductSpentEnabled: boolean;
    setProductCategoryId: (value: string) => void;
    setSalesSKUPurchaseAction: (value: string) => void;
    setSalesSpentAmount: (value: string) => void;
}

export default memo(function Sales({
    sales,
    salesProductSpentEnabled,
    setProductCategoryId,
    setSalesSKUPurchaseAction,
    setSalesSpentAmount,
}: SalesProps) {
    const classes = useStyles();
    const {salesSKUPurchaseActions, salesProductSpent} = sales;
    const setPurchasedSKU = (salesType: string, value: string): any =>
        setSalesSKUPurchaseAction(
            setIn([salesType, "skus"], value.trim(), salesSKUPurchaseActions),
        );

    const setSalesSKUPurchaseActionDateRange = (
        action: string,
        numOfDays: number,
    ) => {
        const actionDateRange = dateRange({
            daysBefore: numOfDays - 1,
            date: new Date(),
        });
        const actionObject = {
            ...salesSKUPurchaseActions[action],
            ...actionDateRange,
        };

        setSalesSKUPurchaseAction(
            setIn([action], actionObject, salesSKUPurchaseActions),
        );
    };

    return (
        <Row>
            <hr />
            <Col sm={12}>
                <PurchaseActionInputRow
                    label="Has purchased SKU:"
                    numberOfDays={Number(
                        getPurchaseActionNumOfDays(
                            salesSKUPurchaseActions.purchased,
                        ),
                    )}
                    setNumberOfDays={(numberOfDays: number) =>
                        setSalesSKUPurchaseActionDateRange(
                            "purchased",
                            numberOfDays,
                        )
                    }
                    setSKU={(sku) => setPurchasedSKU("purchased", sku)}
                    skuValues={getIn(
                        ["purchased", "skus"],
                        salesSKUPurchaseActions,
                    )}
                />
                <PurchaseActionInputRow
                    label="Has not purchased SKU:"
                    numberOfDays={Number(
                        getPurchaseActionNumOfDays(
                            salesSKUPurchaseActions.notPurchased,
                        ),
                    )}
                    setNumberOfDays={(numberOfDays: number) =>
                        setSalesSKUPurchaseActionDateRange(
                            "notPurchased",
                            numberOfDays,
                        )
                    }
                    setSKU={(sku) => setPurchasedSKU("notPurchased", sku)}
                    skuValues={getIn(
                        ["notPurchased", "skus"],
                        salesSKUPurchaseActions,
                    )}
                />
                {salesProductSpentEnabled && (
                    <InputRow label="Has spent in last 30 days:">
                        <Row>
                            <Col md={7}>
                                <InputGroup>
                                    <InputGroup.Addon>£</InputGroup.Addon>
                                    <FormControl
                                        min={0}
                                        onChange={(
                                            event: React.FormEvent<FormControl>,
                                        ): void =>
                                            setSalesSpentAmount(
                                                (event.target as HTMLInputElement)
                                                    .value,
                                            )
                                        }
                                        placeholder="Amount"
                                        type="number"
                                        value={salesProductSpent.minimumSpend}
                                    />
                                </InputGroup>
                            </Col>
                            <Col md={1}>
                                <h4 className={classes.onLabel}>on</h4>
                            </Col>
                            <Col md={4}>
                                <DropDown
                                    items={SALES_LIST}
                                    onChange={setProductCategoryId}
                                    placeholder="Select category"
                                    value={getIn(
                                        ["categoryIds", 0],
                                        salesProductSpent,
                                    )}
                                />
                            </Col>
                        </Row>
                    </InputRow>
                )}
            </Col>
        </Row>
    );
});
