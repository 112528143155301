import {Col, Row} from "react-bootstrap";
import DetailRow from "../../../widget/DetailRow";
//@ts-ignore
import filterListByProp from "../filterListByProp";
//@ts-ignore
import MapWithCircle from "../../MapWithCircle";
import React, {memo} from "react";

export default memo(function Location({userGeo}: any): JSX.Element {
    if (userGeo.isEmpty()) {
        return (
            <Row>
                <hr />
                <Col md={8}>
                    <DetailRow label="LOCATION:">All Location</DetailRow>
                </Col>
            </Row>
        );
    }

    const filterByOperation = filterListByProp(userGeo, "operation");
    //@ts-ignore
    const latestCoordinate = filterByOperation("latestCoordinate").first();

    const latitude = latestCoordinate.getIn(["value", "latitude"]);
    const longitude = latestCoordinate.getIn(["value", "longitude"]);
    const radius = latestCoordinate.getIn(["value", "maxDistanceInMeters"]);

    const markers = [
        {
            position: {
                lat: latitude,
                lng: longitude,
            },
            key: "1",
            defaultAnimation: 2,
            draggable: false,
        },
    ];

    return (
        <Row>
            <hr />
            <Col md={8}>
                <DetailRow label="LOCATION:">Specific</DetailRow>
                <DetailRow label="Longitude:">{longitude}</DetailRow>
                <DetailRow label="Latitude:">{latitude}</DetailRow>
                <DetailRow label="Radius:">{radius} meters</DetailRow>
            </Col>
            <Col md={4}>
                <MapWithCircle markers={markers} radius={radius} />
            </Col>
        </Row>
    );
});
