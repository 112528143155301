import {Col} from "react-bootstrap";
import {
    getLinkActionFromUrl,
    getProductSkuFromUrl,
    isDeepLinkingUrl,
} from "../../pushNotification/messageToFormFormat";
import {linkActions} from "../../pushNotification/PushNotification";
import DetailRow from "../../widget/DetailRow";
import React, {memo} from "react";
import Image from "react-graceful-image";
import {makeStyles} from "@material-ui/core";
import {Tooltip, OverlayTrigger} from "react-bootstrap";

const useStyles = makeStyles({
    image: {
        maxWidth: "100%",
        height: "auto",
    },
});

export default memo(function FbNotification({
    fbNotification,
}: any): JSX.Element {
    const classes = useStyles();
    const buttonText = fbNotification.getIn(["actions", 1, "content", "text"]);
    const dismissButtonText = fbNotification.getIn([
        "actions",
        0,
        "content",
        "text",
    ]);
    const messageBody = fbNotification.getIn(["body", "content", "text"]);
    const imageUrl = fbNotification.getIn(["hero", "background", "url"]);
    const buttonUrl = fbNotification.getIn(["actions", 1, "url"]);

    const websiteUrl = isDeepLinkingUrl(buttonUrl) ? "" : buttonUrl;
    const buttonAction = getLinkActionFromUrl(buttonUrl);
    const productSku = getProductSkuFromUrl(buttonUrl);
    const hasButtonAction = buttonAction !== linkActions.NONE;

    return (
        <Col md={8}>
            {imageUrl && (
                <DetailRow label="IMAGE:">
                    <OverlayTrigger
                        placement="right"
                        overlay={<Tooltip id="tooltip">{imageUrl}</Tooltip>}
                    >
                        <div>
                            <Image src={imageUrl} className={classes.image} />
                        </div>
                    </OverlayTrigger>
                </DetailRow>
            )}

            <DetailRow label="MESSAGE BODY:">{messageBody}</DetailRow>
            <DetailRow label="DISMISS BUTTON TEXT:">
                {dismissButtonText}
            </DetailRow>
            <DetailRow label="BUTTON ACTION:">{buttonAction}</DetailRow>
            {websiteUrl && (
                <DetailRow label="BUTTON URL:">{websiteUrl}</DetailRow>
            )}
            {productSku && (
                <DetailRow label="PRODUCT SKU:">{productSku}</DetailRow>
            )}
            {hasButtonAction && (
                <DetailRow label="BUTTON TEXT:">{buttonText}</DetailRow>
            )}
        </Col>
    );
});
