/*  */

import * as R from "ramda";

import addAuthToHttpAgent from "./addAuthToHttpAgent";
import addPrefixToHttpAgent from "./addPrefixToHttpAgent";
import addTeamNumberToHttpAgent from "./addTeamNumberToHttpAgent";
import addTimeout from "./addTimeoutToHttpAgent";
import createApiInvocation from "./createApiInvocation";
import httpAgent from "./httpAgent";
import account from "./routes/account";
import {
    archiveAdvert,
    deleteAdvert,
    getAdverts,
    multiDeleteAdvert,
    postAdvert,
    updatePlacements,
} from "./routes/advert";
import appMetrics from "./routes/appMetrics";
import appReviews from "./routes/appReviews";
import appSalesFigures from "./routes/appSalesFigures";
import {getAverageBasketSize} from "./routes/averageBasketSize";
import {
    deleteGroupNotification,
    getGroupedNotifications,
    postGroupNotification,
    updateGroupNotifications,
} from "./routes/groupedNotifications";
import messageAudiences from "./routes/messageAudiences";
import {
    deleteNotification,
    getNotification,
    getNotifications,
    postNotification,
    putNotification,
} from "./routes/notification";
import {getTotalAppDownloads} from "./routes/totalAppDownloads";
import {getTotalAppTransactions} from "./routes/totalAppTransactions";
import {getTotalNumberOfOrders} from "./routes/totalNumberOfOrders";
import userSegmentsForApp from "./routes/userSegmentsForApp";
// eslint-disable-line no-duplicate-imports

const endpointHandler = R.curry((apiConfig, handler, payload) => {
    const configuredHandler = R.curry(handler)(httpAgent);
    const addAuth = R.curry(addAuthToHttpAgent)(
        apiConfig.username,
        apiConfig.password,
    );
    const addPrefix = R.curry(addPrefixToHttpAgent)(apiConfig.urlPrefix);
    const addTeam = R.curry(addTeamNumberToHttpAgent)(apiConfig.teamNumber);
    const configureHttpAgent = R.compose(
        addTimeout,
        addTeam,
        addPrefix,
        addAuth,
        configuredHandler,
    );

    const configuredHttpAgent = configureHttpAgent(payload);

    return configuredHttpAgent;
});

const validateConfigurationOrThrow = ({username, password, teamNumber}) => {
    if (!username) {
        throw new Error("username required");
    }

    if (!password) {
        throw new Error("password required");
    }

    if (isNaN(teamNumber)) {
        throw new Error("teamNumber required");
    }

    return true;
};

export default (apiConfig) => {
    validateConfigurationOrThrow(apiConfig);

    const makeEndpointHandler = R.compose(
        createApiInvocation,
        R.curry(endpointHandler)(apiConfig),
    );

    const makeEndpointHandlerNotTakingArgument = (handler) => {
        const handlerIgnoringData = (httpAgent, ignoredData) =>
            handler(httpAgent); // eslint-disable-line no-unused-vars

        return () => makeEndpointHandler(handlerIgnoringData)({});
    };

    return {
        account: makeEndpointHandlerNotTakingArgument(account),
        deleteAdvert: makeEndpointHandler(deleteAdvert),
        archiveAdvert: makeEndpointHandler(archiveAdvert),
        multiDeleteAdvert: makeEndpointHandler(multiDeleteAdvert),
        deleteNotification: makeEndpointHandler(deleteNotification),
        deleteGroupNotification: makeEndpointHandler(deleteGroupNotification),
        getAdverts: makeEndpointHandler(getAdverts),
        getAppMetrics: makeEndpointHandler(appMetrics),
        getAppReviews: makeEndpointHandler(appReviews),
        getAppSalesFigures: makeEndpointHandler(appSalesFigures),
        getTotalAppTransactions: makeEndpointHandler(getTotalAppTransactions),
        getTotalAppDownloads: makeEndpointHandler(getTotalAppDownloads),
        getNotification: makeEndpointHandler(getNotification),
        getNotifications: makeEndpointHandler(getNotifications),
        getUserSegmentsForApp: makeEndpointHandlerNotTakingArgument(
            userSegmentsForApp,
        ),
        getGroupedNotifications: makeEndpointHandler(getGroupedNotifications),
        postAdvert: makeEndpointHandler(postAdvert),
        postNotification: makeEndpointHandler(postNotification),
        putNotification: makeEndpointHandler(putNotification),
        postMessageAudiences: makeEndpointHandler(messageAudiences),
        postGroupNotification: makeEndpointHandler(postGroupNotification),
        updateGroupNotifications: makeEndpointHandler(updateGroupNotifications),
        getTotalNumberOfOrders: makeEndpointHandler(getTotalNumberOfOrders),
        getAverageBasketSize: makeEndpointHandler(getAverageBasketSize),
        updatePlacements: makeEndpointHandler(updatePlacements),
    };
};
