import {createSelector, createStructuredSelector} from "reselect";
import immutable from "immutable";

export const ACTION_NONE = "none";
export const ACTION_VIEW_WEBSITE = "viewWebsite";
export const ACTION_VIEW_PRODUCT_DETAILS = "viewProductDetail";
export const ACTION_VIEW_PRODUCT_LIST = "viewProductList";
export const ACTION_ADD_PRODUCT_TO_BASKET = "addProductToBasket";
export const ACTION_ADD_PRODUCT_TO_LIST = "addProductToList";

export const getPlacements = (state) => state.advert.get("placements");

export default createStructuredSelector({
    placements: getPlacements,
});
