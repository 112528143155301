import formatNumber from "../common/formatNumber";
import {
    getLinkActionFromUrl,
    getProductSkuFromUrl,
    isAddToBasketUrl,
    isViewProductUrl,
} from "../pushNotification/messageToFormFormat";
import {calculateActionedPercentage, displayClickedCount} from "./AdvertTable";

interface TableData {
    actionLink: string;
    actionParams: string;
    actionType: string;
    clickedCount: number;
    deletedAt: string;
    description: string;
    endDate: string;
    height: number;
    id: string;
    imageUrl: string;
    order: number;
    placement: string;
    seenCount: number;
    startDate: string;
    teamName: string;
    teamNumber: number;
    width: number;
    linkedProduct: string;
    videoUrl: string;
    relatedProducts: string;
}

export enum ADVERT_CSV_HEADER {
    SKU = "SKU",
    IMAGE = "IMAGE",
    ADVERT_TYPE = "ADVERT_TYPE",
    DESCRIPTION = "DESCRIPTION",
    ACTION = "ACTION",
    URL_OR_PRODUCT_SKU = "URL_OR_PRODUCT_SKU",
    RELATED_PRODUCTS = "RELATED_PRODUCTS",
    DISPLAY_COUNT = "DISPLAY_COUNT",
    CLICKED_COUNT = "CLICKED_COUNT",
    ACTIONED = "ACTIONED",
    MEMBER_APP = "MEMBER_APP",
    START_DATE = "START_DATE",
    END_DATE = "END_DATE",
}

const getDataForAHeader = (
    headerName: ADVERT_CSV_HEADER,
    item: TableData,
    adTitles: any,
): any => {
    if (headerName === ADVERT_CSV_HEADER.SKU) {
        return item.linkedProduct || "";
    }

    if (headerName === ADVERT_CSV_HEADER.IMAGE) {
        return item.videoUrl || item.imageUrl || "";
    }

    if (headerName === ADVERT_CSV_HEADER.ADVERT_TYPE) {
        return adTitles[item.placement] || "";
    }

    if (headerName === ADVERT_CSV_HEADER.DESCRIPTION) {
        return item.description || "";
    }

    if (headerName === ADVERT_CSV_HEADER.ACTION) {
        return getLinkActionFromUrl(item.actionLink) || "";
    }

    if (headerName === ADVERT_CSV_HEADER.URL_OR_PRODUCT_SKU) {
        const websiteUrl =
            isViewProductUrl(item.actionLink) ||
            isAddToBasketUrl(item.actionLink);
        const productSku = getProductSkuFromUrl(item.actionLink);
        return productSku || websiteUrl || "";
    }

    if (headerName === ADVERT_CSV_HEADER.RELATED_PRODUCTS) {
        return getProductSkuFromUrl(item.relatedProducts) || "";
    }

    if (headerName === ADVERT_CSV_HEADER.DISPLAY_COUNT) {
        return formatNumber(item.seenCount || 0);
    }

    if (headerName === ADVERT_CSV_HEADER.CLICKED_COUNT) {
        return (
            displayClickedCount({
                clickedCount: item.clickedCount,
                actionLink: item.actionLink,
            }) || 0
        );
    }

    if (headerName === ADVERT_CSV_HEADER.ACTIONED) {
        const seenCount = formatNumber(item.seenCount || 0);
        const actionedPercentage = calculateActionedPercentage({
            clickedCount: `${item.clickedCount || 0}`,
            seenCount,
            actionLink: item.actionLink,
        });

        return actionedPercentage;
    }

    if (headerName === ADVERT_CSV_HEADER.MEMBER_APP) {
        return item.teamName || "";
    }

    if (headerName === ADVERT_CSV_HEADER.START_DATE) {
        return item.startDate || "";
    }

    if (headerName === ADVERT_CSV_HEADER.END_DATE) {
        return item.endDate || "";
    }
};
export default function getAdvertTableDataForExport(
    tableData: Array<TableData>,
    headers: Array<ADVERT_CSV_HEADER>,
    adTitles: any,
) {
    const csvData = tableData.map((item) => {
        return headers.map((header) => {
            return getDataForAHeader(header, item, adTitles);
        });
    });
    return [[...headers], ...csvData];
}
