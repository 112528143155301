import * as R from "ramda";
// @ts-ignore
import {BESTWAY_COLOUR_SET} from "../../widget/chart/colourSets";
// @ts-ignore
import ContainerDimensions from "react-container-dimensions";
import createCSVFromGroupedBarChart from "./createCSVDataFromGroupedBarChart";
import DashboardWidget, {STATUSES} from "../widgets/DashboardWidget";
// @ts-ignore
import EngagementScatterGraph from "../../widget/chart/EngagementScatterGraph";
import ExportableChartContainer from "./ExportableChartContainer";
import groupBarChartFormatter from "./groupBarChartFormatter";
import GroupedBarChart from "./GroupedBarChart";
import immutable, {List} from "immutable";
import previousToCurrentChartDataFormatter from "./previousToCurrentChartDataFormatter";
import React, {memo} from "react";

interface Props {
    axisTitles: Object;
    chartData: List<any>;
    infoPopOverText?: string;
    title: string;
    widgetStatus: number;
    tickFormat?: (value: number) => string;
    toolTipFormat?: any;
    showEngagement?: boolean;
    useRecentEngagementData?: boolean;
    filter?: any;
}

const formatChartData: any = R.compose(
    groupBarChartFormatter,
    previousToCurrentChartDataFormatter,
);

const CONTAINER_HEIGHT = 400;

const sortDatasetWithMaxValueFirst = (datasets: any) => {
    const datasetsCopy = [...datasets];

    return datasetsCopy.sort((datasetA, datasetB) => {
        const maxUsersForA = Math.max(datasetA[0].value + datasetA[1].value);
        const maxUsersForB = Math.max(datasetB[0].value + datasetB[1].value);

        return maxUsersForB - maxUsersForA;
    });
};

const calculateCoordinateRange = (datasets: any = []) => {
    if (datasets.length === 0) {
        return [];
    }

    const sortedDataPoints = sortDatasetWithMaxValueFirst(datasets);
    const dataPointWithTotalMaxValue = sortedDataPoints[0];
    const dataPointWithLowestValue = sortedDataPoints[datasets.length - 1];

    return {
        lower: Math.min(
            dataPointWithLowestValue[0].value,
            dataPointWithLowestValue[1].value,
        ),
        upper: Math.max(
            dataPointWithTotalMaxValue[0].value,
            dataPointWithTotalMaxValue[1].value,
        ),
    };
};

const shouldRenderChart = (data: {datasets: any}) => {
    if (!data || !data.datasets) {
        return false;
    }

    const flattenedDataSets = data.datasets.reduce(
        (flattenedData: any, current: any) => [...flattenedData, ...current],
        [],
    );
    const totalAmount = flattenedDataSets.reduce(
        (total: number, current: {value: number}) => total + current.value,
        0,
    );

    return totalAmount > 0;
};

export default memo(function HistogramChart({
    axisTitles = {},
    chartData = immutable.List(),
    title,
    widgetStatus,
    infoPopOverText,
    showEngagement,
    tickFormat,
    toolTipFormat,
    useRecentEngagementData,
}: Props) {
    const barChartData = chartData.isEmpty()
        ? {}
        : formatChartData(chartData.toJS());
    const showChart = shouldRenderChart(barChartData);

    return (
        <ContainerDimensions>
            {({width}: {width: number}) => (
                <DashboardWidget
                    status={
                        widgetStatus === STATUSES.normal && !showChart
                            ? STATUSES.dataUnavailable
                            : widgetStatus
                    }
                >
                    <ExportableChartContainer
                        csvData={createCSVFromGroupedBarChart(
                            barChartData.datasets || [],
                            {
                                groupLabel: "Compared Weeks",
                                dataLabels: ["Last Week", "This Week"],
                            },
                        )}
                        infoPopOverText={infoPopOverText}
                        title={title}
                    >
                        <GroupedBarChart
                            nullGraph={!showChart}
                            axisLabels={barChartData.axisLabels}
                            axisTitles={axisTitles}
                            barColors={BESTWAY_COLOUR_SET}
                            datasets={barChartData.datasets}
                            height={CONTAINER_HEIGHT}
                            horizontalTickFormat={tickFormat}
                            legends={["Previous", "Current"]}
                            showEngagement={showEngagement}
                            tickIsDate
                            toolTipFormat={toolTipFormat}
                            width={width}
                        >
                            {showChart && showEngagement && (
                                <EngagementScatterGraph
                                    calculateCoordinateRange={
                                        calculateCoordinateRange
                                    }
                                    dataPoints={barChartData.datasets}
                                    fillStyle={{fill: BESTWAY_COLOUR_SET[0]}}
                                    useRecentEngagementData={
                                        useRecentEngagementData
                                    }
                                />
                            )}
                        </GroupedBarChart>
                    </ExportableChartContainer>
                </DashboardWidget>
            )}
        </ContainerDimensions>
    );
});
