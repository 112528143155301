/*  */

import {action, observable} from "mobx";
import {observer} from "mobx-react";
import ContainerDimensions from "react-container-dimensions";
import createCSVDataFromMultiLineChart from "../createCSVDataFromMultiLineChart";
import DashboardWidget, {STATUSES} from "../../widgets/DashboardWidget";
import FilteredChart from "./FilteredChart";
import EngagementScatterGraph from "../../../widget/chart/EngagementScatterGraph";
import ExportableChartContainer from "../ExportableChartContainer";
import formatNumber from "../../../common/formatNumber";
import immutable from "immutable";
import MultiLineChart from "../../../widget/chart/MultiLineChart";
import React from "react";

const colorScheme = ["00a65a"];
const CHART_PADDING = {top: 40, bottom: 100};
const AXIS_TITLES = {
    y: "Total Downloads",
};
const TOTAL_DOWNLOADS_CHART_INFO =
    "Illustrates the total cumulative downloads of your app. The increase/decrease vs. the last time period is also given.";

const styles = Object.freeze({
    additionals: {
        color: "#00a65a",
    },
    topLabel: {
        fontSize: 18,
        textAlign: "center",
    },
    widgetContainer: {
        display: "block",
        textAlign: "center",
        margin: "0 auto",
    },
});

@observer
export default class TotalDownloadChart extends FilteredChart {
    @observable
    chartData = immutable.List();
    @observable
    totalDownload = 0;
    @observable
    absoluteChange = 0;

    @action
    onDataDidLoad({absoluteChange, chartData, totalDownload}) {
        this.chartData = immutable.List([
            immutable.Map({
                dataSets: immutable.List(chartData),
                lineChartName: "Total Download Chart",
            }),
        ]);
        this.totalDownload = totalDownload;
        this.absoluteChange = absoluteChange;
    }

    render() {
        const {absoluteChange, chartData, status, totalDownload} = this;

        if (
            status === STATUSES.dataUnavailable ||
            status === STATUSES.dataLoading
        ) {
            return null;
        }

        return (
            <ContainerDimensions>
                {({width}) => (
                    <ExportableChartContainer
                        csvData={createCSVDataFromMultiLineChart(
                            chartData.toJS(),
                            {
                                xLabel: "Date",
                                yLabel: AXIS_TITLES.y,
                            },
                        )}
                        infoPopOverText={TOTAL_DOWNLOADS_CHART_INFO}
                        title="Total App Downloads"
                    >
                        <DashboardWidget
                            status={status}
                            style={styles.widgetContainer}
                        >
                            {status === STATUSES.normal ? (
                                <div style={styles.topLabel}>
                                    {`TOTAL ALL-TIME DOWNLOADS ${formatNumber(
                                        totalDownload,
                                    )} `}
                                    <span
                                        style={styles.additionals}
                                    >{`+ ${formatNumber(
                                        absoluteChange,
                                    )}`}</span>
                                </div>
                            ) : null}
                            <MultiLineChart
                                axisTitles={AXIS_TITLES}
                                chartData={chartData}
                                chartDomain={{
                                    y: [
                                        totalDownload - absoluteChange,
                                        totalDownload +
                                            absoluteChange *
                                                EngagementScatterGraph.VERTICAL_OFFSET_PERCENT,
                                    ],
                                }}
                                chartPadding={CHART_PADDING}
                                colorScheme={colorScheme}
                                height={400}
                                showEngagement
                                solidArea
                                width={width}
                                yAxis={{
                                    title: "",
                                    tickFormat: (tick) => formatNumber(tick),
                                }}
                            />
                        </DashboardWidget>
                    </ExportableChartContainer>
                )}
            </ContainerDimensions>
        );
    }
}
