import React, {memo, CSSProperties} from "react";
//@ts-ignore
import PhoneStock from "./stockphone-no-clock.png";
import {makeStyles} from "@material-ui/core";
import classNames from "classnames";

const useStyles = makeStyles({
    visualisation: {
        backgroundImage: `url("${PhoneStock}")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "left",
        fontFamily: '-apple-system, "Helvetica Neue", Helvetica, sans-serif',
        minHeight: "540px",
        width: "273px",
    },
    notificationContainer: {
        paddingTop: 100,
        marginLeft: "10%",
        marginRight: "10%",
    },
    richNotificationContainer: {
        background: "rgba(240, 240, 240, 0.9)",
        borderRadius: 5,
        paddingBottom: 1,
    },
    image: {
        height: 218,
        width: 218,
        borderRadius: "5px 5px 0 0",
    },
    bodyText: {
        fontSize: 9,
        padding: "5px 10px 0",
    },
    button: {
        color: "rgb(255, 255, 255)",
        borderRadius: 5,
        background: "rgb(255, 34, 85)",
        margin: 5,
        height: 25,
        lineHeight: "25px",
        textAlign: "center",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    dismiss: {
        background: "rgb(180, 180, 180)",
    },
    hidden: {
        backgroundColor: "transparent",
        height: 5,
    },
    imagePreview: (props: CSSProperties) => ({
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center left",
        backgroundImage: props.backgroundImage,
    }),
});

interface Props {
    dismissButtonText?: string;
    enableButtonText?: boolean | string;
    image?: string;
    imageUri?: string;
    bodyText?: string;
}

export default memo(function RichNotification({
    dismissButtonText,
    enableButtonText,
    image,
    imageUri,
    bodyText,
}: Props): JSX.Element {
    const bgImage = image || imageUri;
    const props = {backgroundImage: `url('${bgImage}')`};
    const classes = useStyles(props);
    const parentStyle = classNames("image", classes.visualisation);
    const dismissButtonStyle = classNames(
        classes.button,
        enableButtonText ? classes.dismiss : null,
    );
    const imageStyle = classNames(
        classes.imagePreview,
        classes.image,
        bgImage ? null : classes.hidden,
    );
    return (
        <div className={parentStyle}>
            <div className={classes.notificationContainer}>
                <div className={classes.richNotificationContainer}>
                    {Boolean(image || imageUri) && (
                        <div className={imageStyle}></div>
                    )}
                    <div className={classes.bodyText}>
                        {Boolean(bodyText) ? bodyText : "Body Text Here"}
                    </div>
                    <div className={dismissButtonStyle}>
                        {dismissButtonText}
                    </div>
                    {enableButtonText && (
                        <div className={classes.button}>{enableButtonText}</div>
                    )}
                </div>
            </div>
        </div>
    );
});
