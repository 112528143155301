import {
    VictoryAxis,
    VictoryBar,
    VictoryChart,
    VictoryGroup,
    VictoryLabel,
    VictoryTooltip,
    VictoryVoronoiContainer,
} from "victory";
import formatNumber from "./formatNumber";
import moment from "moment";
import React, {memo} from "react";

const axisStyle = Object.freeze({
    tickLabels: {fontSize: 14},
    ticks: {
        stroke: "rgb(0, 0, 0)",
        size: 4,
    },
});

const horizontalAxis = Object.freeze({
    ...axisStyle,
    axisLabel: {
        fontSize: 15,
        padding: 40,
    },
    tickLabels: {
        fontSize: 13,
        angle: -90,
        textAnchor: "end",
        verticalAnchor: "middle",
        padding: 5,
    },
});

const styles = Object.freeze({
    chartPadding: {
        left: 85,
        right: 30,
        top: 70,
        bottom: 100,
    },
    verticalAxis: {
        ...axisStyle,
        axisLabel: {
            fontSize: 12,
            padding: 75,
        },
    },
    horizontalAxis,
    tooltip: {
        opacity: 1,
        fontSize: 12,
    },
    tootipFlyoutStyle: {
        fill: "rgb(255, 255, 255)",
    },
    toolTip: {
        padding: 0,
    },
});

// export type  ToolTipFormat = (label: {barLabel: string; y: string}) => string
interface Bar {
    label: string;
    value: number | string;
}
interface Props {
    animate?: {
        duration: number;
    };
    axisLabels: Array<string>;
    axisTitles?: {
        x?: string;
        y?: string;
    };
    barColors?: Array<string>;
    children?: any;
    datasets: Array<Array<Bar>>;
    height?: number;
    horizontalTickFormat?: (value: number) => string;
    legends: Array<string>;
    tickIsDate?: boolean;
    tickLabelStyle?: {
        angle: number;
    };
    toolTipFormat?: {
        label: {barLabel: string; y: string};
    };
    width?: number;
    nullGraph?: boolean;
    showEngagement?: boolean;
}

const convertToHuman = (date: string) => {
    const newDate = new Date(date);

    return moment.utc(newDate).isValid()
        ? moment.utc(newDate).format("ddd MMM DD YYYY")
        : "";
};

const defaultToolTipFormatter = ({
    legendName,
    barLabel,
    y,
}: {
    legendName: string;
    barLabel: string;
    y: number;
}) => `${legendName} \n ${convertToHuman(barLabel)}: \n ${formatNumber(y)}`;

const renderToolTip = (toolTipFormatter?: {
    label: {barLabel: string; y: string};
}) => {
    const labelValue: any = toolTipFormatter || defaultToolTipFormatter;

    return (
        <VictoryVoronoiContainer
            labelComponent={
                <VictoryTooltip
                    cornerRadius={2}
                    flyoutStyle={styles.tootipFlyoutStyle}
                    style={styles.toolTip}
                />
            }
            labels={labelValue}
        />
    );
};

export default memo(function GroupedBarChart({
    animate,
    axisTitles = {
        x: "",
        y: "",
    },
    axisLabels = [],
    barColors = [],
    children,
    datasets,
    legends,
    width = 600,
    tickLabelStyle,
    tickIsDate,
    horizontalTickFormat,
    toolTipFormat,
    nullGraph,
}: Props) {
    const OFFSET = 4;
    const MULTIPLIER = 2;

    const graphData = nullGraph && !datasets ? [] : datasets;
    const graphDataLength = graphData.length || 1;
    const barWidth = Math.floor(width / OFFSET / graphDataLength);
    const domainPadding = Object.freeze({
        x: barWidth * MULTIPLIER,
    });
    const xAxisLabels = tickIsDate
        ? axisLabels.map(convertToHuman)
        : axisLabels;
    const victoryBars = legends.map((legend: string, legendIndex: number) => {
        const bardata = graphData.map(
            (dataset: Array<Bar>, datasetsIndex: number) => {
                const barValues = dataset[legendIndex];
                const dataSetValue = datasetsIndex + 1;

                return {
                    x: dataSetValue,
                    y: barValues.value || 0,
                    legendName: legend,
                    barLabel: barValues.label,
                };
            },
        );

        const barStyle = Object.freeze({
            data: {
                width: barWidth,
                fill: nullGraph ? "transparent" : barColors[legendIndex],
            },
        });

        return <VictoryBar key={legendIndex} data={bardata} style={barStyle} />;
    });

    return (
        <VictoryChart
            containerComponent={renderToolTip(toolTipFormat)}
            animate={animate}
            domainPadding={domainPadding}
            padding={styles.chartPadding}
            width={width}
        >
            <VictoryAxis
                label={axisTitles.x}
                style={{
                    ...horizontalAxis,
                    tickLabels: {
                        ...horizontalAxis.tickLabels,
                        ...tickLabelStyle,
                    },
                }}
                tickValues={xAxisLabels}
            />
            <VictoryAxis
                axisLabelComponent={
                    <VictoryLabel text={axisTitles.y} y={120} />
                }
                dependentAxis
                label={axisTitles.y}
                style={styles.verticalAxis}
                tickFormat={horizontalTickFormat}
            />
            <VictoryGroup colorScale="qualitative" offset={barWidth}>
                {victoryBars}
            </VictoryGroup>
            {children}
        </VictoryChart>
    );
});
