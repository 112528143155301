import React, {memo} from "react";
import * as R from "ramda";
import {Col, Row} from "react-bootstrap";
import {EDIT_MSG_PATH} from "./PushNotification";
// @ts-ignore
import AudienceSelection from "./audience/MessageAudienceSelection";
// @ts-ignore
import MessageAler from "./editors/MessageAlert";
// @ts-ignore
import NotificationTypeDropDown from "./editors/NotificationType";
// @ts-ignore
import PredictiveAudienceSize from "./audience/PredictiveAudienceSize";
// @ts-ignore
import RichNotificationEditor from "./editors/RichPushNotification";
// @ts-ignore
import ScheduleEditor from "./editors/ScheduleForm";
import StepContainer from "../widget/StepContainer";
import SubmitAndNavigateButton from "../common/SubmitAndNavigateButton";
import {PushNotificationHooksContainerProps} from "./PushNotificationFormContainer";
import {FormControl, FormGroup} from "react-bootstrap";
import {getIn} from "../common/ramdaDataUtils";

interface SendProps {
    remaining: any;
    submitForm: () => Promise<{success: boolean; isImageUploadError?: boolean}>;
    onSetMessageAlert: (notificationType: any, value: any) => void;
    messageAlert: {
        description: {
            value: string;
        };
        value: string;
        urlToOpen: {
            value: string;
        };
    };
}

const Send = ({
    remaining,
    submitForm,
    onSetMessageAlert,
    messageAlert,
}: SendProps) => {
    const reminderMessages: {[property: string]: string} = {
        audience: "Provide a valid audience selection",
        message: "Provide an alert message",
        schedule: "Provide a schedule",
        url: "Provide a valid URL",
        fbNotification: "Check the rich content fields",
        notificationType: "Select a notification type",
        associatedCampaign: "Please select campaign",
    };

    return (
        <div>
            <div>
                <h4>Description:</h4>
                <FormGroup>
                    <FormControl
                        onChange={(event: any): any => {
                            onSetMessageAlert(
                                ["description", "value"],
                                event.target.value,
                            );
                        }}
                        placeholder="e.g. Supplier name"
                        type="text"
                        value={getIn(["description", "value"], messageAlert)}
                    />
                </FormGroup>
            </div>
            {remaining.length ? (
                <div>
                    <h4>Before you can send:</h4>
                    <ul>
                        {remaining.map((name: string, i: number) => (
                            <li key={i}>{reminderMessages[name]}</li>
                        ))}
                    </ul>
                </div>
            ) : (
                <div>
                    <h4>All set!</h4>
                    <SubmitAndNavigateButton
                        onSubmit={submitForm}
                        path="/message"
                    >
                        Send message
                    </SubmitAndNavigateButton>
                </div>
            )}
        </div>
    );
};

interface Props extends PushNotificationHooksContainerProps {
    featureFlags: any;
    isDuplicate: boolean;
}

export default memo(function PushNotificationForm({
    stepContainerForm = {},
    openNextStep,
    onToggleStep,
    remaining = [],
    onSubmitForm,
    match,
    featureFlags = {},
    isDuplicate,
    messageAlert,
    onSetMessageAlert,
}: Props) {
    const messagingFeatureFlags = featureFlags.messaging;

    const startIndex = 2;
    const features: {[property: string]: any} = {
        message: {
            editor: () => <MessageAler />,
            title: "Alert",
        },
        fbNotification: {
            editor: () => (
                <RichNotificationEditor
                    enableProductDeeplink={
                        R.path(
                            ["fbNotification", "productDeeplink"],
                            messagingFeatureFlags,
                        ) || false
                    }
                    enableProductDeeplinkWithoutLists={
                        R.path(
                            ["fbNotification", "productDeeplinkWithoutLists"],
                            messagingFeatureFlags,
                        ) || false
                    }
                    enableProductDetailsDeeplink={
                        R.path(
                            ["fbNotification", "productDetailsDeeplink"],
                            messagingFeatureFlags,
                        ) || false
                    }
                    enableProductUrl={
                        R.path(
                            ["fbNotification", "productUrl"],
                            messagingFeatureFlags,
                        ) || false
                    }
                />
            ),
            title: "Message",
        },
        schedule: {
            editor: () => <ScheduleEditor />,
            title: "Schedule",
        },
    };

    const featuredKeys = Object.keys(messagingFeatureFlags) || [];

    const enabledFeature = featuredKeys.filter((item) => {
        if (item === "audience") {
            return false;
        }

        return Boolean(messagingFeatureFlags[item]);
    });

    const enabledFeaturesWithSend = [...enabledFeature, "send"];
    const remainingWithOutDisabledFeatures = R.intersection(
        enabledFeature,
        remaining,
    );
    const isUpdate = match.path === EDIT_MSG_PATH;
    const {id, groupId} = match.params;

    return (
        <Row>
            <Col md={9}>
                <div>
                    <StepContainer
                        error={false}
                        badgeTitle={"1"}
                        done={!remaining.includes("audience")}
                        onHeaderClick={(): void => onToggleStep("audience")}
                        onNextButtonClick={(): void =>
                            openNextStep({
                                current: "audience",
                                next: enabledFeature[0],
                            })
                        }
                        open={stepContainerForm.audience}
                        stepTitle={"Audience Selection"}
                    >
                        <AudienceSelection />
                    </StepContainer>
                    {enabledFeature.map((featureName: string, key: number) => (
                        <StepContainer
                            error={false}
                            key={key}
                            badgeTitle={key + startIndex}
                            done={!remaining.includes(featureName)}
                            onHeaderClick={(): void =>
                                onToggleStep(featureName)
                            }
                            onNextButtonClick={(): void =>
                                openNextStep({
                                    current: featureName,
                                    next: enabledFeaturesWithSend[key + 1],
                                })
                            }
                            open={stepContainerForm[featureName]}
                            stepTitle={features[featureName].title}
                        >
                            {features[featureName].editor()}
                        </StepContainer>
                    ))}
                    <StepContainer
                        error={false}
                        badgeTitle={enabledFeature.length + startIndex}
                        done={remaining.length === 0}
                        isLastStep
                        onHeaderClick={(): void => onToggleStep("send")}
                        open={stepContainerForm.send}
                        stepTitle={"Review and Send/Save"}
                    >
                        <>
                            <Send
                                remaining={remainingWithOutDisabledFeatures}
                                onSetMessageAlert={onSetMessageAlert}
                                messageAlert={messageAlert}
                                submitForm={() =>
                                    isUpdate
                                        ? onSubmitForm({
                                              groupId,
                                              notificationId: id,
                                              saveAsDraft: false,
                                          })
                                        : onSubmitForm({groupId})
                                }
                            />

                            <div>
                                <h4>or</h4>
                                <div
                                    style={{
                                        paddingTop: "10px",
                                        paddingBottom: "10px",
                                    }}
                                >
                                    <SubmitAndNavigateButton
                                        onSubmit={() => {
                                            if (isUpdate && !isDuplicate) {
                                                return onSubmitForm({
                                                    groupId,
                                                    notificationId: id,
                                                    saveAsDraft: true,
                                                });
                                            } else {
                                                return onSubmitForm({
                                                    groupId,
                                                    notificationId: isDuplicate
                                                        ? ""
                                                        : id,
                                                    saveAsDraft: true,
                                                });
                                            }
                                        }}
                                        path="/message"
                                    >
                                        Save as Draft
                                    </SubmitAndNavigateButton>
                                </div>
                            </div>
                        </>
                    </StepContainer>
                </div>
            </Col>
            <Col md={3}>
                <PredictiveAudienceSize />
            </Col>
        </Row>
    );
});
