import * as R from "ramda";
import {Team} from "./types";
import {UserData} from "../main/SessionContext";

export interface ApiTeam {
    teamName: string;
    teamNumber: number;
}

export interface ApiUser {
    email: string;
    firstName: string;
    surname: string;
    teamInfo: Array<ApiTeam>;
}

const apiTeamToLocalTeam = R.map(
    ({teamName, teamNumber}: {teamName: string; teamNumber: number}): Team => ({
        name: teamName,
        teamNumber,
    }),
);

export default (
    {email, firstName, surname, teamInfo}: ApiUser,
    password: string,
): UserData => ({
    activeTeam: undefined,
    emailAddress: email,
    firstName,
    password,
    surname,
    teams: apiTeamToLocalTeam(teamInfo),
});
