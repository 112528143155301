import * as R from "ramda";
import {FormattedNumber} from "react-intl";
import Box from "../../widget/Box";
import DashboardWidget, {STATUSES} from "./DashboardWidget";
import getPercentageStringForAppSalesFigures from "./getPercentageStringForAppSalesFigures";
import React, {memo} from "react";
import {makeStyles} from "@material-ui/styles";
import classnames from "classnames";
interface Figures {
    currentMonth: number;
    currentWeek: number;
    currentYear: number;
    previousMonth: number;
    previousWeek: number;
    previousYear: number;
}

const useStyles = makeStyles({
    widgetContainer: {
        display: "block",
        textAlign: "center",
        margin: "0 auto",
        fontSize: 16,
        padding: "10px",
    },
    header: {
        fontWeight: "bold",
        marginBottom: "10px",
        display: "flex",
        flex: 1,
        flexDirection: "row",
    },
    headerFieldName: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        flex: 1,
        fontSize: 16,
    },
    headerFieldLabel: {
        textAlign: "right",
    },
    headerContent: {
        textAlign: "right",
        display: "flex",
        alignItems: "flex-end",
        flex: 1,
        justifyContent: "flex-end",
    },
    rowDatacontainer: {
        display: "flex",
        flex: 1,
        flexDirection: "row",
        marginBottom: "10px",
    },
    increasedValue: {
        color: "rgb(0, 166, 90)",
    },
    decreasedValue: {
        color: "rgb(221, 75, 57)",
    },
    icon: {
        fontSize: 18,
        marginRight: "10px",
    },
    trendContainer: {
        justifyContent: "flex-end",
        alignItems: "center",
    },
    deltaValues: {
        fontSize: 14,
        flexDirection: "column",
    },
    boxStyle: {
        height: "inherit",
        minWidth: 400,
    },
});

const HeaderRow = () => {
    const classes = useStyles();
    return (
        <div className={classes.header}>
            <div className={classes.headerContent} />
            <div className={classes.headerContent}>Last 7 Days</div>
            <div className={classes.headerContent}>Last 30 Days</div>
            <div className={classes.headerContent}>Last 365 Days</div>
        </div>
    );
};

const APP_FIGURES_INFO =
    "Illustrates a breakdown of the transactions submitted via your app over different rolling time periods.";

const APP_FIGURES_LABEL: {[property: string]: string} = {
    appSales: "Total App Sales",
    averageOrderSize: "Average Order Size",
    totalOrderSize: "Total Number of Orders",
};

const Trend = ({currentValue, previousValue, isOrderSize}: any) => {
    const classes = useStyles();
    const difference = currentValue - previousValue;
    const icon = difference < 0 ? "caret-down" : "caret-up";
    const deltaStyles =
        difference < 0 ? classes.decreasedValue : classes.increasedValue;
    const colorStyles = difference === 0 ? {} : deltaStyles;
    const deltaClass = classnames(colorStyles, classes.deltaValues);
    const percentageString = getPercentageStringForAppSalesFigures(
        currentValue,
        previousValue,
    );

    return (
        <div className={classes.trendContainer}>
            <div>
                {isOrderSize ? (
                    currentValue
                ) : (
                    <FormattedNumber
                        currency="GBP"
                        maximumFractionDigits={0}
                        minimumFractionDigits={0}
                        style="currency"
                        value={currentValue}
                    />
                )}
            </div>
            <div className={deltaClass}>
                {difference === 0 ? null : (
                    <i className={classnames(`fa fa-${icon}`, classes.icon)} />
                )}
                {isOrderSize ? (
                    Math.abs(difference)
                ) : (
                    <FormattedNumber
                        currency="GBP"
                        maximumFractionDigits={0}
                        minimumFractionDigits={0}
                        style="currency"
                        value={Math.abs(difference)}
                    />
                )}
                <div>{percentageString}</div>
            </div>
        </div>
    );
};

const RowData = ({
    appFigure,
    figureName,
}: {
    appFigure: Figures;
    figureName: string;
}) => {
    const {
        currentMonth = 0,
        currentWeek = 0,
        currentYear = 0,
        previousMonth = 0,
        previousWeek = 0,
        previousYear = 0,
    } = appFigure;
    const isOrderSize = figureName === "totalOrderSize";
    const classes = useStyles();

    return (
        <div className={classes.rowDatacontainer}>
            <div className={classes.headerFieldName}>
                <p className={classes.headerFieldLabel}>
                    {APP_FIGURES_LABEL[figureName]}
                </p>
            </div>
            <div className={classes.headerContent}>
                <Trend
                    currentValue={currentWeek}
                    isOrderSize={isOrderSize}
                    previousValue={previousWeek}
                />
            </div>
            <div className={classes.headerContent}>
                <Trend
                    currentValue={currentMonth}
                    isOrderSize={isOrderSize}
                    previousValue={previousMonth}
                />
            </div>
            <div className={classes.headerContent}>
                <Trend
                    currentValue={currentYear}
                    isOrderSize={isOrderSize}
                    previousValue={previousYear}
                />
            </div>
        </div>
    );
};

export const onComponentDidMount = ({
    getProps,
    setState,
}: {
    getProps: Function;
    setState: Function;
}) => async () => {
    try {
        const {childrenTeamFilter, getAppSalesFigures = () => {}} = getProps();
        const appSalesFigures = await getAppSalesFigures(childrenTeamFilter);

        setState({
            appSalesFigures,
            widgetStatus: STATUSES.normal,
        });
    } catch (exception) {
        setState({widgetStatus: STATUSES.dataUnavailable});
    }
};

export interface IProps {
    widgetStatus: string;
    appSalesFigures: any;
}

export default memo(function AppSalesFigures({
    widgetStatus,
    appSalesFigures,
}: IProps) {
    const appSalesFiguresKeys = R.keys(appSalesFigures);
    const classes = useStyles();

    return (
        <Box
            infoPopOverText={APP_FIGURES_INFO}
            className={classes.boxStyle}
            title="App Sales Figures"
        >
            <DashboardWidget
                status={widgetStatus}
                className={classes.widgetContainer}
            >
                <HeaderRow />
                {appSalesFiguresKeys.map((item: any) => {
                    const appFigure = appSalesFigures[item];

                    return (
                        appFigure && (
                            <span key={item}>
                                <RowData
                                    appFigure={appFigure}
                                    figureName={item}
                                />
                                <br />
                            </span>
                        )
                    );
                })}
            </DashboardWidget>
        </Box>
    );
});
