import React, {memo, createElement} from "react";
import {Redirect, Route} from "react-router-dom";
import useSession from "../main/useSession";

interface Props {
    component: "input";
    shouldRedirectToDashboard: boolean;
    path: string;
}

export default memo(function PublicRoute({component, path, ...rest}: Props) {
    const {isLoggedIn} = useSession();
    const shouldRedirectToDashboard = path === "/login" && isLoggedIn;
    if (shouldRedirectToDashboard) {
        return <Redirect to="/dashboard" />;
    }

    return (
        <Route
            {...rest}
            render={(props) => (
                <section className="content">
                    {createElement(component, props)}
                </section>
            )}
        />
    );
});
