import * as R from "ramda";
import {
    BANNER_TYPE,
    PRODUCT_LIST_PLACEMENTS,
    RELATED_PRODUCTS,
    PLACEMENT_TYPE_MAP,
    FULL_SCREEN_TYPE,
    BANNER_PLACEMENT_TYPES,
    CATEGORY_BANNER_ADVERTS,
} from "./PlacementConstants";
import {
    getLinkActionFromUrl,
    getProductSkuFromUrl,
    isAddToBasketUrl,
    isViewProductUrl,
} from "../pushNotification/messageToFormFormat";
import {SortableContainer, SortableElement} from "react-sortable-hoc";
import {Table} from "react-bootstrap";
import DragHandle from "../common/buttons/DragHandle";
import {Map} from "immutable";
import LinkButton from "../common/buttons/LinkButton";
import React, {useState} from "react";
import ButtonWithIcon from "../common/buttons/ButtonWithIcon";
import formatNumber from "../common/formatNumber";
import {makeStyles} from "@material-ui/styles";
import {PlacementsUpdateBody, PlacementType} from "./types";
import AdvertConfigsTable from "./AdvertConfigsTable";
import Checkbox from "../widget/Checkbox";
import numeral from "numeral";
import getAdvertTitles from "./getAdvertTitles";
import useSession from "../main/useSession";
import Image from "react-graceful-image";
import moment from "moment";
import VideocamIcon from "@material-ui/icons/Videocam";
import {CSVLink} from "react-csv";
import getAdvertTableDataForExport, {
    ADVERT_CSV_HEADER,
} from "./getAdvertTableDataForExport";

const MAX_WIDTH_INTERSTITIAL = 120;
const MAX_WIDTH_BANNER = 250;
const useStyles = makeStyles({
    image: {
        // @ts-ignore
        minHeight: (placeholderHeight: number) => placeholderHeight || 50,
        maxWidth: MAX_WIDTH_INTERSTITIAL,
        objectFit: "contain",
    },
    bannerImage: {
        // @ts-ignore
        height: (placeholderHeight: number) => placeholderHeight || 50,
        width: MAX_WIDTH_BANNER,
        objectFit: "contain",
    },
    removeButton: {
        alignItems: "center",
        display: "flex",
        height: "100%",
        justifyContent: "center",
    },
    addButton: {
        textAlign: "center",
    },
    emptyMessageList: {
        height: "200px",
        border: "1px solid rgb(211, 211, 211)",
        width: "100%",
        textAlign: "center",
        padding: "30px",
    },
    noMessage: {
        fontSize: "20px",
    },
    noMessageAddItemButton: {
        textAlign: "center",
        marginTop: "30px",
    },
    advertColumn: {
        border: "1px solid rgb(244, 244, 244)",
        flex: 1,
        padding: "8px",
    },
    actionColumn: {
        border: "1px solid rgb(244, 244, 244)",
        padding: "8px",
        textAlign: "center",
    },
    button: {
        margin: "0 5px",
        width: "100px",
    },
    editBtn: {
        backgroundColor: "rgb(85, 153, 153) !important",
        borderColor: "rgb(85, 153, 153) !important",
        color: "rgb(255, 255, 255)",
    },
    frequencyButtonContainer: {
        width: 100,
        textAlign: "center",
    },
    tableTitle: {
        border: "0px solid transparent !important",
    },
    actionButton: {
        width: "90px",
    },
    checkboxContainerHeader: {
        borderLeftWidth: "0 !important",
        borderRightWidth: "0 !important",
        borderBottom: "2px solid rgb(221,221,221) !important",
        width: 40,
        paddingLeft: "40px !important",
        paddingTop: "13px !important",
    },
    checkBoxContainer: {
        width: 40,
        position: "relative",
        paddingLeft: "40px !important",
        paddingTop: "13px !important",
    },
    checkbox: {
        display: "flex",
        marginBottom: 0,
        alignItems: "center",
        margin: "0 !important",
    },
    removeSelectedButton: {
        marginBottom: 20,
        display: "flex",
    },
    imageColumn: {
        verticalAlign: "middle !important",
    },
    videoIconContainer: {
        textAlign: "left",
    },
});

interface AdvertRowProps extends SortableTableProps {
    placement: Map<string, string>;
}

interface SortableTableProps {
    advertList?: any;
    advertFlags: any;
    onDeleteAdvertPressed: (id?: string, filter?: any) => void;
    placementName: string;
    deepLinkingEnabled?: boolean;
    scheduledAdvertEnabled: boolean;
    childrenTeamFilterEnabled: boolean;
    bannerAdvertDeepLinking: boolean;
    onChecked: (id: string) => void;
    selectedIds: Array<string>;
    hideButtons: boolean;
    hideMultiSelect: boolean;
    showPlacementTypeField?: boolean;
    isCategoryBanner: boolean;
}
const NO_DATA_PLACEHOLDER = "-";

export interface AdvertTableContainerProps {
    placementName: string;
    limit: number | string;
    isDone?: boolean;
    isError?: boolean;
    hideButtons?: boolean;
    hideMultiSelect?: boolean;
    advertList?: any;
    onDeleteAdvertPressed: (id: string, filter: any) => void;
    onMultiDeleteAdvertPresed: (ids: Array<string>, filter: any) => void;
    onUpdatePlacements: (body: PlacementsUpdateBody) => void;
    childrenTeamFilter?: string;
    placementType: PlacementType;
    isArchived?: boolean;
    adFrequencyConfigEnabled?: boolean;
    apiClient?: any;
    title: string;
}

export interface AdvertTableProps extends AdvertTableContainerProps {
    deepLinkingEnabled: boolean;
    scheduledAdvertEnabled: boolean;
    childrenTeamFilterEnabled?: boolean;
}
export const calculateActionedPercentage = ({
    clickedCount,
    seenCount,
    actionLink,
}: {
    clickedCount: string;
    seenCount: string;
    actionLink: string;
}) => {
    if (!actionLink) {
        return "N/A";
    }

    const removeCommasFromSeenCount = numeral(seenCount).value();
    const removeCommasFromClickCount = numeral(clickedCount).value();
    const percentage =
        (removeCommasFromClickCount / removeCommasFromSeenCount) * 100;

    if (isNaN(percentage)) {
        return NO_DATA_PLACEHOLDER;
    }

    return `${percentage.toFixed(2)}%`;
};
export const displayClickedCount = ({
    clickedCount,
    actionLink,
}: {
    clickedCount: number | string;
    actionLink: string;
}) => {
    if (!actionLink) {
        return "N/A";
    }

    return formatNumber(clickedCount);
};

const formatDateForAdvert = (
    originalDate: string | Date,
    defaultValue: string = "-",
    format: string = "ddd MMM DD YYYY",
) => {
    if (!originalDate) {
        return defaultValue;
    }

    const date = new Date(originalDate);

    return moment(date).isValid()
        ? moment(originalDate).format(format)
        : defaultValue;
};

const computeAllowedResolution = (
    sourceHeight: number,
    sourceWidth: number,
    maxWidth: number,
): {newHeight: number; newWidth: number} => {
    let newHeight = 0;
    let newWidth = 0;
    if (!sourceHeight || !sourceWidth) {
        return {newHeight, newWidth};
    }

    if (sourceWidth > sourceHeight) {
        newHeight = (maxWidth * sourceHeight) / sourceWidth;
        newWidth = maxWidth;
    } else {
        newWidth = (maxWidth * sourceWidth) / sourceHeight;
        newHeight = maxWidth;
    }

    return {newHeight, newWidth};
};

const MediaImage = ({
    placement,
    placementName,
    placeholderWidth,
    placeholderHeight,
}: {
    placement: Map<string, string>;
    placementName: string;
    placeholderWidth: number;
    placeholderHeight: number;
}) => {
    const placementType = placement.get("placement");
    const maxWidth = BANNER_PLACEMENT_TYPES.includes(placementType)
        ? MAX_WIDTH_BANNER
        : MAX_WIDTH_INTERSTITIAL;
    const {newHeight} = computeAllowedResolution(
        placeholderHeight,
        placeholderWidth,
        maxWidth,
    );

    const classes = useStyles(newHeight);
    const isVideo = placement.get("videoUrl");

    if (isVideo) {
        return placement.get("videoPreviewImageUrl") ? (
            <Image
                src={placement.get("videoPreviewImageUrl")}
                className={
                    BANNER_PLACEMENT_TYPES.includes(placementName)
                        ? classes.bannerImage
                        : classes.image
                }
            />
        ) : (
            <div className={classes.videoIconContainer}>
                <VideocamIcon
                    style={{
                        fontSize: 50,
                        width: 50,
                    }}
                    color="secondary"
                />
            </div>
        );
    }
    return (
        <Image
            src={placement.get("imageUrl")}
            className={
                BANNER_PLACEMENT_TYPES.includes(placementName)
                    ? classes.bannerImage
                    : classes.image
            }
        />
    );
};
const AdvertRow = SortableElement(
    ({
        advertFlags,
        placementName,
        placement,
        onDeleteAdvertPressed,
        bannerAdvertDeepLinking,
        scheduledAdvertEnabled,
        childrenTeamFilterEnabled,
        onChecked,
        hideButtons,
        hideMultiSelect,
        selectedIds,
        showPlacementTypeField,
        isCategoryBanner,
    }: AdvertRowProps) => {
        const buttonUrl = placement.get("actionLink");
        const imageHeight = parseInt(placement.get("height")) || 0;
        const imageWidth = parseInt(placement.get("width")) || 0;

        const websiteUrl =
            isViewProductUrl(buttonUrl) || isAddToBasketUrl(buttonUrl)
                ? ""
                : buttonUrl;
        const buttonAction = getLinkActionFromUrl(buttonUrl);
        const productSku = getProductSkuFromUrl(buttonUrl);
        const seenCountEnabled =
            // @ts-ignore
            R.path(["trackingAdvertsInfo"])(advertFlags) &&
            !Boolean(PRODUCT_LIST_PLACEMENTS.includes(placementName));
        const teamName = placement.get("teamName", "N/A");

        const seenCount = formatNumber(placement.get("seenCount", "0"));
        const clickedCount = placement.get("clickedCount", "0");
        const actionLink = placement.get("actionLink", "");
        const classes = useStyles();
        const isChecked = selectedIds.includes(placement.get("id"));
        const advertType = PLACEMENT_TYPE_MAP[placementName];

        const showActionedPercentage =
            advertType === BANNER_TYPE ||
            advertType === FULL_SCREEN_TYPE ||
            isCategoryBanner;
        const actionedPercentage =
            showActionedPercentage &&
            calculateActionedPercentage({
                clickedCount,
                seenCount,
                actionLink,
            });

        const {userData} = useSession();

        // @ts-ignore
        const adTitles: any = getAdvertTitles(userData?.activeTeam?.teamNumber);
        const isProductListPlacement = PRODUCT_LIST_PLACEMENTS.includes(
            placementName,
        );

        return (
            <tr className="sortable-row">
                {!hideMultiSelect && (
                    <td className={classes.checkBoxContainer}>
                        <DragHandle />
                        <Checkbox
                            checked={isChecked}
                            onChecked={() => onChecked(placement.get("id"))}
                            className={classes.checkbox}
                        />
                    </td>
                )}

                <td
                    className={
                        isProductListPlacement ? undefined : classes.imageColumn
                    }
                >
                    {isProductListPlacement ? (
                        placement.get("linkedProduct")
                    ) : (
                        <MediaImage
                            placeholderWidth={imageWidth}
                            placeholderHeight={imageHeight}
                            placement={placement}
                            placementName={placementName}
                        />
                    )}
                </td>
                {showPlacementTypeField && (
                    <td>{adTitles[placement.get("placement")]}</td>
                )}
                <td className={classes.advertColumn}>
                    {placement.get("description")}
                </td>
                {bannerAdvertDeepLinking && (
                    <td className={classes.advertColumn}>{buttonAction}</td>
                )}
                {bannerAdvertDeepLinking && (
                    <td className={classes.advertColumn}>
                        {productSku || websiteUrl}
                    </td>
                )}
                {placementName === RELATED_PRODUCTS && (
                    <td className={classes.advertColumn}>
                        {placement
                            .get("relatedProducts")
                            // @ts-ignore
                            .join(", ")}
                    </td>
                )}
                {seenCountEnabled && (
                    <td className={classes.advertColumn}>{seenCount}</td>
                )}
                {seenCountEnabled && (
                    <td className={classes.advertColumn}>
                        {displayClickedCount({
                            clickedCount,
                            actionLink,
                        })}
                    </td>
                )}
                {showActionedPercentage && (
                    <td
                        className={classes.advertColumn}
                        align={
                            actionedPercentage === NO_DATA_PLACEHOLDER
                                ? "center"
                                : "left"
                        }
                    >
                        {actionedPercentage}
                    </td>
                )}
                {childrenTeamFilterEnabled && (
                    <td className={classes.advertColumn}>
                        {teamName === "Confex Distribution"
                            ? "Confex"
                            : teamName}
                    </td>
                )}
                {scheduledAdvertEnabled && (
                    <td className={classes.advertColumn}>
                        {formatDateForAdvert(placement.get("startDate"), "N/A")}
                    </td>
                )}
                {scheduledAdvertEnabled && (
                    <td className={classes.advertColumn}>
                        {formatDateForAdvert(placement.get("endDate"), "N/A")}
                    </td>
                )}
                {!hideButtons && (
                    <td className={classes.actionColumn}>
                        <LinkButton
                            destinaton={`/placements/edit/${placementName}/${placement.get(
                                "id",
                            )}`}
                            title="Edit"
                            type="success"
                            className={classes.actionButton}
                            placement={placement}
                        />

                        <div style={{marginTop: 8}}>
                            <ButtonWithIcon
                                iconName="trash"
                                onClick={onDeleteAdvertPressed}
                                size="sm"
                                className={classes.actionButton}
                                title="Archive"
                                type="warning"
                            />
                        </div>
                    </td>
                )}
            </tr>
        );
    },
);

export const EmptyList = () => {
    const classes = useStyles();

    return (
        <div className={classes.emptyMessageList}>
            <p className={classes.noMessage}>
                {"You haven't created any placements"}
            </p>
            <p>
                Placements you create will show up here. To create a new
                placement, click the green <strong>Add</strong> button
            </p>
        </div>
    );
};

export const isLimitReached = (
    advertCount: number = 0,
    limit: number,
): boolean => {
    if (limit === 0) {
        return false;
    }

    return R.gte(advertCount, limit);
};

const SortableTableBody = SortableContainer(
    ({
        advertFlags,
        onDeleteAdvertPressed,
        placementName,
        advertList,
        bannerAdvertDeepLinking,
        scheduledAdvertEnabled,
        childrenTeamFilterEnabled,
        onChecked,
        hideButtons,
        selectedIds,
        hideMultiSelect,
        showPlacementTypeField,
        isCategoryBanner,
    }: SortableTableProps) => (
        <tbody>
            {advertList.map((placement: any, index: number) => {
                return (
                    <AdvertRow
                        key={placement.get("id", "")}
                        advertFlags={advertFlags}
                        bannerAdvertDeepLinking={bannerAdvertDeepLinking}
                        index={index}
                        onDeleteAdvertPressed={() =>
                            onDeleteAdvertPressed(
                                placement.get("id"),
                                placement.get("metaTeamPlacementId"),
                            )
                        }
                        placement={placement}
                        placementName={placementName}
                        scheduledAdvertEnabled={scheduledAdvertEnabled}
                        childrenTeamFilterEnabled={childrenTeamFilterEnabled}
                        onChecked={onChecked}
                        selectedIds={selectedIds}
                        hideButtons={hideButtons}
                        hideMultiSelect={hideMultiSelect}
                        showPlacementTypeField={showPlacementTypeField}
                        isCategoryBanner={isCategoryBanner}
                    />
                );
            })}
        </tbody>
    ),
);

const AdvertTable = ({
    advertFlags,
    onDeleteAdvertPressed,
    placementName,
    limit,
    scheduledAdvertEnabled,
    hideButtons,
    childrenTeamFilterEnabled,
    bannerAdvertDeepLinking,
    seenCountEnabled,
    items,
    onSortEnd,
    adFrequencyConfigEnabled,
    onMultiDeletePressed,
    hideMultiSelect,
    showPlacementTypeField,
    title,
    CSVExportEnabled,
}: any) => {
    const classes = useStyles();
    const [allSelected, setIsSelectAll] = useState(false);
    const [selectedIds, onSetSelectedIds] = useState([]);
    const onChecked = (id: string) => {
        const foundId = selectedIds.find((item) => item === id);
        const newSelectedIds = foundId
            ? R.filter((item: string) => {
                  return item !== id;
              })(selectedIds)
            : [...selectedIds, id];
        // @ts-ignore
        onSetSelectedIds(newSelectedIds);
    };

    const onSelectAll = () => {
        const newSelectedIds = allSelected
            ? []
            : items.map((item: any) => item.get("id")).toArray();
        onSetSelectedIds(newSelectedIds);
        setIsSelectAll(!allSelected);
    };
    const isCategoryBanner = Boolean(
        CATEGORY_BANNER_ADVERTS.find((item) => item === placementName),
    );

    const advertType = PLACEMENT_TYPE_MAP[placementName];
    const showActionedPercentageColumn =
        advertType === BANNER_TYPE ||
        advertType === FULL_SCREEN_TYPE ||
        isCategoryBanner;

    const isProductListPlacement = PRODUCT_LIST_PLACEMENTS.includes(
        placementName,
    );

    const actionHeader = bannerAdvertDeepLinking
        ? [ADVERT_CSV_HEADER.ACTION]
        : [ADVERT_CSV_HEADER.URL_OR_PRODUCT_SKU];

    const headers = [
        ...(isProductListPlacement
            ? [ADVERT_CSV_HEADER.SKU]
            : [ADVERT_CSV_HEADER.IMAGE]),
        ...(showPlacementTypeField ? [ADVERT_CSV_HEADER.ADVERT_TYPE] : []),
        ADVERT_CSV_HEADER.DESCRIPTION,
        ...(isProductListPlacement ? [] : actionHeader),
        ...(placementName === RELATED_PRODUCTS
            ? [ADVERT_CSV_HEADER.RELATED_PRODUCTS]
            : []),
        ...(seenCountEnabled
            ? [ADVERT_CSV_HEADER.DISPLAY_COUNT, ADVERT_CSV_HEADER.CLICKED_COUNT]
            : []),
        ...(showActionedPercentageColumn ? [ADVERT_CSV_HEADER.ACTIONED] : []),
        ...(childrenTeamFilterEnabled ? [ADVERT_CSV_HEADER.MEMBER_APP] : []),
        ...(scheduledAdvertEnabled
            ? [ADVERT_CSV_HEADER.START_DATE, ADVERT_CSV_HEADER.END_DATE]
            : []),
    ];
    const {userData} = useSession();
    // @ts-ignore
    const adTitles: any = getAdvertTitles(userData?.activeTeam?.teamNumber);
    const newData = getAdvertTableDataForExport(
        items.toJS(),
        headers,
        adTitles,
    );

    return (
        <div>
            {adFrequencyConfigEnabled && (
                <AdvertConfigsTable placementName={placementName} />
            )}
            {
                <div className={classes.removeSelectedButton}>
                    <div style={{flex: 1}}>
                        {hideMultiSelect ? null : (
                            <ButtonWithIcon
                                iconName="trash"
                                onClick={() =>
                                    onMultiDeletePressed(selectedIds)
                                }
                                size="sm"
                                title="Archive Selected"
                                disabled={!Boolean(selectedIds.length)}
                            />
                        )}
                    </div>
                    <div>
                        {CSVExportEnabled && (
                            <CSVLink data={newData} filename={title}>
                                <ButtonWithIcon
                                    iconName="download"
                                    size="sm"
                                    title="Export to CSV"
                                />
                            </CSVLink>
                        )}
                    </div>
                </div>
            }
            <Table bordered responsive striped>
                <thead>
                    <tr>
                        {!hideMultiSelect && (
                            <th className={classes.checkboxContainerHeader}>
                                <Checkbox
                                    checked={allSelected}
                                    onChecked={onSelectAll}
                                    className={classes.checkbox}
                                />
                            </th>
                        )}

                        <th>
                            {PRODUCT_LIST_PLACEMENTS.includes(placementName)
                                ? "SKU"
                                : "Image"}
                        </th>
                        {showPlacementTypeField && <th>Advert Type</th>}
                        <th>Description</th>
                        {bannerAdvertDeepLinking && <th>Action</th>}
                        {bannerAdvertDeepLinking && <th>URL / Product Sku</th>}
                        {placementName === RELATED_PRODUCTS && (
                            <th>Related Products</th>
                        )}
                        {seenCountEnabled && <th>Displayed Count</th>}
                        {seenCountEnabled && <th>Clicked Count</th>}
                        {showActionedPercentageColumn && <th>Actioned</th>}
                        {childrenTeamFilterEnabled && <th>Member App</th>}
                        {scheduledAdvertEnabled && <th>Start Date</th>}
                        {scheduledAdvertEnabled && <th>End Date</th>}
                        {hideButtons ? null : (
                            <th className={classes.addButton}>
                                {
                                    // @ts-ignore
                                    <LinkButton
                                        destinaton={`/placements/add/${placementName}`}
                                        disabled={isLimitReached(
                                            items.count(),
                                            limit,
                                        )}
                                        size="sm"
                                        className={classes.actionButton}
                                        title="Add"
                                        type="success"
                                    />
                                }
                            </th>
                        )}
                    </tr>
                </thead>
                <SortableTableBody
                    advertFlags={advertFlags}
                    advertList={items}
                    bannerAdvertDeepLinking={bannerAdvertDeepLinking}
                    distance={20}
                    lockAxis="y"
                    onDeleteAdvertPressed={onDeleteAdvertPressed}
                    onSortEnd={onSortEnd}
                    placementName={placementName}
                    scheduledAdvertEnabled={scheduledAdvertEnabled}
                    childrenTeamFilterEnabled={childrenTeamFilterEnabled}
                    useDragHandle
                    onChecked={onChecked}
                    hideButtons={hideButtons}
                    selectedIds={selectedIds}
                    hideMultiSelect={hideMultiSelect}
                    showPlacementTypeField={showPlacementTypeField}
                    isCategoryBanner={isCategoryBanner}
                />
            </Table>
        </div>
    );
};

export default AdvertTable;
