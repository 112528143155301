export const ACTION_NONE = "none";
export const ACTION_VIEW_WEBSITE = "viewWebsite";
export const ACTION_VIEW_PRODUCT_DETAILS = "viewProductDetail";
export const ACTION_VIEW_PRODUCT_LIST = "viewProductList";
export const ACTION_ADD_PRODUCT_TO_BASKET = "addProductToBasket";
export const ACTION_ADD_PRODUCT_TO_LIST = "addProductToList";

export const ACTION_PATH = {
    [ACTION_VIEW_PRODUCT_LIST]: "/product/list/",
    [ACTION_ADD_PRODUCT_TO_BASKET]: "/product/add/",
    [ACTION_ADD_PRODUCT_TO_LIST]: "/list/add/",
    [ACTION_VIEW_PRODUCT_DETAILS]: "/product/view/",
};
