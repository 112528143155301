import React, {memo} from "react";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles({
    wrapper: {position: "relative"},
    overlayContent: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "rgba(255, 255, 255, 0.8)",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
    },
});
interface Props {
    children: JSX.Element;
    overlayContent: JSX.Element;
}
export default memo(function Overlay({
    children,
    overlayContent,
}: Props): JSX.Element {
    const classes = useStyles();
    return (
        <div className={classes.wrapper}>
            {children}
            <div className={classes.overlayContent}>{overlayContent}</div>
        </div>
    );
});
