import * as R from "ramda";
import {action, computed, observable} from "mobx";
import {getIn, setIn} from "../common/ramdaDataUtils";
import {loadUser} from "./repository";
import accountToLocalUser from "../login/accountToLocalUser";
import APIClient from "@beam/api-client";
import base64 from "base-64";
import getFeatureFlagsForTeamId from "../featureFlags/getFeatureFlagsForTeamId";
import immutable from "immutable";

const DEFAULT_USER = {
    activeTeam: {
        name: "",
        teamNumber: null,
    },
    emailAddress: "",
    firstName: "",
    password: "",
    surname: "",
    teams: [],
};

export default class UserRepository {
    @observable
    user = loadUser();

    @action
    async onLogin(emailAddress, password) {
        try {
            const response = await this.apiClient.fetch("/v1/account", {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Basic ${base64.encode(
                        `${emailAddress}:${password}`,
                    )}`,
                },
            });

            const result = await response.json();

            const authenticatedUser = accountToLocalUser(result, password);

            this.onSetUser(authenticatedUser);
            if (authenticatedUser.teams.length > 1) {
                this.onSetActiveTeam(authenticatedUser.teams[0]);
            }
        } catch (exception) {
            this.user = DEFAULT_USER;
        }
    }

    @action.bound
    onLogout() {
        this.onSetUser(DEFAULT_USER);
    }

    @action
    onSetUser(user) {
        this.user = user;
    }

    @action
    onSetActiveTeam(activeTeam) {
        const currentUser = setIn(["activeTeam"], activeTeam, this.user);

        this.user = currentUser;
    }

    @computed
    get activeTeamNumber() {
        return getIn(["activeTeam", "teamNumber"], this.user);
    }

    @computed
    get currentFeatureFlags() {
        return getFeatureFlagsForTeamId(this.activeTeamNumber || -1);
    }

    @computed
    get isLoggedOut() {
        const {emailAddress, password} = this.user;

        return R.anyPass([
            () => R.isEmpty(emailAddress),
            () => R.isEmpty(password),
            () => R.isNil(getIn(["activeTeam", "teamNumber"], this.user)),
        ]);
    }
}
