import React, {memo, useState} from "react";
import AlertContext from "./AlertContext";

export interface Alert {
    message: string;
    type: string;
    id: string;
}
export default memo(function AlertProvider({children}) {
    const [alerts, setAlerts] = useState<Array<Alert>>([]);

    return (
        <AlertContext.Provider
            value={{
                alerts,
                setAlerts,
            }}
        >
            {children}
        </AlertContext.Provider>
    );
});
