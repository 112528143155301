import "./style";
import "admin-lte/dist/js/app.min";
import "bootstrap";

import {autorun} from "mobx";
import {inject, Provider as MobxProvider} from "mobx-react";
import React, {Component, useMemo} from "react";
import ReactDOM from "react-dom";
import {IntlProvider} from "react-intl";
import {Provider} from "react-redux";
import {HashRouter as Router, Redirect, Route, Switch} from "react-router-dom";

import emptySession from "../../common/emptySession";
import useLocalStorage from "../../common/useLocalStorage";
import AddAdvertConfig from "./advert/AddAdvertConfig";
import AddAdvert from "./advert/AddAdvertContainer";
import Advert from "./advert/AdvertsContainer";
import AlertProvider from "./alert/AlertProvider";
import Alerts from "./alert/AlertsContainer";
import AppThemeProvider from "./AppThemeProvider";
import AddAudienceSelection from "./audienceSelector/AddAudienceSelectionContainer";
import AudienceSelectionDetails from "./audienceSelector/AudienceSelectionDetailContainer";
import AudienceSelectionList from "./audienceSelector/AudienceSelectionListContainer";
import ContactUs from "./contact/ContactUs";
import Dashboard from "./dashboard/Dashboard";
import DefaultDashboard from "./dashboard/default/DefaultDashboardContainer";
import FeatureFlagProvider from "./featureFlags/FeatureFlagProvider";
import LoginForm from "./login/LoginContainer";
import MainSideBarHeader from "./main/MainSideBarHeader";
import SessionProvider from "./main/SessionProvider";
import MessageListContainer from "./message/MessageListContainer";
import MessageActionedEventsView from "./message/view/ActionedEventsView";
import MessageOpenedEventsView from "./message/view/OpenedEventsView";
import MessageView from "./message/view/View";
import mobxStore from "./mobxStore";
import {
    CREATE_MSG_PATH,
    DUPLICATE_MSG_PATH,
    EDIT_MSG_PATH,
} from "./pushNotification/PushNotification";
import PushNotificationForm from "./pushNotification/PushNotificationFormContainer";
import Ribbon from "./Ribbon";
import history from "./router/history";
import PrivateRoute from "./router/PrivateRouteContainer";
import PublicRoute from "./router/PublicRoute";
import SelectTeam from "./selectTeam/SelectTeam";
import store from "./store";
import NotificationList from "./uiNotification/NotificationList";

const IS_BETA = !__PROD__;

@inject("userRepository", "tooltipRepository")
class Beam extends Component {
    componentDidMount() {
        this.autorunDisposer = autorun(() => {
            const {userRepository, tooltipRepository} = this.props;

            if (userRepository.isLoggedOut) {
                tooltipRepository.clearToolTipRepository();
            }
        });
    }

    componentWillUnmount() {
        this.autorunDisposer();
    }

    render() {
        return (
            // eslint-disable-next-line react/jsx-filename-extension
            <div className="wrapper">
                {IS_BETA ? <Ribbon /> : null}
                <MainSideBarHeader>
                    <Switch>
                        <Route
                            exact
                            path="/"
                            render={() => <Redirect to="/dashboard" />}
                        />
                        <PrivateRoute
                            component={Advert}
                            exact
                            path="/placements"
                            requiredFeatureFlag="placements"
                            title="Ads & Product Placement"
                        />
                        <PrivateRoute
                            component={Advert}
                            exact
                            path="/placements/:activeTab"
                            requiredFeatureFlag="placements"
                            title="Ads & Product Placement"
                        />
                        <PrivateRoute
                            component={AddAdvert}
                            exact
                            path="/placements/add/:placementName"
                            requiredFeatureFlag="placements"
                            subTitle="create placement"
                            title="Ads & Product Placement"
                        />
                        <PrivateRoute
                            component={AddAdvert}
                            exact
                            path="/placements/edit/:placementName/:advertId"
                            requiredFeatureFlag="placements"
                            subTitle="create placement"
                            title="Ads & Product Placement"
                        />
                        <PrivateRoute
                            component={AddAdvertConfig}
                            exact
                            path="/placements/configure/:placementName"
                            requiredFeatureFlag="placements"
                            title="Ads & Product Placement"
                        />
                        <PrivateRoute
                            component={AddAdvertConfig}
                            exact
                            path="/placements/configure/:placementName/:advertConfigId"
                            requiredFeatureFlag="placements"
                            title="Ads & Product Placement"
                        />
                        <PrivateRoute
                            component={DefaultDashboard}
                            exact
                            path="/dashboard"
                            requiredFeatureFlag="dashboard"
                        />
                        <PrivateRoute
                            component={Dashboard}
                            exact
                            path="/business-intelligence"
                            requiredFeatureFlag="businessIntelligenceDashboard"
                            title="Business Intelligence"
                        />
                        <PublicRoute
                            component={LoginForm}
                            exact
                            path="/login"
                        />
                        <PrivateRoute
                            component={MessageListContainer}
                            exact
                            path="/message"
                            requiredFeatureFlag="messaging"
                            title="Push Notifications"
                        />
                        <PrivateRoute
                            component={PushNotificationForm}
                            exact
                            path={DUPLICATE_MSG_PATH}
                            requiredFeatureFlag="messaging"
                            subTitle="duplicate"
                            title="Push Notifications"
                        />
                        <PrivateRoute
                            component={PushNotificationForm}
                            exact
                            path={CREATE_MSG_PATH}
                            requiredFeatureFlag="messaging"
                            subTitle="create"
                            title="Push Notifications"
                        />
                        <PrivateRoute
                            component={PushNotificationForm}
                            exact
                            path={EDIT_MSG_PATH}
                            requiredFeatureFlag="messaging"
                            subTitle="edit"
                            title="Push Notifications"
                        />
                        <PrivateRoute
                            component={MessageView}
                            exact
                            path="/message/:id"
                            requiredFeatureFlag="messaging"
                        />
                        <PrivateRoute
                            component={MessageOpenedEventsView}
                            exact
                            path="/message/:id/openedEvents/:teamNumber"
                            requiredFeatureFlag="messaging"
                        />
                        <PrivateRoute
                            component={MessageActionedEventsView}
                            exact
                            path="/message/:id/actionedEvents/:teamNumber"
                            requiredFeatureFlag="messaging"
                        />
                        <PrivateRoute
                            component={SelectTeam}
                            exact
                            path="/select-team"
                        />

                        <PrivateRoute
                            component={AudienceSelectionList}
                            exact
                            path="/audience-builder"
                            title="Audience Builder"
                        />
                        <PrivateRoute
                            component={AddAudienceSelection}
                            exact
                            path="/audience-builder/add"
                            subTitle="new"
                            title="Create an Audience"
                        />
                        <PrivateRoute
                            component={AudienceSelectionDetails}
                            exact
                            path="/audience-builder/:id"
                            title="Audience Details"
                        />

                        <PublicRoute
                            component={ContactUs}
                            exact
                            path="/contact-us"
                        />
                    </Switch>
                </MainSideBarHeader>
                <NotificationList />
                <Alerts />
            </div>
        );
    }
}

ReactDOM.render(
    <AppThemeProvider>
        <Provider store={store}>
            <MobxProvider {...mobxStore}>
                <IntlProvider locale="en">
                    <Router history={history}>
                        <AlertProvider>
                            <SessionProvider>
                                <FeatureFlagProvider>
                                    <Beam />
                                </FeatureFlagProvider>
                            </SessionProvider>
                        </AlertProvider>
                    </Router>
                </IntlProvider>
            </MobxProvider>
        </Provider>
    </AppThemeProvider>,
    document.getElementById("beam"),
);
