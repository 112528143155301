export default (emailAddress: string): boolean => {
    const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

    if (!emailAddress) {
        return false;
    }

    if (typeof emailAddress === "object") {
        throw new Error("email address must be a string");
    }

    return pattern.test(emailAddress);
};
