import {inject, observer} from "mobx-react";
import React, {Component, memo, useEffect, useState} from "react";
import AddAudienceSelection, {
    AddAudienceSelectionProps,
} from "./AddAudienceSelection";
import AudienceSelectorRepository from "./AudienceSelectorRepository";
import useAlert from "../alert/useAlert";

interface AudienceSelectorHooksContainerProps
    extends AddAudienceSelectionProps {
    onGetPotentialReach: () => Promise<{success: boolean}>;
    onSetAudienceSelectionByPath: (
        path: string[],
        value: string | Object,
    ) => Promise<{success: boolean}> | {success: boolean};
    onSetUserIdentifiers: (
        userIdentifiers: string,
    ) => Promise<{success: boolean}>;
}

const HooksContainer = memo(function AudienceSelectorHooksContainer(
    props: AudienceSelectorHooksContainerProps,
) {
    const [
        hasReachedMaxUserIdentifiers,
        setHasReachedMaxUserIdentifiers,
    ] = useState(false);
    const {addErrorAlert, addSuccessAlert} = useAlert();

    const evaulateForAlerts = (results: {success: boolean}) => {
        if (results && !results.success) {
            addErrorAlert(
                "An error occurred while fetching audience estimate. Please try again.",
            );
        }
    };

    const onGetPotentialReach = async () => {
        const results = await props.onGetPotentialReach();

        evaulateForAlerts(results);
    };

    const onSaveAudienceSelection = async () => {
        const {success} = await props.onSaveAudienceSelection();
        if (success) {
            addSuccessAlert("Successfully saved audience selections");
        } else {
            addErrorAlert(
                "An error occurred while saving audience selection. Please try again.",
            );
        }

        return {success};
    };

    const onSetAudienceSelectionByPath = async (
        path: string[],
        value: string | Object,
    ) => {
        const results = await props.onSetAudienceSelectionByPath(path, value);
        evaulateForAlerts(results);
    };

    const onSetUserIdentifiers = async (userIdentifiers: string) => {
        const results = await props.onSetUserIdentifiers(userIdentifiers);

        evaulateForAlerts(results);
        setHasReachedMaxUserIdentifiers(
            props.audienceSelection.userIdentifiers.length > 100,
        );
    };

    useEffect(() => {
        onGetPotentialReach();
    }, []);

    return (
        <AddAudienceSelection
            {...props}
            hasReachedMaxUserIdentifiers={hasReachedMaxUserIdentifiers}
            onSaveAudienceSelection={onSaveAudienceSelection}
            onSetAudienceSelectionByPath={onSetAudienceSelectionByPath}
            onSetUserIdentifiers={onSetUserIdentifiers}
        />
    );
});

interface Props {
    audienceSelectorRepository: AudienceSelectorRepository;
}

@inject("audienceSelectorRepository", "predictiveAudienceRepository")
@observer
class AddAudienceSelectionContainer extends Component<Props> {
    render() {
        const {
            audienceSelection,
            onSetAudienceSelectionByPath,
            onSetUserIdentifiers,
            onSaveAudienceSelection,
            onGetPotentialReach,
        } = this.props.audienceSelectorRepository;

        return (
            <HooksContainer
                audienceSelection={audienceSelection}
                onSetAudienceSelectionByPath={onSetAudienceSelectionByPath}
                onSetUserIdentifiers={onSetUserIdentifiers}
                onSaveAudienceSelection={onSaveAudienceSelection}
                onGetPotentialReach={onGetPotentialReach}
            />
        );
    }
}

export default AddAudienceSelectionContainer;
