/*  */

import {autobind} from "core-decorators";
import {} from "./FilteredChart";
import {inject} from "mobx-react";
import BeamAPIClient from "../../../apiClient/BeamAPIClient";
import React from "react";
import StackedUserChart from "./StackedUserChart";

@inject("apiClient")
export default class TotalPurchasers extends React.Component {
    static INFO_POP_OVER_TEXT = `Illustrates the total number of Purchasing Users in the selected time period, alongside a daily breakdown of Purchasing vs. Non-Purchasing Users.
    
A Purchasing user is a user who has placed an order through your app during the given time period.
    
A Non-Purchasing user is a user who has not placed an order through your app during the given time period.`;

    @autobind
    dataLoader(query) {
        return this.props.apiClient
            ? this.props.apiClient.invokeGetEndpoint(
                  "totalPurchasersOverActiveDevices",
                  query,
              )
            : Promise.reject(new Error("No API client available"));
    }

    render() {
        return (
            <StackedUserChart
                axisTitles={{
                    y: "Active Purchasers",
                }}
                changeKey="activePurchasersDeviceCountChange"
                changeTitle="ACTIVE PURCHASERS"
                dataLoader={this.dataLoader}
                childrenTeamFilter={this.props.childrenTeamFilter}
                endDate={this.props.endDate}
                infoPopOverText={TotalPurchasers.INFO_POP_OVER_TEXT}
                lastMonthKey="totalActivePurchasers"
                showEngagement
                startDate={this.props.startDate}
                title="Active Purchasers"
            />
        );
    }
}
