import * as R from "ramda";
const VALID_TYPES = ["image/gif", "image/jpeg", "image/png"];

const isAcceptedImageType = (type: string) => R.contains(type, VALID_TYPES);

interface IsValidDimensionProps {
    dimension: any;
    maxHeight: number;
    maxWidth: number;
}

interface ImageValidationProps {
    image: any;
    maxWidth?: number;
    maxHeight?: number;
    imageDimensionsLimitEnabled?: boolean;
    relaxRuleEnabled?: boolean;
}

interface ImageDimension {
    width: number;
    height: number;
}

export const isValidDimension = ({
    dimension,
    maxHeight,
    maxWidth,
}: IsValidDimensionProps) => {
    const isHeightInvalid = R.compose(
        R.gte(maxHeight),
        //@ts-ignore
        R.prop("height"),
    );
    const isWidthInvalid = R.compose(
        R.gte(maxWidth),
        //@ts-ignore
        R.prop("width"),
    );
    return R.allPass([isHeightInvalid, isWidthInvalid])(dimension);
};

const getImageDimension = (image: string): Promise<ImageDimension> =>
    new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () =>
            resolve({
                width: img.width,
                height: img.height,
            });
        img.onerror = reject;
        img.src = URL.createObjectURL(image);
    });

export default async ({
    image,
    maxWidth = 600,
    maxHeight = 600,
    imageDimensionsLimitEnabled = true,
    relaxRuleEnabled,
}: ImageValidationProps) => {
    if (R.isNil(image)) {
        return {
            isError: true,
            errorMessage: "Invalid Image File",
        };
    }
    if (!isAcceptedImageType(image.type)) {
        return {
            isError: true,
            errorMessage:
                "Image file should only be in PNG, GIF and JPEG format",
        };
    }

    const SIZE_LIMIT = 2000000;
    if (image.size > SIZE_LIMIT) {
        return {
            isError: true,
            errorMessage: `Image file dimension should not exceed 2mb`,
        };
    }

    let dimension = {width: 0, height: 0};
    try {
        dimension = await getImageDimension(image);
    } catch (error) {
        console.log(
            "There was a problem getting the dimensions for this image",
        );
    }

    if (
        !relaxRuleEnabled &&
        imageDimensionsLimitEnabled &&
        !isValidDimension({dimension, maxWidth, maxHeight})
    ) {
        return {
            isError: true,
            errorMessage: `Image file dimension should not exceed ${maxWidth}x${maxHeight}`,
        };
    }

    return {isError: false, errorMessage: "", dimension};
};
