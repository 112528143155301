import {createMuiTheme} from "@material-ui/core";
import {
    StylesProvider,
    ThemeProvider,
    createGenerateClassName,
} from "@material-ui/styles";
import React from "react";

const theme = createMuiTheme();

const generateClassName = createGenerateClassName({
    productionPrefix: "beam",
});

export default function AppThemeProvider({
    children,
}: {
    children: Array<React.ReactNode> | React.ReactNode;
}): JSX.Element {
    return (
        <StylesProvider generateClassName={generateClassName}>
            <ThemeProvider theme={theme}>
                {/* <CssBaseline /> */}
                {children}
            </ThemeProvider>
        </StylesProvider>
    );
}
