import React, {memo} from "react";

const styles = Object.freeze({
    label: {textShadow: "1px 1px 1px rgb(90, 90, 90)"},
});

interface Props {
    children: any;
}
export default memo(function OverlayLabel({children}: Props): JSX.Element {
    return <span style={styles.label}>{children}</span>;
});
