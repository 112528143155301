import * as R from "ramda";

const hasWidget = R.curry(
    (
        audienceLandscape: {[propertyName: string]: string},
        widgetType: string,
        widgetName: string,
    ) => (audienceLandscape[widgetType] ? [] : [widgetName]),
);

export default (feature: any): Array<string> => {
    const audienceLandscape = feature.audienceLandscape;

    const isWidgetEnabled = hasWidget(audienceLandscape);

    return [
        ...isWidgetEnabled(
            "pushNotificationEnabled",
            "PUSH_NOTIFICATIONS_ENABLED",
        ),
    ];
};
