import {Circle} from "rc-progress";
import {Col, Row, Table} from "react-bootstrap";
import {inject, observer} from "mobx-react";
import getPercentage from "../../common/getPercentage";
import Loading from "../../widget/Loading";
import React, {memo, useEffect} from "react";
import truncate from "../../common/truncate";
import {makeStyles} from "@material-ui/styles";
import classnames from "classnames";

const truncateTo10 = truncate(10);

const useStyles = makeStyles({
    content: {
        margin: 5,
        padding: 5,
        paddingBottom: 15,
        textAlign: "center",
        borderRadius: 5,
        border: "1px solid #3c8dbc",
        backgroundColor: "rgb(255, 255, 255)",
        overflow: "auto",
    },
    subTitle: {
        display: "block",
        fontSize: 14,
    },
    audienceCoverageText: {
        width: "100%",
    },
    main: {
        position: "relative",
        display: "inline-block",
        width: 190,
        height: 190,
    },
    textContent: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: 15,
        flexDirection: "column",
        textAlign: "center",
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
    },
    progressContainer: {
        position: "absolute",
        padding: 10,
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
    },
    textContentPercent: {
        width: "100%",
        fontWeight: "bold",
        color: "rgb(68, 68, 68)",
        fontSize: 25,
    },
    loadingMessage: {
        width: "99%",
        textAlign: "center",
    },
    optionOverViewContainer: {
        marginTop: "1em",
        textAlign: "left",
    },
    recipeintsTableContainer: {
        marginTop: 5,
    },
    recipeintsTable: {
        marginTop: 10,
    },
    recipeintsTableHeader: {
        textAlign: "center",
    },
    optionValue: {
        marginBottom: "10px",
    },

    iconStyle: {
        fontSize: 40,
        width: "100%",
    },

    centerFullWidth: {
        width: "100%",
        textAlign: "center",
        marginTop: "10px",
    },

    mainStyle: {padding: 20},
});

const LoadingMessage = () => {
    const classes = useStyles();

    return (
        <div>
            <Loading size={2} />
            <div className={classes.loadingMessage}>Calculating...</div>
        </div>
    );
};

const OptionValue = ({label, value}: {label: string; value: string}) => {
    const classes = useStyles();

    return value ? (
        <div className={classes.optionValue}>
            <strong>
                {label}
                &nbsp;
            </strong>
            <br />
            <span>{value}</span>
        </div>
    ) : null;
};

const AudienceSize = ({
    potentialReach,
    totalAudienceSize,
}: {
    potentialReach: number;
    totalAudienceSize: number;
}) => {
    const classes = useStyles();

    const iconStyle = classnames("fa fa-users", classes.iconStyle);
    const mainStyle = classnames("audience-size-container", classes.mainStyle);
    const centerFullWidth = classnames(
        "audience-icon-container",
        classes.centerFullWidth,
    );

    const potentitalReachContent =
        potentialReach > 0
            ? `${potentialReach.toLocaleString()} of ${totalAudienceSize.toLocaleString()} devices`
            : "No devices match your rules";

    return (
        <div className={mainStyle}>
            <div className={centerFullWidth}>
                <i className={iconStyle} />
            </div>
            <div className={centerFullWidth}>{potentitalReachContent}</div>
        </div>
    );
};

const AudienceSizeChart = ({
    isLoading,
    potentialReach,
    totalAudienceSize,
}: {
    isLoading: boolean;
    potentialReach: number;
    totalAudienceSize: number;
}) => {
    const strokeWidth = 5;
    const strokeColor = "rgb(0, 192, 239)";
    const classes = useStyles();

    const audienceCoveragePercent = getPercentage({
        part: potentialReach,
        total: totalAudienceSize,
    });

    const containerClass = classnames("textContent", classes.textContent);

    return (
        <div className={classes.main}>
            <div className={containerClass}>
                {isLoading ? (
                    <LoadingMessage />
                ) : (
                    <AudienceSize
                        potentialReach={potentialReach}
                        totalAudienceSize={totalAudienceSize}
                    />
                )}
            </div>
            <div className={classes.progressContainer}>
                <Circle
                    percent={audienceCoveragePercent}
                    strokeColor={strokeColor}
                    strokeWidth={strokeWidth}
                />
            </div>
        </div>
    );
};

const RecipientTable = ({recipients}: any) => {
    const DEVICE_TYPE: {[prop: string]: string} = {
        ios: "iPhone",
        android: "Android",
    };

    const classes = useStyles();

    return recipients.length ? (
        <Row className={classes.recipeintsTableContainer}>
            <div>
                <Table bordered hover className={classes.recipeintsTable}>
                    <thead>
                        <tr>
                            <th className={classes.recipeintsTableHeader}>
                                Device
                            </th>
                            <th className={classes.recipeintsTableHeader}>
                                Identifier
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {recipients.map((recipient: any, key: number) => (
                            <tr key={key}>
                                <td>{DEVICE_TYPE[recipient.device]}</td>
                                <td>
                                    {truncateTo10(recipient.userIdentifier)}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </Row>
    ) : null;
};

const AudienceOverview = ({audienceOverview}: any) => {
    const classes = useStyles();

    return (
        <div className={classes.optionOverViewContainer}>
            <OptionValue
                label="Inactive users for x number of days"
                value={audienceOverview.numberOfDaysInactive}
            />
            <OptionValue
                label="Has Purchased"
                value={audienceOverview.hasPurchased}
            />
            <OptionValue
                label="Has Not Purchased"
                value={audienceOverview.hasNotPurchased}
            />
            <OptionValue label="Has Spent" value={audienceOverview.hasSpent} />
            <OptionValue
                label="User Segment"
                value={audienceOverview.userSegments}
            />
            <OptionValue
                label="Specific User Identifiers"
                value={audienceOverview.specificUserIdentifier}
            />
            <OptionValue
                label="Users Who Did Not Action Existing Campaign"
                value={audienceOverview.usersDidNotActionOnExistingCampaign}
            />
        </div>
    );
};

const PredictiveAudienceSize = memo(function ({
    clearAudienceOverView,
    potentialReach,
    totalAudienceSize,
    isLoading,
    recipients,
    audienceOverview,
}: any) {
    const classes = useStyles();
    useEffect(() => {
        return function cleanup() {
            clearAudienceOverView();
        };
    }, []);

    return (
        <Row className={classes.content}>
            <Col md={12}>
                <h4>
                    Estimated Reach
                    <span className={classes.subTitle}> last 30 days</span>
                </h4>
            </Col>
            <Col md={12}>
                <AudienceSizeChart
                    isLoading={isLoading}
                    potentialReach={potentialReach}
                    totalAudienceSize={totalAudienceSize}
                />
                <RecipientTable recipients={recipients} />
                <AudienceOverview audienceOverview={audienceOverview} />
            </Col>
        </Row>
    );
});

interface PredictiveAudienceSizeContainerProps {
    predictiveAudienceRepository?: any;
}

@inject("predictiveAudienceRepository")
@observer
export default class PredictiveAudienceSizeContainer extends React.Component<
    PredictiveAudienceSizeContainerProps
> {
    render() {
        const {
            clearAudienceOverView,
            totalAudienceSize,
            isLoading,
            recipients,
            potentialReach,
            audienceOverview,
        } = this.props.predictiveAudienceRepository!;

        return (
            <PredictiveAudienceSize
                clearAudienceOverView={clearAudienceOverView}
                totalAudienceSize={totalAudienceSize}
                isLoading={isLoading}
                recipients={recipients}
                potentialReach={potentialReach}
                audienceOverview={audienceOverview}
            />
        );
    }
}
