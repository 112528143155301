import React, {memo, useState} from "react";
import {CSVLink} from "react-csv";
import ButtonWithIcon from "../common/buttons/ButtonWithIcon";
import sortList from "../common/sortList";
import useFeatureFlag from "../featureFlags/useFeatureFlag";
import getPushNotificationTableDataForExport, {
    PushNotificationTableHeaders,
} from "./getPushNotificationTableDataForExport";
import MessageTable, {SORT_OPTIONS, CurrentChecked} from "./MessageTable";
import {Notification} from "./types";

interface Props {
    currentChecked: CurrentChecked;
    groupId: string;
    notifications: Array<Notification>;
    onDeleteMessagePressed: (item: string) => void;
    onChecked: (id: string, title: string) => void;
    groupName: string;
}

const formatWithPercentage = (whole: number, part: number): number => {
    if (whole === 0 || part === 0) {
        return part;
    }

    return Number(((part / whole) * 100).toFixed(2));
};

const openedCountFixMap = {
    "5df8c0f0083db70001827afc": [175, 84],
    "5df26306a94c8d000160cec2": [123],
    "5df24a2073665c00011941a7": [156, 43],
    "5df24702a94c8d00015ff856": [134],
    "5df0e51073665c00011077df": [112],
    "5df0e5c5a94c8d0001588366": [147],
};

const styles = Object.freeze({
    exportButton: {
        display: "flex",
        flex: 1,
        justifyContent: "flex-end",
        marginTop: -40,
    },
});
export default memo(function MessageTableContainer({
    currentChecked,
    groupId = "",
    notifications,
    onDeleteMessagePressed,
    onChecked,
    groupName,
}: Props): JSX.Element {
    const [activeSort, setActiveSort] = useState(SORT_OPTIONS.sendTime);

    let sortOrder = sortList(activeSort, "descend");

    if (
        activeSort === "message" ||
        activeSort === "notificationType" ||
        activeSort === "status"
    ) {
        sortOrder = sortList(activeSort, "ascend");
    }

    let draftNotifications: Array<Notification> = [];
    let notificationsWithComputedValues: Array<Notification> = [];
    notifications.map((item) => {
        // @ts-ignore
        const [numberOfOpens, actioned] = openedCountFixMap[item._id] || [
            item.numberOfOpens,
            item.actioned,
        ];

        const formattedNotification = {
            ...item,
            numberOfOpens,
            actioned,
            opened: formatWithPercentage(
                item.numberOfNotificationsPublishedToSns,
                numberOfOpens,
            ),
            computedActioned:
                !item.fbNotification || item.fbNotification.actions.length === 1
                    ? -1
                    : formatWithPercentage(numberOfOpens, actioned),
        };

        if (item.status === "DRAFT") {
            draftNotifications.push(formattedNotification);
            return;
        }

        notificationsWithComputedValues.push(formattedNotification);
    });

    const sortedNotifications = sortOrder(notificationsWithComputedValues);
    const allNotifications = [...draftNotifications, ...sortedNotifications];
    const {featureFlags = {}} = useFeatureFlag();
    const csvDataHeaders = [
        PushNotificationTableHeaders.IMAGE,
        PushNotificationTableHeaders.TITLE,
        PushNotificationTableHeaders.DESCRIPTION,
        PushNotificationTableHeaders.ESTIMATED_SENT,
        PushNotificationTableHeaders.OPENED,
        PushNotificationTableHeaders.ACTIONED,
        PushNotificationTableHeaders.SEND_TIME,
        ...(featureFlags.advertChildrenTeamFilter
            ? [PushNotificationTableHeaders.MEMBER_APP]
            : []),
        PushNotificationTableHeaders.STATUS,
    ];

    const csvData = getPushNotificationTableDataForExport(
        allNotifications,
        csvDataHeaders,
    );

    return (
        <>
            {featureFlags.allowCSVExport && (
                <div style={styles.exportButton}>
                    <CSVLink data={csvData} filename={groupName}>
                        <ButtonWithIcon
                            iconName="download"
                            size="sm"
                            title="Export to CSV"
                        />
                    </CSVLink>
                </div>
            )}
            {
                // @ts-ignore
                <MessageTable
                    notifications={allNotifications}
                    onDeleteMessagePressed={onDeleteMessagePressed}
                    onChecked={onChecked}
                    activeSort={activeSort}
                    onChangeActiveSort={setActiveSort}
                    currentChecked={currentChecked}
                    groupId={groupId}
                />
            }
        </>
    );
});
