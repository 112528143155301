import * as R from "ramda";
import {Col, Row} from "react-bootstrap";
import Badge from "../../widget/Badge";
import Box from "../../widget/Box";
import formatDate from "../../date/formatDate";
import React, {memo} from "react";
import Table from "../../widget/Table";
import {makeStyles} from "@material-ui/styles";
import classnames from "classnames";

const APP_REVIEWS_INFO =
    "Recent reviews of your app, collected from the iOS App Store and Google Play stores.";
const STORE_ICONS: {[property: string]: string} = {
    google_play: "ion ion-android-playstore",
    apple: "ion ion-social-apple",
};

const useStyles = makeStyles({
    icon: {
        color: "rgb(232, 10, 61)",
        fontSize: 20,
    },
    platform: {
        width: "100%",
        textAlign: "center",
    },
});

interface Review {
    store: string;
    author: string;
    date: string;
    stars: string;
    title: string;
}

const StarBadge = (numStars: string) => {
    const stars = parseInt(numStars, 10);
    const StarIcon = () => <i className="fa fa-star" />;

    return (
        <Badge state="info">
            <>
                {R.range(0, stars).map(
                    (_: any, key: string | number | undefined) => (
                        <StarIcon key={key} />
                    ),
                )}
            </>
        </Badge>
    );
};

export default memo(function AppReviews({
    reviews = [],
}: {
    reviews: Array<Review>;
}) {
    const classes = useStyles();
    const cols = [
        {
            displayTitle: "Store",
            id: "store",
        },
        {
            displayTitle: "Date",
            id: "date",
        },
        {
            displayTitle: "Author",
            id: "author",
        },
        {
            displayTitle: "Stars",
            id: "stars",
        },
        {
            displayTitle: "Title",
            id: "title",
        },
    ];

    const rows = reviews.map(({store, date, author, stars, title}: Review) => {
        const iconClass = classnames(classes.icon, STORE_ICONS[store]);

        return {
            store: (
                <div className={classes.platform}>
                    <i className={iconClass} />
                </div>
            ),
            date: formatDate(date, "-", "ddd, MMM DD, YYYY"),
            author,
            stars: StarBadge(stars),
            title,
        };
    });

    return (
        <Box infoPopOverText={APP_REVIEWS_INFO} title="Recent App Reviews">
            <Row>
                <Col md={12}>
                    <Table cols={cols} rows={rows} />
                </Col>
            </Row>
        </Box>
    );
});
