// @ts-ignore
import {getIn} from "../common/ramdaDataUtils";
import {inject, observer} from "mobx-react";
import React, {Component, memo, useEffect, useState} from "react";
import AudienceSelectionDetail from "./AudienceSelectionDetail";
import useFeatureFlag from "../featureFlags/useFeatureFlag";
import useAlert from "../alert/useAlert";
import AudienceSelectorRepository from "./AudienceSelectorRepository";
import {SalesProductSpent} from "./types";

interface AudienceSelectionDetailContainerProps {
    match: any;
    onGetAudienceSelectionDetails: (
        id: string,
    ) => Promise<{
        success: boolean;
        result: any;
    }>;
    getLocationValue: (location: any) => string;
    getSpentInCategory: (productSpent: SalesProductSpent) => string;
}

const HooksContainer = memo(function AudienceSelectionDetailContainer(
    props: AudienceSelectionDetailContainerProps,
) {
    const {featureFlags} = useFeatureFlag();
    const [audienceSelection, setAudienceSelection] = useState({});
    const {addErrorAlert} = useAlert();

    useEffect(() => {
        getAudienceSelectionDetails();
    }, []);

    const getAudienceSelectionDetails = async () => {
        const id = getIn(["params", "id"], props.match);

        const {result, success} = await props.onGetAudienceSelectionDetails(id);

        if (!success) {
            addErrorAlert(
                "An error occured while fetching audience selection. Please try again",
            );
        }

        setAudienceSelection(result);
    };

    const {getLocationValue, getSpentInCategory} = props;
    const selection: any = audienceSelection || {};
    const location = getLocationValue(selection.location || {});
    const spent = getSpentInCategory(
        getIn(["sales", "salesProductSpent"], selection),
    );
    const userNotificationActioned =
        getIn(["userNotificationActioned"], selection) || {};

    return (
        <AudienceSelectionDetail
            selection={selection}
            location={location}
            userNotificationActioned={userNotificationActioned}
            spent={spent}
            featureFlags={featureFlags}
        />
    );
});

interface Props {
    audienceSelectorRepository: AudienceSelectorRepository;
    match: any;
}

@inject("audienceSelectorRepository")
@observer
class AudienceSelectionDetailContainer extends Component<Props> {
    render() {
        const {
            onGetAudienceSelectionDetails,
            getLocationValue,
            getSpentInCategory,
        } = this.props.audienceSelectorRepository;
        return (
            <HooksContainer
                onGetAudienceSelectionDetails={onGetAudienceSelectionDetails}
                getLocationValue={getLocationValue}
                getSpentInCategory={getSpentInCategory}
                match={this.props.match}
            />
        );
    }
}

export default AudienceSelectionDetailContainer;
