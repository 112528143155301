import * as R from "ramda";
import {Button, Col, FormControl, Row} from "react-bootstrap";
import PopOverButton from "../common/buttons/PopOverButton";
import React, {memo, useState} from "react";
import {Notifications} from "./types";

const styles = {
    createButton: {
        width: 244,
        marginTop: 10,
    },
    input: {
        width: 244,
    },
    container: {
        position: "relative",
    },
};

interface Props {
    notifications: Notifications;
    onCreateNotificationGrouping: Function;
    onFinishGrouping: () => void;
    style: Object;
}

export default memo(function CreateNEWGroupPopOver({
    notifications,
    onCreateNotificationGrouping,
    onFinishGrouping,
    style,
}: Props): JSX.Element {
    const [groupName, setGroupName] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onSubmitButtonClicked = async (): Promise<void> => {
        try {
            setIsSubmitting(true);
            await onCreateNotificationGrouping({
                groupName,
                notifications,
            });
            setIsSubmitting(false);
            setGroupName("");
            onFinishGrouping();
        } catch (e) {
            setIsSubmitting(false);
        }
    };
    const onChangeGroupName = (value: string): void => {
        setGroupName(value);
    };

    return (
        <PopOverButton
            buttonIconName="folder"
            buttonTitle="Create new group"
            disabled={R.isEmpty(notifications)}
            popOverId="create-group"
            popOverTitle="Create new group"
            size="sm"
            style={style}
        >
            <Row>
                <Col md={12}>
                    <FormControl
                        onChange={(event: any) => {
                            onChangeGroupName(event.target.value);
                        }}
                        placeholder="Group name"
                        style={styles.input}
                        value={groupName}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <Button
                        bsStyle="primary"
                        disabled={
                            isSubmitting ||
                            R.compose(R.isEmpty, R.trim)(groupName)
                        }
                        onClick={onSubmitButtonClicked}
                        style={styles.createButton}
                    >
                        {isSubmitting ? "Creating a group..." : "Create"}
                    </Button>
                </Col>
            </Row>
        </PopOverButton>
    );
});
