export const getAverageBasketSize = (
    httpAgent,
    {fromDate, toDate, childrenTeamFilter},
) => {
    return httpAgent.get("/averageBasketSize").query({
        fromDate,
        toDate,
        childrenTeamFilter,
    });
};
