import * as R from "ramda";
import {action, computed, observable} from "mobx";
import {
    generateAddProductUrl,
    generateViewProductListUrl,
    generateViewProductUrl,
    generateViewWebsiteUrl,
    generateAddProductToList,
    getAppName,
} from "../pushNotification/getNotificationLinkUrl";
import {getIn, setIn} from "../common/ramdaDataUtils";
import {go} from "../main/eventHandlers";
import {
    PLACEMENT_TYPE_MAP,
    FULL_SCREEN_TYPE,
    CATEGORY_BANNER_ADVERTS,
} from "./PlacementConstants";
import BeamAPIClient from "../apiClient/BeamAPIClient";
import isValidImageFile from "./isValidImageFile";
import {isValidImageUrl, isValidUrl} from "../common/isValidUrl";
import {
    ACTION_NONE,
    ACTION_VIEW_WEBSITE,
    ACTION_VIEW_PRODUCT_DETAILS,
    ACTION_VIEW_PRODUCT_LIST,
    ACTION_ADD_PRODUCT_TO_BASKET,
    ACTION_ADD_PRODUCT_TO_LIST,
    ACTION_PATH,
} from "./constants";

const ADVERT_FORM_DEFAULT = {
    fields: {
        description: "",
        placement: "",
        image: "",
        imageDimension: {width: 0, height: 0},
        videoPreviewImageUrl: "",
        videoUrl: "",
        croppedImage: null,
        order: 0,
        sku: "",
        croppedDimension: {
            width: 0,
            height: 0,
        },
        relatedProducts: "",
        startDate: new Date(),
        endDate: new Date(),
        action: "",
        actionValue: "",
        actionLabel: "",
        childrenTeamFilter: "",
    },
    error: {
        description: false,
        placement: false,
        image: false,
        linkedProduct: false,
        relatedProducts: false,
        startDate: false,
        endDate: false,
        actionLabel: false,
    },
};

const productListPlacements = [
    "productList",
    "newProducts",
    "offersForYou",
    "basketProductPlacement",
    "trendingProducts",
    "relatedProducts",
    "softDrinksProducts",
    "confectioneryProducts",
    "alcoholProducts",
    "tobaccoProducts",
    "groceryProducts",
    "householdProducts",
    "categoryProductsConfectionery",
    "categoryProductsEnergyDrinks",
    "categoryProductsGrocery",
    "categoryProductsCrispsSnacks",
    "categoryProductsSoftDrinks",
    "categoryProductsLicensedTrade",
    "categoryProductsSeasonal",
    "categoryProductsNonFood",
    "categoryProductsSmokersAccessories",
    "categoryProductsToiletries",
    "categoryProductsPromotions",
    "categoryProductsSchoolApproved",
    "categoryProductsTobacco",
    "categoryProductsAlcohol",
    "categoryProductsCosmetics",
    "categoryProductsPersonalCare",
    "categoryProductsSnacking",
    "categoryProductsChilled",
    "categoryProductsEnergyDrinks",
    "categoryProductsHousehold",
    "categoryProductsWater",
];

const isProductList = (formValues) =>
    productListPlacements.includes(getIn(["fields", "placement"], formValues));

const isValidProductListField = (formValues, field) => {
    if (isProductList(formValues)) {
        return Boolean(getIn(["fields", field], formValues));
    }

    return true;
};

const isNilOrEmpty = R.anyPass([R.isNil, R.isEmpty]);

export const isValidActionValue = (action, value, enabledFullStop) => {
    if (isNilOrEmpty(value)) {
        return false;
    }

    if (action === ACTION_VIEW_WEBSITE) {
        return isValidUrl(value);
    } else {
        const matchedValues = enabledFullStop
            ? /^[a-zA-Z0-9 .,-]+$/i
            : /^[a-zA-Z0-9 ,-]+$/i;

        return value.match(matchedValues) !== null;
    }
};

export const parseActionValues = (action, value) => {
    if (action === ACTION_NONE || action === ACTION_VIEW_WEBSITE) {
        return null;
    }
    const removeWhiteSpace = value.replace(/\s/g, "");
    const splitActionValues = removeWhiteSpace.split(",");
    const filterActionValues = splitActionValues.filter(
        (value) => value.length,
    );
    const joinActionValues = filterActionValues.join(",");
    return joinActionValues;
};

export const getFirstSku = (skus) => skus.split(",")[0].trim() || "";

const bannerPlacements = [
    "banner",
    "Home Banner 1",
    "Home Banner 2",
    "Home Banner 3",
];

const isBannerPlacement = (placement) =>
    bannerPlacements.includes(placement) ||
    CATEGORY_BANNER_ADVERTS.includes(placement);

export default class AdvertRepository {
    constructor(apiClient) {
        this.apiClient = apiClient;
    }

    @observable
    advertForm = ADVERT_FORM_DEFAULT;
    @observable
    isFormLoading = false;

    @observable
    isVideoInterstitials = false;

    @observable
    allowFullStopOnSku = false;

    @action.bound
    onSetIsVideoInterstitials(value) {
        this.isVideoInterstitials = value;
    }

    @action.bound
    onSetFullStopOnSku(value) {
        this.allowFullStopOnSku = value;
    }

    @computed
    get actionLink() {
        const {advertForm} = this;
        const placementType = getIn(["fields", "placement"], advertForm);

        if (
            !isBannerPlacement(placementType) &&
            placementType !== "interstitial"
        ) {
            return "";
        }

        const action = getIn(["fields", "action"], advertForm) || "none";
        const value = getIn(["fields", "actionValue"], advertForm) || "";
        const teamName = getAppName();

        const actionUrls = {
            [ACTION_ADD_PRODUCT_TO_BASKET]: generateAddProductUrl(
                value,
                teamName,
            ),
            [ACTION_VIEW_PRODUCT_DETAILS]: generateViewProductUrl(
                value,
                teamName,
            ),
            [ACTION_VIEW_PRODUCT_LIST]: generateViewProductListUrl(
                value,
                teamName,
            ),
            [ACTION_ADD_PRODUCT_TO_LIST]: generateAddProductToList(
                value,
                teamName,
            ),
            [ACTION_VIEW_WEBSITE]: generateViewWebsiteUrl(value),
            [ACTION_NONE]: "",
        };

        return actionUrls[action] || "";
    }

    isValidImage = (image) => {
        if (image) {
            if (typeof image === "object") {
                return isValidImageFile(image);
            } else if (typeof image === "string") {
                return isValidImageUrl(image);
            }
        }
        return false;
    };

    @computed
    get validatedAdvertForm() {
        const {advertForm = {}, allowFullStopOnSku} = this;
        const fieldValues = advertForm.fields || {};
        const {
            placement,
            image,
            actionValue,
            actionLabel,
            action,
            startDate,
            endDate,
            videoUrl,
            videoPreviewImageUrl,
        } = fieldValues;
        const advertType = PLACEMENT_TYPE_MAP[placement];

        const imageError =
            !this.isVideoInterstitials && !this.isValidImage(image);
        const videoError =
            this.isVideoInterstitials && (!videoUrl || !isValidUrl(videoUrl));

        const mediaErrors = isProductList(advertForm)
            ? {}
            : {video: videoError, image: imageError};
        const videoPreviewImageError =
            this.isVideoInterstitials && !videoPreviewImageUrl;
        const placementError = R.isEmpty(placement);
        const skuError = !isValidProductListField(advertForm, "sku");

        const isActionless = isNilOrEmpty(action) || action === ACTION_NONE;
        const actionValueHasError = isActionless
            ? false
            : !isValidActionValue(action, actionValue, allowFullStopOnSku);

        const isFullscreenAdvert = advertType === FULL_SCREEN_TYPE;
        const actionLabelHasError =
            isFullscreenAdvert &&
            !isActionless &&
            (!actionLabel || actionLabel?.length < 3);

        const relatedProductsError =
            placement === "relatedProducts" &&
            !isValidProductListField(advertForm, "relatedProducts");

        const startDateError = R.isNil(startDate);
        const endDateError = R.isNil(endDate);

        return setIn(
            ["error"],
            {
                placement: placementError,
                relatedProducts: relatedProductsError,
                sku: skuError,
                actionValue: actionValueHasError,
                actionLabel: actionLabelHasError,
                startDate: startDateError,
                endDate: endDateError,
                ...mediaErrors,
            },
            advertForm,
        );
    }

    @computed
    get isFormDisabled() {
        const advertErrors = R.values(
            getIn(["validatedAdvertForm", "error"], this),
        );

        return R.compose(R.contains(true))(advertErrors);
    }

    @action.bound
    onChangeAdvertFields = (value, field) => {
        const updatedFormValues = setIn(
            ["fields", field],
            value,
            this.advertForm,
        );

        this.advertForm = updatedFormValues;
    };

    @action.bound
    onResetAdvertFields() {
        this.advertForm = ADVERT_FORM_DEFAULT;
    }

    onSaveSuccess = () => {
        this.isFormLoading = false;
        this.onResetAdvertFields();
        return {success: true};
    };

    @action.bound
    onBulkChangeAdvertFields = (formFields) => {
        if (formFields.videoUrl) {
            this.onSetIsVideoInterstitials(true);
        }
        const updatedFormValues = {
            ...this.advertForm,
            fields: {
                ...this.advertForm.fields,
                ...formFields,
            },
        };

        this.advertForm = updatedFormValues;
    };

    @action.bound
    async onEdit(advertId, advertPayload, actionLabel) {
        const {advertForm, actionLink} = this;

        const newImage = getIn(
            [
                "fields",
                this.isVideoInterstitials ? "videoPreviewImageUrl" : "image",
            ],
            advertForm,
        );

        const isImageFile = typeof newImage === "object";
        let imageUploadResponse = {};
        const action = getIn(["fields", "action"], advertForm) || "none";

        if (isImageFile) {
            imageUploadResponse = await this.apiClient.invokePostWithFormData(
                "campaignImages",
                {
                    image: newImage,
                },
            );
        }

        const mediaUrl = isImageFile ? imageUploadResponse.imageUrl : newImage;
        const mediaPaylod = this.isVideoInterstitials
            ? {
                  videoPreviewImageUrl: mediaUrl,
                  imageUrl: "",
              }
            : {
                  imageUrl: mediaUrl,
                  videoUrl: "",
                  videoPreviewImageUrl: "",
              };

        const updateAdvertPayload = {
            ...advertPayload,
            ...mediaPaylod,
            actionLink: action === "none" ? null : actionLink,
            actionLabel: action === "none" ? null : actionLabel,
        };

        await this.apiClient.invokePutEndpoint(
            `placements/${advertId}`,
            updateAdvertPayload,
        );
    }

    @action.bound
    async onSave(advertPayload) {
        await this.apiClient.invokePostWithFormData(
            "placements",
            advertPayload,
        );
    }

    @action.bound
    async onSaveAdverts(advertId) {
        this.isFormLoading = true;
        try {
            const isEditMode = Boolean(advertId);
            const {
                advertForm,
                actionLink,
                onEditPlacement,
                onSavePlacement,
            } = this;
            const advertFields = advertForm.fields || {};

            const {
                placement,
                description,
                sku,
                relatedProducts,
                endDate,
                startDate,
                childrenTeamFilter,
                actionLabel,
                action,
                actionValue,
                videoPreviewImageUrl,
                videoUrl,
            } = advertFields;

            const formattedActionValues = parseActionValues(
                action,
                actionValue,
            );
            if (formattedActionValues) {
                this.onChangeAdvertFields(formattedActionValues, "actionValue");
            }

            const placementType = getIn(["fields", "placement"], advertForm);
            const dimension = getIn(["fields", "imageDimension"], advertForm);

            const width = dimension?.width;
            const height = dimension?.height;

            const image = getIn(["fields", "image"], advertForm)
                ? {image: getIn(["fields", "image"], advertForm)}
                : {};
            const media = videoUrl
                ? {
                      videoPreviewImageUrl,
                      videoUrl,
                  }
                : image;

            const childrenTeamFilterQuery = childrenTeamFilter
                ? {childrenTeamFilter}
                : {};

            const isAddProductToList = action === ACTION_ADD_PRODUCT_TO_LIST;

            const advertPayload = {
                actionType: action,
                actionParams: isAddProductToList
                    ? getFirstSku(actionValue)
                    : actionValue,
                actionLabel,
                actionLink,
                description,
                placement,
                linkedProduct: sku,
                relatedProducts,
                order: 0,
                ...(width && {width}),
                ...(height && {height}),
                startDate: startDate ? startDate.toString() : "",
                endDate: endDate ? endDate.toString() : "",
                type: PLACEMENT_TYPE_MAP[placement],
                ...childrenTeamFilterQuery,
                ...media,
            };

            isEditMode
                ? await this.onEdit(advertId, advertPayload, actionLabel)
                : await this.onSave(advertPayload);

            return this.onSaveSuccess();
        } catch (exception) {
            this.isFormLoading = false;
            return {success: false};
        }
    }
}
