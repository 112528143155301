import React, {memo} from "react";
import {makeStyles, Theme} from "@material-ui/core";
import classnames from "classnames";

const useStyles = makeStyles<Theme>({
    betaRibbon: {
        width: "150px",
        position: "fixed",
        textAlign: "center",
        lineHeight: "39px",
        letterSpacing: "1px",
        transform: "rotate(-45deg)",
        WebkitTransform: "rotate(-45deg)",
        boxShadow: "0 0 3px rgba(0,0,0,.3)",
        color: "rgb(255,255,255)",
        zIndex: 9999,
    },
    position: {
        top: "8px",
        left: "-50px",
        transform: "rotate(-45deg)",
        WebkitTransform: "rotate(-45deg)",
    },
    color: {
        backgroundColor: "rgb(238, 68, 15)",
    },
});

export default memo(function Ribbon(): JSX.Element {
    const classes = useStyles();
    const parentClassName = classnames(
        classes.betaRibbon,
        classes.position,
        classes.color,
    );
    return <div className={parentClassName}>{"BETA"}</div>;
});
