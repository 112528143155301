import * as R from "ramda";

export default R.curry(
    ({apiClient, widgetConfiguration}: any) => (
        widgetToLoad: string,
        ...args: any
    ) => {
        const {dataAccess} = widgetConfiguration[widgetToLoad];

        return R.call(apiClient[dataAccess], ...args);
    },
);
