import React, {memo, useEffect, useState} from "react";
import {
    Button,
    Col,
    FormControl,
    FormGroup,
    Row,
    HelpBlock,
    Well,
} from "react-bootstrap";
import InputRow from "../widget/InputRow";
import Box from "../widget/Box";
import Checkbox from "../widget/Checkbox";
import {observer, inject} from "mobx-react";
import useSession from "../main/useSession";
import moment from "moment";
import * as R from "ramda";
import useNavigate from "@beam/use-navigate";
import {DropdownList} from "react-widgets";

const ORDER_LIST = ["Fixed", "Random", "Rotation"];
const MAX_LENGTH_OF_PERIOD = 30;
const LABEL_GRID = 5;
const VALUE_GRID = 7;

interface AdvertConfigFormProps {
    advertConfigId?: string;
    placement?: string;
    apiClient: any;
}

const styles = Object.freeze({
    guidelines: {
        fontSize: 12,
    },
});

const AdvertConfigAdvice = () => (
    <Well>
        <p style={styles.guidelines}>
            <p>
                You can use the following settings to manage how often Full
                Screen Ads (Interstitials) can be be displayed in the app.
            </p>
            <p>
                The rules apply for as many days as you set.
                <br />
                For example, if you set the rules to last for 7 days, they will
                apply from today for the next 7 days.
                <br />
                The rules will then automatically start again on the 8th day for
                the next 7 days, and so on.
                <br />
                The rules simply continue until you remove them and set a new
                rule.
            </p>
            <p>
                You can configure how many times the interstitial(s) should be
                displayed during the amount of days you set.
            </p>
            <p>
                <b>
                    NOTE: Interstitials will only be displayed a maximum of once
                    per day. You cannot configure interstitials to be shown more
                    than once per day.
                </b>
            </p>
            <p>
                If you have more than one interstitial scheduled to display, you
                can set if they should be displayed in the fixed order you have
                listed, a random order, or on rotation. Rotation means that if
                there are 3 interstitials to display, then once all 3
                interstitials have been displayed, the 1st interstitial that was
                displayed originally will now be displayed again, and so on.
            </p>
            <p>
                If you have more than one interstitial scheduled to display, you
                can allow users to dismiss them and re-display the main app
                straight away.
                <br />
                If you allow this however, users may not see any of the other
                ads that you have set to display.
            </p>
            <p>For example, if you set:</p>
            <p>
                <li>
                    <b>
                        How many days should these interstitial rules apply for?
                    </b>
                    : 7
                </li>
                <li>
                    <b>Max times interstitial(s) should be displayed</b>: 1
                </li>
                <li>
                    <b>
                        Display order, if more than one interstitial to display
                    </b>
                    : Random
                </li>
                <li>
                    <b>Allow user to dismiss all interstitials</b>: No
                </li>
            </p>
            <p>
                Then in the app, the user will see the interstitial(s) once
                during the 7 days. The user will not be able to dismiss the
                interstitials if there is more than one to display.
                <br />
                Then, during the following 7 days (from day 8) the
                interstitial(s) will be displayed again, and if there is more
                than one to display, the first interstitial to display won’t be
                the same as the first interstitial that was displayed during the
                previous 7 days.
            </p>
        </p>
    </Well>
);

const AdvertConfigForm = memo(
    ({advertConfigId, placement, apiClient}: AdvertConfigFormProps) => {
        const {userData} = useSession();
        const {navigate} = useNavigate();
        const teamNumber = userData.activeTeam!.teamNumber;

        const [isFormLoading, setIsFormLoading] = useState(false);
        const [advertConfigForm, setAdvertConfigForm] = useState({
            periodLength: "",
            maxShowings: "",
            order: "",
            skippable: false,
        });

        const onSave = () => {
            onAdConfigurationSave(teamNumber, placement!, advertConfigId!);
            if (!isFormLoading) {
                navigate("/placements");
            }
        };

        const onAdConfigurationSave = async (
            teamNumber: any,
            placement: string,
            advertConfigId: string,
        ) => {
            const timestamp = moment.utc().startOf("day").toDate();

            setIsFormLoading(true);
            try {
                const advertConfigurationPayload = {
                    ...advertConfigForm,
                    timestamp,
                    teamNumber,
                    placement,
                };

                if (advertConfigId) {
                    await apiClient.invokePutEndpoint(
                        `advertConfigs/${advertConfigId}`,
                        advertConfigurationPayload,
                    );
                    setIsFormLoading(false);
                    return;
                }

                await apiClient.invokePostWithFormData(
                    `advertConfigs?teamNumber=${teamNumber}`,
                    advertConfigurationPayload,
                );

                setIsFormLoading(false);
                return {success: true};
            } catch (exception) {
                setIsFormLoading(false);
                return {success: false};
            }
        };

        const onGetAdvertConfigById = async (teamNumber: any, id: string) => {
            const response = await apiClient.invokeGetEndpoint(
                `advertConfigs/${id}`,
                {teamNumber},
            );
            return response;
        };

        const fetchAdvertConfigs = async () => {
            const configs = await onGetAdvertConfigById(
                teamNumber,
                advertConfigId!,
            );
            if (configs) {
                const {periodLength, maxShowings, order, skippable} = configs;
                setAdvertConfigForm({
                    periodLength,
                    maxShowings,
                    order,
                    skippable,
                });
            }
        };

        const isMaxShowingsInvalid = (): boolean => {
            return (
                parseInt(advertConfigForm.maxShowings) >
                parseInt(advertConfigForm.periodLength)
            );
        };
        const isLengthOfPeriodInvalid = (): boolean => {
            return (
                parseInt(advertConfigForm.periodLength) > MAX_LENGTH_OF_PERIOD
            );
        };

        const hasError = (): boolean => {
            return Boolean(
                !advertConfigForm.periodLength ||
                    !advertConfigForm.maxShowings ||
                    !advertConfigForm.order ||
                    isLengthOfPeriodInvalid() ||
                    isMaxShowingsInvalid() ||
                    parseInt(advertConfigForm.periodLength) <= 0 ||
                    parseInt(advertConfigForm.maxShowings) <= 0,
            );
        };

        useEffect(() => {
            if (advertConfigId) {
                fetchAdvertConfigs();
            }
        }, []);

        const onFormChange = (field: string, value: any) => {
            setAdvertConfigForm({
                ...advertConfigForm,
                [field]: value,
            });
        };

        const errorMessage = (): number => {
            if (
                parseInt(advertConfigForm.periodLength) >
                    MAX_LENGTH_OF_PERIOD ||
                parseInt(advertConfigForm.periodLength) <= 0 ||
                advertConfigForm.periodLength === ""
            ) {
                return MAX_LENGTH_OF_PERIOD;
            }
            return parseInt(advertConfigForm.periodLength);
        };

        const validate = (value: string, field?: string) => {
            if (parseInt(value) <= 0) {
                return "error";
            }

            if (!value) {
                return "error";
            }

            if (
                field === "maxShowings" &&
                parseInt(value) > parseInt(advertConfigForm.periodLength)
            ) {
                return "error";
            }

            if (parseInt(value) > MAX_LENGTH_OF_PERIOD) {
                return "error";
            }

            return null;
        };

        return (
            <Row>
                <Col md={12}>
                    <Box
                        styleState="primary"
                        title="Configure Full Screen (Interstitial) Ad Rule"
                        infoPopOverText=""
                    >
                        <AdvertConfigAdvice />
                        <Col md={12}>
                            <InputRow
                                label="How many days should these interstitial rules apply for?"
                                labelGrid={LABEL_GRID}
                                valueGrid={VALUE_GRID}
                            >
                                <FormGroup
                                    validationState={validate(
                                        advertConfigForm.periodLength,
                                        "periodLength",
                                    )}
                                >
                                    <FormControl
                                        onChange={(e) =>
                                            onFormChange(
                                                "periodLength",
                                                (e.target as HTMLInputElement)
                                                    .value,
                                            )
                                        }
                                        placeholder="e.g. 1"
                                        type="number"
                                        value={advertConfigForm.periodLength}
                                        min={1}
                                        max={MAX_LENGTH_OF_PERIOD}
                                    />
                                    <HelpBlock>
                                        {isLengthOfPeriodInvalid() ||
                                        parseInt(
                                            advertConfigForm.periodLength,
                                        ) <= 0
                                            ? "Please enter a number between 1 and 30"
                                            : "This field is required"}
                                    </HelpBlock>
                                </FormGroup>
                            </InputRow>

                            <InputRow
                                label="Max times interstitial(s) should be displayed:"
                                sublabel="(No more than once per day)"
                                labelGrid={LABEL_GRID}
                                valueGrid={VALUE_GRID}
                            >
                                <FormGroup
                                    validationState={validate(
                                        advertConfigForm.maxShowings,
                                        "maxShowings",
                                    )}
                                >
                                    <FormControl
                                        onChange={(e) =>
                                            onFormChange(
                                                "maxShowings",
                                                (e.target as HTMLInputElement)
                                                    .value,
                                            )
                                        }
                                        placeholder="e.g. 1"
                                        type="number"
                                        value={advertConfigForm.maxShowings}
                                        min={1}
                                        max={
                                            advertConfigForm.periodLength ||
                                            MAX_LENGTH_OF_PERIOD
                                        }
                                    />
                                    <HelpBlock>
                                        {isMaxShowingsInvalid() ||
                                        parseInt(advertConfigForm.maxShowings) >
                                            MAX_LENGTH_OF_PERIOD ||
                                        parseInt(
                                            advertConfigForm.maxShowings,
                                        ) <= 0
                                            ? "Please enter a number between 1 and " +
                                              `${errorMessage()}`
                                            : "This field is required"}
                                    </HelpBlock>
                                </FormGroup>
                            </InputRow>

                            <InputRow
                                label="Display order, if more than one interstitial to display:"
                                labelGrid={LABEL_GRID}
                                valueGrid={VALUE_GRID}
                            >
                                <FormGroup
                                    validationState={validate(
                                        advertConfigForm.order,
                                    )}
                                >
                                    <DropdownList
                                        data={ORDER_LIST}
                                        onChange={(value) =>
                                            onFormChange("order", value)
                                        }
                                        defaultValue="Fixed"
                                        placeholder="None"
                                        value={advertConfigForm.order}
                                    />
                                    <HelpBlock>
                                        This field is required
                                    </HelpBlock>
                                </FormGroup>
                            </InputRow>

                            <InputRow
                                label="Allow user to dismiss all interstitials:"
                                labelGrid={LABEL_GRID}
                                valueGrid={VALUE_GRID}
                            >
                                <FormGroup>
                                    <Checkbox
                                        checked={advertConfigForm.skippable}
                                        onChecked={(value) =>
                                            onFormChange("skippable", value)
                                        }
                                    />
                                </FormGroup>
                            </InputRow>

                            <Col md={12}>
                                <Button
                                    disabled={isFormLoading || hasError()}
                                    onClick={onSave}
                                >
                                    {isFormLoading ? "Submitting.." : "Submit"}
                                </Button>
                            </Col>
                        </Col>
                    </Box>
                </Col>
            </Row>
        );
    },
);

interface Props {
    apiClient: any;
    match: any;
}

@inject("apiClient")
@observer
export default class AddAdvertConfigContainer extends React.Component<Props> {
    render() {
        const advertConfigId: string | undefined = R.path(
            ["match", "params", "advertConfigId"],
            this.props,
        );
        const placement: string | undefined = R.path(
            ["match", "params", "placementName"],
            this.props,
        );

        return (
            <AdvertConfigForm
                advertConfigId={advertConfigId}
                placement={placement}
                apiClient={this.props.apiClient}
            />
        );
    }
}
