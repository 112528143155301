import {ButtonToolbar, Overlay, Popover, ButtonProps} from "react-bootstrap";
import ButtonWithIcon from "./ButtonWithIcon";
import React, {memo, useState, CSSProperties} from "react";

interface Props {
    buttonIconName: string;
    buttonIconStyle?: CSSProperties;
    buttonStyle?: CSSProperties;
    buttonTitle?: string;
    children: JSX.Element | Array<JSX.Element>;
    disabled?: boolean;
    noBorder?: boolean;
    placement?: string;
    popOverId?: string;
    popOverTitle?: string | JSX.Element;
    size: ButtonProps["bsSize"];
    style?: CSSProperties;
    transparent?: boolean;
}

const styles = Object.freeze({
    noBorder: {
        border: "none",
        background: "transparent",
    },
});
export default memo(function PopOverButton({
    buttonTitle = "",
    buttonIconName,
    buttonIconStyle,
    buttonStyle,
    children,
    disabled,
    noBorder = false,
    style,
    size = "sm",
    placement = "bottom",
    popOverId = "popOver",
    popOverTitle,
    transparent,
}: Props): JSX.Element {
    const borderStyle = noBorder ? styles.noBorder : {};
    const buttonAndBorderStyle = {
        ...buttonStyle,
        ...borderStyle,
    };

    const [show, setShow] = useState(false);
    const [target, setTarget] = useState();

    const onShowOverlay = (e: any) => {
        setShow(true);
        setTarget(e.target);
    };
    const onHide = () => {
        setShow(false);
    };

    return (
        <ButtonToolbar style={style}>
            <ButtonWithIcon
                transparent={transparent}
                disabled={disabled}
                iconName={buttonIconName}
                iconStyle={buttonIconStyle}
                onClick={onShowOverlay}
                size={size}
                style={buttonAndBorderStyle}
                title={buttonTitle}
            />
            <Overlay
                onHide={onHide}
                placement={placement}
                rootClose
                show={show}
                target={target}
            >
                <Popover id={popOverId} title={popOverTitle}>
                    {children}
                </Popover>
            </Overlay>
        </ButtonToolbar>
    );
});
