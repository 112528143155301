import {History, Location} from "history";
import {stringify} from "querystringify";
// @ts-ignore
import {useContext} from "react";
// @ts-ignore
import {__RouterContext, match} from "react-router";

interface IRouterContext {
    history: History;
    location: Location;
    match: match<IURLParams>;
}

interface IURLParams {
    [x: string]: string | undefined;
}

interface IParams {
    [key: string]: string | boolean | number;
}

export default function useNavigate(): {
    navigate: (
        pathname: string,
        queryParams?: IParams,
        privateParams?: IParams,
    ) => void;
    history: History;
} {
    const context: IRouterContext | undefined = useContext(__RouterContext);

    if (!context) {
        throw new Error(
            "use-navigate may only be used within a react-router context.",
        );
    }

    const {history} = context;

    return {
        navigate(pathname, queryParams, privateParams) {
            if (__DEV__ && pathname.includes("?")) {
                throw new Error("Use queryParams instead");
            }

            history.push({
                pathname,
                // @ts-ignore
                search: queryParams
                    ? stringify((queryParams as unknown) as object)
                    : undefined,
                state: privateParams,
            });
        },
        history,
    };
}
