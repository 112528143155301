import {connect} from "react-redux";
import {createSelector} from "reselect";
// @ts-ignore
import {
    getNotificationDetails,
    resetMessageDetailsToEmpty,
} from "../eventHandlers";
import React, {useEffect, useState} from "react";
import MessageDetail from "./MessageDetails";

interface Props {
    onGetNotificationDetails: (id: string) => Promise<any>;
    match: any;
    reset: () => void;
    messageDetails: any;
}

function View({
    onGetNotificationDetails,
    match,
    reset,
}: Props): JSX.Element | null {
    const [messageDetails, setMessageDetails] = useState(null);
    const id = match.params.id;

    const getNotification = async (): Promise<any> => {
        const {results} = await onGetNotificationDetails(id);
        setMessageDetails(results);
    };
    useEffect(() => {
        getNotification();
        return function cleanup() {
            reset();
        };
    }, [id]);
    return (
        <div>
            {messageDetails ? (
                <MessageDetail messageDetails={messageDetails} />
            ) : null}
        </div>
    );
}

const dispatchSelector = createSelector(
    (dispatch: Function): Function => dispatch,
    (dispatch: Function): Object => ({
        onGetNotificationDetails: (notificationId: string): Object =>
            dispatch(getNotificationDetails({notificationId})),
        reset: () => dispatch(resetMessageDetailsToEmpty()),
    }),
);

export default connect(null, dispatchSelector)(View);
