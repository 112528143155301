import React, {memo, useState, useMemo, useCallback, useEffect} from "react";
import FeatureFlagContext from "./FeatureFlagContext";
import {loadUser} from "../main/repository";
import getFeatureFlagsForTeamId from "./getFeatureFlagsForTeamId";

import useApiClient from "../apiClient/useApiClient";

export default memo(function FeatureFlagProvider({children}) {
    const userData = loadUser();
    const teamNumber =
        (userData && userData.activeTeam && userData.activeTeam.teamNumber) ||
        0;

    const featureFlagsDefault = getFeatureFlagsForTeamId(teamNumber);

    const [featureFlags, setFeatureFlags] = useState(
        // @ts-ignore
        featureFlagsDefault.toJS(),
    );
    const {getRequest} = useApiClient();
    const [backendFeatureFlags, setBackendFeatureFlags] = useState({});
    const fetchFeatureFlagFromBackend = useCallback(async () => {
        try {
            const results = await getRequest("featureFlags");
            const formattedResults = results?.featureFlags?.reduce(
                (featureFlagObject: any, currentValue: string) => {
                    return {
                        ...featureFlagObject,
                        [currentValue]: true,
                    };
                },
                {},
            );

            setBackendFeatureFlags(formattedResults);
        } catch {}
    }, [getRequest]);

    useEffect(() => {
        setBackendFeatureFlags({});
        if (teamNumber) {
            fetchFeatureFlagFromBackend();
            const newFeatureFlags = getFeatureFlagsForTeamId(teamNumber);
            // @ts-ignore
            setFeatureFlags(newFeatureFlags.toJS());
        }
    }, [teamNumber]);

    const combinedFeatureFlags = useMemo(
        () => ({
            ...featureFlags,
            ...backendFeatureFlags,
        }),
        [featureFlags, backendFeatureFlags],
    );

    return (
        <FeatureFlagContext.Provider
            value={{
                featureFlags: combinedFeatureFlags,
                setFeatureFlags,
            }}
        >
            {children}
        </FeatureFlagContext.Provider>
    );
});
