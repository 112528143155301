import {createAction} from "redux-actions";

const MESSAGE_MODULE = "beam/message";

export const setMessageList = createAction(`${MESSAGE_MODULE}/MESSAGE_LIST`);
export const setMessageDetail = createAction(
    `${MESSAGE_MODULE}/MESSAGE_DETAIL`,
);
export const setMessageGroupList = createAction(
    `${MESSAGE_MODULE}/MESSAGE_GROUP_LIST`,
);
