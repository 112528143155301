import * as R from "ramda";

interface Menu {
    subMenus?: Array<any>;
}

export default (menu: Menu) =>
    R.allPass([
        R.has("subMenus"),
        R.compose(R.not, R.isEmpty, R.prop("subMenus")),
    ])(menu);
