import {statesType} from "../style/BootstrapConstants";
import classNames from "classnames";
import collapseButton from "./button/collapseButton";
import InfoButton from "../common/buttons/InfoButton";
import InfoPopOverText from "../common/InfoPopOverText";
import keyedNodeList from "../common/keyedNodeList";
import React, {memo, CSSProperties} from "react";
import removeButton from "./button/removeButton";

const styles = Object.freeze({
    title: {
        display: "block",
        float: "left",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        width: "100%",
        boxSizing: "border-box",
        overflow: "hidden",
        padding: "5px 0",
    } as CSSProperties,
    titleWithInfoButton: {
        width: "calc(100% - 45px)",
    } as CSSProperties,
    infoButton: {
        paddingLeft: 5,
        paddingRight: 5,
    } as CSSProperties,
    infoButtonContainer: {
        float: "right",
    } as CSSProperties,
    boxTools: {
        padding: "5px 0",
    } as CSSProperties,
});

// Box
// https://almsaeedstudio.com/themes/AdminLTE/documentation/index.html#component-box

interface Props {
    buttons?: JSX.Element | Array<any>;
    children?: JSX.Element | Array<JSX.Element>;
    collapsible?: boolean;
    infoPopOverText?: string;
    onHeaderClick?: any;
    removable?: boolean;
    removeBodyPadding?: boolean;
    removeHeaderBorder?: boolean;
    solid?: boolean;
    style?: CSSProperties;
    styleState?: statesType;
    title?: string | JSX.Element;
    className?: any;
}

export default memo(function Box({
    buttons,
    children,
    collapsible,
    infoPopOverText,
    onHeaderClick,
    styleState,
    removable,
    removeBodyPadding,
    removeHeaderBorder,
    solid,
    style,
    title,
    className,
}: Props): JSX.Element {
    const boxButtons = keyedNodeList(buttons);

    const boxBodyClassNames = classNames("box-body", {
        "no-padding": removeBodyPadding,
    });
    const boxHeaderClassNames = classNames("box-header", {
        "with-border": !removeHeaderBorder,
    });
    const boxClassNames = classNames(
        "box",
        {
            [`box-${styleState || ""}`]: styleState,
            "box-solid": solid,
        },
        style,
        className,
    );
    const titleStyle = Boolean(infoPopOverText)
        ? {...styles.title, ...styles.titleWithInfoButton}
        : styles.title;
    const boxHeaderStyle = Boolean(onHeaderClick) ? {cursor: "pointer"} : {};

    return (
        <div className={boxClassNames} style={style}>
            <div className={boxHeaderClassNames} style={boxHeaderStyle}>
                <h3
                    className="box-title"
                    onClick={onHeaderClick}
                    style={titleStyle}
                >
                    {title}
                </h3>
                <div className="box-tools pull-right" style={styles.boxTools}>
                    {Boolean(infoPopOverText) && (
                        <InfoButton
                            buttonStyle={styles.infoButton}
                            placement="left"
                            popOverTitle={title}
                            style={styles.infoButtonContainer}
                        >
                            <InfoPopOverText info={infoPopOverText} />
                        </InfoButton>
                    )}
                    {boxButtons}
                    {removable && removeButton}
                    {collapsible && collapseButton}
                </div>
            </div>
            <div className={boxBodyClassNames}>{children}</div>
        </div>
    );
});
