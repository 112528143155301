import React, {useState, memo, CSSProperties} from "react";
import isEmailAddress from "./isEmailAddress";
import useSession from "../main/useSession";
import useNavigate from "@beam/use-navigate";
import {Button} from "react-bootstrap";
// @ts-ignore
import t from "tcomb-form";

const styles = Object.freeze({
    form: {
        marginBottom: 15,
    } as CSSProperties,
    formContainer: {
        maxWidth: "330px",
        padding: 20,
        margin: "0 auto",
        backgroundColor: "rgb(255, 255, 255)",
    } as CSSProperties,
    description: {
        textAlign: "center",
    } as CSSProperties,
    getSupportLink: {
        textAlign: "center",
    } as CSSProperties,
});

const FormSchema = t.struct({
    emailAddress: t.subtype(t.Str, (email: string) => isEmailAddress(email)),
    password: t.String,
});

const LoadingSubmitButton = ({isLoading}: {isLoading: boolean}) => (
    <Button block bsStyle="primary" type="submit">
        {isLoading ? "Logging In.." : "Login"}
    </Button>
);

export default memo(function LoginContainer() {
    const [loading, setLoading] = useState(false);
    const [emailAddressAndPassword, setEmailAddressOrPassword] = useState({
        emailAddress: "",
        password: "",
    });
    const {navigate} = useNavigate();

    let form: t;

    const {login} = useSession();
    const onLogin = async (event: Object) => {
        try {
            // @ts-ignore
            event.preventDefault();
            if (!form.validate().isValid()) {
                return;
            }

            setLoading(true);
            const {success, nextRoute} = await login(
                emailAddressAndPassword.emailAddress,
                emailAddressAndPassword.password,
            );
            setLoading(false);

            if (success) {
                navigate(nextRoute);
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const handleOnBlur = (name: string) => () => {
        form.getComponent(name).validate().isValid();
    };

    const options = {
        auto: "label",
        fields: {
            emailAddress: {
                type: "email",
                error: "Invalid email",
                attrs: {
                    placeholder: "Email Address",
                    onBlur: handleOnBlur("emailAddress"),
                },
            },

            password: {
                type: "password",
                error: "Password field is required",
                attrs: {
                    placeholder: "Password",
                    onBlur: handleOnBlur("password"),
                },
            },
        },
    };

    return (
        <div className="login-box">
            <div className="login-logo">
                <img src={require("./logo.png")} width={180} />
            </div>
            <div style={styles.formContainer}>
                <form
                    className="login-form"
                    noValidate
                    onSubmit={(event) => onLogin(event)}
                    style={styles.form}
                >
                    <p style={styles.description}>Login to your account</p>
                    <t.form.Form
                        ref={(c: any) => (form = c)}
                        onChange={(value: any) => {
                            setEmailAddressOrPassword((prevState) => {
                                return {
                                    ...prevState,
                                    emailAddress: value.emailAddress,
                                    password: value.password,
                                };
                            });
                        }}
                        options={options}
                        type={FormSchema}
                        value={emailAddressAndPassword}
                    />
                    <LoadingSubmitButton isLoading={loading} />
                </form>
                <div style={styles.getSupportLink}>
                    <a href="/#/contact-us">Get support</a>
                </div>
            </div>
        </div>
    );
});
