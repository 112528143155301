import classnames from "classnames";
import InfoButton from "../common/buttons/InfoButton";
import InfoPopOverText from "../common/InfoPopOverText";
import React, {memo, CSSProperties} from "react";

export interface Props {
    children?: Array<JSX.Element>;
    boldText?: string;
    boxColor?: string;
    color?: string;
    icon?: string;
    iconNode?: JSX.Element;
    infoPopOverText?: string;
    text?: string;
}

const styles = Object.freeze({
    infoBoxTextContainer: {
        float: "left",
        width: "80%",
        overflow: "hidden",
        textOverflow: "ellipsis",
    } as CSSProperties,
    infoButtonContainer: {float: "right"} as CSSProperties,
    infoButton: {padding: "0px"},
    infoButtonIcon: {color: "rgb(255, 255, 255)"},
});

export default memo(function infoBox({
    children,
    boldText,
    boxColor,
    color,
    icon,
    iconNode,
    infoPopOverText,
    text,
}: Props): JSX.Element {
    const infoBoxClassName = classnames("info-box", {
        [`bg-${boxColor}`]: Boolean(boxColor),
    });
    const infoBoxIconClassName = classnames("info-box-icon", {
        [`bg-${color}`]: Boolean(color),
    });
    const iconToShow = iconNode ? iconNode : <i className={icon} />;

    return (
        <div className={infoBoxClassName}>
            <span className={infoBoxIconClassName}>{iconToShow}</span>
            <div className="info-box-content">
                <span className="info-box-text">
                    <span style={styles.infoBoxTextContainer}>{text}</span>
                    {Boolean(infoPopOverText) && (
                        <InfoButton
                            buttonIconStyle={styles.infoButtonIcon}
                            buttonStyle={styles.infoButton}
                            popOverTitle={text || ""}
                            style={styles.infoButtonContainer}
                        >
                            <InfoPopOverText info={infoPopOverText} />
                        </InfoButton>
                    )}
                </span>
                <span className="info-box-number">{boldText}</span>
                {children}
            </div>
        </div>
    );
});
