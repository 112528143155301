import moment from "moment";

export default (originalDate: string, defaultValue: string = "-"): string => {
    if (!originalDate) {
        return defaultValue;
    }
    const date = new Date(originalDate);

    return moment(date).isValid()
        ? moment(date).format("ddd MMM DD YYYY h:mm A")
        : defaultValue; // eslint-disable-line moment-utc/no-moment-without-utc
};
