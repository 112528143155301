import React from "react";
import * as R from "ramda";
import {connect} from "react-redux";
import {createSelector} from "reselect";
//@ts-ignore
import {onRemovingGroupingWasPressed} from "../eventHandlers";
import ButtonWithIcon from "../../common/buttons/ButtonWithIcon";
import {Notifications} from "../types";

interface Common {
    notifications: Notifications;
    onFinishGrouping: () => void;
    rowId: string;
}

interface Props extends Common {
    onRemoveGroupingOfNotifications?: (query: any) => Promise<any>;
}

interface FunctionProps {
    groupId: string;
    notificationIds: string;
}

interface InjectedHooks {
    rowId: string;
    notifications: Notifications;
    onRemoveGroupingOfNotifications?: (query: any) => Promise<any>;
    onFinishGrouping: Function;
}

// eslint-disable-next-line import/prefer-default-export
export const onRemoveGroupingHandler = async (
    props: InjectedHooks,
): Promise<void> => {
    const {
        rowId,
        notifications,
        onRemoveGroupingOfNotifications,
        onFinishGrouping,
    } = props;

    const notificationIds = R.compose(
        R.join(","),
        //@ts-ignore
        R.pluck("_id"),
    )(notifications);
    //@ts-ignore
    await onRemoveGroupingOfNotifications({groupId: rowId, notificationIds});
    await onFinishGrouping();
};

function RemoveGroupButton({
    notifications,
    onFinishGrouping,
    rowId,
    onRemoveGroupingOfNotifications,
}: Props): JSX.Element {
    return (
        <ButtonWithIcon
            disabled={R.isEmpty(notifications)}
            iconName="object-ungroup"
            onClick={() =>
                onRemoveGroupingHandler({
                    rowId,
                    notifications,
                    onRemoveGroupingOfNotifications,
                    onFinishGrouping,
                })
            }
            size="sm"
            title="Ungroup"
            type="warning"
        />
    );
}

const dispatchSelector = createSelector(
    (dispatch: Function): Function => dispatch,
    (dispatch: Function): Object => ({
        onRemoveGroupingOfNotifications: ({
            groupId,
            notificationIds,
        }: FunctionProps) =>
            dispatch(onRemovingGroupingWasPressed({groupId, notificationIds})),
    }),
);

export default connect(null, dispatchSelector, null, {pure: true})(
    RemoveGroupButton,
);
