import * as R from "ramda";
import {getIn} from "../../common/ramdaDataUtils";

const MIN_VALID_LATITUDE = -90;
const MAX_VALID_LATITUDE = 90;
const MIN_VALID_LONGITUDE = -180;
const MAX_VALID_LONGITUDE = 180;
const MIN_VALID_DISTANCE = 1;

const isANumber = R.compose(
    R.complement(isNaN),
    R.curry(parseInt)(R.__, 10), // eslint-disable-line no-underscore-dangle
);

export const isValidLatitude = R.allPass([
    isANumber,
    R.gte(MAX_VALID_LATITUDE),
    R.lte(MIN_VALID_LATITUDE),
]);

export const isValidLongitude = R.allPass([
    isANumber,
    R.gte(MAX_VALID_LONGITUDE),
    R.lte(MIN_VALID_LONGITUDE),
]);

export const isValidMaxDistance = R.allPass([
    isANumber,
    R.lte(MIN_VALID_DISTANCE),
]);

export const isDone = (locationState: Object): boolean => {
    if (getIn(["wantSpecificLocation"], locationState) === false) {
        return true;
    }

    const validLatitude = isValidLatitude(
        getIn(["specificLocation", "latitude"], locationState),
    );

    const validLongitude = isValidLongitude(
        getIn(["specificLocation", "longitude"], locationState),
    );

    const validMaxDistance = isValidMaxDistance(
        getIn(["specificLocation", "maxDistanceInMeters"], locationState),
    );

    return validLatitude && validLongitude && validMaxDistance;
};

export const maxDistanceSelector = R.ifElse(
    isValidMaxDistance,
    // @ts-ignore
    R.curry(R.flip(parseInt))(10),
    R.always(1),
);
