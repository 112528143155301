export default <{[property: string]: any}>{
    AverageBasketSize: {
        dataAccess: "getAverageBasketSize",
        placeholderData: [],
    },
    AppDownloads: {
        dataAccess: "getAppMetrics",
        placeholderData: {
            apple: 0,
            googlePlay: 0,
        },
    },
    AppReviews: {
        dataAccess: "getAppReviews",
        placeholderData: [],
    },
    AudienceLandscape: {
        dataAccess: "getAudienceLandscape",
        placeholderData: {count: 0},
    },
    TotalActiveDevice: {
        dataAccess: "getTotalActiveOverNewDevices",
        placeholderData: {chartData: []},
    },
    TotalNumberOfOrders: {
        dataAccess: "getTotalNumberOfOrders",
        placeholderData: [],
    },
    TotalTransactionValue: {
        dataAccess: "getTotalAppTransactions",
        placeholderData: [],
    },
};
