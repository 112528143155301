import {Col, Row} from "react-bootstrap";
import DetailRow from "../../../widget/DetailRow";
import React, {memo} from "react";

const MESSAGE = "View message";
const MESSAGE_PATH = "#/message/";

interface Props {
    notificationSpecId: string;
}

export default memo(function NotificationActioned({
    notificationSpecId,
}: Props): JSX.Element {
    return (
        <Row>
            <hr />
            <Col md={8}>
                <DetailRow label="USERS WHO DID NOT ACTION EXISTING CAMPAIGN">
                    <a href={`${MESSAGE_PATH}${notificationSpecId}`}>
                        {MESSAGE}
                    </a>
                </DetailRow>
            </Col>
        </Row>
    );
});
