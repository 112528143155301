import {PlacementType} from "./types";

export const BANNER = "banner";
export const INTERSTITIAL = "interstitial";
export const INTERSTITIAL_ENTRANCE = "interstitialEntrance";
export const PRODUCT_LIST = "productList";
export const OFFERS_FOR_YOU = "offersForYou";
export const OFFERS_FOR_YOU_HEADER = "offersForYouHeader";
export const BASKET_PRODUCT_PLACEMENT = "basketProductPlacement";
export const TRENDING_PRODUCTS = "trendingProducts";
export const NEW_PRODUCTS = "newProducts";
export const RELATED_PRODUCTS = "relatedProducts";
export const HOME_BANNER_1 = "Home Banner 1";
export const HOME_BANNER_2 = "Home Banner 2";
export const HOME_BANNER_3 = "Home Banner 3";
export const SOFT_DRINKS_PRODUCTS = "softDrinksProducts";
export const CONFECTIONERY_PRODUCTS = "confectioneryProducts";
export const ALCOHOL_PRODUCTS = "alcoholProducts";
export const TOBACCO_PRODUCTS = "tobaccoProducts";
export const GROCERY_PRODUCTS = "groceryProducts";
export const HOUSEHOLD_PRODUCTS = "householdProducts";
export const BANNER_TYPE = "banner";
export const FULL_SCREEN_TYPE = "fullscreen";
export const PRODUCT_LIST_TYPE = "product_list";
export const FEATURED = "featured";

export const CATEGORY_BANNER_CONFECTIONERY = "categoryBannerConfectionery";
export const CATEGORY_BANNER_ENERGY_DRINKS = "categoryBannerEnergyDrinks";
export const CATEGORY_BANNER_GROCERY = "categoryBannerGrocery";
export const CATEGORY_BANNER_CRISPSSNACKS = "categoryBannerCrispsSnacks";
export const CATEGORY_BANNER_SOFTDRINKS = "categoryBannerSoftDrinks";
export const CATEGORY_BANNER_LICENSEDTRADE = "categoryBannerLicensedTrade";
export const CATEGORY_BANNER_SEASONAL = "categoryBannerSeasonal";
export const CATEGORY_BANNER_NONFOOD = "categoryBannerNonFood";
export const CATEGORY_BANNER_SMOKERSACCESSORIES =
    "categoryBannerSmokersAccessories";
export const CATEGORY_BANNER_TOILETRIES = "categoryBannerToiletries";
export const CATEGORY_BANNER_PROMOTIONS = "categoryBannerPromotions";
export const CATEGORY_BANNER_SCHOOLAPPROVED = "categoryBannerSchoolApproved";
export const CATEGORY_BANNER_TOBACCO = "categoryBannerTobacco";
export const CATEGORY_BANNER_ALCOHOL = "categoryBannerAlcohol";

export const CATEGORY_BANNER_COSMETICS = "categoryBannerCosmetics";
export const CATEGORY_BANNER_PERSONAL_CARE = "categoryBannerPersonalCare";
export const CATEGORY_BANNER_SNACKING = "categoryBannerSnacking";
export const CATEGORY_BANNER_CHILLED = "categoryBannerChilled";
export const CATEGORY_BANNER_HOUSEHOLD = "categoryBannerHousehold";
export const CATEGORY_BANNER_WATER = "categoryBannerWater";

export const CATEGORY_PRODUCTS_CONFECTIONERY = "categoryProductsConfectionery";
export const CATEGORY_PRODUCTS_ENERGY_DRINKS = "categoryProductsEnergyDrinks";
export const CATEGORY_PRODUCTS_GROCERY = "categoryProductsGrocery";
export const CATEGORY_PRODUCTS_CRISPSSNACKS = "categoryProductsCrispsSnacks";
export const CATEGORY_PRODUCTS_SOFTDRINKS = "categoryProductsSoftDrinks";
export const CATEGORY_PRODUCTS_LICENSEDTRADE = "categoryProductsLicensedTrade";
export const CATEGORY_PRODUCTS_SEASONAL = "categoryProductsSeasonal";
export const CATEGORY_PRODUCTS_NONFOOD = "categoryProductsNonFood";
export const CATEGORY_PRODUCTS_SMOKERSACCESSORIES =
    "categoryProductsSmokersAccessories";
export const CATEGORY_PRODUCTS_TOILETRIES = "categoryProductsToiletries";
export const CATEGORY_PRODUCTS_PROMOTIONS = "categoryProductsPromotions";
export const CATEGORY_PRODUCTS_SCHOOLAPPROVED =
    "categoryProductsSchoolApproved";
export const CATEGORY_PRODUCTS_TOBACCO = "categoryProductsTobacco";
export const CATEGORY_PRODUCTS_ALCOHOL = "categoryProductsAlcohol";

export const CATEGORY_PRODUCTS_COSMETICS = "categoryProductsCosmetics";
export const CATEGORY_PRODUCTS_PERSONAL_CARE = "categoryProductsPersonalCare";
export const CATEGORY_PRODUCTS_SNACKING = "categoryProductsSnacking";
export const CATEGORY_PRODUCTS_CHILLED = "categoryProductsChilled";
export const CATEGORY_PRODUCTS_HOUSEHOLD = "categoryProductsHousehold";
export const CATEGORY_PRODUCTS_WATER = "categoryProductsWater";

export const PLACEMENT_TYPE_MAP: {[key: string]: string} = Object.freeze({
    [BANNER]: BANNER_TYPE,
    [INTERSTITIAL]: FULL_SCREEN_TYPE,
    [INTERSTITIAL_ENTRANCE]: FULL_SCREEN_TYPE,
    [PRODUCT_LIST]: PRODUCT_LIST_TYPE,
    [OFFERS_FOR_YOU]: PRODUCT_LIST_TYPE,
    [BASKET_PRODUCT_PLACEMENT]: PRODUCT_LIST_TYPE,
    [TRENDING_PRODUCTS]: PRODUCT_LIST_TYPE,
    [NEW_PRODUCTS]: PRODUCT_LIST_TYPE,
    [SOFT_DRINKS_PRODUCTS]: PRODUCT_LIST_TYPE,
    [CONFECTIONERY_PRODUCTS]: PRODUCT_LIST_TYPE,
    [ALCOHOL_PRODUCTS]: PRODUCT_LIST_TYPE,
    [TOBACCO_PRODUCTS]: PRODUCT_LIST_TYPE,
    [GROCERY_PRODUCTS]: PRODUCT_LIST_TYPE,
    [HOUSEHOLD_PRODUCTS]: PRODUCT_LIST_TYPE,
    [HOME_BANNER_1]: BANNER_TYPE,
    [HOME_BANNER_2]: BANNER_TYPE,
    [HOME_BANNER_3]: BANNER_TYPE,
});

export const PRODUCT_LIST_PLACEMENTS = [
    PRODUCT_LIST,
    OFFERS_FOR_YOU,
    BASKET_PRODUCT_PLACEMENT,
    NEW_PRODUCTS,
    TRENDING_PRODUCTS,
    RELATED_PRODUCTS,
    SOFT_DRINKS_PRODUCTS,
    CONFECTIONERY_PRODUCTS,
    ALCOHOL_PRODUCTS,
    TOBACCO_PRODUCTS,
    GROCERY_PRODUCTS,
    HOUSEHOLD_PRODUCTS,
    CATEGORY_PRODUCTS_CONFECTIONERY,
    CATEGORY_PRODUCTS_ENERGY_DRINKS,
    CATEGORY_PRODUCTS_GROCERY,
    CATEGORY_PRODUCTS_CRISPSSNACKS,
    CATEGORY_PRODUCTS_SOFTDRINKS,
    CATEGORY_PRODUCTS_LICENSEDTRADE,
    CATEGORY_PRODUCTS_SEASONAL,
    CATEGORY_PRODUCTS_NONFOOD,
    CATEGORY_PRODUCTS_SMOKERSACCESSORIES,
    CATEGORY_PRODUCTS_TOILETRIES,
    CATEGORY_PRODUCTS_PROMOTIONS,
    CATEGORY_PRODUCTS_SCHOOLAPPROVED,
    CATEGORY_PRODUCTS_TOBACCO,
    CATEGORY_PRODUCTS_ALCOHOL,
    CATEGORY_PRODUCTS_COSMETICS,
    CATEGORY_PRODUCTS_PERSONAL_CARE,
    CATEGORY_PRODUCTS_SNACKING,
    CATEGORY_PRODUCTS_CHILLED,
    CATEGORY_PRODUCTS_HOUSEHOLD,
    CATEGORY_PRODUCTS_WATER,
];

export const BANNER_PLACEMENT_TYPES = [
    HOME_BANNER_1,
    HOME_BANNER_2,
    HOME_BANNER_3,
    BANNER,
    CATEGORY_BANNER_CONFECTIONERY,
    CATEGORY_BANNER_ENERGY_DRINKS,
    CATEGORY_BANNER_GROCERY,
    CATEGORY_BANNER_CRISPSSNACKS,
    CATEGORY_BANNER_SOFTDRINKS,
    CATEGORY_BANNER_LICENSEDTRADE,
    CATEGORY_BANNER_SEASONAL,
    CATEGORY_BANNER_NONFOOD,
    CATEGORY_BANNER_SMOKERSACCESSORIES,
    CATEGORY_BANNER_TOILETRIES,
    CATEGORY_BANNER_PROMOTIONS,
    CATEGORY_BANNER_SCHOOLAPPROVED,
    CATEGORY_BANNER_TOBACCO,
    CATEGORY_BANNER_ALCOHOL,
    CATEGORY_BANNER_COSMETICS,
    CATEGORY_BANNER_PERSONAL_CARE,
    CATEGORY_BANNER_SNACKING,
    CATEGORY_BANNER_CHILLED,
    CATEGORY_BANNER_HOUSEHOLD,
    CATEGORY_BANNER_WATER,
];

export const INTERSTITIAL_PLACEMENT_TYPES = [
    INTERSTITIAL,
    INTERSTITIAL_ENTRANCE,
];

export const CATEGORY_BANNER_ADVERTS: Array<PlacementType> = [
    CATEGORY_BANNER_CONFECTIONERY,
    CATEGORY_BANNER_ENERGY_DRINKS,
    CATEGORY_BANNER_GROCERY,
    CATEGORY_BANNER_CRISPSSNACKS,
    CATEGORY_BANNER_SOFTDRINKS,
    CATEGORY_BANNER_LICENSEDTRADE,
    CATEGORY_BANNER_SEASONAL,
    CATEGORY_BANNER_NONFOOD,
    CATEGORY_BANNER_SMOKERSACCESSORIES,
    CATEGORY_BANNER_TOILETRIES,
    CATEGORY_BANNER_PROMOTIONS,
    CATEGORY_BANNER_SCHOOLAPPROVED,
    CATEGORY_BANNER_TOBACCO,
    CATEGORY_BANNER_ALCOHOL,
    CATEGORY_BANNER_COSMETICS,
    CATEGORY_BANNER_PERSONAL_CARE,
    CATEGORY_BANNER_SNACKING,
    CATEGORY_BANNER_CHILLED,
    CATEGORY_BANNER_HOUSEHOLD,
    CATEGORY_BANNER_WATER,
];

export const CATEGORY_PRODUCT_ADVERTRS: Array<PlacementType> = [
    CATEGORY_PRODUCTS_CONFECTIONERY,
    CATEGORY_PRODUCTS_ENERGY_DRINKS,
    CATEGORY_PRODUCTS_GROCERY,
    CATEGORY_PRODUCTS_CRISPSSNACKS,
    CATEGORY_PRODUCTS_SOFTDRINKS,
    CATEGORY_PRODUCTS_LICENSEDTRADE,
    CATEGORY_PRODUCTS_SEASONAL,
    CATEGORY_PRODUCTS_NONFOOD,
    CATEGORY_PRODUCTS_SMOKERSACCESSORIES,
    CATEGORY_PRODUCTS_TOILETRIES,
    CATEGORY_PRODUCTS_PROMOTIONS,
    CATEGORY_PRODUCTS_SCHOOLAPPROVED,
    CATEGORY_PRODUCTS_TOBACCO,
    CATEGORY_PRODUCTS_ALCOHOL,
    CATEGORY_PRODUCTS_COSMETICS,
    CATEGORY_PRODUCTS_PERSONAL_CARE,
    CATEGORY_PRODUCTS_SNACKING,
    CATEGORY_PRODUCTS_CHILLED,
    CATEGORY_PRODUCTS_HOUSEHOLD,
    CATEGORY_PRODUCTS_WATER,
];
