import * as R from "ramda";
import sortList from "../../common/sortList";
import {ChartValue} from "./types";

const SortDescend = sortList("label", "descend");
const SortAscend = sortList("label", "ascend");

export default (
    simpleChartData: Array<ChartValue>,
    chartRange: number = 7,
): Array<ChartValue> => {
    const formatItems = (item: Array<{label: string; value: string}>) => [
        {
            label: item[1].label,
            value: item[1].value,
        },
        {
            label: item[0].label,
            value: item[0].value,
        },
    ];
    const format = (item: Array<{label: string; value: string}>) => ({
        label: item[0].label,
        value: formatItems(item),
    });

    const dataToBeTaken = R.length(simpleChartData) - chartRange * 2;

    const getLastNDates = R.compose(
        R.dropLast(dataToBeTaken),
        // @ts-ignore
        SortDescend,
    );

    const groupDateByPreviousAndCurrent = R.compose(
        // @ts-ignore
        R.transpose,
        R.splitAt(chartRange),
    );

    const formatDateValues = R.compose(SortAscend, R.map(format));

    return R.compose(
        formatDateValues,
        groupDateByPreviousAndCurrent,
        getLastNDates,
        // @ts-ignore
    )(simpleChartData);
};
