import Circle from "../widget/map/Circle";
import React, {memo} from "react";
import SimpleMap from "../widget/Map";

const MapDefaults = Object.freeze({
    radius: "1000",
    zoom: 13,
    height: 400,
});
interface Props {
    handleRadiusChange?: Function;
    handleCenterChange?: Function;
    radius: number;
    markers: Array<any>;
    editable?: boolean;
}
export default memo(function MapWithCircle({
    handleRadiusChange,
    handleCenterChange,
    radius,
    markers,
    editable,
}: Props): JSX.Element {
    return (
        <SimpleMap
            center={markers[0].position}
            defaultZoom={MapDefaults.zoom}
            // @ts-ignore
            height={MapDefaults.height}
            markers={markers}
        >
            <Circle
                center={markers[0].position}
                editable={editable}
                onCenterChanged={handleCenterChange}
                onRadiusChanged={handleRadiusChange}
                radius={radius}
            />
        </SimpleMap>
    );
});
