import {FormControl, FormGroup, HelpBlock, Well} from "react-bootstrap";
import {getIn} from "../../common/ramdaDataUtils";
import {inject, observer} from "mobx-react";
import {linkActions} from "../PushNotification";
import Checkbox from "../../widget/Checkbox";
import DropDownList from "react-widgets/lib/DropdownList";
import getImageValidationState from "../../common/getImageValidationState";
import InputRow from "../../widget/InputRow";
import React, {Component, memo} from "react";
import RichNotificationContainer from "../../visualisation/RichNotificationContainer";
import ProductSkuGuidelines from "../../common/ProductSkuGuidelines";
import PushNotificationRepository from "../PushNotificationRepository";

const styles = Object.freeze({
    container: {
        display: "flex",
    },
    inputContainer: {
        flex: 1,
    },
    visualisationContainer: {
        paddingLeft: "15px",
    },
    guidelines: {
        marginTop: 10,
        fontSize: 12,
    },
});

interface RichPushNotificationFormProps {
    richPushNotifications: any;
    onSetRichPushNotifications: (notificationType: any, value: any) => void;
    richPushNotificationErrors: any;
    enableProductUrl: boolean;
    enableProductDeeplink: boolean;
    enableProductDeeplinkWithoutLists: boolean;
    enableProductDetailsDeeplink: boolean;
}

const getInputValidationState = (inputError: boolean) =>
    inputError ? "error" : null;
const isLinkActionVisitWebsite = (
    richPushNotifications: any,
    linkActions: any,
): boolean =>
    getIn(["linkAction", "value"], richPushNotifications) ===
    linkActions.VISIT_WEBSITE;

const getSelections = (
    linkActions: any,
    enableProductDeeplink: boolean,
    enableProductDeeplinkWithoutLists: boolean,
    enableProductDetailsDeeplink: boolean,
) => {
    const selection = Object.keys(linkActions).map((key) => linkActions[key]);

    if (enableProductDeeplink) {
        return selection;
    }

    if (enableProductDeeplinkWithoutLists) {
        return [
            linkActions.NONE,
            linkActions.VISIT_WEBSITE,
            linkActions.VIEW_PRODUCT_DETAILS,
            linkActions.VIEW_PRODUCT_LIST,
            linkActions.ADD_PRODUCT_TO_BASKET,
        ];
    }

    if (enableProductDetailsDeeplink) {
        return [
            linkActions.NONE,
            linkActions.VISIT_WEBSITE,
            linkActions.VIEW_PRODUCT_DETAILS,
        ];
    }

    return [linkActions.NONE, linkActions.VISIT_WEBSITE];
};

const UrlOrSkuInput = ({
    richPushNotifications,
    onSetRichPushNotifications,
    isUrl,
    errors,
}: {
    richPushNotifications: any;
    onSetRichPushNotifications: (notificationType: any, value: any) => void;
    isUrl: boolean;
    errors: any;
}) => {
    // const {richPushNotifications, onSetRichPushNotifications} = this.props.pushNotificationRepository

    const fieldProps = isUrl
        ? {
              label: "WEBSITE URL:",
              fieldName: "buttonUrl",
              placeholder: "e.g. any website URL",
              validationMessage: "Please enter a valid URL",
          }
        : {
              label: "PRODUCT SKU:",
              fieldName: "productSku",
              placeholder: "e.g 101-1",
              validationMessage: "This field is required",
          };

    const {label, fieldName, placeholder, validationMessage} = fieldProps;

    return (
        <InputRow label={label}>
            <FormGroup
                validationState={getInputValidationState(errors[fieldName])}
            >
                <FormControl
                    onChange={(event: any): any => {
                        const filteredValue = event.target.value.replace(
                            /\s/g,
                            "",
                        );

                        onSetRichPushNotifications(
                            [fieldName, "value"],
                            filteredValue,
                        );
                    }}
                    placeholder={placeholder}
                    type="text"
                    value={getIn([fieldName, "value"], richPushNotifications)}
                />
                <HelpBlock>{validationMessage}</HelpBlock>
            </FormGroup>
        </InputRow>
    );
};

const onSelectImage = async (onSetRichPushNotifications: any, event: any) => {
    const imageFile: any = event.target.files[0];

    const {errorMessage, isError, dimension} = await getImageValidationState({
        image: imageFile,
        maxWidth: 600,
        maxHeight: 600,
        imageDimensionsLimitEnabled: true,
    });

    if (isError) {
        onSetRichPushNotifications(["imageFile"], {
            error: true,
            changed: false,
            errorMessage,
            value: "",
            dimension: {width: 0, height: 0},
        });

        return;
    }

    onSetRichPushNotifications(["imageFile"], {
        value: imageFile,
        error: false,
        changed: true,
        dimension,
    });
};

const ImageGuideLines = () => {
    return (
        <Well>
            <b>Image Upload Guidelines:</b>
            <ul style={styles.guidelines}>
                <li>Images should be in PNG, JPG, or GIF format</li>
                <li>Images should not exceed the size of 2mb</li>
                <li>Images should ideally be and not exceed 600x600 pixels</li>
            </ul>
        </Well>
    );
};

const FbNotificationForm = ({
    richPushNotifications,
    onSetRichPushNotifications,
    richPushNotificationErrors,
    enableProductUrl,
    enableProductDeeplink,
    enableProductDeeplinkWithoutLists,
    enableProductDetailsDeeplink,
}: RichPushNotificationFormProps) => {
    const enableButtonText =
        richPushNotifications.linkAction.value !== linkActions.NONE;
    const isUrl = enableProductUrl
        ? isLinkActionVisitWebsite(richPushNotifications, linkActions)
        : true;

    const deepLinkingActions = getSelections(
        linkActions,
        enableProductDeeplink,
        enableProductDeeplinkWithoutLists,
        enableProductDetailsDeeplink,
    );

    return (
        <div style={styles.container}>
            <div style={styles.inputContainer}>
                <form>
                    <ImageGuideLines />
                    <InputRow label="IMAGE:">
                        <FormGroup
                            validationState={getInputValidationState(
                                getIn(
                                    ["imageFile", "error"],
                                    richPushNotifications,
                                ),
                            )}
                        >
                            <FormControl
                                onChange={(event) =>
                                    onSelectImage(
                                        onSetRichPushNotifications,
                                        event,
                                    )
                                }
                                type="file"
                            />
                            <HelpBlock>
                                {" "}
                                {getIn(
                                    ["imageFile", "errorMessage"],
                                    richPushNotifications,
                                )}
                            </HelpBlock>
                        </FormGroup>
                    </InputRow>
                    <InputRow label="MESSAGE BODY:">
                        <FormGroup
                            validationState={getInputValidationState(
                                richPushNotificationErrors.body,
                            )}
                        >
                            <FormControl
                                onChange={(event: any): any =>
                                    onSetRichPushNotifications(
                                        ["body", "value"],
                                        event.target.value,
                                    )
                                }
                                placeholder='e.g. "Check out these great offers!"'
                                type="text"
                                value={getIn(
                                    ["body", "value"],
                                    richPushNotifications,
                                )}
                            />
                            <HelpBlock>This field is required</HelpBlock>
                        </FormGroup>
                    </InputRow>
                    <InputRow label="DISMISS BUTTON TEXT:">
                        <FormGroup
                            validationState={getInputValidationState(
                                richPushNotificationErrors.dismissButtonText,
                            )}
                        >
                            <FormControl
                                onChange={(event: any): any =>
                                    onSetRichPushNotifications(
                                        ["dismissButtonText", "value"],
                                        event.target.value,
                                    )
                                }
                                placeholder='e.g. "No thanks!"'
                                type="text"
                                value={getIn(
                                    ["dismissButtonText", "value"],
                                    richPushNotifications,
                                )}
                            />
                            <HelpBlock>This field is required</HelpBlock>
                        </FormGroup>
                    </InputRow>
                    {enableProductUrl && (
                        <div>
                            <ProductSkuGuidelines
                                actionsList={deepLinkingActions}
                            />
                            <InputRow label="PRIMARY BUTTON ACTION:">
                                <DropDownList
                                    data={deepLinkingActions}
                                    onChange={(value): any =>
                                        onSetRichPushNotifications(
                                            ["linkAction", "value"],
                                            value,
                                        )
                                    }
                                    textField="text"
                                    value={getIn(
                                        ["linkAction", "value"],
                                        richPushNotifications,
                                    )}
                                    valueField="value"
                                />
                            </InputRow>
                        </div>
                    )}
                    {enableButtonText && (
                        <UrlOrSkuInput
                            isUrl={isUrl}
                            errors={richPushNotificationErrors}
                            richPushNotifications={richPushNotifications}
                            onSetRichPushNotifications={
                                onSetRichPushNotifications
                            }
                        />
                    )}
                    {enableButtonText && (
                        <InputRow label="PRIMARY BUTTON TEXT:">
                            <FormGroup
                                validationState={getInputValidationState(
                                    richPushNotificationErrors.buttonText,
                                )}
                            >
                                <FormControl
                                    onChange={(event: any): any =>
                                        onSetRichPushNotifications(
                                            ["buttonText", "value"],
                                            event.target.value,
                                        )
                                    }
                                    placeholder='e.g. "View Details"'
                                    type="text"
                                    value={getIn(
                                        ["buttonText", "value"],
                                        richPushNotifications,
                                    )}
                                />
                                <HelpBlock>This field is required</HelpBlock>
                            </FormGroup>
                        </InputRow>
                    )}
                </form>
            </div>
            <div style={styles.visualisationContainer}>
                <RichNotificationContainer
                    action1={getIn(
                        ["dismissButtonText", "value"],
                        richPushNotifications,
                    )}
                    action2={
                        enableButtonText &&
                        getIn(["buttonText", "value"], richPushNotifications)
                    }
                    bodyText={getIn(["body", "value"], richPushNotifications)}
                    image={getIn(["image", "value"], richPushNotifications)}
                    imageFile={getIn(
                        ["imageFile", "value"],
                        richPushNotifications,
                    )}
                />
            </div>
        </div>
    );
};

const RichPushNotificationForm = memo(function ({
    richPushNotifications,
    onSetRichPushNotifications,
    richPushNotificationErrors,
    enableProductUrl,
    enableProductDeeplink,
    enableProductDeeplinkWithoutLists,
    enableProductDetailsDeeplink,
}: RichPushNotificationFormProps) {
    return (
        <div>
            <FormGroup>
                <Checkbox
                    checked={richPushNotifications.wantFbNotification}
                    onChecked={(value) =>
                        onSetRichPushNotifications(
                            ["wantFbNotification"],
                            value,
                        )
                    }
                >
                    <div>Send rich content?</div>
                </Checkbox>
            </FormGroup>

            {richPushNotifications.wantFbNotification && (
                <FbNotificationForm
                    richPushNotifications={richPushNotifications}
                    onSetRichPushNotifications={onSetRichPushNotifications}
                    richPushNotificationErrors={richPushNotificationErrors}
                    enableProductUrl={enableProductUrl}
                    enableProductDeeplink={enableProductDeeplink}
                    enableProductDeeplinkWithoutLists={
                        enableProductDeeplinkWithoutLists
                    }
                    enableProductDetailsDeeplink={enableProductDetailsDeeplink}
                />
            )}
        </div>
    );
});

interface RichPushNotificationFormContainerProps {
    pushNotificationRepository?: PushNotificationRepository;
    enableProductUrl: boolean;
    enableProductDeeplink: boolean;
    enableProductDeeplinkWithoutLists: boolean;
    enableProductDetailsDeeplink: boolean;
}

@inject("pushNotificationRepository")
@observer
class RichPushNotificationFormContainer extends Component<
    RichPushNotificationFormContainerProps
> {
    render() {
        const {
            richPushNotifications,
            onSetRichPushNotifications,
            richPushNotificationErrors,
        } = this.props.pushNotificationRepository!;
        const {
            enableProductUrl,
            enableProductDeeplink,
            enableProductDeeplinkWithoutLists,
            enableProductDetailsDeeplink,
        } = this.props;

        return (
            <RichPushNotificationForm
                richPushNotifications={richPushNotifications}
                onSetRichPushNotifications={onSetRichPushNotifications}
                richPushNotificationErrors={richPushNotificationErrors}
                enableProductUrl={enableProductUrl}
                enableProductDeeplink={enableProductDeeplink}
                enableProductDeeplinkWithoutLists={
                    enableProductDeeplinkWithoutLists
                }
                enableProductDetailsDeeplink={enableProductDetailsDeeplink}
            />
        );
    }
}

export default RichPushNotificationFormContainer;
