import * as R from "ramda";

export default R.curry(
    (hasMessageGroupingFeature: boolean, messages: any): boolean => {
        if (hasMessageGroupingFeature) {
            return R.isEmpty(messages);
        }

        return messages.isEmpty();
    },
);
