import {Button, Collapse} from "react-bootstrap";
import {states, statesType} from "../style/BootstrapConstants";
import Badge from "./Badge";
import Box from "./Box";
import React, {memo} from "react";

const getBadgeState = (
    open?: boolean,
    done?: boolean,
    error?: boolean,
): string => {
    switch (true) {
        case open:
            return states.info;
        case error:
            return states.danger;
        case done:
            return states.success;
        default:
            return states.default;
    }
};

const getBadgeIcon = (
    open: boolean,
    badgeTitle: statesType,
    done: boolean,
    error: boolean,
): string | JSX.Element => {
    switch (true) {
        case open:
            return badgeTitle;
        case error:
            return "✗";
        case done:
            return "✔";
        default:
            return badgeTitle;
    }
};

interface Props {
    badgeTitle?: any;
    buttons?: Array<any>;
    children?: Array<JSX.Element> | JSX.Element | string | number | any;
    done?: boolean;
    error?: boolean;
    isLastStep?: boolean;
    onHeaderClick?: Function;
    onNextButtonClick?: Function;
    open: boolean;
    stepTitle: string;
}

export default memo(function StepContainer({
    badgeTitle,
    children,
    done = false,
    error = false,
    isLastStep,
    onNextButtonClick,
    onHeaderClick,
    open,
    stepTitle,
    buttons = [],
}: Props): JSX.Element {
    const badgeIcon = getBadgeIcon(open, badgeTitle, done, error);

    const badgeColorVariation = getBadgeState(open, done, error);
    const badgePositionInBoxTitleBugfixStyle = {verticalAlign: "top"};

    const localTitle = (
        <span>
            <Badge
                state={badgeColorVariation}
                style={badgePositionInBoxTitleBugfixStyle}
            >
                {badgeIcon}
            </Badge>{" "}
            {stepTitle}
        </span>
    );

    const styleState = open ? "primary" : "default";

    const doneButton = onNextButtonClick && (
        <Button
            bsStyle="info"
            onClick={(event) => {
                event.stopPropagation();
                onNextButtonClick();
            }}
        >
            <strong>Next</strong>
        </Button>
    );

    const boxToolsBtn = buttons.concat([doneButton]);

    const boxToolsBtns = open ? boxToolsBtn : [];

    const contentInCollapseStyle = {padding: 10};

    return (
        <Box
            buttons={isLastStep ? buttons : boxToolsBtns}
            onHeaderClick={onHeaderClick}
            removeBodyPadding
            removeHeaderBorder
            solid
            styleState={styleState}
            title={localTitle}
        >
            <Collapse in={open}>
                <div>
                    <div style={contentInCollapseStyle}>{children}</div>
                </div>
            </Collapse>
        </Box>
    );
});
