import {ADVERT_TITLES} from "./advertTitles";
import TEAM_NUMBERS, {BCP_TEAMS} from "../teamNumbers";

const DEVELOPMENT_ADVERT_TITLES = {
    ...ADVERT_TITLES,
};

const YACHTBUOY_ADVERT_TITLES = {
    ...ADVERT_TITLES,
};

const CHEF_CENTRAL_ADVERT_TITLES = {
    ...ADVERT_TITLES,
};

const SINGHS_ADVERT_TITLES = {
    ...ADVERT_TITLES,
};

const WHARFDALE_ADVERT_TITLES = {
    ...ADVERT_TITLES,
};

const SANDERSON_ADVERT_TITLES = {
    ...ADVERT_TITLES,
};

const BLAKEMORE_WHOLESALE_ADVERT_TITLES = {
    ...ADVERT_TITLES,
};

const BXR_ADVERT_TITLES = {
    ...ADVERT_TITLES,
};

const ABRA_ADVERT_TITLES = {
    ...ADVERT_TITLES,
    productList: "Exclusive Deals Products Placement",
    newProducts: "New Products Placement",
    offersForYou: "Featured Products Placement",
};

const BESTWAY_ADVERT_TITLES = {
    ...ADVERT_TITLES,
};

const SANDERSON_APP_ENTRY_ADVERT_TITLES = {
    ...ADVERT_TITLES,
};

const PEACH_ADVERT_TITLES = {
    ...ADVERT_TITLES,
};

const MUMMY_SOCIAL_ADVERT_TITLES = {
    ...ADVERT_TITLES,
};

const CONFEX_TITLES = {
    ...ADVERT_TITLES,
    productList: "Exclusive Deals Products List",
    banner: "Banner Ads",
    trendingProducts: "Recommended Products Carousel",
    offersForYou: "Featured Products Carousel",
};

const SALES_DEMO_TITLE = {
    ...ADVERT_TITLES,
    interstitialAdsEntrance: "Beacon Full Screen Ads",
    interstitialAdsEntranceArchived: "Archived Beacon Full Screen Ads",
};

const B2B_STORE_ADVERT_TITLES = {
    ...ADVERT_TITLES,
    offersForYou: "Home Screen Featured Products Carousel",
    basketProductPlacement: "Shopping Basket Featured Products Carousel",
};

const CONFIGURATION = Object.freeze({
    [TEAM_NUMBERS.development]: DEVELOPMENT_ADVERT_TITLES,
    [TEAM_NUMBERS.rnfdigital]: ADVERT_TITLES,
    [TEAM_NUMBERS.bestway]: BESTWAY_ADVERT_TITLES,
    [TEAM_NUMBERS.chefcentral]: CHEF_CENTRAL_ADVERT_TITLES,
    [TEAM_NUMBERS.wholesaleDemoAll]: SALES_DEMO_TITLE,
    [TEAM_NUMBERS.wholesaleDemo]: SALES_DEMO_TITLE,
    [TEAM_NUMBERS.singhs]: SINGHS_ADVERT_TITLES,
    [TEAM_NUMBERS.wharfedale]: WHARFDALE_ADVERT_TITLES,
    [TEAM_NUMBERS.yachtbuoy]: YACHTBUOY_ADVERT_TITLES,
    [TEAM_NUMBERS.sanderson]: SANDERSON_ADVERT_TITLES,
    [TEAM_NUMBERS.blakemoreWholesale]: BLAKEMORE_WHOLESALE_ADVERT_TITLES,
    [TEAM_NUMBERS.bxr]: BXR_ADVERT_TITLES,
    [TEAM_NUMBERS.abra]: ABRA_ADVERT_TITLES,
    [TEAM_NUMBERS.bestwayFoodservice]: BESTWAY_ADVERT_TITLES,
    [TEAM_NUMBERS.bestPets]: BESTWAY_ADVERT_TITLES,
    [TEAM_NUMBERS.blakemoreTradePartners]: ADVERT_TITLES,
    [TEAM_NUMBERS.filshill]: SANDERSON_APP_ENTRY_ADVERT_TITLES,
    [TEAM_NUMBERS.peach]: PEACH_ADVERT_TITLES,
    [TEAM_NUMBERS.thames]: SANDERSON_APP_ENTRY_ADVERT_TITLES,
    [TEAM_NUMBERS.threePears]: SANDERSON_APP_ENTRY_ADVERT_TITLES,
    [TEAM_NUMBERS.kitwave]: SANDERSON_APP_ENTRY_ADVERT_TITLES,
    [TEAM_NUMBERS.hbClark]: SANDERSON_APP_ENTRY_ADVERT_TITLES,
    [TEAM_NUMBERS.mjBaker]: SANDERSON_APP_ENTRY_ADVERT_TITLES,
    [TEAM_NUMBERS.mummysocial]: MUMMY_SOCIAL_ADVERT_TITLES,
    [TEAM_NUMBERS.confex]: CONFEX_TITLES,
    [TEAM_NUMBERS.htDrinks]: SANDERSON_APP_ENTRY_ADVERT_TITLES,
    [TEAM_NUMBERS.giro]: SANDERSON_APP_ENTRY_ADVERT_TITLES,
    [TEAM_NUMBERS.eastAndWest]: SANDERSON_APP_ENTRY_ADVERT_TITLES,
    [TEAM_NUMBERS.iainHill]: SANDERSON_APP_ENTRY_ADVERT_TITLES,
    [TEAM_NUMBERS.woodsFoodservice]: SANDERSON_APP_ENTRY_ADVERT_TITLES,
    [TEAM_NUMBERS.chappleAndJenkinsSanderson]: SANDERSON_APP_ENTRY_ADVERT_TITLES,
    [TEAM_NUMBERS.oreillys]: SANDERSON_APP_ENTRY_ADVERT_TITLES,
});

export default (teamNumber: number): Object => {
    if (BCP_TEAMS.includes(teamNumber)) {
        return B2B_STORE_ADVERT_TITLES;
    } else {
        return CONFIGURATION[teamNumber] || ADVERT_TITLES;
    }
};
