/*  */

import * as R from "ramda";
import APIClient from "@beam/api-client";
import base64 from "base-64";
import URI from "urijs";
import UserRepository from "../main/UserRepository";
import {loadUser} from "../main/repository";

const BASE_URL = "/v1/";
const METHODS_WITH_URL_PARAMS = ["GET", "DELETE"];
const THROW_RESPONSES = [404, 400];

const WITH_BODY_OPTION = ["POST", "PUT"];

export default class BeamAPIClient extends APIClient {
    constructor(userRepository) {
        super();
        this.userRepository = userRepository;
    }

    getBodyOption(method, contentType, requestParams) {
        if (contentType !== "multipart/form-data") {
            return WITH_BODY_OPTION.includes(method)
                ? {body: JSON.stringify(requestParams)}
                : {};
        }

        const formData = new FormData();

        R.mapObjIndexed((item, key) => {
            formData.append(key, item);
        }, requestParams);

        return {body: formData};
    }

    // eslint-disable-next-line complexity
    async invokeNetworkRequest(
        method,
        path,
        parameters,
        contentType = "application/json",
    ) {
        const userData = loadUser();
        const activeTeam = userData.activeTeam || {};
        const {emailAddress, password} = userData || {};

        const requestParams = {
            ...parameters,
            teamNumber: activeTeam.teamNumber,
        };
        const isUrlWithParams = METHODS_WITH_URL_PARAMS.includes(method);

        const baseURLWithPath = `${BASE_URL}${path}`;
        const url = isUrlWithParams
            ? URI(baseURLWithPath).query(requestParams).toString()
            : `${BASE_URL}${path}`;

        const contentTypeHeader =
            contentType === "multipart/form-data"
                ? {}
                : {"Content-Type": contentType};

        const result = await this.fetch(url, {
            method,
            headers: {
                ...contentTypeHeader,
                Authorization: `Basic ${base64.encode(
                    `${emailAddress}:${password}`,
                )}`,
            },
            ...this.getBodyOption(method, contentType, requestParams),
        });

        if (THROW_RESPONSES.includes(result.status)) {
            throw new Error(`Network request error ${result.status}`);
        }

        return result;
    }

    invokeGetEndpoint = async (path, parameters) => {
        const result = await this.invokeNetworkRequest("GET", path, parameters);

        return result.json();
    };

    async invokeDeleteEndpoint(path, parameters) {
        await this.invokeNetworkRequest("DELETE", path, parameters);

        return {success: true};
    }

    async invokePostEndpoint(path, parameters) {
        const response = await this.invokeNetworkRequest(
            "POST",
            path,
            parameters,
        );

        const result = await response.json();

        return result;
    }

    async invokePostWithFormData(path, parameters) {
        const response = await this.invokeNetworkRequest(
            "POST",
            path,
            parameters,
            "multipart/form-data",
        );

        const result = await response.json();

        return result;
    }

    async invokePutEndpoint(path, parameters) {
        const response = await this.invokeNetworkRequest(
            "PUT",
            path,
            parameters,
        );

        const result = await response.json();

        return result;
    }
}
