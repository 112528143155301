import React, {useEffect, useState} from "react";
import {useCallback} from "react";
import {useMemo} from "react";
import useAlert from "../alert/useAlert";
import useApiClient from "../apiClient/useApiClient";
import useFeatureFlag from "../featureFlags/useFeatureFlag";
import CategoryAdverts from "./CategoryAdverts";
import {
    CATEGORY_BANNER_ADVERTS,
    CATEGORY_PRODUCT_ADVERTRS,
} from "./PlacementConstants";
import {promiseAlert} from "promise-alert";
import {PlacementType} from "./types";

export default function CategoryAdvertsContainer() {
    const {featureFlags} = useFeatureFlag();

    const categoryBannerAdvertsEnabled = useMemo(() => {
        return CATEGORY_BANNER_ADVERTS.filter((item) => {
            return Boolean(featureFlags[item]);
        }).sort();
    }, [featureFlags]);

    const categoryProductAdvertsEnabled = useMemo(() => {
        return CATEGORY_PRODUCT_ADVERTRS.filter((item) => {
            return Boolean(featureFlags[item]);
        }).sort();
    }, [featureFlags]);

    const {getRequest, deleteRequest, putRequest} = useApiClient();
    const {addErrorAlert, addSuccessAlert} = useAlert();
    const [allCategoryAdverts, setAllCategoryAdverts] = useState([]);
    const onFetchAdverts = useCallback(async () => {
        try {
            const response = await getRequest("placements", {
                placement: "allCategoryAdverts",
                includeSeenAndClickCounts: true,
            });
            setAllCategoryAdverts(response);
        } catch {
            addErrorAlert(
                "Something went wrong while fetching Category Adverts. Please try again.",
            );
        }
    }, [setAllCategoryAdverts, addErrorAlert]);

    useEffect(() => {
        onFetchAdverts();
    }, []);

    const confirmAdvertDelete = useCallback(
        async (id: string) => {
            try {
                await deleteRequest(`placements`, {id});
                addSuccessAlert("Successfully archived placement");
                onFetchAdverts();
            } catch (e) {
                addErrorAlert(
                    "An error occured while trying to archive placement. Please try again.",
                );
            }
        },
        [onFetchAdverts, addErrorAlert, addSuccessAlert, deleteRequest],
    );

    const onDeleteAdvertPressed = useCallback(
        (id: string) => {
            return promiseAlert({
                title: `Archive Ad Placement`,
                text: `Are you sure that you want to archive the selected ad placement?`,
                type: "warning",
                showCancelButton: true,
            }).then((confirmed: boolean) => {
                if (confirmed) {
                    return confirmAdvertDelete(id);
                }

                return undefined;
            });
        },
        [confirmAdvertDelete],
    );

    const onUpdatePlacements = useCallback(
        async (placements: any) => {
            await putRequest("placements", {placements});
        },
        [putRequest],
    );

    const confirmMultiAdvertDelete = useCallback(
        async (ids: Array<string>) => {
            try {
                await deleteRequest("placements", {id: ids[0]}, {ids});
                addSuccessAlert("Successfully archived placement");
                onFetchAdverts();
            } catch (e) {
                addErrorAlert(
                    "An error occured while trying to archive placement. Please try again.",
                );
            }
        },
        [deleteRequest, addSuccessAlert, onFetchAdverts, addErrorAlert],
    );
    const onMultiDeleteAdvertPresed = useCallback(
        (ids: Array<string>) => {
            return promiseAlert({
                title: `Archive Ad Placement`,
                text: `Are you sure that you want to archive the selected ad placement?`,
                type: "warning",
                showCancelButton: true,
            }).then((confirmed: boolean) => {
                if (confirmed) {
                    return confirmMultiAdvertDelete(ids);
                }

                return undefined;
            });
        },
        [confirmMultiAdvertDelete],
    );

    const groupedCategoryAdvertsEnabled = useMemo(() => {
        let finalData: Array<PlacementType> = [];

        categoryBannerAdvertsEnabled.forEach((catBannerItem) => {
            finalData.push(catBannerItem);
            const foundEquivalentProductAdvert = categoryProductAdvertsEnabled.find(
                (catProductItem) =>
                    catProductItem.split("categoryProducts")[1] ===
                    catBannerItem.split("categoryBanner")[1],
            );

            if (foundEquivalentProductAdvert) {
                finalData.push(foundEquivalentProductAdvert);
            }
        });

        categoryProductAdvertsEnabled.forEach((catProductItem) => {
            const itemExists = finalData.find(
                (item) => catProductItem === item,
            );
            if (itemExists) {
                return;
            }

            finalData.push(catProductItem);
            const foundEquivalentBannerADvert = categoryBannerAdvertsEnabled.find(
                (catBannerItem) =>
                    catBannerItem.split("categoryBanner")[1] ===
                    catProductItem.split("categoryProducts")[1],
            );

            if (foundEquivalentBannerADvert) {
                finalData.push(foundEquivalentBannerADvert);
            }
        });
        return finalData;
    }, [categoryBannerAdvertsEnabled, categoryProductAdvertsEnabled]);

    return (
        <CategoryAdverts
            categoryAdvertsEnabled={groupedCategoryAdvertsEnabled}
            allCategoryAdverts={allCategoryAdverts}
            onUpdatePlacements={onUpdatePlacements}
            onDeleteAdvertPressed={onDeleteAdvertPressed}
            onMultiDeleteAdvertPresed={onMultiDeleteAdvertPresed}
        />
    );
}
